import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  useTheme,
  Typography,
  Button,
  Alert,
  AlertTitle,
  TextField,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { chessterms } from "../../../data/chessterms/chessterms"; // Assuming this is your data source
import HelpModal from "../../../components/HelpModal";

import WarningIcon from "@mui/icons-material/Warning";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import processCrossword from "../../../utils/crosswordGenerator";
import CrosswordHelp from "../../../help/CrosswordHelp";

import { trackEvent } from "../../../config/ga";
import { useUser } from "../../../context/UserContext";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";

import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function ChessCrossword() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [crosswordData, setCrosswordData] = useState(null); // State to store crossword data
  const [selectedTerms, setSelectedTerms] = useState([]); // State to store selected terms
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [userInput, setUserInput] = useState({}); // State to store user's input
  const [completed, setCompleted] = useState(false); // State to track if the crossword is completed
  const [showIncorrectAlert, setShowIncorrectAlert] = useState(false); // State to show incorrect alert
  const [quitPrompt, setQuitPrompt] = useState(false); // State to show quit prompt
  const [quitCompleted, setQuitCompleted] = useState(false); // State to track if the crossword was quit
  const [gameStarted, setGameStarted] = useState(false); // State to track if the game has started

  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");

  // Utility function to shuffle an array
  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/crossword",
      title: "Chessboard Magic - Crossword",
      description:
        "Solve chess-themed crosswords and improve your chess knowledge while having fun!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to select a specified number of words based on length criteria
  const selectWordsByLength = useCallback((terms, count, minLen, maxLen) => {
    const filteredTerms = terms.filter(
      (term) => term.Length >= minLen && (!maxLen || term.Length <= maxLen)
    );
    return shuffleArray(filteredTerms).slice(0, count);
  }, []);

  // Function to select words according to the specified criteria
  const selectWords = useCallback(
    (terms) => {
      const largeWords = selectWordsByLength(terms, 3, 10, 12);
      const mediumWords = selectWordsByLength(terms, 5, 6, 9);
      const smallWords = selectWordsByLength(terms, 12, 4, 6);
      // const largeWords = selectWordsByLength(terms, 1, 10, 12);
      // const mediumWords = selectWordsByLength(terms, 1, 6, 9);
      // const smallWords = selectWordsByLength(terms, 1, 4, 6);
      return [...largeWords, ...mediumWords, ...smallWords];
    },
    [selectWordsByLength]
  );

  // Function to replace term and key in the description with underscores (case insensitive)
  const replaceTermWithUnderscores = (description, term, key) => {
    const termRegex = new RegExp(term, "gi");
    const keyRegex = new RegExp(key, "gi");
    return description
      .replace(termRegex, (match) => "_".repeat(match.length))
      .replace(keyRegex, (match) => "_".repeat(match.length));
  };

  // Function to get the first two sentences of a description
  const getFirstSentences = (description) => {
    const match = description.match(/(.*?[.?!])\s(.*?[.?!])\s/);
    return match ? `${match[1]} ${match[2]}` : description;
  };

  // Function to number words based on top-left most to bottom-right
  const numberWords = (positionObjArr) => {
    return positionObjArr
      .map((wordObj, index) => ({ ...wordObj, index }))
      .sort((a, b) => {
        if (a.yNum === b.yNum) {
          return a.xNum - b.xNum;
        }
        return a.yNum - b.yNum;
      })
      .map((wordObj, sortedIndex) => ({
        ...wordObj,
        customNumber: sortedIndex + 1,
      }));
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Generate crossword
  const generateCrossword = useCallback(() => {
    const selectedTerms = selectWords(chessterms);
    setSelectedTerms(selectedTerms);
    const words = selectedTerms.map((term) => term.Key);
    const result = processCrossword(words);

    // Add custom numbering
    result.positionObjArr = numberWords(result.positionObjArr);

    setCrosswordData(result);
    setUserInput({}); // Reset user input when generating a new crossword
    setCompleted(false); // Reset completed flag
    setQuitCompleted(false); // Reset quit completed flag
    setGameStarted(true); // Set game started to true

    // GA Tracking
    trackEvent("Games", "Crossword-Play", "Crossword");
    // Internal Tracking
    incrementGameProperty("Crossword-Plays");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.Crossword) {
        userData.Puzzles.Crossword.Played =
          (userData.Puzzles.Crossword.Played || 0) + 1;
      } else {
        userData.Puzzles.Crossword = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [selectWords, userData, userRef]);

  // Reset inputs
  const handleReset = () => {
    setUserInput({});
  };

  const handleInputChange = (event, row, col) => {
    const { value } = event.target;
    setUserInput((prevInput) => ({
      ...prevInput,
      [`${row}-${col}`]: value.toUpperCase(),
    }));
  };

  const checkCrossword = () => {
    if (!crosswordData) return false;

    const isCorrect = crosswordData.positionObjArr.every((wordObj) => {
      const { wordStr, xNum, yNum, isHorizon } = wordObj;
      for (let i = 0; i < wordStr.length; i++) {
        const letter = wordStr[i];
        const key = isHorizon ? `${yNum}-${xNum + i}` : `${yNum + i}-${xNum}`;
        if (userInput[key] !== letter.toUpperCase()) {
          return false;
        }
      }

      // GA Tracking
      trackEvent("Games", "Crossword-Completed", "Crossword");
      // Internal Tracking
      incrementGameProperty("Crossword-Completed");
      if (userData) {
        userData.Puzzles.Crossword.Completed =
          (userData.Puzzles.Crossword.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }

      return true;
    });

    setCompleted(isCorrect);
    if (!isCorrect) {
      setShowIncorrectAlert(true);
      setTimeout(() => setShowIncorrectAlert(false), 3000);
    }
    return isCorrect;
  };

  const handleQuit = () => {
    setQuitPrompt(true);
  };

  const handleConfirmQuit = () => {
    if (crosswordData) {
      const filledInput = {};
      crosswordData.positionObjArr.forEach((wordObj) => {
        const { wordStr, xNum, yNum, isHorizon } = wordObj;
        for (let i = 0; i < wordStr.length; i++) {
          const key = isHorizon ? `${yNum}-${xNum + i}` : `${yNum + i}-${xNum}`;
          filledInput[key] = wordStr[i].toUpperCase();
        }
      });
      setUserInput(filledInput);
      setCompleted(false);
      setQuitCompleted(true);
      setQuitPrompt(false);
    }
  };

  const handleCancelQuit = () => {
    setQuitPrompt(false);
  };

  const renderCrossword = () => {
    if (!crosswordData) return null;

    // Create an empty grid based on the height and width
    const grid = Array(crosswordData.height)
      .fill(null)
      .map(() => Array(crosswordData.width).fill(null));

    // Populate the grid based on positionObjArr and add custom numbers at the start of each word
    crosswordData.positionObjArr.forEach((wordObj) => {
      const { wordStr, xNum, yNum, isHorizon, customNumber } = wordObj;
      if (isHorizon) {
        grid[yNum][xNum] = { number: customNumber, letter: wordStr[0] };
        for (let i = 1; i < wordStr.length; i++) {
          grid[yNum][xNum + i] = { letter: wordStr[i] };
        }
      } else {
        grid[yNum][xNum] = { number: customNumber, letter: wordStr[0] };
        for (let i = 1; i < wordStr.length; i++) {
          grid[yNum + i][xNum] = { letter: wordStr[i] };
        }
      }
    });

    return (
      <Box sx={{ pt: 1 }}>
        {grid.map((row, rowIndex) => (
          <Box key={rowIndex} display="flex">
            {row.map((cell, cellIndex) => (
              <Box
                key={cellIndex}
                width="40px"
                height="40px"
                sx={{
                  border: cell ? "0.25px solid rgba(0, 0, 0, 0.5)" : "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  margin: "1px",
                }}
              >
                {cell && cell.number && (
                  <Typography
                    variant="caption"
                    component="span"
                    position="absolute"
                    top={0}
                    left={3}
                    fontSize="10px"
                  >
                    {cell.number}
                  </Typography>
                )}
                {cell && (
                  <TextField
                    inputProps={{ maxLength: 1, autoComplete: "off" }}
                    value={userInput[`${rowIndex}-${cellIndex}`] || ""}
                    onChange={(event) =>
                      handleInputChange(event, rowIndex, cellIndex)
                    }
                    sx={{
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                      "& input": {
                        padding: 0,
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                        border: "none",
                        boxSizing: "border-box",
                        height: "40px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    );
  };

  const renderHints = () => {
    if (!crosswordData) return null;

    const acrossHints = [];
    const downHints = [];

    crosswordData.positionObjArr.forEach((wordObj) => {
      const { wordStr, isHorizon, customNumber } = wordObj;
      const term = selectedTerms.find((term) => term.Key === wordStr);
      const hint =
        completed || quitCompleted
          ? term.Description
          : replaceTermWithUnderscores(
              getFirstSentences(term.Description),
              term.Term,
              term.Key
            );

      if (isHorizon) {
        acrossHints.push(
          <Typography key={customNumber} sx={{ pt: 2 }}>
            {customNumber}.{" (" + term.Key.length + ")"} {hint}
          </Typography>
        );
      } else {
        downHints.push(
          <Typography key={customNumber} sx={{ pt: 2 }}>
            {customNumber}.{" (" + term.Key.length + ")"} {hint}
          </Typography>
        );
      }
    });

    return (
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        flexWrap="wrap"
      >
        <Box
          sx={{
            width: { xs: "90%", md: "calc(45%)" },
            mb: { xs: 2, md: 0 },
            p: 2,
          }}
        >
          <Typography variant="h6">{t("Crossword.game.across")}</Typography>
          {acrossHints}
        </Box>
        <Box
          sx={{
            width: { xs: "90%", md: "calc(45%)" },
            mb: { xs: 2, md: 0 },
            p: 2,
          }}
        >
          <Typography variant="h6">{t("Crossword.game.down")}</Typography>
          {downHints}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <ContentHeader
        title={t("Crossword.header.title")}
        subtitle={t("Crossword.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      {isSmallScreen && (
        <Alert
          severity="warning"
          icon={<WarningIcon sx={{ color: "white" }} />}
          style={{
            backgroundColor: colors.red[500],
            color: "white",
            marginBottom: "20px",
          }}
        >
          <AlertTitle>{t("Crossword.game.warning.title")}</AlertTitle>
          {t("Crossword.game.warning.message")}
        </Alert>
      )}
      <Helmet>
        <title>Crossword</title>
        <meta
          name="description"
          content="Solve chess-themed crosswords and improve your chess knowledge while having fun!"
        />
        <meta property="og:title" content="Crossword" />
        <meta
          property="og:description"
          content="Solve chess-themed crosswords and improve your chess knowledge while having fun!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/crossword.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/crossword`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Crossword" />
        <meta
          name="twitter:description"
          content="Solve chess-themed crosswords and improve your chess knowledge while having fun!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/crossword.png`}
        />
      </Helmet>
      <Box>
        <Box sx={{ pb: 1 }}>
          <Button
            variant="contained"
            onClick={generateCrossword}
            style={{ marginRight: 10 }}
            startIcon={
              <PlayCircleOutlineRoundedIcon
                style={{ color: colors.black[900] }}
              />
            }
          >
            {t("Crossword.buttons.play")}
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            startIcon={
              <RestartAltRoundedIcon style={{ color: colors.black[900] }} />
            }
            onClick={handleReset}
          >
            {t("Crossword.buttons.reset")}
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            style={{ marginRight: 10 }}
            startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
          >
            {t("Crossword.buttons.help")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={checkCrossword}
            style={{ marginRight: 10 }}
          >
            {t("Crossword.buttons.check")}
          </Button>
          {quitPrompt ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleConfirmQuit}
                style={{ marginRight: 10 }}
              >
                {t("Crossword.buttons.confirm")}
              </Button>
              <Button variant="contained" onClick={handleCancelQuit}>
                {t("Crossword.buttons.cancel")}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={handleQuit}
              disabled={!gameStarted || completed || quitCompleted}
            >
              {t("Crossword.buttons.quit")}
            </Button>
          )}
          <HelpModal
            open={open}
            onClose={handleClose}
            title={t("Crossword.helpDetails.title")}
            content={<CrosswordHelp />}
          />
        </Box>
        {showIncorrectAlert && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {t("Crossword.game.incorrect.message")}
          </Alert>
        )}
        {completed && !quitCompleted && (
          <Alert
            severity="success"
            icon={<CheckCircleOutlineIcon />}
            sx={{ mt: 2 }}
          >
            {t("Crossword.game.completed.message")}
          </Alert>
        )}
        {crosswordData && renderCrossword()}
        {crosswordData && renderHints()}
      </Box>
    </Box>
  );
}

export default ChessCrossword;
