export const chessquotes = [
  {
    Author: "A. G. Gardiner",
    Quote:
      "Blessed be the memory of him who gave the world this immortal game.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "The beauty of a move lies not in its appearance but in the thought behind it.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote: "The threat is stronger than the execution.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote: "The isolated Pawn casts gloom over the entire chessboard.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "The passed Pawn is a criminal, who should be kept under lock and key. Mild measures, such as police surveillance, are not sufficient.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote: "The defensive power of a pinned piece is only imaginary.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "In the middlegame, the king is merely an extra, but in the endgame, he is one of the star actors.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote: "Even the laziest King flees wildly in the face of a double check!",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "Many men, many styles; what is chess style but the intangible expression of the will to win.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "A thorough knowledge of the elements takes us more than half the road to mastership.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote: "No pawn exchanges, no file-opening, no attack.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote: "Strategically important points should be overprotected.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "How vain are our fears! I thought to myself. Sometimes we fear that which our opponent (or fate) had never even considered!",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "Chess strategy as such today is still in its diapers, despite Tarrasch's statement 'We live today in a beautiful time of progress in all fields'.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "The great mobility of the King forms one of the chief characteristics of all endgame strategy.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "The main objective of any operation in an open file is the eventual occupation of the seventh or eighth rank.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "Ridicule can do much, for instance, embitter the existence of young talents; but one thing is not given to it, to put a stop permanently to the incursion of new and powerful ideas.",
  },
  {
    Author: "Aaron Nimzowitsch",
    Quote:
      "It is a well-known phenomenon that the same amateur who can conduct the middle game quite creditably is usually perfectly helpless in the end game.",
  },
  {
    Author: "Adolf Anderson",
    Quote:
      "He who plays with Morphy must abandon all hope of catching him in a trap no matter how cunningly laid, but must assume that it is so clear to Morphy that therre can be no question of a false step",
  },
  {
    Author: "Albert Einstein",
    Quote:
      "I have always disliked the fierce competitive spirit embodied in that highly intellectual game.",
  },
  {
    Author: "Albert Einstein",
    Quote:
      "The enormous mental resilience, without which no Chess player can exist, was so much taken up by Chess that he could never free his mind of this game.",
  },
  {
    Author: "Albert Einstein",
    Quote:
      "Chess holds its master in its own bonds, shackling the mind and brain so that the inner freedom of the very strongest must suffer.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "When asked, How is that you pick better moves than your opponents?, I responded: I'm very glad you asked me that, because, as it happens, there is a very simple answer. I think up my own moves, and I make my opponent think up his.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "During a Chess competition a Chessmaster should be a combination of a beast of prey and a monk.",
  },
  {
    Author: "Alexander Alekhine",
    Quote: "Chess, like other arts, must be practiced to be appreciated.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "For my victory over Capablanca, I am indebted primarily to my superiority in the field of psychology.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "You can become a big master in chess only if you see your mistakes and shortcomings. Exactly the same as in life itself.",
  },
  {
    Author: "Alexander Alekhine",
    Quote: "A lifetime is not enough to learn everything about chess.",
  },
  {
    Author: "Alexander Alekhine",
    Quote: "Chess first of all teaches you to be objective.",
  },
  {
    Author: "Alexander Alekhine",
    Quote: "I study chess eight hours a day, on principle.",
  },
  {
    Author: "Alexander Alekhine",
    Quote: "I do not play chess – I fight at chess.",
  },
  {
    Author: "Alexander Alekhine",
    Quote: "Play on both sides of the board is my favourite strategy.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "For success, I consider three factors necessary: firstly, an awareness of my own strengths and weaknesses; secondly, an accurate understanding of my opponent's strengths and weaknesses; thirdly, a higher aim than momentary satisfaction.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "Capablanca was snatched too early from the chess world. With his death, we have lost a great chess genius, the like of whom we will never see again.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "Young players expose themselves to grave risks when they blindly imitate the innovations of masters without themselves first checking all the details and consequences of these innovations.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "Playing for complications is an extreme measure that a player should adopt only when he cannot find a clear and logical plan.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "The retreat of a minor piece to the back rank, where it cuts the lines of communication between the rooks, is permissible only in exceptional cases.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "As a rule, so-called 'positional' sacrifices are considered more difficult, and therefore more praiseworthy, than those which are based exclusively on an exact calculation of tactical possibilities.",
  },
  {
    Author: "Alexander Alekhine",
    Quote:
      "Chess for me is not a game, but an art. Yes, and I take upon myself all those responsibilities which an art imposes on its adherents.",
  },
  {
    Author: "Alexander Kotov",
    Quote: "Bobby Fischer is the greatest Chess genius of all time!",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "The placing of the centre pawns determines the 'topography' of a game of chess.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "It is better to follow out a plan consistently even if it isn't the best one than to play without a plan at all. The worst thing is to wander about aimlessly.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "Sit there for five hours? Certainly not! A player must walk about between moves, it helps his thinking.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "Just as the pianist practices the most complicated pieces to improve the technique of his fingers, so too a grandmaster must keep his vision in trim by daily analysis of positions with sharp possibilities, and this applies whether he prefers such positions in his play or not.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "There is no doubt that the reason for my awful oversight was over-confidence that sapped my sense of danger. So that is where to look for the cause of bad blunders - in the exulting feeling of self-congratulation.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "In analysing complicated variations one must examine each branch of the tree once and once only.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "It has always been recognized that chess is an art, and its best practitioners have been described as artists.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "If a chess statistician were to try and satisfy his curiosity over which stage of the game proved decisive in the majority of cases, he would certainly come to the conclusion that it is the middlegame that provides the most decisive stage.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "The main thing that develops positional judgement, that perfects it and makes it many-sided, is detailed analytical work, sensible tournament practice, a self-critical attitude to your games and a rooting out of all the defects in your play.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "My achievements in the field of chess are the result of immense hard work in studying theory.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "In choosing an opening plan players think most of all of harmonious development for the pieces, but sometimes leave the development of the queen out of their considerations. Yet the Queen is the most valuable and important piece and the whole outcome can depend upon how successfully she plays her role.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "I soon realized that it is not enough for a master simply to analyse variations scrupulously just like an accountant. He must learn to work out which particular moves he should consider and then examine just as many variations as necessary - no more and no less.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "Experience and the constant analysis of the most varied positions builds up a store of knowledge in a player's mind enabling him often at a glance to assess this or that position.",
  },
  {
    Author: "Alexander Kotov",
    Quote:
      "Once we have chosen the right formation in the centre we have created opportunities for our pieces and laid the foundation of subsequent victory.",
  },
  {
    Author: "Alexei Suetin",
    Quote: "My God, Bobby Fischer plays so simply",
  },
  {
    Author: "Alfred Binet",
    Quote:
      "Could we look into the head of a Chess player, we should see there a whole world of feelings, images, ideas, emotion and passion",
  },
  {
    Author: "Amber Steenbock",
    Quote: "Live, lose, and learn, by observing your opponent how to win",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "Nowadays, when you are not a grandmaster at 14, you can forget about it",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "In chess, knowledge is a very transient thing. It changes so fast that even a single mouse-slip sometimes changes the evaluation.",
  },
  {
    Author: "Viswanathan Anand",
    Quote: "A win is a win, which is about that particular moment.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "You need to motivate yourself, no matter what – definitely when things are bad, but also when things are good. Or else, you risk becoming complacent.",
  },
  {
    Author: "Viswanathan Anand",
    Quote: "Each match I play is the most important one yet.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "It is important that you don’t let your opponent impose his style of play on you. A part of that begins mentally.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "Preparedness for a game that usually lasts four-five hours requires good physical condition and also steady nerves.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "Psychology plays a big part but I always say psychology will only be a differentiator when the players are of equal technical strength.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "If you have a strong opponent, a competition is stimulating. I am generally most open to ideas when I have had a bad result.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "In any match, there are few critical moments where there's no second best decision. The rest of the moves are intuitive.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "For me, each game is a new challenge, which has to be dealt with rationally and systematically. At that time, every other thought fades into oblivion.",
  },
  {
    Author: "Viswanathan Anand",
    Quote: "You bring to chess facets of your personality and what you are.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "There is always the risk of being over-confident when you are preparing to face a weaker player.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "I attend to my fitness. I go to the gym every day and try to maintain my physical fitness; without that, it is tough to take challenges on the chess board.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "I think an important lesson from the game is that once you have made a move, you cannot take it back. You really have to measure your decisions.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "Nowadays, when you're not a grandmaster at 14, you can forget about it.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "For every door the computers have closed they have opened a new one.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "When you play with the best in the world, it is important that you not lose focus. You must be fully focused. Even a minor error could result in a massive defeat.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "Just before a game, I try to keep a clear mind so that I can focus better.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "I would never suggest to anyone that they drop school for chess. First of all, even if you can make it in chess, your social skills need to be developed there.",
  },
  {
    Author: "Viswanathan Anand",
    Quote:
      "I like to keep myself physically and mentally fit before any important match. I usually take a short nap just before the game and do not practice immediately before the tournament.",
  },
  {
    Author: "Anatoly Karpov",
    Quote: "Chess is everything: art, science, and sport.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "By all means examine the games of the great chess players, but don't swallow them whole. Their games are valuable not for their separate moves, but for their vision of chess, their way of thinking.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "The first great chess players, including the world champion, got by perfectly well without constant coaches.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "I still love to play chess. So I do not even spend a minute on the possibility to step back.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "Like dogs who sniff each other when meeting, chess players have a ritual at first acquaintance: they sit down to play speed chess.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "The ideal in chess can only be a collective image, but in my opinion it is Capablanca who most closely approaches this.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "An amusing fact: as far as I can recall, when playing the Ruy Lopez I have not yet once in my life had to face the Marshall Attack!",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "I lost the match. I blame only myself for this. There were many opportunities to win. But I missed them, no one else.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "But how difficult it can be to gain the desired full point against an opponent of inferior strength, when this is demanded by the tournament position!",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "I didn't picture myself as even a grandmaster, to say nothing of aspiring to the chess crown. This was not because I was timid - I wasn't - but because I simply lived in one world, and the grandmasters existed in a completely different one. People like that were not really even people, but like gods or mythical heroes.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "I have found after 1.d4 there are more opportunities for richer play.",
  },
  {
    Author: "Anatoly Karpov",
    Quote: "I like 1.e4 very much, but my results are better with 1.d4.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "It doesn't require much for misfortune to strike in the King's Gambit - one incautious move, and Black can be on the edge of the abyss.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "I still remember Botvinnik's reaction to each of my games, right from the opening moves. At first he would express amazement, then annoyance, and, finally irritation.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "The fact that a knight is temporarily on the edge of the board is of no great significance.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "It is dangerous to maintain equality at the cost of placing the pieces passively.",
  },
  {
    Author: "Anatoly Karpov",
    Quote:
      "The truth is that my chess development was nothing out of the ordinary, and it proceeded probably at a pace no faster than others.",
  },
  {
    Author: "Anonymous",
    Quote: "A bad day of Chess is better than any good day at work.",
  },
  {
    Author: "Aristotle",
    Quote:
      "When you are lonely, when you feel yourself an alien in the world, play Chess. This will raise your spirits and be your counselor in war.",
  },
  {
    Author: "Assaic",
    Quote: "In Chess, just as in life, today's bliss may be tomorrow's poison.",
  },
  {
    Author: "Assiac",
    Quote:
      "For surely of all the drugs in the world, Chess must be the most permanently pleasurable.",
  },
  {
    Author: "Baron Tassilo",
    Quote:
      "Chess is in its essence a game, in its form an art, and in its execution a science.",
  },
  {
    Author: "Benjamin Franklin",
    Quote:
      "Chess is so interesting in itself, as not to need the view of gain to induce engaging in it; and thence it is never played for money.",
  },
  {
    Author: "Benjamin Franklin",
    Quote:
      "The game of Chess is not merely an idle amusement; several very valuable qualities of the mind are to be acquired and strengthened by it, so as to become habits ready on all occasions; for life is a kind of Chess.",
  },
  {
    Author: "Benjamin Franklin",
    Quote:
      "Life is a kind of Chess, with struggle, competition, good and ill events.",
  },
  {
    Author: "Bent Larsen",
    Quote: "The stomach is an essential part of the Chess master.",
  },
  {
    Author: "Bent Larsen",
    Quote: "Chess is a beautiful mistress.",
  },
  {
    Author: "Blaise Pascal",
    Quote: "Chess is the gymnasium of the mind.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "1.e4 - Best by test.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "I like the moment I crush a man's ego.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "All that matters on the chessboard is good moves.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Chess is a matter of delicate judgement, knowing when to punch and how to duck.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "Don't even mention losing to me. I can't stand to think of it.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "Chess demands total concentration.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "There are tough players and nice guys, and I'm a tough player.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "My opponents make good moves too. Sometimes I don't take these things into consideration.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "The turning point in my career came with the realization that Black should play to win instead of just steering for equality.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "Nothing is so healing as the human touch.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Chess is war over the board. The object is to crush the opponent’s mind.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "You can only get good at Chess if you love the game.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "All I ever want to do is just play chess.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "I don’t believe in psychology. I believe in good moves.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "If you don’t win, it’s not a great tragedy – the worst that happens is that you lose a game.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Psychologically, you have to have confidence in yourself and this confidence should be based on fact.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "That’s what chess is all about. One day you give your opponent a lesson, the next day he gives you one.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Alekhine developed as a player much more slowly than most. In his twenties, he was an atrocious chessplayer, and didn’t mature until he was well into his thirties.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "In chess so much depends on opening theory, so the champions before the last century did not know as much as I do and other players do about opening theory. So if you just brought them back from the dead they wouldn’t do well. They’d get bad openings.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "I prepare myself well. I know what I can do before I go in. I'm always confident.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "My opponents make good moves too. But I make good moves and then I make the best move.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "Chess demands total concentration and a love for the game.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "I play honestly and I play to win. If I lose, I take my medicine.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "I am the best player in the world, and I am here to prove it.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "There are tough players and nice guys, and I'm a tough player.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "I read a book. That's a good way to start learning anything.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "I don't believe in heroes, but I believe in the man who makes heroes.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Concentrate on material gains. Whatever your opponent gives you take unless you see a good reason not to.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "To play for a draw, at any rate with white, is to some degree a crime against chess.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "The old chess is too limited. Imagine playing cards, poker for example, where the best hand always wins. It just kills the game.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "Chess is really ninety-nine percent calculation.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "You have to have the fighting spirit. You have to force moves and take chances.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "In chess so much depends on opening theory, so the champions before the last century did not know as much as I do and other players do about opening theory.",
  },
  {
    Author: "Bobby Fischer",
    Quote: "I got strong ideas and they've been proven right, that's all.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "I know people who have all the will in the world, but still can't play good chess.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Chess is a matter of delicate judgement, knowing when to punch and how to duck.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "First of all, we have to understand what the enemy is thinking. Then we have to be more aggressive than they are.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "If you're going to play the game properly, you'd better know every rule.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "I object to being called a chess genius because I consider myself to be an all-around genius who just happens to play chess.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Your body has to be in top condition. Your chess deteriorates as your body does. You can't separate body from mind.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "Chess and me, it's hard to take them apart. It's like my alter ego.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "It's just you and your opponent at the board and you're trying to prove something.",
  },
  {
    Author: "Bobby Fischer",
    Quote:
      "One of the things I like about the chess world is that it's so peaceful. I can't think of any other world that's so peaceful.",
  },
  {
    Author: "Bogolyubov",
    Quote:
      "When I have White, I win because I am white; When I have Black, I win because I am Bogolyubov",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "There is only one thing Fischer does in Chess without pleasure: to lose!",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "When you play Bobby, it is not a question if you win or lose. It is a question if you survive",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "There is only one thing Fischer does in Chess without pleasure: to lose!",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "My forte was the middlegame. I had a good feeling for the critical moments of the play. This undoubtedly compensated for my lack of opening preparation and, possibly, not altogether perfect play in the endgame. In my games things often did not reach the endgame!",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "The computer age has arrived, and it influences everything: analysis, preparation, information. Now a different talent is required - the ability to synthesize ideas.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "The shortcoming of hanging pawns is that they present a convenient target for attack. As the exchange of men proceeds, their potential strength lessens and during the endgame they turn out, as a rule, to be weak.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "Often, in the Ruy Lopez, one must be patient, wait and carry on a lengthy and wearisome struggle.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "The place of chess in the society is closely related to the attitude of young people towards our game.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "The Soviet Union was an exception, but even there chess players were not rich. Only Fischer changed that.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "I also follow chess on the Internet, where Kasparov's site is very interesting.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "The best indicator of a Chess Player's form is his ability to sense the Climax of the game.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "I try to help developing junior chess. When I lived in USSR, I got a lot of free help from very good coaches - now I am trying to repay that debt.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "Nowadays there is more dynamism in chess, modern players like to take the initiative. Usually they are poor defenders though.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "Nowadays young people have great choice of occupations, hobbies, etc, so chess is experiencing difficulties because of the high competition. Now it's hard to make living in chess, so our profession does attract young people.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "After I won the title, I was confronted with the real world. People do not behave naturally anymore - hypocrisy is everywhere.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "In my country, at that time, being a champion of chess was like being a King. At that time I was a King and when you are King you feel a lot of responsibility, but there is nobody there to help you.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "Bobby Fischer has an enormous knowledge of chess and his familiarity with the chess literature of the USSR is immense.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "Nowadays the dynamic element is more important in chess - players more often sacrifice material to obtain dynamic compensation.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "We can compare classical chess and rapid chess with theatre and cinema - some actors don't like the latter and prefer to work in the theatre.",
  },
  {
    Author: "Boris Spassky",
    Quote: "Which do I prefer? Sex or chess? It depends on the position.",
  },
  {
    Author: "Boris Spassky",
    Quote:
      "The best tournament that I have ever played in was in 1950. It was great - a waiter came to you during the game, and you could order anything you wanted to drink (even some vodka, if you liked). Pity, there are no longer tournaments organized in this manner.",
  },
  {
    Author: "Bruce A. Moon",
    Quote: "Good offense and good defense both begin with good development.",
  },
  {
    Author: "Bruce A. Moon",
    Quote:
      "Adequate compensation for a sacrifice is having a sound combination leading to a winning position; adequate compensation for a blunder is having your opponent snatch defeat from the jaws of victory.",
  },
  {
    Author: "Bruce Pandolfini",
    Quote:
      "I like to say that Bobby Fischer was the greatest player ever. But what made Fischer a genius was his ability to blend an American freshness and pragmatism with Russian ideas about strategy.",
  },
  {
    Author: "Bruce Pandolfini",
    Quote:
      "Fischer was a master of clarity and a king of artful positioning. His opponents would see where he was going but were powerless to stop him.",
  },
  {
    Author: "Bruce Pandolfini",
    Quote:
      "We don't really know how the game was invented, though there are suspicions. As soon as we discover the culprits, we'll let you know.",
  },
  {
    Author: "Burt Hochberg",
    Quote:
      "With or without the title, Bobby Fischer was unquestionably the greatest player of his time.",
  },
  {
    Author: "C. J. S. Purdy",
    Quote: "Methodical thinking is of more use in Chess than inspiration.",
  },
  {
    Author: "Capablanca",
    Quote:
      "A passed Pawn increases in strength as the number of pieces on the board diminishes.",
  },
  {
    Author: "Cecil Purdy",
    Quote: "Pawn endings are to Chess what putting is to golf.",
  },
  {
    Author: "Charles Buxton",
    Quote:
      "In life, as in Chess, one's own Pawns block one's way. A man's very wealth, ease, leisure, children, books, which should help him to win, more often checkmate him.",
  },
  {
    Author: "Che Guevara",
    Quote:
      "You know, comrade Pachman, I don't enjoy being a Minister, I would rather play Chess like you.",
  },
  {
    Author: "Chernev",
    Quote: "Every Chess master was once a beginner.",
  },
  {
    Author: "Chinese proverb",
    Quote: "Life is like a game of Chess, changing with each move.",
  },
  {
    Author: "Dave Regis",
    Quote:
      "Fischer Chess play was always razor-sharp, rational and brilliant. One of the best ever.",
  },
  {
    Author: "David Bronstein",
    Quote: "The most powerful weapon in Chess is to have the next move.",
  },
  {
    Author: "David Bronstein",
    Quote: "The essence of Chess is thinking about what Chess is.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "A strong player requires only a few minutes of thought to get to the heart of the conflict. You see a solution immediately, and half an hour later merely convince yourself that your intuition has not deceived you.",
  },
  {
    Author: "David Bronstein",
    Quote: "Chess is imagination.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "If you have made a mistake or committed an inaccuracy there is no need to become annoyed and to think that everything is lost. You have to reorientate yourself quickly and find a new plan in the new situation.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "A game of chess is not an examination of knowledge; it is a battle of nerves.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "It is no secret that any talented player must in his soul be an artist, and what could be dearer to his heart and soul than the victory of the subtle forces of reason over crude material strength! Probably everyone has his own reason for liking the King`s Gambit, but my love for it can be seen in precisely those terms.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "When you play against an experienced opponent who exploits all the defensive resources at his command you sometimes have to walk time and again, along the narrow path of 'the only move'.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Even in the heat of a middlegame battle the master still has to bear in mind the outlines of a possible future ending.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "There is no disputing that in the eyes of Schlechter, Teichmann or even Rubinstein, the backward pawn was something more substantial than lively piece play, but in our day the latter is more often preferred.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Far from all of the obvious moves that go without saying are correct.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "There is not a single true chess-player in the world whose heart does not beat faster at the mere sound of such long beloved and familiar words as 'gambit games'.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "What the devil possessed me to reply 1. ... e5?? I completely forgot that Spassky, like Spielmann in the past, very much likes to play the King's Gambit.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Independence of thought is a most valuable quality in a chess-player, both at the board and when preparing for a game.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Sometimes at lectures I am asked: how would the champions of the last century play today? I think that, after making a hurried study of modern openings, and watching one or two tournaments, the champions of the last century, and indeed the century before that, would very quickly occupy the same place that they occupied when they were alive.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "It would be as naive to study the song of the nightingale, as it would be ridiculous to try and win a King's Gambit against a representative of the old chess guard.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Theory regards this opening as incorrect, but it is impossible to agree with this. Out of the five tournament games played by me with the King's Gambit, I have won all five.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Some pieces in the King's Indian appear on a 'special price' list: the dark square bishops are at the top of that list.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "Two passed pawns advancing on the enemy pieces have brought me more than a dozen points in tournaments.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "When everything on the board is clear it can be so difficult to conceal your thoughts from your opponent.",
  },
  {
    Author: "David Bronstein",
    Quote:
      "But whatever you might say and whatever I might say, a machine which can play chess with people is one of the most marvelous wonders of our 20th century!",
  },
  {
    Author: "David Bronstein",
    Quote:
      "It is annoying that the rules of chess do not allow a pawn to take either horizontally or backwards, but only forwards ... This psychological tuning is ideal for attacking purposes, but what about for defense?",
  },
  {
    Author: "David Bronstein",
    Quote:
      "To play a match for the World Championship is the cherished dream of every chess player.",
  },
  {
    Author: "David Levy",
    Quote:
      "By the beauty of his games, the clarity of his play, and the brilliance of his ideas, Fischer made himself an artist of the same stature as Brahms, Rembrandt, and Shakespeare",
  },
  {
    Author: "David Levy",
    Quote: "I prefer to lose a really good game than to win a bad one",
  },
  {
    Author: "Dominic Lawson",
    Quote: "Nothing excites jaded Grandmasters more than a theoretical novelty",
  },
  {
    Author: "Dostoyevsky",
    Quote:
      "Man is a frivolous, a specious creature, and like a Chess player, cares more for the process of attaining his goal than for the goal itself",
  },
  {
    Author: "Edmar Mednis",
    Quote:
      "After a bad opening, there is hope for the middle game. After a bad middle game, there is hope for the endgame. But once you are in the endgame, the moment of truth has arrived",
  },
  {
    Author: "Edmar Mednis",
    Quote: "In Chess, at least, the brave inherit the earth",
  },
  {
    Author: "Eduard Gufeld",
    Quote:
      "For me, Chess is life and every game is like a new life. Every Chess player gets to live many lives in one lifetime",
  },
  {
    Author: "Edward Morgan Foster",
    Quote:
      "Chess is a forcing house where the fruits of character can ripen more fully than in life",
  },
  {
    Author: "Efim Geller",
    Quote:
      "It was clear to me that the vulnerable point of the American Grandmaster (Bobby Fischer) was in double-edged, hanging, irrational positions, where he often failed to find a win even in a won position",
  },
  {
    Author: "Elie Agur",
    Quote:
      "Nonsense was the last thing Fischer was interested in, as far as Chess was concerned",
  },
  {
    Author: "Emanuel Lasker",
    Quote:
      "The laws of Chess do not permit a free choice: you have to move whether you like it or not",
  },
  {
    Author: "Emanuel Lasker",
    Quote:
      "In Chess, as it is played by masters, chance is practically eliminated",
  },
  {
    Author: "Emanuel Lasker",
    Quote: "Chess is above all, a fight!",
  },
  {
    Author: "Emanuel Lasker",
    Quote: "On the chessboard, lies and hypocrisy do not survive long",
  },
  {
    Author: "Emanuel Lasker",
    Quote:
      "The combination player thinks forward; he starts from the given position, and tries the forceful moves in his mind",
  },
  {
    Author: "Emanuel Lasker",
    Quote: "The hardest game to win is a won game",
  },
  {
    Author: "Emanuel Lasker",
    Quote: "Chess is above all, a fight!",
  },
  {
    Author: "Emanuel Lasker",
    Quote: "Without error there can be no brilliancy",
  },
  {
    Author: "Emanuel Lasker",
    Quote:
      "I have added these principles to the law: get the Knights into action before both Bishops are developed",
  },
  {
    Author: "Emanuel Lasker",
    Quote: "When you see a good move, look for a better one",
  },
  {
    Author: "Eugene Znosko-Borovsky",
    Quote:
      "It is not a move, even the best move that you must seek, but a realizable plan",
  },
  {
    Author: "Max Euwe",
    Quote:
      "Whoever sees no other aim in the game than that of giving checkmate to one's opponent will never become a good Chess player",
  },
  {
    Author: "Francois Andre Danican Philidor",
    Quote: "The Pawns are the soul of the game",
  },
  {
    Author: "Frank Brady",
    Quote:
      "Bobby Fischer's current state of mind is indeed a tragedy. One of the worlds greatest Chess players - the pride and sorrow of American Chess",
  },
  {
    Author: "Frank Marshall",
    Quote: "A bad plan is better than none at all",
  },
  {
    Author: "Fred Reinfeld",
    Quote: "The Pin is mightier than the sword",
  },
  {
    Author: "French Proverb",
    Quote: "You cannot play at Chess if you are kind-hearted",
  },
  {
    Author: "Eduard Gufeld",
    Quote: "You are for me the Queen on d8 and I am the Pawn on d7!! ",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Ultimately, what separates a Winner from a Loser at the Grandmaster level is the Willingness to do the Unthinkable.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "Chess is an art and not a spectator sport.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "The highest Art of the Chess player lies in not allowing your Opponent to show you what he can do.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "I like to say that the attacker always has the advantage.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Sometimes the hardest thing to do in a pressure situation is to allow the tension to persist. The temptation is to make a decision, any decision, even if it is an inferior choice.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "One does not succeed by sticking to convention.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Attackers may sometimes regret bad moves, but it is much worse to forever regret an opportunity you allowed to pass you by.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "If you don't take risks, you don't drink champagne.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Chess is one of the few arts where composition takes place simultaneously with performance.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "It's not enough to be talented. It's not enough to work hard and to study late into the night. You must also become intimately aware of the methods you use to reach your decisions.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "The ability to work hard for days on end without losing focus is a talent. The ability to keep absorbing new information after many hours of study is a talent.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "A brilliant strategy is, certainly, a matter of intelligence, but intelligence without audaciousness is not enough.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "The biggest problem I see among people who want to excel in chess – and in business and in life in general – is not trusting their instincts enough.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "We think about time as something not to waste, not as something to invest.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "A master looks at every move he would like to make, especially the impossible ones.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Setbacks and losses are both inevitable and essential if you're going to improve and become a good, even great, competitor. The art is in avoiding catastrophic losses in the key battles.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "Chess is mental torture.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "Chess is life in miniature. Chess is struggle, chess is battles.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Chess helps you to concentrate, improve your logic. It teaches you to play by the rules and take responsibility for your actions, how to problem solve in an uncertain environment.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "The best chess masters of every epoch have been closely linked with the values of the society in which they lived and worked. All the changes of a cultural, political, and psychological background are reflected in the style and ideas of their play.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "I see my own style as being a symbiosis of the styles of Alekhine, Tal and Fischer.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "The worst enemy of the strategist is the clock. Time trouble... Reduces us all to pure reflex and reaction, tactical play. Emotion and instinct cloud our strategic vision when there is no time for proper evaluation.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "In chess the rules are fixed and the outcome is unpredictable, whereas in Putin's Russia the rules are unpredictable and the outcome is fixed.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Winning is not a secret that belongs to a very few, winning is something that we can learn by studying ourselves, studying the environment and making ourselves ready for any challenge that is in front of us.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "It was an impressive achievement, of course, and a human achievement by the members of the IBM team, but Deep Blue was only intelligent the way your programmable alarm clock is intelligent. Not that losing to a $10 million alarm clock made me feel any better.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Nowadays games immediately appear on the Internet and thus the life of novelties is measured in hours. Modern professionals do not have the right to be forgetful - it is 'life threatening'.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Losing can persuade you to change what doesn't need to be changed, and winning can convince you everything is fine even if you are on the brink of disaster.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "The loss of my childhood was the price for becoming the youngest world champion in history. When you have to fight every day from a young age, your soul can be contaminated. I lost my childhood. I never really had it. Today I have to be careful not to become cruel, because I became a soldier too early.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Though I would have liked my chances in a rematch in 1998 if I were better prepared, it was clear then that computer superiority over humans in chess had always been just a matter of time.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Tactics involve calculations that can tax the human brain, but when you boil them down, they are actually the simplest part of chess and are almost trivial compared to strategy.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "I’ve seen - both in myself and my competitors - how satisfaction can lead to a lack of vigilance, then to mistakes and missed opportunities.",
  },
  {
    Author: "Garry Kasparov",
    Quote:
      "Caissa, the goddess of chess, had punished me for my conservative play, for betraying my nature.",
  },
  {
    Author: "Garry Kasparov",
    Quote: "It's quite difficult for me to imagine my life without chess.",
  },
  {
    Author: "Genrikh Chepukaitis",
    Quote: "You need not play well - just help your opponent to play badly.",
  },
  {
    Author: "Georg Kieninger",
    Quote:
      "Dazzling combinations are for the many, shifting wood is for the few.",
  },
  {
    Author: "George Bernard Shaw",
    Quote:
      "Chess is a foolish expedient for making idle people believe they are doing something very clever when they are only wasting their time.",
  },
  {
    Author: "Gerald Abrahams",
    Quote:
      "The tactician knows what to do when there is something to do; whereas the strategian knows what to do when there is nothing to do.",
  },
  {
    Author: "Gerald Abrahams",
    Quote: "Good positions don't win games, good moves do.",
  },
  {
    Author: "Gustav Schenk",
    Quote:
      "There must have been a time when men were demigods, or they could not have invented Chess.",
  },
  {
    Author: "H. E. Bird",
    Quote:
      "Place the contents of the Chess box in a hat, shake them up vigorously, pour them on the board from a height of two feet, and you get the style of Steinitz.",
  },
  {
    Author: "H. G. Wells",
    Quote: "There is no remorse like the remorse of Chess.",
  },
  {
    Author: "H. G. Wells",
    Quote:
      "The passion for playing Chess is one of the most unaccountable in the world.",
  },
  {
    Author: "Hans Kmoch",
    Quote:
      "The battle for the ultimate truth will never be won. And that's why Chess is so fascinating.",
  },
  {
    Author: "Hein Donner",
    Quote:
      "I love all positions. Give me a difficult positional game, I will play it. But totally won positions, I cannot stand them.",
  },
  {
    Author: "Hindu proverb",
    Quote:
      "Chess is a sea in which a gnat may drink and an elephant may bathe.",
  },
  {
    Author: "Horowitz",
    Quote: "One bad move nullifies forty good ones.",
  },
  {
    Author: "Howard Staunton",
    Quote:
      "After black's reply to 1.e4 with 1..e5, leaves him always trying to get into the game.",
  },
  {
    Author: "Hung Tzu Ch'eng",
    Quote:
      "Human affairs are like a Chess game: only those who do not take it seriously can be called good players.",
  },
  {
    Author: "Irish saying",
    Quote:
      "When the Chess game is over, the Pawn and the King go back to the same box.",
  },
  {
    Author: "Irving Chernev",
    Quote:
      "The sign of a great Master is his ability to win a won game quickly and painlessly.",
  },
  {
    Author: "Isaac Kashdan",
    Quote:
      "In Fischer's hands, a slight theoretical advantage is as good a being a Queen ahead.",
  },
  {
    Author: "Italian Proverb",
    Quote: "It is no time to be playing Chess when the house is on fire.",
  },
  {
    Author: "James Mason",
    Quote: "Every Pawn is a potential Queen.",
  },
  {
    Author: "Jamie Walter Adams",
    Quote:
      "These are not pieces, they are men! For any man to walk into the line of fire will be one less man in your army to fight for you. Value every troop and use him wisely, throw him not to the dogs as he is there to serve his King.",
  },
  {
    Author: "Jamie Walter Adams",
    Quote:
      "I'm not a materialistic person, in that, I don't suffer the lack or loss of money. The absence of worldly goods I don't look back on. For Chess is a way I can be as materialistic as I want without having to sell my soul.",
  },
  {
    Author: "Jan Tinman",
    Quote:
      "Half the variations which are calculated in a tournament game turn out to be completely superfluous. Unfortunately, no one knows in advance which half.",
  },
  {
    Author: "Jeremy Silman",
    Quote:
      "Fischer, who may or may not be mad as a hatter, has every right to be horrified.",
  },
  {
    Author: "Jeremy Silman",
    Quote:
      "If your opponent cannot do anything active, then don't rush the position; instead you should let him sit there, suffer, and beg you for a draw.",
  },
  {
    Author: "Jim Slater",
    Quote:
      "If you aren't afraid of Spassky, then I have removed the element of money",
  },
  {
    Author: "Johann Wolfgang von Goethe",
    Quote:
      "Daring ideas are like Chess men moved forward. They may be beaten, but they may start a winning game.",
  },
  {
    Author: "John Collins",
    Quote:
      "Not only will I predict his triumph over Botvinnik, but I'll go further and say that he'll probably be the greatest Chess player that ever lived.",
  },
  {
    Author: "John Collins",
    Quote:
      "Bobby is the finest Chess player this country ever produced. His memory for the moves, his brilliance in dreaming up combinations, and his fierce determination to win are uncanny.",
  },
  {
    Author: "John Jacobs",
    Quote:
      "At this time Fischer is simply a level above all the best Chessplayers in the world.",
  },
  {
    Author: "John van der Wiel",
    Quote:
      "When you absolutely don't know what to do anymore, it is time to panic.",
  },
  {
    Author: "Jose Raul Capablanca",
    Quote:
      "As one by one I mowed them down, my superiority soon became apparent.",
  },
  {
    Author: "Jose Raul Capablanca",
    Quote: "A good player is always lucky.",
  },
  {
    Author: "Jose Raul Capablanca",
    Quote:
      "You may learn much more from a game you lose than from a game you win. You will have to lose hundreds of games before becoming a good player.",
  },
  {
    Author: "Jose Raul Capablanca",
    Quote:
      "Chess books should be used as we use glasses: to assist the sight, although some players make use of them as if they thought they conferred sight.",
  },
  {
    Author: "Jose Raul Capablanca",
    Quote:
      "In order to improve your game, you must study the endgame before everything else, for whereas the endings can be studied and mastered by themselves, the middle game and the opening must be studied in relation to the endgame.",
  },
  {
    Author: "Joseph Platz",
    Quote: "Fischer is under obligation to nobody.",
  },
  {
    Author: "Judith Polgar",
    Quote:
      "Chess is thirty to forty percent psychology. You don't have this when you play a computer. I can't confuse it.",
  },
  {
    Author: "Keith Hayward",
    Quote: "I consider Fischer to be one of the greatest opening experts ever.",
  },
  {
    Author: "Ken Smith",
    Quote: "Bobby Fischer is the greatest Chess player who has ever lived.",
  },
  {
    Author: "Keres",
    Quote: "The older I grow, the more I value Pawns.",
  },
  {
    Author: "King Khusros II",
    Quote:
      "If a ruler does not understand Chess, how can he rule over a kingdom?",
  },
  {
    Author: "Koblentz",
    Quote: "No price is too great for the scalp of the enemy King.",
  },
  {
    Author: "Kurt Richter",
    Quote: "What would Chess be without silly mistakes?",
  },
  {
    Author: "Larry Evans",
    Quote: "Fischer wanted to give the Russians a taste of their own medicine.",
  },
  {
    Author: "Larry Evans",
    Quote:
      "Fischer is the strongest player in the world. In fact, the strongest player who ever lived.",
  },
  {
    Author: "Larry Evans",
    Quote: "Robert Fischer is a law unto himself.",
  },
  {
    Author: "Lisa Lane",
    Quote:
      "There's never before been a Chess player with such a thorough knowledge of the intricacies of the game and such an absolutely indomitable will to win. I think Bobby is the greatest player that ever lived.",
  },
  {
    Author: "Lodewijk Prins",
    Quote: "The only thing Chess players have in common is Chess.",
  },
  {
    Author: "Marcel Duchamp",
    Quote:
      "The Chess pieces are the block alphabet which shapes thoughts; and these thoughts, although making a visual design on the chessboard, express their beauty abstractly, like a poem.",
  },
  {
    Author: "Marcel Duchamp",
    Quote:
      "I am still a victim of Chess. It has all the beauty of art and much more. It cannot be commercialized. Chess is much purer than art in its social position.",
  },
  {
    Author: "Marcel Duchamp",
    Quote:
      "Not all artists are Chess players, but all Chess players are artists.",
  },
  {
    Author: "Mark Pasternak",
    Quote: "Chess is not like life... it has rules!",
  },
  {
    Author: "Mark Twain",
    Quote:
      "I played Chess with him and would have beaten him sometimes only he always took back his last move, and ran the game out differently.",
  },
  {
    Author: "Matthew Selman",
    Quote:
      "Chess isn't a game of speed, it is a game of speech through actions.",
  },
  {
    Author: "Maurice Ashley",
    Quote:
      "Look at Garry Kasparov. After he loses, invariably he wins the next game. He just kills the next guy. That's something that we have to learn to be able to do.",
  },
  {
    Author: "Max Euwe",
    Quote:
      "Alekhine is a poet who creates a work of art out of something that would hardly inspire another man to send home a picture post card.",
  },
  {
    Author: "Max Euwe",
    Quote: "Strategy requires thought, tactics require observation.",
  },
  {
    Author: "Mednis",
    Quote: "Winning isn't everything... but losing is nothing.",
  },
  {
    Author: "Mig Greengard",
    Quote:
      "Fischer is an American Chess tragedy on par with Morphy and Pillsbury.",
  },
  {
    Author: "Miguel Najdorf",
    Quote: "Fischer prefers to enter Chess history alone.",
  },
  {
    Author: "Miguel Najdorf",
    Quote:
      "I won't play with you anymore. You have insulted my friend --when an opponent cursed himself for a blunder.",
  },
  {
    Author: "Miguel Najdorf",
    Quote: "Bobby just drops the pieces and they fall on the right squares.",
  },
  {
    Author: "Mike Goodall",
    Quote: "Spassky will not be psyched out by Fischer.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "Suddenly it was obvious to me in my analysis I had missed what Fischer had found with the greatest of ease at the board.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "Everything is in a state of flux, and this includes the world of Chess.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "Chess, like any creative activity, can exist only through the combined efforts of those who have creative talent, and those who have the ability to organize their creative work.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "Chess is a part of culture and if a culture is declining then Chess too will decline.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "Chess mastery essentially consists of analyzing Chess positions accurately.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "The boy (then a 12-year-old boy named Anatoly Karpov) doesn't have a clue about Chess, and there's no future at all for him in this profession.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote: "Chess is the art which expresses the science of logic.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote: "Chess is the art of analysis.",
  },
  {
    Author: "Mikhail Botvinnik",
    Quote:
      "Chess is no whit inferior to the violin, and we have a large number of professional violinists",
  },
  {
    Author: "Mikhail Tal",
    Quote:
      "Many Chess players were surprised when after the game, Fischer quietly explained: 'I had already analyzed this possibility' in a position which I thought was not possible to foresee from the opening",
  },
  {
    Author: "Mikhail Tal",
    Quote:
      "When I asked Fischer why he had not played a certain move in our game, he replied: 'Well, you laughed when I wrote it down!'",
  },
  {
    Author: "Mikhail Tal",
    Quote:
      "It is difficult to play against Einstein's theory --on his first loss to Fischer.",
  },
  {
    Author: "Mikhail Tal",
    Quote:
      "You must take your opponent into a deep dark forest where 2+2=5, and the path leading out is only wide enough for one.",
  },
  {
    Author: "Mikhail Tal",
    Quote: "There are two types of sacrifices: correct ones and mine.",
  },
  {
    Author: "Mikhail Tal",
    Quote: "Some sacrifices are sound; the rest are mine.",
  },
  {
    Author: "Mikhail Tal",
    Quote:
      "Later, I began to succeed in decisive games. Perhaps because I realized a very simple truth: not only was I worried, but also my opponent",
  },
  {
    Author: "Mortimer Collins",
    Quote:
      "There are two classes of men; those who are content to yield to circumstances and who play whist; those who aim to control circumstances, and who play Chess.",
  },
  {
    Author: "Nigel Short",
    Quote:
      "If your opponent offers you a draw, try to work out why he thinks he's worse off.",
  },
  {
    Author: "Nigel Short",
    Quote: "Chess is ruthless: you've got to be prepared to kill people.",
  },
  {
    Author: "Nigel Short",
    Quote:
      "Modern Chess is too much concerned with things like Pawn structure. Forget it, Checkmate ends the game.",
  },
  {
    Author: "Paul Keres",
    Quote:
      "In complicated positions, Bobby Fischer hardly had to be afraid of anybody.",
  },
  {
    Author: "Paul Kollar",
    Quote:
      "Fischer sacrificed virtually everything most of us weakies (to use his term) value, respect, and cherish, for the sake of an artful, often beautiful board game, for the ambivalent privilege of being its greatest master.",
  },
  {
    Author: "Paul Morphy",
    Quote:
      "The ability to play chess is the sign of a gentleman. The ability to play chess well is the sign of a wasted life.",
  },
  {
    Author: "Paul Morphy",
    Quote: "Help your pieces so they can help you.",
  },
  {
    Author: "Paul Morphy",
    Quote: "Chess is eminently and emphatically the philosopher's game.",
  },
  {
    Author: "Paul Morphy",
    Quote:
      "I am more strongly confirmed than ever in the belief that the time devoted to chess is literally frittered away.",
  },
  {
    Author: "Paul Morphy",
    Quote:
      "Chess never has been and never can be aught but a recreation. It should not be indulged in to the detriment of other and more serious avocations.",
  },
  {
    Author: "Paul Morphy",
    Quote:
      "It [chess] is not only the most delightful and scientific, but the most moral of amusements.",
  },
  {
    Author: "Paul Morphy",
    Quote:
      "Let the chessboard supersede the card table, and a great improvement will be visible in the morals of the community.",
  },
  {
    Author: "François-André Danican Philidor",
    Quote: "The Pawns are the soul of the game.",
  },
  {
    Author: "François-André Danican Philidor",
    Quote:
      "A Pawn, when separated from his fellows, will seldom or never make a fortune.",
  },
  {
    Author: "François-André Danican Philidor",
    Quote:
      "Pawns: they are the soul of this game, they alone form the attack and defense.",
  },
  {
    Author: "François-André Danican Philidor",
    Quote:
      "It is always advantageous to exchange your king's bishop pawn for the king's pawn, since this leads to the seizure of the centre and, in addition, to the opening of a file for the rook.",
  },
  {
    Author: "François-André Danican Philidor",
    Quote: "The pawn is the soul of chess.",
  },
  {
    Author: "Proverb",
    Quote: "A player surprised is half beaten.",
  },
  {
    Author: "Purdy",
    Quote:
      "Examine moves that smite! A good eye for smites is far more important than a knowledge of strategical principles.",
  },
  {
    Author: "Purdy",
    Quote:
      "Any material change in a position must come about by mate, a capture, or a Pawn promotion.",
  },
  {
    Author: "Purdy",
    Quote: "Chess is as much a mystery as women.",
  },
  {
    Author: "Ralph Charell",
    Quote:
      "Avoid the crowd. Do your own thinking independently. Be the Chess player, not the Chess piece.",
  },
  {
    Author: "Renaud and Kahn",
    Quote: "Chess is played with the mind and not with the hands!",
  },
  {
    Author: "Reshevsky",
    Quote: "My style is somewhere between that of Tal and Petrosian.",
  },
  {
    Author: "Reti",
    Quote: "Chess was Capablanca's mother tongue.",
  },
  {
    Author: "Reuben Fine",
    Quote: "I'd rather have a Pawn than a finger.",
  },
  {
    Author: "Reuben Fine",
    Quote: "Discovered check is the dive bomber of the Chessboard.",
  },
  {
    Author: "Richard Dawkings",
    Quote:
      "Personally, I rather look forward to a computer program winning the world Chess Championship. Humanity needs a lesson in humility.",
  },
  {
    Author: "Richard Fenton",
    Quote: "A man that will take back a move at Chess will pick a pocket.",
  },
  {
    Author: "Richard Reti",
    Quote:
      "Chess is a fighting game which is purely intellectual and includes chance.",
  },
  {
    Author: "Richard Reti",
    Quote:
      "It is the aim of the modern school, not to treat every position according to one general law, but according to the principle inherent in the position.",
  },
  {
    Author: "Rick Kennedy",
    Quote: "Pawns are born free, yet they are everywhere in chains",
  },
  {
    Author: "Rob Sillars",
    Quote: "Castle early and often.",
  },
  {
    Author: "Rob Sillars",
    Quote: "Only sissies Castle.",
  },
  {
    Author: "Robert Hubner",
    Quote: "Those who say they understand Chess, understand nothing.",
  },
  {
    Author: "Ron Gross",
    Quote:
      "I guess a certain amount of temperament is expected of Chess geniuses.",
  },
  {
    Author: "Rudolf Spielman",
    Quote:
      "We cannot resist the fascination of sacrifice, since a passion for sacrifices is part of a Chessplayer's nature.",
  },
  {
    Author: "Rudolph Spielmann",
    Quote:
      "A good sacrifice is one that is not necessarily sound but leaves your opponent dazed and confused.",
  },
  {
    Author: "Salo Flohr",
    Quote: "Is Bobby Fischer quite sane?",
  },
  {
    Author: "Samuel Standige Boden",
    Quote:
      "In a gambit you give up a Pawn for the sake of getting a lost game.",
  },
  {
    Author: "Saudin Robovic",
    Quote:
      "Becoming successful at Chess allows you to discover your own personality. That's what I want for the kids I teach.",
  },
  {
    Author: "Saudin Robovic",
    Quote:
      "Chess is a terrific way for kids to build self-image and self-esteem.",
  },
  {
    Author: "Saudin Robovic",
    Quote: "Chess opens and enriches your mind.",
  },
  {
    Author: "Savielly Tartakover",
    Quote:
      "The tactician must know what to do whenever something needs doing; the strategist must know what to do when nothing needs doing.",
  },
  {
    Author: "Savielly Tartakover",
    Quote: "The blunders are all there on the board, waiting to be made.",
  },
  {
    Author: "Savielly Tartakover",
    Quote: "To avoid losing a piece, many a person has lost the game.",
  },
  {
    Author: "Savielly Tartakover",
    Quote: "It's always better to sacrifice your opponent's men.",
  },
  {
    Author: "Savielly Tartakover",
    Quote: "Some part of a mistake is always correct.",
  },
  {
    Author: "Savielly Tartakover",
    Quote:
      "The winner of the game is the player who makes the next-to-last mistake.",
  },
  {
    Author: "Savielly Tartakower",
    Quote: "It is always better to sacrifice your opponent's men.",
  },
  {
    Author: "Savielly Tartakower",
    Quote: "All Chess players should have a hobby.",
  },
  {
    Author: "Savielly Tartakower",
    Quote:
      "A Chess game is divided into three stages: the first, when you hope you have the advantage, the second when you believe you have an advantage, and the third... when you know you're going to lose!",
  },
  {
    Author: "Savielly Tartakower",
    Quote: "The mistakes are there, waiting to be made.",
  },
  {
    Author: "Savielly Tartakower",
    Quote: "Chess is a fairy tale of 1001 blunders.",
  },
  {
    Author: "Saviely Tartakower",
    Quote: "No one ever won a game by resigning.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "I had a toothache during the first game. In the second game I had a headache. In the third game it was an attack of rheumatism. In the fourth game, I wasn't feeling well. And in the fifth game? Well, must one have to win every game?",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "I have always a slight feeling of pity for the man who has no knowledge of Chess.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "White lost because he failed to remember the right continuation and had to think up the moves himself.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "Up to this point White has been following well-known analysis. But now he makes a fatal error: he begins to use his own head.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "Weak points or holes in the opponent's position must be occupied by pieces not Pawns.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "First-class players lose to second-class players because second-class players sometimes play a first-class game.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "A thorough understanding of the typical mating continuations makes the most complicated sacrificial combinations leading up to them not only not difficult, but almost a matter of course.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "Chess is a terrible game. If you have no center, your opponent has a freer position. If you do have a center, then you really have something to worry about!",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "When you don't know what to play, wait for an idea to come into your opponent's mind. You may be sure that idea will be wrong.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote: "I look one move ahead... the best!",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "One of these modest little moves may be more embarrassing to your opponent than the biggest threat.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "What is the object of playing a gambit opening?... To acquire a reputation of being a dashing player at the cost of losing a game.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote: "Mistrust is the most necessary characteristic of the Chess player.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "Chess is a terrible game. If you have no center, your opponent has a freer position. If you do have a center, then you really have something to worry about!",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "A thorough understanding of the typical mating continuations makes the most complicated sacrificial combinations leading up to them not only not difficult, but almost a matter of course.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote: "Chess, like love, like music, has the power to make people happy.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote: "It is not enough to be a good player... you must also play well.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote: "He who fears an isolated Queen's Pawn should give up Chess.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "One doesn't have to play well, it's enough to play better than your opponent.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote: "Before the endgame, the Gods have placed the middle game.",
  },
  {
    Author: "Siegbert Tarrasch",
    Quote:
      "Many have become Chess Masters, no one has become the Master of Chess.",
  },
  {
    Author: "Sir Arthur Conan Doyle",
    Quote: "Excellence at Chess is one mark of a scheming mind.",
  },
  {
    Author: "Sir John Simon",
    Quote: "Chess is a cold bath for the mind.",
  },
  {
    Author: "Soltis",
    Quote: "Chess is really ninety-nine percent calculation.",
  },
  {
    Author: "Soren Kierkegaard",
    Quote:
      "I feel as if I were a piece in a game of Chess, when my opponent says of it: That piece cannot be moved.",
  },
  {
    Author: "Spielmann",
    Quote:
      "Play the opening like a book, the middle game like a magician, and the endgame like a machine.",
  },
  {
    Author: "Stanley Ellin",
    Quote: "The way he plays Chess demonstrates a man's whole nature.",
  },
  {
    Author: "Stanley Kubrick",
    Quote:
      "Chess teaches you to control the initial excitement you feel when you see something that looks good and it trains you to think objectively when you're in trouble.",
  },
  {
    Author: "Stephan Gerzadowicz",
    Quote: "Openings teach you openings. Endgames teach you chess!",
  },
  {
    Author: "Sytze Faber",
    Quote:
      "We must make sure that Chess will not be like a dead language, very interesting, but for a very small group.",
  },
  {
    Author: "Teichmann",
    Quote: "Chess is 99 percent tactics.",
  },
  {
    Author: "Tevis",
    Quote: "When in doubt... play Chess!",
  },
  {
    Author: "Thomas Huxley",
    Quote:
      "The chessboard is the world, the pieces are the phenomena of the Universe, the rules of the game are what we call the laws of Nature and the player on the other side is hidden from us.",
  },
  {
    Author: "Tim Harding",
    Quote:
      "Already at 15 years of age he was a Grandmaster, a record at that time, and his battle to reach the top was the background for all the major Chess events of the 1960.",
  },
  {
    Author: "Viktor Korchnoi",
    Quote:
      "No Chess Grandmaster is normal; they only differ in the extent of their madness.",
  },
  {
    Author: "Vladimir Nabokov",
    Quote:
      "Chess problems demand from the composer the same virtues that characterize all worthwhile art: originality, invention, conciseness, harmony, complexity, and splendid insincerity.",
  },
  {
    Author: "Vladimir Nabokov",
    Quote:
      "Of all my Russian books, the defense contains and diffuses the greatest 'warmth' which may seem odd seeing how supremely abstract Chess is supposed to be.",
  },
  {
    Author: "Vladimir Putin",
    Quote: "Chess makes man wiser and clear-sighted.",
  },
  {
    Author: "Vlastimil Hort",
    Quote: "In blitz, the Knight is stronger than the Bishop",
  },
  {
    Author: "Vlastimil Hort",
    Quote:
      "I believe that Chess possesses a magic that is also a help in advanced age. A rheumatic knee is forgotten during a game of Chess and other events can seem quite unimportant in comparison with a catastrophe on the chessboard.",
  },
  {
    Author: "Walter Shipman",
    Quote:
      "It began to feel as though you were playing against Chess itself --on playing against Robert Fischer.",
  },
  {
    Author: "Wilhelm Steinitz",
    Quote: "The King is a fighting piece. Use it!",
  },
  {
    Author: "Wilhelm Steinitz",
    Quote:
      "I have never in my life played the French Defence, which is the dullest of all openings.",
  },
  {
    Author: "Wilhelm Steinitz",
    Quote:
      "Chess is so inspiring that I do not believe a good player is capable of having an evil thought during the game.",
  },
  {
    Author: "Wilhelm Steinitz",
    Quote: "A sacrifice is best refuted by accepting it.",
  },
  {
    Author: "Wilhelm Steinitz",
    Quote: "Only the player with the initiative has the right to attack.",
  },
  {
    Author: "Wilhelm Steinitz",
    Quote:
      "A win by an unsound combination, however showy, fills me with artistic horror.",
  },
  {
    Author: "William Ewart Napier",
    Quote:
      "Of Chess it has been said that life is not long enough for it, but that is the fault of life, not Chess.",
  },
  {
    Author: "William Steinitz",
    Quote:
      "Capture of the adverse King is the ultimate but not the first object of the game.",
  },
  {
    Author: "Yasser Seirawan",
    Quote:
      "Though most people love to look at the games of the great attacking masters, some of the most successful players in history have been the quiet. positional players. They slowly grind you down by taking away your space, tying up your pieces, and leaving you with virtually nothing to do!",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "Let the perfectionist play postal.",
  },
  {
    Author: "Yasser Seirawan",
    Quote:
      "Bobby is the most misunderstood, misquoted celebrity walking the face of this earth.",
  },
  {
    Author: "Yasser Seirawan",
    Quote:
      "Describing one competitive advantage of IBM's Deep Blue chess computer. It has no fear.",
  },
  {
    Author: "Yasser Seirawan",
    Quote:
      "How come the little things bother you when you are in a bad position? They don't bother you in good positions.",
  },
  {
    Author: "Yasser Seirawan",
    Quote:
      "Throughout chess history, great debates have raged about the pros and cons of hanging pawns. The debates are nonsense; the answer is cut and dried. If the pawns can be attacked and forced to move forward, they are weak. If they can be defended and remain where they are, they are strong.",
  },
  {
    Author: "Yasser Seirawan",
    Quote:
      "The world championship is a disputed title. You've got a situation like boxing. Speaking as a member of the chess world, it's extremely undignified.",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "Boy, that was a bear trap! You got both limbs, I can’t escape.",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "It was a good grub. (pawn-wise)",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "Protect your children – don’t show them the games of MVL.",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "Some games are more drawn than others.",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "I gotta stay with the horse I brought to the party.",
  },
  {
    Author: "Yasser Seirawan",
    Quote: "Learn to love the training, and all goals are possible.",
  },
  {
    Author: "Yates and Winter",
    Quote:
      "In the perfect Chess combination as in a first-rate short story, the whole plot and counter-plot should lead up to a striking finale, the interest not being allayed until the very last moment.",
  },
  {
    Author: "Yuri Balashov",
    Quote:
      "Do you realize Fischer almost never has any bad pieces? He exchanges them, and the bad pieces remain with his opponents.",
  },
  {
    Author: "Zita Rajcsanyi",
    Quote:
      "Fischer is completely natural. He plays no roles. He's like a child. Very, very simple.",
  },
];
