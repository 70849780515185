// Feature for managing Firebase Authentication
import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { auth } from "../config/firebase";

export async function handleEmailLogin(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (!userCredential.user.emailVerified) {
      await signOut(auth); // Sign out the user immediately
      return {
        success: false,
        error: "Email not verified. Please check your inbox.",
      };
    }
    return { success: true };
  } catch (error) {
    console.error("Error signing in:", error.message);
    return { success: false, error: getFriendlyErrorMessage(error) };
  }
}

export async function handleGoogleSignIn() {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
    return true;
  } catch (error) {
    console.error("Error signing in with Google:", error.message);
    return { success: false, error: getFriendlyErrorMessage(error) };
  }
}

export async function handleEmailSignUp(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await sendEmailVerification(userCredential.user);
    return true;
  } catch (error) {
    console.error("Error creating new account:", error.message);
    return { success: false, error: getFriendlyErrorMessage(error) };
  }
}

export async function handlePasswordReset(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    console.error("Error sending password reset email:", error.message);
    return { success: false, error: getFriendlyErrorMessage(error) };
  }
}

export async function logout() {
  try {
    await signOut(auth);
  } catch (err) {}
}

function getFriendlyErrorMessage(error) {
  switch (error.code) {
    case "auth/invalid-email":
      return "Invalid email address. Please check and try again.";
    case "auth/user-disabled":
      return "This account has been disabled. Please contact support for help.";
    case "auth/user-not-found":
      return "No account found with this email. Please check and try again.";
    case "auth/wrong-password":
      return "Incorrect password. Please check and try again.";
    case "auth/email-already-in-use":
      return "This email is already in use. Please use a different email.";
    case "auth/weak-password":
      return "Password is too weak. Please use a stronger password.";
    case "auth/network-request-failed":
      return "Network error. Please check your connection and try again.";
    case "auth/too-many-requests":
      return "Too many login attempts. Please try again later.";
    case "auth/invalid-credential":
      return "Invalid credential";
    default:
      return "An error occurred. Please try again.";
  }
}
