import React from "react";
import { SvgIcon } from "@mui/material";

function CrosswordIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.8) translate(100, 100)">
        <path d="m1140 240h-396v-180c0-19.875-16.125-36-36-36h-216c-19.875 0-36 16.125-36 36v180h-180c-19.875 0-36 16.125-36 36v216c0 19.875 16.125 36 36 36h180v144h-396c-19.875 0-36 16.125-36 36v216c0 19.875 16.125 36 36 36h396v180c0 19.875 16.125 36 36 36h216c19.875 0 36-16.125 36-36v-612h396c19.875 0 36-16.125 36-36v-216c0-19.875-16.125-36-36-36zm-612-144h144v144h-144zm144 216v144h-144v-144zm-360 144v-144h144v144zm-216 288h144v144h-144zm216 144v-144h144v144zm216-144h144v144h-144zm0 360v-144h144v144zm144-432h-144v-144h144zm72-360h144v144h-144zm360 144h-144v-144h144z" />
      </g>
    </SvgIcon>
  );
}

export default CrosswordIcon;
