import React, { useState } from "react";
import {
  Box,
  Container,
  Button,
  useTheme,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { tokens } from "../styles/theme";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import CloseIcon from "@mui/icons-material/Close";
import PatreonIcon from "../icons/PatreonIcon";
import ShareComponent from "./ShareComponent";
import { useTranslation } from "react-i18next";

const AppTopbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false); // State to control the visibility of the dropdown

  const handleToggle = () => setOpen((prev) => !prev); // Toggle the dropdown visibility
  const handleClose = () => setOpen(false); // Close the dropdown

  const { t } = useTranslation("Menu");

  return (
    <Box
      component="footer"
      sx={{
        m: 0,
        p: 0,
        position: "sticky",
        top: 0,
        width: "100%",
        height: "50px",
        pt: 0.5,
        borderBottom: `0.5px solid ${colors.black[300]}`,
        backgroundColor: colors.black[100],
        zIndex: 10,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
          maxWidth: "100%",
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0px",
            position: "relative",
          }}
        >
          {/* Left-aligned Share button */}
          <Button
            variant="none"
            style={{ marginRight: 10 }}
            startIcon={<ShareRoundedIcon />}
            onClick={handleToggle} // Toggle the dropdown box when clicked
          >
            {t("share")}
          </Button>

          <Box>
            {/* Right-aligned social icons
            <IconButton
              component="a"
              href="https://www.facebook.com/chessboardmagic"
              target="_blank"
              sx={{ color: "#1877f2" }}
            >
              <FacebookIcon />
            </IconButton>

            <IconButton
              component="a"
              href="https://www.twitter.com/chessboardmagic"
              target="_blank"
              sx={{ color: "#1DA1F2" }}
            >
              <TwitterIcon />
            </IconButton> */}

            <Tooltip title="Visit my Lichess profile">
              <IconButton
                component="a"
                href="https://lichess.org/@/HollowLeaf"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/${
                    useTheme().palette.mode === "dark"
                      ? "lichessdotorgwhite.png"
                      : "lichessdotorg.png"
                  }`}
                  alt="Lichess"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Visit my Chess.com profile">
              <IconButton
                component="a"
                href="https://www.chess.com/member/HollowLeaf"
                target="_blank"
                sx={{
                  color: useTheme().palette.mode === "dark" ? "#fff" : "#000",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/chessdotcom.png`}
                  alt="Chess.com"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Support us on Patreon">
              <IconButton
                component="a"
                href="https://patreon.com/ChessboardMagic"
                target="_blank"
                sx={{ color: colors.black[900] }}
              >
                <PatreonIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Dropdown box for ShareComponent */}
          {open && (
            <Paper
              sx={{
                position: "absolute",
                top: "100%", // Position directly below the Share button
                left: 0,
                mt: 1,
                width: 300,
                boxShadow: 24,
                zIndex: 10,
                p: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ShareComponent />
              <IconButton
                onClick={handleClose}
                aria-label="close"
                sx={{ ml: 1 }}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AppTopbar;
