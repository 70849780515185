import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const GuessTheEvalHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <Box>
      <Typography>{t("GuessTheEval.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheEval.helpDetails.instructions.start_game.primary"
            )}
            secondary={t(
              "GuessTheEval.helpDetails.instructions.start_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheEval.helpDetails.instructions.make_guess.primary"
            )}
            secondary={t(
              "GuessTheEval.helpDetails.instructions.make_guess.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheEval.helpDetails.instructions.check_eval.primary"
            )}
            secondary={t(
              "GuessTheEval.helpDetails.instructions.check_eval.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheEval.helpDetails.instructions.improve_skills.primary"
            )}
            secondary={t(
              "GuessTheEval.helpDetails.instructions.improve_skills.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheEvalHelp;
