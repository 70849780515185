import React, { useState, useEffect } from "react";
import { Chess } from "chess.js";
import {
  Box,
  Grid,
  useTheme,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChessSlideHelp from "../../../help/ChessSlideHelp";
import HelpModal from "../../../components/HelpModal";
import { Helmet } from "react-helmet";
import { eco_12 } from "../../../data/eco/eco";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { themeColors } from "../../../styles/boardtheme";
import { trackEvent } from "../../../config/ga";
import { useUser } from "../../../context/UserContext";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";

import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

const initialPuzzle = [
  [
    { id: 1, colour: [1, 0, 0, 1], piece: ["bR", "bN", "bP", "bP"] },
    { id: 2, colour: [1, 0, 0, 1], piece: ["bB", "bQ", "bP", "bP"] },
    { id: 3, colour: [1, 0, 0, 1], piece: ["bK", "bB", "bP", "bP"] },
    { id: 4, colour: [1, 0, 0, 1], piece: ["bN", "bR", "bP", "bP"] },
  ],
  [
    { id: 5, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
    { id: 6, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
    { id: 7, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
    { id: 8, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
  ],
  [
    { id: 9, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
    { id: 10, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
    { id: 11, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
    { id: 12, colour: [1, 0, 0, 1], piece: ["", "", "", ""] },
  ],
  [
    { id: 13, colour: [1, 0, 0, 1], piece: ["wP", "wP", "wR", "wN"] },
    { id: 14, colour: [1, 0, 0, 1], piece: ["wP", "wP", "wB", "wQ"] },
    { id: 15, colour: [1, 0, 0, 1], piece: ["wP", "wP", "wK", "wB"] },
    { id: 16, colour: [1, 0, 0, 1], piece: ["wP", "wP", "wN", "wR"] },
  ],
];

function getTileTooltip(id, item) {
  if (id === 1) {
    if (item === 1) {
      return "A8";
    } else if (item === 2) {
      return "B8";
    } else if (item === 3) {
      return "A7";
    } else {
      return "B7";
    }
  } else if (id === 2) {
    if (item === 1) {
      return "C8";
    } else if (item === 2) {
      return "D8";
    } else if (item === 3) {
      return "C7";
    } else {
      return "D7";
    }
  } else if (id === 3) {
    if (item === 1) {
      return "E8";
    } else if (item === 2) {
      return "F8";
    } else if (item === 3) {
      return "E7";
    } else {
      return "F7";
    }
  } else if (id === 4) {
    if (item === 1) {
      return "G8";
    } else if (item === 2) {
      return "H8";
    } else if (item === 3) {
      return "G7";
    } else {
      return "H7";
    }
  } else if (id === 5) {
    if (item === 1) {
      return "A6";
    } else if (item === 2) {
      return "B6";
    } else if (item === 3) {
      return "A5";
    } else {
      return "B5";
    }
  } else if (id === 6) {
    if (item === 1) {
      return "C6";
    } else if (item === 2) {
      return "D6";
    } else if (item === 3) {
      return "C5";
    } else {
      return "D5";
    }
  } else if (id === 7) {
    if (item === 1) {
      return "E6";
    } else if (item === 2) {
      return "F6";
    } else if (item === 3) {
      return "E5";
    } else {
      return "F5";
    }
  } else if (id === 8) {
    if (item === 1) {
      return "G6";
    } else if (item === 2) {
      return "H6";
    } else if (item === 3) {
      return "G5";
    } else {
      return "H5";
    }
  } else if (id === 5) {
    if (item === 1) {
      return "A6";
    } else if (item === 2) {
      return "B6";
    } else if (item === 3) {
      return "A5";
    } else {
      return "B5";
    }
  } else if (id === 6) {
    if (item === 1) {
      return "C6";
    } else if (item === 2) {
      return "D6";
    } else if (item === 3) {
      return "C5";
    } else {
      return "D5";
    }
  } else if (id === 7) {
    if (item === 1) {
      return "E6";
    } else if (item === 2) {
      return "F6";
    } else if (item === 3) {
      return "E5";
    } else {
      return "F5";
    }
  } else if (id === 8) {
    if (item === 1) {
      return "G6";
    } else if (item === 2) {
      return "H6";
    } else if (item === 3) {
      return "G5";
    } else {
      return "H5";
    }
  } else if (id === 9) {
    if (item === 1) {
      return "A4";
    } else if (item === 2) {
      return "B4";
    } else if (item === 3) {
      return "A3";
    } else {
      return "B3";
    }
  } else if (id === 10) {
    if (item === 1) {
      return "C4";
    } else if (item === 2) {
      return "D4";
    } else if (item === 3) {
      return "C3";
    } else {
      return "D3";
    }
  } else if (id === 11) {
    if (item === 1) {
      return "E4";
    } else if (item === 2) {
      return "F4";
    } else if (item === 3) {
      return "E3";
    } else {
      return "F3";
    }
  } else if (id === 12) {
    if (item === 1) {
      return "G4";
    } else if (item === 2) {
      return "H4";
    } else if (item === 3) {
      return "G3";
    } else {
      return "H3";
    }
  } else if (id === 13) {
    if (item === 1) {
      return "A2";
    } else if (item === 2) {
      return "B2";
    } else if (item === 3) {
      return "A1";
    } else {
      return "B1";
    }
  } else if (id === 14) {
    if (item === 1) {
      return "C2";
    } else if (item === 2) {
      return "D2";
    } else if (item === 3) {
      return "C1";
    } else {
      return "D1";
    }
  } else if (id === 15) {
    if (item === 1) {
      return "E2";
    } else if (item === 2) {
      return "F2";
    } else if (item === 3) {
      return "E1";
    } else {
      return "F1";
    }
  } else if (id === 16) {
    if (item === 1) {
      return "G2";
    } else if (item === 2) {
      return "H2";
    } else if (item === 3) {
      return "G1";
    } else {
      return "H1";
    }
  } else {
    return "";
  }
}

const Tile = ({ tile, onClick, colors, uniqueKey, tileWidth }) => {
  const { userData } = useUser();
  const selectedTheme = themeColors[userData?.theme || "Modern Minimal"];

  return (
    <div
      variant="outlined"
      style={{
        width: tileWidth,
        height: tileWidth,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: tile.id === null ? "auto" : "pointer", // Disable pointer for null id
        userSelect: "none",
        background:
          tile.colour && tile.colour[0] === 0 ? colors.blue[500] : "white",
      }}
      onClick={tile.id !== null ? onClick : null} // Only attach click handler if id is not null
    >
      <Grid container spacing={0}>
        {[0, 1, 2, 3].map((index) => (
          <Tooltip
            key={`${uniqueKey}-${index}`} // Use uniqueKey here
            title={getTileTooltip(tile.id, index + 1)}
            followCursor
            arrow
            placement="top"
          >
            <Grid
              item
              key={index} // Add a unique key here
              sx={{
                flex: 1,
                background:
                  tile.id && tile.colour && tile.colour[index] === 0
                    ? selectedTheme.darkSquare
                    : selectedTheme.lightSquare,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: tile.id ? "0.5px solid black" : "none",
                height: tileWidth / 2,
                width: tileWidth / 2,
              }}
            >
              {tile.id !== null && tile.piece[index] !== "" && (
                <img
                  src={`img/chesspieces/${
                    userData?.pieceset?.toLowerCase() || "maestro"
                  }/${tile.piece[index][0].toLowerCase()}${tile.piece[
                    index
                  ][1].toUpperCase()}.png`}
                  alt={`Piece ${index}`}
                  width={tileWidth / 2 - (tileWidth >= 120 ? 10 : 5)}
                  height={tileWidth / 2 - (tileWidth >= 120 ? 10 : 5)}
                />
              )}
              {tile.id !== null && tile.piece[index] === "" && (
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  alt={`Piece ${index}`}
                  width={tileWidth / 2 - (tileWidth >= 120 ? 10 : 5)}
                  height={tileWidth / 2 - (tileWidth >= 120 ? 10 : 5)}
                />
              )}
              {tile.id === null && (
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  alt={`Piece ${index}`}
                  width={tileWidth / 2 - (tileWidth >= 120 ? 10 : 5)}
                  height={tileWidth / 2 - (tileWidth >= 120 ? 10 : 5)}
                />
              )}
            </Grid>
          </Tooltip>
        ))}
      </Grid>
    </div>
  );
};

const ChessSlide = () => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const { setShareData } = useShare();

  const [puzzle, setPuzzle] = useState(initialPuzzle);
  const [selectedPgn, setSelectedPgn] = useState("");
  const [selectedPgnName, setSelectedPgnName] = useState("");
  const [status, setStatus] = useState("");
  const { userData, userRef } = useUser();
  const { t } = useTranslation("Games");
  const [matches, setMatches] = useState();

  function getFen(pgn) {
    const chess = new Chess();

    // Remove move numbers and extra spaces from the PGN
    const cleanPgn = pgn.replace(/\d+\.\s+/g, "");

    // Split the cleaned PGN by spaces
    const moves = cleanPgn.split(/\s+/).filter(Boolean);

    moves.forEach((move) => {
      chess.move(move);
    });

    const fen = chess.fen();

    return fen;
  }

  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  useEffect(() => {
    if (selectedPgn) {
      let fenArray = parseFen(getFen(selectedPgn));
      // Get the blank puzzle
      let newPuzzle = JSON.parse(JSON.stringify(initialPuzzle));
      newPuzzle[0][0].piece = [
        fenArray[0][0],
        fenArray[0][1],
        fenArray[1][0],
        fenArray[1][1],
      ];
      newPuzzle[0][1].piece = [
        fenArray[0][2],
        fenArray[0][3],
        fenArray[1][2],
        fenArray[1][3],
      ];
      newPuzzle[0][2].piece = [
        fenArray[0][4],
        fenArray[0][5],
        fenArray[1][4],
        fenArray[1][5],
      ];
      newPuzzle[0][3].piece = [
        fenArray[0][6],
        fenArray[0][7],
        fenArray[1][6],
        fenArray[1][7],
      ];
      newPuzzle[1][0].piece = [
        fenArray[2][0],
        fenArray[2][1],
        fenArray[3][0],
        fenArray[3][1],
      ];
      newPuzzle[1][1].piece = [
        fenArray[2][2],
        fenArray[2][3],
        fenArray[3][2],
        fenArray[3][3],
      ];
      newPuzzle[1][2].piece = [
        fenArray[2][4],
        fenArray[2][5],
        fenArray[3][4],
        fenArray[3][5],
      ];
      newPuzzle[1][3].piece = [
        fenArray[2][6],
        fenArray[2][7],
        fenArray[3][6],
        fenArray[3][7],
      ];
      newPuzzle[2][0].piece = [
        fenArray[4][0],
        fenArray[4][1],
        fenArray[5][0],
        fenArray[5][1],
      ];
      newPuzzle[2][1].piece = [
        fenArray[4][2],
        fenArray[4][3],
        fenArray[5][2],
        fenArray[5][3],
      ];
      newPuzzle[2][2].piece = [
        fenArray[4][4],
        fenArray[4][5],
        fenArray[5][4],
        fenArray[5][5],
      ];
      newPuzzle[2][3].piece = [
        fenArray[4][6],
        fenArray[4][7],
        fenArray[5][6],
        fenArray[5][7],
      ];
      newPuzzle[3][0].piece = [
        fenArray[6][0],
        fenArray[6][1],
        fenArray[7][0],
        fenArray[7][1],
      ];
      newPuzzle[3][1].piece = [
        fenArray[6][2],
        fenArray[6][3],
        fenArray[7][2],
        fenArray[7][3],
      ];
      newPuzzle[3][2].piece = [
        fenArray[6][4],
        fenArray[6][5],
        fenArray[7][4],
        fenArray[7][5],
      ];
      newPuzzle[3][3].piece = [
        fenArray[6][6],
        fenArray[6][7],
        fenArray[7][6],
        fenArray[7][7],
      ];

      newPuzzle[getRandomNumber(3)][getRandomNumber(3)].id = null;
      let shuffledPuzzle = shuffle2DArray(newPuzzle);
      setPuzzle(shuffledPuzzle);
    }
  }, [selectedPgn]);

  function shuffle2DArray(arr2D) {
    // Step 1: Flatten the 2D array into a 1D array
    const flatArray = arr2D.flat();

    // Step 2: Shuffle the elements in the 1D array randomly
    for (let i = flatArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [flatArray[i], flatArray[j]] = [flatArray[j], flatArray[i]];
    }

    // Step 3: Reconstruct the 2D array from the shuffled 1D array
    const size = Math.sqrt(flatArray.length);
    const shuffled2DArray = [];
    for (let i = 0; i < size; i++) {
      const row = [];
      for (let j = 0; j < size; j++) {
        row.push(flatArray[i * size + j]);
      }
      shuffled2DArray.push(row);
    }

    return shuffled2DArray;
  }

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/chessslide",
      title: "Chessboard Magic - Chess Slide",
      description:
        "Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const initializePuzzle = () => {
    let randomIndex = Math.floor(Math.random() * eco_12.length);
    let newSelectedPgn = eco_12[randomIndex];

    setSelectedPgnName(newSelectedPgn.name);
    setSelectedPgn(newSelectedPgn.pgn);
    setStatus("");

    // GA Tracking
    trackEvent("Games", "ChessSlide-Play", "Chess Slide");
    // Internal Tracking
    incrementGameProperty("ChessSlide-Plays");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.ChessSlide) {
        userData.Puzzles.ChessSlide.Played =
          (userData.Puzzles.ChessSlide.Played || 0) + 1;
      } else {
        userData.Puzzles.ChessSlide = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const checkPuzzle = () => {
    const completedPuzzle = [...puzzle];
    let counter = 1;
    let correct = 0;
    for (let row = 0; row < completedPuzzle.length; row++) {
      for (let col = 0; col < completedPuzzle[row].length; col++) {
        if (completedPuzzle[row][col].id === counter) {
          correct = correct + 1;
        }
        counter = counter + 1;
      }
    }

    counter = 1;

    if (correct >= 15) {
      for (let row = 0; row < completedPuzzle.length; row++) {
        for (let col = 0; col < completedPuzzle[row].length; col++) {
          completedPuzzle[row][col] = {
            ...completedPuzzle[row][col], // Copy the existing properties
            id: counter, // Update the id property
          };
          counter = counter + 1;
        }
      }
      setPuzzle(completedPuzzle);
      setStatus(t("Completed"));

      // GA Tracking
      trackEvent("Games", "ChessSlide-Completed", "Chess Slide");
      // Internal Tracking
      incrementGameProperty("ChessSlide-Completed");
      if (userData) {
        userData.Puzzles.ChessSlide.Completed =
          (userData.Puzzles.ChessSlide.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
      return;
    }

    setStatus("Incompleted");
    setMatches(correct);
  };

  const handleTileClick = (row, col) => {
    // Find the coordinates of the missing tile
    const { row: emptyRow, col: emptyCol } = findEmptyTile();

    // Check if the clicked tile can be moved to the empty space
    if (
      (row === emptyRow && Math.abs(col - emptyCol) === 1) ||
      (col === emptyCol && Math.abs(row - emptyRow) === 1)
    ) {
      const newPuzzle = [...puzzle];
      let emptyitem = newPuzzle[emptyRow][emptyCol];
      // Swap the clicked tile with the empty space
      newPuzzle[emptyRow][emptyCol] = newPuzzle[row][col];
      newPuzzle[row][col] = emptyitem; // Set the clicked tile to null

      setPuzzle(newPuzzle);
    }
  };

  function parseFen(fen) {
    const board = [];
    const rows = fen.split(" ")[0].split("/");

    for (let row of rows) {
      const newRow = [];
      for (let char of row) {
        if (!isNaN(char)) {
          // If it's a number, represent empty squares with that many spaces
          newRow.push(...Array(Number(char)).fill(""));
        } else {
          // Otherwise, add the piece to the row (using uppercase for white, lowercase for black)
          if (char === char.toUpperCase()) {
            newRow.push(`w${char}`);
          } else {
            newRow.push(`b${char.toLowerCase()}`);
          }
        }
      }
      board.push(newRow);
    }

    return board;
  }

  const findEmptyTile = () => {
    for (let row = 0; row < puzzle.length; row++) {
      for (let col = 0; col < puzzle[row].length; col++) {
        if (puzzle[row][col].id === null) {
          return { row, col };
        }
      }
    }
    // Return a default value or handle the case where the missing tile is not found.
    return { row: -1, col: -1 };
  };

  // Help Modal
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [gridWidth, setGridWidth] = useState(500);

  const handleResize = () => {
    if (window.innerWidth >= 730) {
      setGridWidth(480);
    } else if (window.innerWidth < 730 && window.innerWidth >= 450) {
      setGridWidth(360);
    } else if (window.innerWidth < 450) {
      setGridWidth(300);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <ContentHeader
        title={t("ChessSlide.header.title")}
        subtitle={t("ChessSlide.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Chess Slide</title>
        <meta
          name="description"
          content="Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills."
        />
        <meta property="og:title" content="Chess Slide" />
        <meta
          property="og:description"
          content="Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/chessslide.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/chessslide`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Slide" />
        <meta
          name="twitter:description"
          content="Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/chessslide.png`}
        />
      </Helmet>
      <Box>
        <Grid item xs={12} sx={{ p: "0px" }}>
          <Button
            variant="contained"
            onClick={initializePuzzle}
            style={{ marginRight: 10 }}
            startIcon={
              <PlayCircleOutlineRoundedIcon
                style={{ color: colors.black[900] }}
              />
            }
          >
            {t("ChessSlide.buttons.play")}
          </Button>
          <Button
            variant="contained"
            onClick={checkPuzzle}
            style={{ marginRight: 10 }}
            startIcon={
              <CheckCircleOutlineRoundedIcon
                style={{ color: colors.black[900] }}
              />
            }
          >
            {t("ChessSlide.buttons.check")}
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
          >
            {t("ChessSlide.buttons.help")}
          </Button>
          <HelpModal
            open={open}
            onClose={handleClose}
            title={t("ChessSlide.helpDetails.title")}
            content={<ChessSlideHelp />}
          ></HelpModal>
          <Box sx={{ padding: "20px 0 0 0" }}>
            <Typography variant="h6">{selectedPgnName}</Typography>
            <Typography>{selectedPgn}</Typography>
          </Box>
        </Grid>
        <Grid container spacing={0} style={{ width: gridWidth + 50 }}>
          {puzzle.map((row, rowIndex) => (
            <Grid container item key={rowIndex}>
              {row.map((tile, columnIndex) => (
                <Grid
                  item
                  key={columnIndex}
                  sx={{ display: "flex", border: "1.5px solid #FFF" }}
                >
                  <Tile
                    key={`${rowIndex}-${columnIndex}`} // Add a unique key here as well
                    tile={tile}
                    onClick={() => handleTileClick(rowIndex, columnIndex)}
                    colors={colors}
                    tileWidth={gridWidth / 4}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: "20px 0 0 0" }}>
            <Typography>
              {status === "Completed"
                ? t("ChessSlide.game.completed")
                : status === "Incomplete"
                ? t("ChessSlide.game.incomplete")
                : status
                ? t("ChessSlide.game.status") +
                  ": " +
                  matches +
                  " " +
                  t("ChessSlide.game.correctSquares")
                : ""}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChessSlide;
