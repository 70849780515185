import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next"; // Assuming you're using i18next

const GuessTheMoveHelp = () => {
  const { t } = useTranslation("Games"); // Hook for translation

  const listItemTextStyle = {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.6)",
  };

  return (
    <Box>
      <Typography component="div">
        {t("GuessTheMove.helpDetails.description")}
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheMove.helpDetails.instructions.start_game.primary"
            )}
            secondary={t(
              "GuessTheMove.helpDetails.instructions.start_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheMove.helpDetails.instructions.make_move.primary"
            )}
            secondary={
              <>
                <Typography component="div" style={listItemTextStyle}>
                  {t(
                    "GuessTheMove.helpDetails.instructions.make_move.secondary.description"
                  )}
                </Typography>
                <Box component="div" sx={{ pl: 2 }}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={t(
                          "GuessTheMove.helpDetails.instructions.make_move.secondary.steps.0.primary"
                        )}
                        secondary={t(
                          "GuessTheMove.helpDetails.instructions.make_move.secondary.steps.0.secondary"
                        )}
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={t(
                          "GuessTheMove.helpDetails.instructions.make_move.secondary.steps.1.primary"
                        )}
                        secondary={t(
                          "GuessTheMove.helpDetails.instructions.make_move.secondary.steps.1.secondary"
                        )}
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={t(
                          "GuessTheMove.helpDetails.instructions.make_move.secondary.steps.2.primary"
                        )}
                        secondary={t(
                          "GuessTheMove.helpDetails.instructions.make_move.secondary.steps.2.secondary"
                        )}
                        style={listItemTextStyle}
                      />
                    </ListItem>
                  </List>
                </Box>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheMove.helpDetails.instructions.check_progress.primary"
            )}
            secondary={
              <Typography component="div" style={listItemTextStyle}>
                {t(
                  "GuessTheMove.helpDetails.instructions.check_progress.secondary"
                )}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheMove.helpDetails.instructions.keep_practicing.primary"
            )}
            secondary={t(
              "GuessTheMove.helpDetails.instructions.keep_practicing.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheMoveHelp;
