// Firebase Imports
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Firebase application configuration
const firebaseConfig = {
  apiKey: "AIzaSyCDdRAEabRROKnDTu5jB0ww_fY4qFMIfzE",
  authDomain: "chessboardmagic-9f89e.firebaseapp.com",
  projectId: "chessboardmagic-9f89e",
  storageBucket: "chessboardmagic-9f89e.appspot.com",
  messagingSenderId: "542753781793",
  appId: "1:542753781793:web:4f434bfb616c05e8d08b60",
  measurementId: "G-5T1WFE8M68",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
