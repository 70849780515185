export const themeColors = {
  "White Stripe Theme": {
    lightSquare: "#FFFFFF",
    lightSquarePattern:
      "repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0.1) 2px, transparent 2px, transparent 4px)",
    darkSquare: "#CCCCCC",
    darkSquarePattern:
      "repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0.1) 2px, transparent 2px, transparent 4px)",
  },
  "Classic Wooden Theme": {
    lightSquare: "#f0d9b5",
    darkSquare: "#b58863",
  },
  "Blue and Gray Theme": {
    lightSquare: "#c6d9ec",
    darkSquare: "#8b9db6",
  },
  "Green and Brown Theme": {
    lightSquare: "#d9ead3",
    darkSquare: "#769656",
  },
  "Red and Beige Theme": {
    lightSquare: "#f3d9d2",
    darkSquare: "#b5614e",
  },
  "Black and White Classic": {
    lightSquare: "#ffffff",
    darkSquare: "#000000",
  },
  "Ocean Blue Theme": {
    lightSquare: "#aad5e8",
    darkSquare: "#286090",
  },
  "Purple and Pink Theme": {
    lightSquare: "#ebcde0",
    darkSquare: "#9b72aa",
  },
  "Earthy Tones": {
    lightSquare: "#f0e7d8",
    darkSquare: "#a79c93",
  },
  "Desert Sand Theme": {
    lightSquare: "#e4d2a3",
    darkSquare: "#ab9663",
  },
  "Autumn Leaves Theme": {
    lightSquare: "#ffc996",
    darkSquare: "#935734",
  },
  "Cool Gray": {
    lightSquare: "#c2c4e2",
    darkSquare: "#6a6a8a",
  },
  "Warm Tones": {
    lightSquare: "#f6dcbf",
    darkSquare: "#aa6e6e",
  },
  "Green Oasis": {
    lightSquare: "#d1e3dd",
    darkSquare: "#588c7e",
  },
  "Golden Brown": {
    lightSquare: "#f5e1cb",
    darkSquare: "#b7a88c",
  },
  "Serene Green": {
    lightSquare: "#e0ecdb",
    darkSquare: "#5e877e",
  },
  "Rustic Orange": {
    lightSquare: "#eddcd3",
    darkSquare: "#a66b48",
  },
  "Sage Gray": {
    lightSquare: "#d6e7ca",
    darkSquare: "#9b827a",
  },
  "Steel Blue": {
    lightSquare: "#f4d6b7",
    darkSquare: "#4b5362",
  },
  "Modern Minimal": {
    lightSquare: "#f2e6e0",
    darkSquare: "#6e7280",
  },
  "Indigo Dream": {
    lightSquare: "#e7e1d4",
    darkSquare: "#6a6a8a",
  },
  "Midnight Hues": {
    lightSquare: "#f1e3d0",
    darkSquare: "#3c415e",
  },
  "Mauve Delight": {
    lightSquare: "#dfd5e2",
    darkSquare: "#4e4864",
  },
  "Peachy Glow": {
    lightSquare: "#efddd0",
    darkSquare: "#55433f",
  },
  "Soft Lavender": {
    lightSquare: "#c9d7eb",
    darkSquare: "#8a919f",
  },
  "Creamy Beige": {
    lightSquare: "#f2eadb",
    darkSquare: "#7a715f",
  },
  "Sandstone Brown": {
    lightSquare: "#ffe5d1",
    darkSquare: "#8f7266",
  },
  "Ocean Breeze": {
    lightSquare: "#c4dbea",
    darkSquare: "#61888a",
  },
  "Autumn Gold": {
    lightSquare: "#ffcc99",
    darkSquare: "#cc9933",
  },
  "Forest Green": {
    lightSquare: "#cce4a9",
    darkSquare: "#336633",
  },
  "Elegant Maroon": {
    lightSquare: "#ccaeb5",
    darkSquare: "#6b3636",
  },
  "Royal Purple": {
    lightSquare: "#926aa6",
    darkSquare: "#5d478b",
  },
  "Sunset Orange": {
    lightSquare: "#f19066",
    darkSquare: "#94463f",
  },
  "Vintage Rose": {
    lightSquare: "#d9a7c7",
    darkSquare: "#704d72",
  },
  "Mediterranean Blue": {
    lightSquare: "#b8cce4",
    darkSquare: "#3c4f67",
  },
  "Teal Paradise": {
    lightSquare: "#86b5b2",
    darkSquare: "#2e5554",
  },
  "Citrus Burst": {
    lightSquare: "#f6e475",
    darkSquare: "#b9c446",
  },
  "Lavender Fields": {
    lightSquare: "#c9a7a9",
    darkSquare: "#7e6166",
  },
  "Midnight Serenity": {
    lightSquare: "#2b5876",
    darkSquare: "#0f3057",
  },
  "Desert Mirage": {
    lightSquare: "#edc9af",
    darkSquare: "#72593d",
  },
  "Vintage Teal": {
    lightSquare: "#a1c5ab",
    darkSquare: "#667c74",
  },
  "Electric Blue": {
    lightSquare: "#44c2e2",
    darkSquare: "#287ca7",
  },
  "Orchid Bloom": {
    lightSquare: "#cc96e8",
    darkSquare: "#5d4380",
  },
  "Rustic Charm": {
    lightSquare: "#b4886b",
    darkSquare: "#3d3d3d",
  },
  "Coral Crush": {
    lightSquare: "#ff6b6b",
    darkSquare: "#b32424",
  },
  "Lemon Zest": {
    lightSquare: "#fde74c",
    darkSquare: "#f29b30",
  },
  "Minty Fresh": {
    lightSquare: "#7ed6b1",
    darkSquare: "#4a7f67",
  },
  "Dreamy Lilac": {
    lightSquare: "#c9a7e2",
    darkSquare: "#72598d",
  },
  "Sienna Brown": {
    lightSquare: "#a86f44",
    darkSquare: "#603d20",
  },
  "Tropical Paradise": {
    lightSquare: "#ffeead",
    darkSquare: "#ffa649",
  },
  "Fiery Red": {
    lightSquare: "#ff6b6b",
    darkSquare: "#d81e5b",
  },
};
