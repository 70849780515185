import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const WhereAreMyPiecesHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>{t("WhereAreMyPieces.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("WhereAreMyPieces.helpDetails.instructions.play.title")}
            secondary={t(
              "WhereAreMyPieces.helpDetails.instructions.play.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhereAreMyPieces.helpDetails.instructions.placeAndDrag.title"
            )}
            secondary={t(
              "WhereAreMyPieces.helpDetails.instructions.placeAndDrag.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("WhereAreMyPieces.helpDetails.instructions.check.title")}
            secondary={t(
              "WhereAreMyPieces.helpDetails.instructions.check.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("WhereAreMyPieces.helpDetails.instructions.quit.title")}
            secondary={t(
              "WhereAreMyPieces.helpDetails.instructions.quit.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhereAreMyPieces.helpDetails.instructions.finalPosition.title"
            )}
            secondary={t(
              "WhereAreMyPieces.helpDetails.instructions.finalPosition.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default WhereAreMyPiecesHelp;
