import React from "react";
import { SvgIcon } from "@mui/material";

function GifIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.8) translate(100, 100)">
        <path d="m200.02 1200h799.97c33.141 0 60-26.859 60-60v-860.02c0.65625-30.891-11.625-60.656-33.844-82.125l-164.02-164.02c-21.469-22.219-51.234-34.5-82.125-33.844h-579.98c-33.141 0-60 26.859-60 60v1080c0 33.141 26.859 60 60 60zm800.02-39.984h-800.02c-11.062 0-20.016-8.9531-20.016-20.016v-260.02h840v260.02c0 5.2969-2.1094 10.406-5.8594 14.156s-8.8594 5.8594-14.156 5.8594zm-8.2969-939.98-111.75-0.046875c-22.078 0-39.984-17.906-39.984-39.984v-111.7zm-791.72-180.05h579.98c5.2969 0 10.406 2.1094 14.156 5.8594s5.8594 8.8594 5.8594 14.156v120c0 21.234 8.3906 41.578 23.438 56.578 15 15 35.344 23.438 56.531 23.438h120c5.2969 0 10.406 2.1094 14.156 5.8594s5.8594 8.8125 5.8594 14.109v560.02h-840v-780c0-11.062 8.9531-20.016 20.016-20.016z" />
        <path d="m399.98 639.98c2.0156 0 3.7969-0.375 5.7656-0.46875l-18 31.5v0.046875c-10.594 18.281-10.453 40.875 0.28125 59.109 10.734 18.609 30.656 30 52.125 29.812h319.69c21.422 0.14062 41.297-11.25 51.984-29.812 10.734-18.234 10.875-40.828 0.28125-59.109l-17.625-31.078h105.52c33.141 0 60-26.859 60-60v-199.97c0-33.141-26.859-60-60-60h-200.02c-31.406 0.14062-57.375 24.469-59.578 55.781-13.453-12.328-31.781-17.906-49.828-15.094-18.094 2.8125-33.844 13.734-42.891 29.578l-7.4062 12.984c-27.562-50.438-80.109-82.219-137.63-83.25-57.516-0.98438-111.09 28.922-140.44 78.422-29.297 49.453-29.812 110.86-1.3125 160.82 28.453 49.969 81.562 80.766 139.08 80.719zm300-279.98h200.02c5.2969 0 10.406 2.1094 14.156 5.8594s5.8594 8.8594 5.8594 14.156v199.97c0 5.2969-2.1094 10.406-5.8594 14.156s-8.8594 5.8594-14.156 5.8594h-128.29l-91.688-161.11v-58.875c0-11.062 8.9531-20.016 19.969-20.016zm-117.56 50.016c3.6562-6.2812 10.359-10.125 17.578-10.125s13.922 3.8438 17.578 10.125l159.84 280.82c3.4688 5.9531 3.4688 13.312 0 19.219-3.5625 6.2812-10.266 10.125-17.484 9.9844h-319.78c-7.2188 0.14062-13.922-3.7031-17.484-9.9844-3.4688-5.9062-3.4688-13.266 0-19.219zm-182.44-50.016c53.578 0.28125 100.5 35.906 115.17 87.422l-84.422 148.31c-33.375 8.8594-68.953 2.9531-97.594-16.266-28.688-19.219-47.672-49.875-52.172-84.094-4.4531-34.219 6.0469-68.719 28.875-94.641 22.781-25.922 55.641-40.781 90.141-40.734z" />
        <path d="m399.98 960h20.016c5.2969 0 10.406 2.1094 14.156 5.8594s5.8594 8.8594 5.8594 14.156c0 11.016 8.9531 19.969 19.969 19.969 11.062 0 20.016-8.9531 20.016-19.969 0-33.141-26.859-60-60-60h-20.016c-33.141 0-60 26.859-60 60v80.016-0.046875c0 33.141 26.859 60 60 60h20.016c33.141 0 60-26.859 60-60v-19.969c0-5.2969-2.1094-10.406-5.8594-14.156s-8.8594-5.8594-14.156-5.8594h-19.969c-11.062 0-20.016 8.9531-20.016 20.016 0 11.016 8.9531 19.969 20.016 19.969 0 5.2969-2.1094 10.406-5.8594 14.156s-8.8594 5.8594-14.156 5.8594h-20.016c-11.016 0-19.969-8.9531-19.969-20.016v-79.969c0-11.062 8.9531-20.016 19.969-20.016z" />
        <path d="m660 960c11.062 0 20.016-8.9531 20.016-20.016 0-11.016-8.9531-19.969-20.016-19.969h-120c-11.062 0-20.016 8.9531-20.016 19.969 0 11.062 8.9531 20.016 20.016 20.016h39.984v120h-39.984c-11.062 0-20.016 8.9531-20.016 20.016 0 11.016 8.9531 19.969 20.016 19.969h120c11.062 0 20.016-8.9531 20.016-19.969 0-11.062-8.9531-20.016-20.016-20.016h-39.984v-120z" />
        <path d="m840 920.02h-99.984c-11.062 0-20.016 8.9531-20.016 19.969v160.03c0 11.016 8.9531 19.969 20.016 19.969 11.016 0 19.969-8.9531 19.969-19.969v-60h40.031c11.016 0 19.969-8.9531 19.969-20.016s-8.9531-20.016-19.969-20.016h-40.031v-39.984h80.016c11.062 0 20.016-8.9531 20.016-20.016 0-11.016-8.9531-19.969-20.016-19.969z" />{" "}
      </g>
    </SvgIcon>
  );
}

export default GifIcon;
