import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const HandAndBrainHelp = () => {
  const { t } = useTranslation("Learn");

  return (
    <>
      <Typography>{t("HandAndBrain.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "HandAndBrain.helpDetails.instructions.start_game.primary"
            )}
            secondary={t(
              "HandAndBrain.helpDetails.instructions.start_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "HandAndBrain.helpDetails.instructions.piece_suggestions.primary"
            )}
            secondary={t(
              "HandAndBrain.helpDetails.instructions.piece_suggestions.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "HandAndBrain.helpDetails.instructions.engine_move.primary"
            )}
            secondary={t(
              "HandAndBrain.helpDetails.instructions.engine_move.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "HandAndBrain.helpDetails.instructions.game_over.primary"
            )}
            secondary={t(
              "HandAndBrain.helpDetails.instructions.game_over.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default HandAndBrainHelp;
