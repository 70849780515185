import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const GuessTheEloHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <Box>
      <Typography>{t("GuessTheElo.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheElo.helpDetails.instructions.watch_game.primary"
            )}
            secondary={t(
              "GuessTheElo.helpDetails.instructions.watch_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheElo.helpDetails.instructions.think_ratings.primary"
            )}
            secondary={t(
              "GuessTheElo.helpDetails.instructions.think_ratings.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheElo.helpDetails.instructions.guess_ratings.primary"
            )}
            secondary={t(
              "GuessTheElo.helpDetails.instructions.guess_ratings.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheElo.helpDetails.instructions.check_result.primary"
            )}
            secondary={t(
              "GuessTheElo.helpDetails.instructions.check_result.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheElo.helpDetails.instructions.keep_playing.primary"
            )}
            secondary={t(
              "GuessTheElo.helpDetails.instructions.keep_playing.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheEloHelp;
