import React, { useState, useEffect } from "react";
import "./index.css";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TableContainer,
  Box,
  useTheme,
  Alert,
  AlertTitle,
} from "@mui/material";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import WordsearchHelp from "../../../help/WordsearchHelp";
import { chessterms } from "../../../data/chessterms/chessterms";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { trackEvent } from "../../../config/ga";
import { Helmet } from "react-helmet";
import { useUser } from "../../../context/UserContext";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function Wordsearch() {
  const [clickedSquare, setClickedSquare] = useState(null);
  const [currentSquare, setCurrentSquare] = useState(null);
  const [selectedString, setSelectedString] = useState("");
  const [foundWords, setFoundWords] = useState([]);
  const [foundWordName, setFoundWordName] = useState("");
  const [foundWordDefinition, setFoundWordDefinition] = useState("");
  const [foundWordType, setFoundWordType] = useState("");
  const [type] = useState("A");
  const [boardSize] = useState(20);
  const [fillGaps] = useState(false);
  const [grid, setGrid] = useState(
    createEmptyGrid(boardSize, boardSize, fillGaps)
  );
  const [smallWordCount] = useState(4);
  const [mediumWordCount] = useState(5);
  const [largeWordCount] = useState(1);
  const [customWords] = useState("");
  const [foundWordCounter, setFoundWordCounter] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [firstGame, setFirstGame] = useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/wordsearch",
      title: "Chessboard Magic - Chess Wordsearch",
      description:
        "Challenge your mind with Chess Wordsearch! Find hidden chess terms in a grid and enhance your chess vocabulary.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function generateRandomLetter() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    return alphabet[randomIndex];
  }

  function createEmptyGrid(rows, cols) {
    const grid = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < cols; j++) {
        row.push({
          letter: "",
          selected: false,
          found: 0,
        });
      }
      grid.push(row);
    }
    return grid;
  }

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function getRandomUniqueNumbers(min, max, count) {
    if (count === 0) return [];
    const result = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [result[i], result[j]] = [result[j], result[i]];
    }
    return result.slice(0, count);
  }

  function handleMouseDown(row, col) {
    if (row >= 0 && row < grid.length && col >= 0 && col < grid[row].length) {
      const newGrid = [...grid];
      clearHighlighting(newGrid);
      setSelectedString(newGrid[row][col].letter);
      newGrid[row][col].selected = true;
      setGrid(newGrid);
      setClickedSquare({ row, col });
    }
  }

  function handleMouseUp() {
    setClickedSquare(null);

    const updatedFoundWords = foundWords.map((wordObj) => {
      if (selectedString.toUpperCase() === wordObj.word) {
        setFoundGrid();
        if (customWords.length === 0) {
          let foundItem = chessterms.find(
            (term) => selectedString.toUpperCase() === term.Key
          );
          setFoundWordName(foundItem.Term);
          setFoundWordDefinition(foundItem.Description);
          setFoundWordType(getWordType(foundItem.Type));
        } else {
          setFoundWordName(selectedString);
          setFoundWordType("Custom");
          setFoundWordDefinition("");
        }
        return { ...wordObj, found: foundWordCounter + 1 };
      }
      return wordObj;
    });

    setFoundWords(updatedFoundWords);
    let check = areAllWordsFound(updatedFoundWords);
    if (check) {
      setGameCompleted(true);

      // GA Tracking
      trackEvent("Games", "Wordsearch-Success", "Wordsearch");
      // Internal Tracking
      incrementGameProperty("Wordsearch-Completed");
      if (userData) {
        userData.Puzzles.Wordsearch.Completed =
          (userData.Puzzles.Wordsearch.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
    }
  }

  function areAllWordsFound(foundWords) {
    return foundWords.every((word) => word.found > 0);
  }

  function setFoundGrid() {
    const newGrid = [...grid];
    let { row, col } = currentSquare;
    const rowDiff = row - clickedSquare.row;
    const colDiff = col - clickedSquare.col;
    clearHighlighting(newGrid);

    if (rowDiff === 0 && colDiff !== 0) {
      // Horizontal
      const colIncrement = colDiff > 0 ? 1 : -1;
      for (let j = clickedSquare.col; j !== col; j += colIncrement) {
        newGrid[row][j].found = foundWordCounter + 1;
      }
    } else if (colDiff === 0 && rowDiff !== 0) {
      // Vertical
      const rowIncrement = rowDiff > 0 ? 1 : -1;
      for (let i = clickedSquare.row; i !== row; i += rowIncrement) {
        newGrid[i][col].found = foundWordCounter + 1;
      }
    } else if (Math.abs(rowDiff) === Math.abs(colDiff)) {
      // Diagonal
      const rowDirection = rowDiff > 0 ? 1 : -1;
      const colDirection = colDiff > 0 ? 1 : -1;
      let i = clickedSquare.row;
      let j = clickedSquare.col;
      while (i !== row && j !== col) {
        newGrid[i][j].found = foundWordCounter + 1;
        i += rowDirection;
        j += colDirection;
      }
    } else {
      setSelectedString("");
      clearHighlighting(newGrid);
      newGrid[clickedSquare.row][clickedSquare.col].found =
        foundWordCounter + 1;
      setGrid(newGrid);
      return;
    }

    newGrid[row][col].found = foundWordCounter + 1;
    setGrid(newGrid);
    setFoundWordCounter(foundWordCounter + 1);
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleMouseEnter(row, col) {
    setCurrentSquare({ row, col });
    if (clickedSquare) {
      const newGrid = [...grid];
      let letters = "";
      const rowDiff = row - clickedSquare.row;
      const colDiff = col - clickedSquare.col;

      clearHighlighting(newGrid);

      if (rowDiff === 0 && colDiff !== 0) {
        const colIncrement = colDiff > 0 ? 1 : -1;
        for (let j = clickedSquare.col; j !== col; j += colIncrement) {
          newGrid[row][j].selected = true;
          letters += newGrid[row][j].letter;
        }
      } else if (colDiff === 0 && rowDiff !== 0) {
        const rowIncrement = rowDiff > 0 ? 1 : -1;
        for (let i = clickedSquare.row; i !== row; i += rowIncrement) {
          newGrid[i][col].selected = true;
          letters += newGrid[i][col].letter;
        }
      } else if (Math.abs(rowDiff) === Math.abs(colDiff)) {
        const rowDirection = rowDiff > 0 ? 1 : -1;
        const colDirection = colDiff > 0 ? 1 : -1;
        let i = clickedSquare.row;
        let j = clickedSquare.col;
        while (i !== row && j !== col) {
          newGrid[i][j].selected = true;
          letters += newGrid[i][j].letter;
          i += rowDirection;
          j += colDirection;
        }
      } else {
        setSelectedString("");
        clearHighlighting(newGrid);
        newGrid[clickedSquare.row][clickedSquare.col].selected = true;
        setGrid(newGrid);
        return;
      }

      newGrid[row][col].selected = true;
      letters += newGrid[row][col].letter;
      setGrid(newGrid);
      setSelectedString(letters);
    }
  }

  function clearHighlighting(newGrid) {
    for (let i = 0; i < newGrid.length; i++) {
      for (let j = 0; j < newGrid[i].length; j++) {
        newGrid[i][j].selected = false;
      }
    }
  }

  function resetGame() {
    setFoundWordName("");
    setFoundWordDefinition("");
    setFoundWordType("");
    setFoundWordCounter(0);
    setGameCompleted(false);
    const updatedFoundWords = foundWords.map((wordObj) => ({
      ...wordObj,
      found: 0,
    }));
    setFoundWords(updatedFoundWords);

    const newGrid = grid.map((row) =>
      row.map((cell) => ({
        ...cell,
        found: 0,
      }))
    );
    setGrid(newGrid);
  }

  function newGame() {
    const grid = createEmptyGrid(boardSize, boardSize);
    const [smallWords, mediumWords, largeWords] = categorizeWords();
    const selectedWords = selectRandomWords(
      smallWords,
      mediumWords,
      largeWords
    );
    placeWordsInGrid(grid, selectedWords);
    fillEmptySpaces(grid);
    setFoundWordName("");
    setFoundWordDefinition("");
    setFoundWordType("");
    setGameCompleted(false);
    setFirstGame(false);
    setFoundWords(selectedWords.map((word) => ({ word: word.Key, found: 0 })));
    setGrid(grid);

    // GA Tracking
    trackEvent("Games", "Wordsearch-Play", "Wordsearch");
    // Internal Tracking
    incrementGameProperty("Wordsearch-Play");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.Wordsearch) {
        userData.Puzzles.Wordsearch.Played =
          (userData.Puzzles.Wordsearch.Played || 0) + 1;
      } else {
        userData.Puzzles.Wordsearch = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }

  function categorizeWords() {
    let smallWords = [];
    let mediumWords = [];
    let largeWords = [];

    let filteredTerms =
      type !== "A"
        ? chessterms.filter(
            (term) =>
              term.Type === type && parseInt(term.Length, 10) <= boardSize
          )
        : chessterms.filter((term) => parseInt(term.Length, 10) <= boardSize);

    if (customWords.length > 0) {
      filteredTerms = customWords.split(",").map((item) => ({
        Term: item,
        Key: item.replace(/\s/g, "").toUpperCase(),
        Length: item.length,
        Type: "T",
        Description: "N/A",
      }));
    }

    filteredTerms.forEach((term) => {
      if (term.Length <= 5) smallWords.push(term);
      else if (term.Length <= 10) mediumWords.push(term);
      else if (term.Length < 20) largeWords.push(term);
    });

    return [smallWords, mediumWords, largeWords];
  }

  function selectRandomWords(smallWords, mediumWords, largeWords) {
    const selectedWords = [];
    const addWords = (words, count) => {
      const randomIndices = getRandomUniqueNumbers(0, words.length - 1, count);
      randomIndices.forEach((index) => selectedWords.push(words[index]));
    };
    addWords(smallWords, smallWordCount);
    addWords(mediumWords, mediumWordCount);
    addWords(largeWords, largeWordCount);
    return selectedWords;
  }

  function placeWordsInGrid(grid, wordList) {
    wordList.forEach((wordObj) => {
      let isValidPlacement = false;
      let attempts = 0;
      do {
        if (attempts > 50) break;
        const direction = Math.floor(Math.random() * 8);
        const rowIncrement = [0, 0, 1, -1, 1, 1, -1, -1];
        const colIncrement = [1, -1, 0, 0, 1, -1, -1, 1];
        let rowPosition, colPosition;

        if (direction === 0) {
          rowPosition = getRandomInt(0, boardSize - 1);
          colPosition = getRandomInt(0, boardSize - wordObj.Length - 1);
        } else if (direction === 1) {
          rowPosition = getRandomInt(0, boardSize - 1);
          colPosition = getRandomInt(wordObj.Length - 1, boardSize - 1);
        } else if (direction === 2) {
          rowPosition = getRandomInt(0, boardSize - wordObj.Length);
          colPosition = getRandomInt(0, boardSize - 1);
        } else if (direction === 3) {
          rowPosition = getRandomInt(wordObj.Length - 1, boardSize - 1);
          colPosition = getRandomInt(0, boardSize - 1);
        } else if (direction === 4) {
          rowPosition = getRandomInt(0, boardSize - wordObj.Length - 1);
          colPosition = getRandomInt(0, boardSize - wordObj.Length - 1);
        } else if (direction === 5) {
          rowPosition = getRandomInt(0, boardSize - wordObj.Length - 1);
          colPosition = getRandomInt(wordObj.Length - 1, boardSize - 1);
        } else if (direction === 6) {
          rowPosition = getRandomInt(wordObj.Length - 1, boardSize - 1);
          colPosition = getRandomInt(wordObj.Length - 1, boardSize - 1);
        } else {
          rowPosition = getRandomInt(wordObj.Length - 1, boardSize - 1);
          colPosition = getRandomInt(0, boardSize - wordObj.Length);
        }

        for (let j = 0; j < wordObj.Length; j++) {
          if (
            grid[rowPosition + j * rowIncrement[direction]][
              colPosition + j * colIncrement[direction]
            ].letter !== ""
          ) {
            isValidPlacement = false;
            attempts++;
            break;
          } else {
            isValidPlacement = true;
          }
        }

        if (isValidPlacement) {
          for (let j = 0; j < wordObj.Length; j++) {
            grid[rowPosition + j * rowIncrement[direction]][
              colPosition + j * colIncrement[direction]
            ].letter = wordObj.Key[j];
          }
          break;
        }
      } while (!isValidPlacement);
    });
  }

  function fillEmptySpaces(grid) {
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid[i].length; j++) {
        if (grid[i][j].letter === "") {
          grid[i][j].letter = generateRandomLetter();
        }
      }
    }
  }

  function getWordType(type) {
    switch (type) {
      case "O":
        return t("Wordsearch.game.chessOpening");
      case "P":
      case "W":
        return t("Wordsearch.game.chessPlayer");
      case "E":
        return t("Wordsearch.game.chessEngine");
      default:
        return t("Wordsearch.game.chessTerm");
    }
  }

  function preventDefaultDrag(event) {
    event.preventDefault();
  }

  return (
    <Box>
      <ContentHeader
        title={t("Wordsearch.header.title")}
        subtitle={t("Wordsearch.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      {isSmallScreen && (
        <Alert
          severity="warning"
          icon={<WarningIcon sx={{ color: "white" }} />}
          style={{
            backgroundColor: colors.red[500],
            color: "white",
            marginBottom: "20px",
          }}
        >
          <AlertTitle>{t("Wordsearch.game.warning.title")}</AlertTitle>
          {t("Wordsearch.game.warning.message")}
        </Alert>
      )}
      <Helmet>
        <title>Wordsearch</title>
        <meta
          name="description"
          content="Challenge your mind with Chess Wordsearch! Find hidden chess terms in a grid and enhance your chess vocabulary."
        />
        <meta property="og:title" content="Wordsearch" />
        <meta
          property="og:description"
          content="A Chess Based Wordsearch Game."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/wordsearch.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/wordsearch`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Wordsearch" />
        <meta
          name="twitter:description"
          content="Challenge your mind with Chess Wordsearch! Find hidden chess terms in a grid and enhance your chess vocabulary."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/wordsearch.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={newGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.black[900] }}
            />
          }
        >
          {t("Wordsearch.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={resetGame}
          style={{ marginRight: 10 }}
          startIcon={
            <RestartAltRoundedIcon style={{ color: colors.black[900] }} />
          }
        >
          {t("Wordsearch.buttons.reset")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
        >
          {t("Wordsearch.buttons.help")}
        </Button>
        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("Wordsearch.helpDetails.title")}
          content={<WordsearchHelp />}
        />
      </Box>
      {!firstGame && (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{
            padding: "20px 0px 20px 0px",
          }}
        >
          <Box
            id="wordsearch"
            sx={{
              minWidth: "550px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <TableContainer className="grid-table">
              <Table onDragStart={preventDefaultDrag} draggable="false">
                <TableBody>
                  {grid.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, colIndex) => (
                        <TableCell
                          key={colIndex}
                          onMouseDown={() =>
                            handleMouseDown(rowIndex, colIndex)
                          }
                          onMouseEnter={() =>
                            handleMouseEnter(rowIndex, colIndex)
                          }
                          onMouseUp={handleMouseUp}
                          className={`${
                            cell.selected
                              ? "selected"
                              : cell.found
                              ? `found-${cell.found % 10} cell`
                              : "cell"
                          }`}
                          style={{
                            padding: "0px",
                            width: "20px",
                            lineHeight: "25px",
                          }}
                        >
                          {cell.letter}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              flexGrow: 1,
              marginLeft: 2,
            }}
          >
            {gameCompleted && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <CheckCircleOutlineIcon
                  style={{
                    color: "green",
                    marginRight: "5px",
                    fontSize: "32px",
                  }}
                />
                <Typography>{t("Wordsearch.game.completed")}</Typography>
              </Box>
            )}
            <Box
              id="words"
              sx={{
                padding: "10px",
                overflowWrap: "break-word",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography component="div">
                {foundWords.map((item, index) => (
                  <span
                    key={index}
                    className={item.found !== 0 ? "strikethrough" : ""}
                    style={{
                      marginRight: "10px",
                      display: "inline-block", // Ensure words wrap correctly
                      wordBreak: "break-word", // Ensure long words break correctly
                    }}
                  >
                    {item.word}
                  </span>
                ))}
              </Typography>
            </Box>{" "}
            <Box id="description" sx={{ paddingLeft: 1, marginTop: 1 }}>
              <Typography variant="h7">{foundWordType}</Typography>
              <Typography variant="h4" sx={{ color: colors.blue[500] }}>
                {foundWordName}
              </Typography>
              <Typography>{foundWordDefinition}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Wordsearch;
