import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

// Reusable Component
function EngineBar({ value = 0.2, color = "white", height = "400px" }) {
  const [calculatedValue, setCalculatedValue] = useState(value); // Hold the value state

  useEffect(() => {
    // Ensure value is set after mount
    setCalculatedValue(value);
  }, [value]);

  // Check if the value contains 'M' (for mate evaluation)
  const isMateEvaluation =
    typeof calculatedValue === "string" &&
    calculatedValue.toUpperCase().includes("M");

  // If it's a mate evaluation, set the normalized value to 5 for white mating, -5 for black mating
  let numericValue = 0;
  if (isMateEvaluation) {
    numericValue = calculatedValue.startsWith("-") ? -5 : 5; // If the value starts with "-", black is mating, otherwise white is mating
  } else {
    // Otherwise, ensure the numeric value is within the range of -5 to 5
    numericValue = Math.max(Math.min(calculatedValue, 5), -5);
  }

  // Calculate the heights based on the normalized value for non-mate evaluations
  let mainHeight = `${((numericValue + 5) / 10) * 100}%`; // Proportional height for non-mate
  let secondaryHeight = `${(1 - (numericValue + 5) / 10) * 100}%`; // Remaining height for non-mate

  // If color is black, flip the values of mainHeight and secondaryHeight
  if (color === "black") {
    [mainHeight, secondaryHeight] = [secondaryHeight, mainHeight];
  }

  // Determine which color is at the bottom and which is at the top
  const mainColor = color === "white" ? "#EEEEEE" : "#333333"; // White or Black
  const secondaryColor = color === "white" ? "#333333" : "#EEEEEE"; // Opposite

  // Determine if White is better (value > 0 or white is mating) or Black is better (value <= 0 or black is mating)
  const isWhiteBetter = numericValue > 0;

  // Determine the display value: show the mate evaluation or formatted numeric score
  const displayValue = isMateEvaluation
    ? calculatedValue
    : numericValue.toFixed(1);

  // Set the text color based on which side is better
  const textColor = isWhiteBetter ? "#333333" : "#EEEEEE"; // Black text if White is better, White text if Black is better

  return (
    <Box
      sx={{
        width: "20px", // Fixed width for the bar
        height: height, // Dynamically set the height based on the chessboard
        backgroundColor: "#000000", // Default black background
        display: "flex",
        flexDirection: "column-reverse", // Stack boxes from bottom to top
        position: "relative",
      }}
    >
      {/* Bottom Section (Main Color) */}
      <Box
        sx={{
          width: "100%",
          height: mainHeight,
          backgroundColor: mainColor, // Color determined by `color` prop
          transition: "height 0.5s ease", // Smooth transition for the height
        }}
      />

      {/* Top Section (Secondary Color) */}
      <Box
        sx={{
          width: "100%",
          height: secondaryHeight,
          backgroundColor: secondaryColor, // Opposite of `color` prop
          transition: "height 0.5s ease", // Smooth transition for the height
        }}
      />

      {/* Evaluation Text */}
      <Typography
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top:
            color === "white"
              ? isWhiteBetter
                ? "auto"
                : 0
              : isWhiteBetter
              ? 0
              : "auto", // Flip based on color and evaluation
          bottom:
            color === "white"
              ? isWhiteBetter
                ? 0
                : "auto"
              : isWhiteBetter
              ? "auto"
              : 0, // Flip based on color and evaluation
          color: textColor, // Change text color based on which side is better
          fontWeight: "bold",
          fontSize: "8px", // Smaller font size
          transition: "color 0.5s ease", // Smooth transition for color change
        }}
      >
        {displayValue}
      </Typography>
    </Box>
  );
}

export default EngineBar;
