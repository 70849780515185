import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  useTheme,
  Button,
  TextareaAutosize,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //CircularProgress,
} from "@mui/material";
import { Chess } from "chess.js";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import { useShare } from "../../../context/ShareContext";
import GameViewer from "../../../components/GameViewer";

import { useTranslation } from "react-i18next";
//import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
//import { ResponsiveLine } from "@nivo/line"; // Nivo chart component

function PGNViewer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pgnInput, setPgnInput] = useState("");
  const [formattedPgn, setFormattedPgn] = useState(""); // New state for formatted PGN
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isPgnValid, setIsPgnValid] = useState(false);
  // const [isAnalyzing, setIsAnalyzing] = useState(false); // Track if analysis is in progress
  //const [evaluation, setEvaluation] = useState([]); // Store evaluations here

  const { setShareData } = useShare();
  const stockfishWorker = useRef(null);

  const { t } = useTranslation("Tools");

  useEffect(() => {
    stockfishWorker.current = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    return () => {
      if (stockfishWorker.current) {
        stockfishWorker.current.terminate();
      }
    };
  }, []);

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/gifgenerator",
      title: "Chessboard Magic - PGN Viewer",
      description:
        "PGN Viewer lets you upload, view, and analyze chess games with an interactive board and move-by-move game evaluation.",
    };

    setShareData(newShareData);
  }, [setShareData]);

  const textareaStyle = {
    width: "100%",
    padding: "10px",
    resize: "none",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.5",
  };

  // Function to adjust PGN by adding a space after every period if missing
  const adjustPgnFormat = (pgn) => {
    return pgn
      .replace(/\[[^\]]*\]/g, "") // Remove metadata between square brackets
      .replace(/(\d+)\.(?!\s)/g, "$1. ") // Make sure there is a space after the number
      .replace(/\{[^}]*\}/g, "") // Remove Comments
      .replace(/\s+/g, " ") // Replace 2 or more spaces with a single space
      .trim();
  };

  const handlePgnInputChange = (e) => {
    setPgnInput(e.target.value);
  };

  const handleLoadPgn = () => {
    if (pgnInput.trim() === "") {
      setAlertMessage(t("PGNViewer.alerts.empty"));
      setAlertSeverity("error");
      return;
    }

    const newFormattedPgn = adjustPgnFormat(pgnInput);

    const newGame = new Chess();
    try {
      newGame.loadPgn(newFormattedPgn);
      setIsPgnValid(true);
      setFormattedPgn(newFormattedPgn);
      setAlertMessage(t("PGNViewer.alerts.success"));
      setAlertSeverity("success");
      setDialogOpen(false);
    } catch (error) {
      setIsPgnValid(false);
      setAlertMessage(t("PGNViewer.alerts.error"));
      setAlertSeverity("error");
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  /*const handleEngineAnalysis = async () => {
    setIsAnalyzing(true);
    const chess = new Chess();
    const evaluationArray = [];
    chess.loadPgn(formattedPgn);
    const moves = chess.history();
    chess.reset(); // Reset the chessboard to the starting position

    for (let i = 0; i < moves.length; i++) {
      chess.move(moves[i]);

      const fen = chess.fen();
      const turn = fen.split(" ")[1]; // "w" for White, "b" for Black

      await new Promise((resolve) => {
        if (stockfishWorker.current) {
          let finalEvaluation = null;

          stockfishWorker.current.postMessage(`position fen ${fen}`);
          stockfishWorker.current.postMessage("go depth 18");

          stockfishWorker.current.onmessage = (event) => {
            const message = event.data;

            if (message.startsWith("info") && message.includes("score")) {
              const scoreMatch = message.match(/score (cp|mate) (-?\d+)/);
              if (scoreMatch) {
                const scoreType = scoreMatch[1];
                let scoreValue = parseInt(scoreMatch[2], 10);

                if (scoreType === "cp") {
                  let evaluation = scoreValue / 100;

                  // Negate evaluation for Black
                  if (turn === "b") {
                    evaluation = -evaluation;
                  }

                  // Store the final evaluation for centipawn score
                  finalEvaluation = evaluation;
                } else if (scoreType === "mate") {
                  const mateValue = Math.abs(scoreValue);
                  let mateEvaluation =
                    scoreValue > 0 ? `M${mateValue}` : `-M${mateValue}`;

                  if (turn === "b") {
                    mateEvaluation = mateEvaluation.startsWith("-")
                      ? mateEvaluation.replace("-", "") // Convert -M to M
                      : `-M${mateValue}`; // Convert M to -M
                  }

                  // Store the final evaluation for mate score
                  finalEvaluation = mateEvaluation;
                }
              }
            }

            // Stockfish sends multiple messages; we only resolve on "bestmove"
            if (message.startsWith("bestmove")) {
              if (finalEvaluation !== null) {
                evaluationArray.push({ move: i + 1, value: finalEvaluation }); // Only push the final evaluation
              }
              resolve(); // Resolve the promise after receiving the final evaluation
            }
          };
        }
      });
    }

    // Set the evaluation array in the state
    setEvaluation(evaluationArray);
    setIsAnalyzing(false);
    setAlertMessage("Engine analysis completed.");
    setAlertSeverity("success");
  };*/

  /*const processedData = evaluation.map((evalData) => {
    // Check if it's a mate score (Mx or -Mx)
    const isMate =
      typeof evalData.value === "string" && evalData.value.includes("M");

    let chartValue;

    if (isMate) {
      // If the evaluation is a mate, convert Mx/-Mx to 5/-5 for chart rendering
      chartValue = evalData.value.startsWith("-") ? -5 : 5;
    } else {
      // For centipawn evaluation, use the value as is
      chartValue = Math.max(-5, Math.min(5, evalData.value));
    }

    // Flip the mate evaluation if it's Black's turn (assuming we know the turn)
    // Here, you would use a condition similar to how you flip centipawn values based on the turn
    const turn = evalData.turn; // Assuming you store whose turn it is (w for White, b for Black)
    if (isMate && turn === "b") {
      // Flip M and -M for Black's turn
      chartValue = chartValue === 5 ? -5 : 5;
    }

    return {
      x: evalData.move, // Move number
      y: chartValue, // Processed value for chart
      originalValue: evalData.value, // Keep the original value for the tooltip
      turn: evalData.turn, // Track whose turn it was (if needed)
    };
  });

  const chartData = [
    {
      id: "Evaluation",
      color: "hsl(200, 70%, 50%)",
      data: processedData,
    },
  ];*/

  return (
    <Box>
      <ContentHeader
        title={t("PGNViewer.header.title")}
        subtitle={t("PGNViewer.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[14]}
      />
      <Helmet>
        <title>PGN Viewer</title>
        <meta
          name="description"
          content="PGN Viewer lets you upload, view, and analyze chess games with an interactive board and move-by-move game evaluation."
        />
        <meta property="og:title" content="PGN Viewer" />
        <meta
          property="og:description"
          content="PGN Viewer lets you upload, view, and analyze chess games with an interactive board and move-by-move game evaluation."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/pgnviewer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/pgnviewer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="PGN Viewer" />
        <meta
          name="twitter:description"
          content="PGN Viewer lets you upload, view, and analyze chess games with an interactive board and move-by-move game evaluation."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/pgnviewer.png`}
        />
      </Helmet>
      <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
        <Button variant="contained" onClick={handleDialogOpen}>
          {t("PGNViewer.buttons.loadpgn")}
        </Button>

        {/*<Button
          variant="contained"
          onClick={handleEngineAnalysis}
          disabled={!isPgnValid}
          startIcon={
            isAnalyzing ? (
              <CircularProgress
                size={24}
                style={{ color: colors.black[900] }}
              />
            ) : (
              <MemoryRoundedIcon style={{ color: colors.black[900] }} />
            )
          }
        >
          Engine Analysis
        </Button>*/}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("PGNViewer.labels.enterpgn")}</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            minRows={4}
            placeholder={t("PGNViewer.labels.enterpgnhere")}
            value={pgnInput}
            onChange={handlePgnInputChange}
            style={textareaStyle}
          />
          {alertMessage && (
            <Box sx={{ mt: 2 }}>
              <Alert severity={alertSeverity} sx={{ paddingY: 1, paddingX: 1 }}>
                {alertMessage}
              </Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLoadPgn}
            variant="contained"
            sx={{
              backgroundColor: "green",
              color: "white",
              "&:hover": {
                backgroundColor: "darkgreen", // optional hover effect
              },
            }}
          >
            {t("PGNViewer.buttons.loadpgn")}
          </Button>
        </DialogActions>
      </Dialog>
      {isPgnValid && (
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
          <Box sx={{ width: "100%", maxWidth: "800px", mr: 2 }}>
            <GameViewer Moves={formattedPgn} ShowEval={true} />
          </Box>
        </Box>
      )}
      {/* Nivo Area Chart 
      {evaluation.length > 0 && (
        <Box sx={{ height: "200px", maxWidth: "500px", marginTop: "20px" }}>
          <ResponsiveLine
            data={chartData}
            margin={{ top: 20, right: 10, bottom: 20, left: 0 }}
            axisTop={null}
            axisRight={null}
            curve="monotoneX"
            axisBottom={null}
            axisLeft={null}
            xScale={{ type: "linear" }}
            yScale={{
              type: "linear",
              min: -5,
              max: 5,
              stacked: true,
              reverse: false,
            }}
            areaOpacity={0.5}
            enableArea={true}
            lineWidth={1}
            pointSize={1}
            pointColor={{ theme: "background" }}
            pointBorderColor={{ from: "serieColor" }}
            useMesh={true}
            tooltip={({ point }) => {
              return (
                <div
                  style={{
                    background: colors.black[100],
                    padding: "9px 12px",
                    border: "1px solid #ccc",
                  }}
                >
                  Evaluation: <strong>{point.data.originalValue}</strong>
                  <br />
                </div>
              );
            }}
            theme={{
              grid: {
                line: {
                  stroke: colors.black[400],
                  strokeWidth: 0.25,
                },
              },
              tooltip: {
                container: {
                  background: colors.grey[100],
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "2px",
                  boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
                  padding: "5px 9px",
                },
              },
            }}
          />
        </Box>
      )}*/}
    </Box>
  );
}

export default PGNViewer;
