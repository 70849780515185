export const eco_1 = [
  {
    eco: "A00",
    name: "Amar Opening",
    pgn: "1. Nh3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Anderssen's Opening",
    pgn: "1. a3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Barnes Opening",
    pgn: "1. f3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Clemenz Opening",
    pgn: "1. h3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Grob Opening",
    pgn: "1. g4",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Hungarian Opening",
    pgn: "1. g3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Kádas Opening",
    pgn: "1. h4",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Mieses Opening",
    pgn: "1. d3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Polish Opening",
    pgn: "1. b4",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Saragossa Opening",
    pgn: "1. c3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Sodium Attack",
    pgn: "1. Na3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Van Geet Opening",
    pgn: "1. Nc3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening",
    pgn: "1. e3",
    Length: 1,
  },
  {
    eco: "A00",
    name: "Ware Opening",
    pgn: "1. a4",
    Length: 1,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack",
    pgn: "1. b3",
    Length: 1,
  },
  {
    eco: "A02",
    name: "Bird Opening",
    pgn: "1. f4",
    Length: 1,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3",
    Length: 1,
  },
  {
    eco: "A10",
    name: "English Opening",
    pgn: "1. c4",
    Length: 1,
  },
  {
    eco: "A40",
    name: "Queen's Pawn Game",
    pgn: "1. d4",
    Length: 1,
  },
  {
    eco: "B00",
    name: "King's Pawn Game",
    pgn: "1. e4",
    Length: 1,
  },
];

export const eco_2 = [
  {
    eco: "A00",
    name: "Grob Opening: Alessi Gambit",
    pgn: "1. g4 f5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Grob Opening: Double Grob",
    pgn: "1. g4 g5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Dutch Defense",
    pgn: "1. g3 f5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Indian Defense",
    pgn: "1. g3 Nf6",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Myers Defense",
    pgn: "1. g3 g5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Sicilian Invitation",
    pgn: "1. g3 c5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Symmetrical Variation",
    pgn: "1. g3 g6",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Schneider Gambit",
    pgn: "1. h4 g5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Lasker Simul Special",
    pgn: "1. g3 h5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Mieses Opening: Reversed Rat",
    pgn: "1. d3 e5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Polish Opening: Birmingham Gambit",
    pgn: "1. b4 c5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Polish Opening: Dutch Defense",
    pgn: "1. b4 f5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Polish Opening: Grigorian Variation",
    pgn: "1. b4 Nc6",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Polish Opening: Karniewski Variation",
    pgn: "1. b4 Nh6",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Polish Opening: Outflank Variation",
    pgn: "1. b4 c6",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Polish Opening: Symmetrical Variation",
    pgn: "1. b4 b5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Laroche Gambit",
    pgn: "1. Nc3 b5",
    Length: 2,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Reversed Nimzowitsch",
    pgn: "1. Nc3 e5",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Classical Variation",
    pgn: "1. b3 d5",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Dutch Variation",
    pgn: "1. b3 f5",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: English Variation",
    pgn: "1. b3 c5",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Indian Variation",
    pgn: "1. b3 Nf6",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Modern Variation",
    pgn: "1. b3 e5",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Polish Variation",
    pgn: "1. b3 b5",
    Length: 2,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Symmetrical Variation",
    pgn: "1. b3 b6",
    Length: 2,
  },
  {
    eco: "A02",
    name: "Bird Opening",
    pgn: "1. f4 Nf6",
    Length: 2,
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit",
    pgn: "1. f4 e5",
    Length: 2,
  },
  {
    eco: "A02",
    name: "Bird Opening: Hobbs Gambit",
    pgn: "1. f4 g5",
    Length: 2,
  },
  {
    eco: "A02",
    name: "Bird Opening: Horsefly Defense",
    pgn: "1. f4 Nh6",
    Length: 2,
  },
  {
    eco: "A02",
    name: "Bird Opening: Myers Defense",
    pgn: "1. f4 b5",
    Length: 2,
  },
  {
    eco: "A03",
    name: "Bird Opening: Dutch Variation",
    pgn: "1. f4 d5",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Arctic Defense",
    pgn: "1. Nf3 f6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Basman Defense",
    pgn: "1. Nf3 h6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Black Mustang Defense",
    pgn: "1. Nf3 Nc6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Dutch Variation",
    pgn: "1. Nf3 f5",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Herrstrom Gambit",
    pgn: "1. Nf3 g5",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Kingside Fianchetto",
    pgn: "1. Nf3 g6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Pirc Invitation",
    pgn: "1. Nf3 d6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Polish Defense",
    pgn: "1. Nf3 b5",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Queen's Gambit Invitation",
    pgn: "1. Nf3 e6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Queenside Fianchetto Variation",
    pgn: "1. Nf3 b6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Ross Gambit",
    pgn: "1. Nf3 e5",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Sicilian Invitation",
    pgn: "1. Nf3 c5",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Slav Invitation",
    pgn: "1. Nf3 c6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: St. George Defense",
    pgn: "1. Nf3 a6",
    Length: 2,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Ware Defense",
    pgn: "1. Nf3 a5",
    Length: 2,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 Nf6",
    Length: 2,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 d5",
    Length: 2,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Defense",
    pgn: "1. c4 f5",
    Length: 2,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Lithuanian Variation",
    pgn: "1. c4 Nc6",
    Length: 2,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense",
    pgn: "1. c4 d5",
    Length: 2,
  },
  {
    eco: "A10",
    name: "English Opening: Great Snake Variation",
    pgn: "1. c4 g6",
    Length: 2,
  },
  {
    eco: "A10",
    name: "English Opening: Jaenisch Gambit",
    pgn: "1. c4 b5",
    Length: 2,
  },
  {
    eco: "A10",
    name: "English Opening: Myers Defense",
    pgn: "1. c4 g5",
    Length: 2,
  },
  {
    eco: "A11",
    name: "English Opening: Caro-Kann Defensive System",
    pgn: "1. c4 c6",
    Length: 2,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense",
    pgn: "1. c4 e6",
    Length: 2,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense",
    pgn: "1. c4 Nf6",
    Length: 2,
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation",
    pgn: "1. c4 e5",
    Length: 2,
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation",
    pgn: "1. c4 c5",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Australian Defense",
    pgn: "1. d4 Na6",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Borg Defense: Borg Gambit",
    pgn: "1. d4 g5",
    Length: 2,
  },
  {
    eco: "A40",
    name: "English Defense",
    pgn: "1. d4 b6",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Englund Gambit",
    pgn: "1. d4 e5",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Horwitz Defense",
    pgn: "1. d4 e6",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Mikenas Defense",
    pgn: "1. d4 Nc6",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Polish Defense",
    pgn: "1. d4 b5",
    Length: 2,
  },
  {
    eco: "A40",
    name: "Queen's Pawn Game: Modern Defense",
    pgn: "1. d4 g6",
    Length: 2,
  },
  {
    eco: "A41",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d6",
    Length: 2,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5",
    Length: 2,
  },
  {
    eco: "A45",
    name: "Indian Defense",
    pgn: "1. d4 Nf6",
    Length: 2,
  },
  {
    eco: "A80",
    name: "Dutch Defense",
    pgn: "1. d4 f5",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Barnes Defense",
    pgn: "1. e4 f6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Borg Defense",
    pgn: "1. e4 g5",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Carr Defense",
    pgn: "1. e4 h6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Duras Gambit",
    pgn: "1. e4 f5",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Goldsmith Defense",
    pgn: "1. e4 h5",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Hippopotamus Defense",
    pgn: "1. e4 Nh6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Lemming Defense",
    pgn: "1. e4 Na6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense",
    pgn: "1. e4 Nc6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Owen Defense",
    pgn: "1. e4 b6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Pirc Defense",
    pgn: "1. e4 d6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "St. George Defense",
    pgn: "1. e4 a6",
    Length: 2,
  },
  {
    eco: "B00",
    name: "Ware Defense",
    pgn: "1. e4 a5",
    Length: 2,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense",
    pgn: "1. e4 d5",
    Length: 2,
  },
  {
    eco: "B02",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6",
    Length: 2,
  },
  {
    eco: "B06",
    name: "Modern Defense",
    pgn: "1. e4 g6",
    Length: 2,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6",
    Length: 2,
  },
  {
    eco: "B20",
    name: "Sicilian Defense",
    pgn: "1. e4 c5",
    Length: 2,
  },
  {
    eco: "C00",
    name: "French Defense",
    pgn: "1. e4 e6",
    Length: 2,
  },
  {
    eco: "C20",
    name: "King's Pawn Game",
    pgn: "1. e4 e5",
    Length: 2,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d5",
    Length: 2,
  },
];

export const eco_3 = [
  {
    eco: "A00",
    name: "Anderssen's Opening: Polish Gambit",
    pgn: "1. a3 a5 2. b4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Barnes Opening: Hammerschlag",
    pgn: "1. f3 e5 2. Kf2",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Clemenz Opening: Spike Lee Gambit",
    pgn: "1. h3 h5 2. g4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Crab Opening",
    pgn: "1. a4 e5 2. h4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Global Opening",
    pgn: "1. h3 e5 2. a3",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Grob Opening: Double Grob, Coca-Cola Gambit",
    pgn: "1. g4 g5 2. f4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit",
    pgn: "1. g4 d5 2. Bg2",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Alekhine",
    pgn: "1. g3 e5 2. Nf3",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Beginner's Trap",
    pgn: "1. h4 d5 2. Rh3",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Kádas Gambit",
    pgn: "1. h4 c5 2. b4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Mieses Opening: Myers Spike Attack",
    pgn: "1. d3 g6 2. g4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Polish Opening: Bugayev Attack",
    pgn: "1. b4 e5 2. a3",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Sodium Attack: Chenoboskion Variation",
    pgn: "1. Na3 g6 2. g4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Valencia Opening",
    pgn: "1. d3 e5 2. Nd2",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Battambang Variation",
    pgn: "1. a3 e5 2. Nc3",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Düsseldorf Gambit",
    pgn: "1. Nc3 c5 2. b4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Myers Attack",
    pgn: "1. Nc3 g6 2. h4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Twyble Attack",
    pgn: "1. Nc3 c5 2. Rb1",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Tübingen Gambit",
    pgn: "1. Nc3 Nf6 2. g4",
    Length: 3,
  },
  {
    eco: "A00",
    name: "Ware Opening: Meadow Hay Trap",
    pgn: "1. a4 e5 2. Ra3",
    Length: 3,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Graz Attack",
    pgn: "1. b3 d5 2. Ba3",
    Length: 3,
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Bahr Gambit",
    pgn: "1. f4 e5 2. Nc3",
    Length: 3,
  },
  {
    eco: "A02",
    name: "Bird Opening: Mujannah",
    pgn: "1. f4 Nf6 2. c4",
    Length: 3,
  },
  {
    eco: "A02",
    name: "Bird Opening: Wagner-Zwitersch Gambit",
    pgn: "1. f4 f5 2. e4",
    Length: 3,
  },
  {
    eco: "A03",
    name: "Bird Opening: Dutch Variation, Dudweiler Gambit",
    pgn: "1. f4 d5 2. g4",
    Length: 3,
  },
  {
    eco: "A03",
    name: "Bird Opening: Sturm Gambit",
    pgn: "1. f4 d5 2. c4",
    Length: 3,
  },
  {
    eco: "A03",
    name: "Bird Opening: Williams Gambit",
    pgn: "1. f4 d5 2. e4",
    Length: 3,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Lisitsyn Gambit",
    pgn: "1. Nf3 f5 2. e4",
    Length: 3,
  },
  {
    eco: "A05",
    name: "Polish Opening: Zukertort System",
    pgn: "1. Nf3 Nf6 2. b4",
    Length: 3,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Lemberger Gambit",
    pgn: "1. Nf3 Nf6 2. e4",
    Length: 3,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Nimzo-Larsen Variation",
    pgn: "1. Nf3 Nf6 2. b3",
    Length: 3,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Quiet System",
    pgn: "1. Nf3 Nf6 2. e3",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Nimzo-Larsen Attack: Classical Variation",
    pgn: "1. Nf3 d5 2. b3",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Ampel Variation",
    pgn: "1. Nf3 d5 2. Rg1",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Old Indian Attack",
    pgn: "1. Nf3 d5 2. d3",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Reversed Mexican Defense",
    pgn: "1. Nf3 d5 2. Nc3",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Santasiere's Folly",
    pgn: "1. b4 d5 2. Nf3",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Tennison Gambit",
    pgn: "1. e4 d5 2. Nf3",
    Length: 3,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: The Potato",
    pgn: "1. Nf3 d5 2. a4",
    Length: 3,
  },
  {
    eco: "A07",
    name: "King's Indian Attack",
    pgn: "1. Nf3 d5 2. g3",
    Length: 3,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 d5 2. c4",
    Length: 3,
  },
  {
    eco: "A10",
    name: "English Opening: Achilles-Omega Gambit",
    pgn: "1. c4 Nf6 2. e4",
    Length: 3,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Defense, Hickmann Gambit",
    pgn: "1. c4 f5 2. e4",
    Length: 3,
  },
  {
    eco: "A10",
    name: "English Opening: Wade Gambit",
    pgn: "1. c4 f5 2. g4",
    Length: 3,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense",
    pgn: "1. c4 e6 2. Nf3",
    Length: 3,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, King's Knight Variation",
    pgn: "1. Nf3 Nf6 2. c4",
    Length: 3,
  },
  {
    eco: "A15",
    name: "English Orangutan",
    pgn: "1. c4 Nf6 2. b4",
    Length: 3,
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Queen's Knight Variation",
    pgn: "1. c4 Nf6 2. Nc3",
    Length: 3,
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation, Nimzowitsch Variation",
    pgn: "1. c4 e5 2. Nf3",
    Length: 3,
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Reversed Sicilian",
    pgn: "1. c4 e5 2. Nc3",
    Length: 3,
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation",
    pgn: "1. c4 c5 2. Nf3",
    Length: 3,
  },
  {
    eco: "A30",
    name: "English Opening: Wing Gambit",
    pgn: "1. c4 c5 2. b4",
    Length: 3,
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Normal Variation",
    pgn: "1. c4 c5 2. Nc3",
    Length: 3,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex Declined",
    pgn: "1. d4 e5 2. d5",
    Length: 3,
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Alekhine",
    pgn: "1. d4 e5 2. Nf3",
    Length: 3,
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed French",
    pgn: "1. d4 e5 2. e3",
    Length: 3,
  },
  {
    eco: "A41",
    name: "Old Indian Defense",
    pgn: "1. d4 d6 2. c4",
    Length: 3,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni Gambit Accepted",
    pgn: "1. d4 c5 2. dxc5",
    Length: 3,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5 2. d5",
    Length: 3,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Zilbermints-Benoni Gambit",
    pgn: "1. d4 c5 2. b4",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Canard Opening",
    pgn: "1. d4 Nf6 2. f4",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit",
    pgn: "1. d4 Nf6 2. g4",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Indian Defense: Omega Gambit",
    pgn: "1. d4 Nf6 2. e4",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Indian Defense: Pawn Push Variation",
    pgn: "1. d4 Nf6 2. d5",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Indian Defense: Tartakower Attack",
    pgn: "1. d4 Nf6 2. g3",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Paleface Attack",
    pgn: "1. d4 Nf6 2. f3",
    Length: 3,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack",
    pgn: "1. d4 Nf6 2. Bg5",
    Length: 3,
  },
  {
    eco: "A46",
    name: "Indian Defense: Knights Variation",
    pgn: "1. d4 Nf6 2. Nf3",
    Length: 3,
  },
  {
    eco: "A50",
    name: "Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4",
    Length: 3,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Alapin Variation",
    pgn: "1. d4 f5 2. Qd3",
    Length: 3,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Hopton Attack",
    pgn: "1. d4 f5 2. Bg5",
    Length: 3,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Korchnoi Attack",
    pgn: "1. d4 f5 2. h3",
    Length: 3,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Krejcik Gambit",
    pgn: "1. d4 f5 2. g4",
    Length: 3,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Raphael Variation",
    pgn: "1. d4 f5 2. Nc3",
    Length: 3,
  },
  {
    eco: "A81",
    name: "Dutch Defense: Fianchetto Attack",
    pgn: "1. d4 f5 2. g3",
    Length: 3,
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit",
    pgn: "1. d4 f5 2. e4",
    Length: 3,
  },
  {
    eco: "A84",
    name: "Dutch Defense",
    pgn: "1. d4 f5 2. c4",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense",
    pgn: "1. e4 Nc6 2. d4",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Declined Variation",
    pgn: "1. e4 Nc6 2. Nf3",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Pseudo-Spanish Variation",
    pgn: "1. e4 Nc6 2. Bb5",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Wheeler Gambit",
    pgn: "1. e4 Nc6 2. b4",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Pirc Defense",
    pgn: "1. e4 d6 2. d4",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Rat Defense: Harmonist",
    pgn: "1. e4 d6 2. f4",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Rat Defense: Petruccioli Attack",
    pgn: "1. e4 d6 2. h4",
    Length: 3,
  },
  {
    eco: "B00",
    name: "Rat Defense: Spike Attack",
    pgn: "1. e4 d6 2. g4",
    Length: 3,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Zilbermints Gambit",
    pgn: "1. e4 d5 2. b4",
    Length: 3,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Krejcik Variation",
    pgn: "1. e4 Nf6 2. Bc4",
    Length: 3,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Maróczy Variation",
    pgn: "1. e4 Nf6 2. d3",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. Nc3",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack",
    pgn: "1. e4 c6 2. c4",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Breyer Variation",
    pgn: "1. e4 c6 2. d3",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Euwe Attack",
    pgn: "1. e4 c6 2. b3",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Hillbilly Attack",
    pgn: "1. e4 c6 2. Bc4",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Labahn Attack",
    pgn: "1. e4 c6 2. b4",
    Length: 3,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Spike Variation",
    pgn: "1. e4 c6 2. g4",
    Length: 3,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Amazon Attack",
    pgn: "1. e4 c5 2. Qg4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Bowdler Attack",
    pgn: "1. e4 c5 2. Bc4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Brick Variation",
    pgn: "1. e4 c5 2. Nh3",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Grob Variation",
    pgn: "1. e4 c5 2. g4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Keres Variation",
    pgn: "1. e4 c5 2. Ne2",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: King David's Opening",
    pgn: "1. e4 c5 2. Ke2",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Kronberger Variation",
    pgn: "1. e4 c5 2. Na3",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Lasker-Dunne Attack",
    pgn: "1. e4 c5 2. g3",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Mengarini Variation",
    pgn: "1. e4 c5 2. a3",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Myers Attack, with a4",
    pgn: "1. e4 c5 2. a4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Myers Attack, with h4",
    pgn: "1. e4 c5 2. h4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Snyder Variation",
    pgn: "1. e4 c5 2. b3",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Staunton-Cochrane Variation",
    pgn: "1. e4 c5 2. c4",
    Length: 3,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit",
    pgn: "1. e4 c5 2. b4",
    Length: 3,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: McDonnell Attack",
    pgn: "1. e4 c5 2. f4",
    Length: 3,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit",
    pgn: "1. e4 c5 2. d4",
    Length: 3,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation",
    pgn: "1. e4 c5 2. c3",
    Length: 3,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3",
    Length: 3,
  },
  {
    eco: "B27",
    name: "Sicilian Defense",
    pgn: "1. e4 c5 2. Nf3",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Banzai-Leong Gambit",
    pgn: "1. e4 e6 2. b4",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Bird Invitation",
    pgn: "1. e4 e6 2. Bb5",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Chigorin Variation",
    pgn: "1. e4 e6 2. Qe2",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Horwitz Attack",
    pgn: "1. e4 e6 2. b3",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: King's Indian Attack",
    pgn: "1. e4 e6 2. d3",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Knight Variation",
    pgn: "1. e4 e6 2. Nf3",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: La Bourdonnais Variation",
    pgn: "1. e4 e6 2. f4",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Normal Variation",
    pgn: "1. e4 e6 2. d4",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Queen's Knight",
    pgn: "1. e4 e6 2. Nc3",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Réti-Spielmann Attack",
    pgn: "1. e4 e6 2. g3",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Steiner Variation",
    pgn: "1. e4 e6 2. c4",
    Length: 3,
  },
  {
    eco: "C00",
    name: "French Defense: Steinitz Attack",
    pgn: "1. e4 e6 2. e5",
    Length: 3,
  },
  {
    eco: "C20",
    name: "Bongcloud Attack",
    pgn: "1. e4 e5 2. Ke2",
    Length: 3,
  },
  {
    eco: "C20",
    name: "Center Game",
    pgn: "1. e4 e5 2. d4",
    Length: 3,
  },
  {
    eco: "C20",
    name: "English Opening: The Whale",
    pgn: "1. e4 e5 2. c4",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Alapin Opening",
    pgn: "1. e4 e5 2. Ne2",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: King's Head Opening",
    pgn: "1. e4 e5 2. f3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Leonardis Variation",
    pgn: "1. e4 e5 2. d3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: MacLeod Attack",
    pgn: "1. e4 e5 2. c3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Mengarini's Opening",
    pgn: "1. e4 e5 2. a3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Napoleon Attack",
    pgn: "1. e4 e5 2. Qf3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Tortoise Opening",
    pgn: "1. e4 e5 2. Bd3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Wayward Queen Attack",
    pgn: "1. e4 e5 2. Qh5",
    Length: 3,
  },
  {
    eco: "C20",
    name: "King's Pawn Opening",
    pgn: "1. e4 e5 2. b3",
    Length: 3,
  },
  {
    eco: "C20",
    name: "Portuguese Opening",
    pgn: "1. e4 e5 2. Bb5",
    Length: 3,
  },
  {
    eco: "C23",
    name: "Bishop's Opening",
    pgn: "1. e4 e5 2. Bc4",
    Length: 3,
  },
  {
    eco: "C25",
    name: "Vienna Game",
    pgn: "1. e4 e5 2. Nc3",
    Length: 3,
  },
  {
    eco: "C30",
    name: "King's Gambit",
    pgn: "1. e4 e5 2. f4",
    Length: 3,
  },
  {
    eco: "C40",
    name: "King's Knight Opening",
    pgn: "1. e4 e5 2. Nf3",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Amazon Attack",
    pgn: "1. d4 d5 2. Qd3",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit",
    pgn: "1. d4 d5 2. e4",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d5 2. e3",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System",
    pgn: "1. d4 d5 2. Bf4",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation",
    pgn: "1. d4 d5 2. Nc3",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Levitsky Attack",
    pgn: "1. d4 d5 2. Bg5",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Mason Attack",
    pgn: "1. d4 d5 2. f4",
    Length: 3,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Zurich Gambit",
    pgn: "1. d4 d5 2. g4",
    Length: 3,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Zukertort Variation",
    pgn: "1. d4 d5 2. Nf3",
    Length: 3,
  },
  {
    eco: "D06",
    name: "Queen's Gambit",
    pgn: "1. d4 d5 2. c4",
    Length: 3,
  },
];

export const eco_4 = [
  {
    eco: "A00",
    name: "Barnes Opening: Fool's Mate",
    pgn: "1. f3 e5 2. g4 Qh4#",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Creepy Crawly Formation: Classical Defense",
    pgn: "1. h3 d5 2. a3 e5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit Declined",
    pgn: "1. g4 d5 2. Bg2 c6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Grob Opening: London Defense",
    pgn: "1. g4 e5 2. h3 Nc6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Catalan Formation",
    pgn: "1. g3 d5 2. Bg2 e6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Modern Defense",
    pgn: "1. g3 d5 2. Bg2 c5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Slav Formation",
    pgn: "1. g3 d5 2. Bg2 c6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Van Kuijk Gambit",
    pgn: "1. g3 h5 2. Nf3 h4",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Polish Opening: Baltic Defense",
    pgn: "1. b4 d5 2. Bb2 Bf5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Polish Opening: Czech Defense",
    pgn: "1. b4 e5 2. Bb2 d6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Polish Opening: German Defense",
    pgn: "1. b4 d5 2. Bb2 Qd6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Polish Opening: King's Indian Variation",
    pgn: "1. b4 Nf6 2. Bb2 g6",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Polish Opening: Wolferts Gambit",
    pgn: "1. b4 e5 2. Bb2 c5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Billockus-Johansen Gambit",
    pgn: "1. Nc3 e5 2. Nf3 Bc5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Damhaug Gambit",
    pgn: "1. Nc3 d5 2. f4 e5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Warsteiner Gambit",
    pgn: "1. Nc3 d5 2. f4 g5",
    Length: 4,
  },
  {
    eco: "A00",
    name: "Ware Opening: Wing Gambit",
    pgn: "1. a4 b5 2. axb5 Bb7",
    Length: 4,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Modern Variation",
    pgn: "1. b3 e5 2. Bb2 Nc6",
    Length: 4,
  },
  {
    eco: "A02",
    name: "Bird Opening: Double Duck Formation",
    pgn: "1. f4 f5 2. d4 d5",
    Length: 4,
  },
  {
    eco: "A02",
    name: "Bird Opening: Hobbs-Zilbermints Gambit",
    pgn: "1. f4 h6 2. Nf3 g5",
    Length: 4,
  },
  {
    eco: "A02",
    name: "Bird Opening: Lasker Gambit",
    pgn: "1. f4 e5 2. fxe5 f6",
    Length: 4,
  },
  {
    eco: "A02",
    name: "Bird Opening: Platz Gambit",
    pgn: "1. f4 e5 2. fxe5 Ne7",
    Length: 4,
  },
  {
    eco: "A02",
    name: "Bird Opening: Schlechter Gambit",
    pgn: "1. f4 e5 2. fxe5 Nc6",
    Length: 4,
  },
  {
    eco: "A04",
    name: "Zukertort Defense: Kingside Variation",
    pgn: "1. Nf3 Nh6 2. d4 g6",
    Length: 4,
  },
  {
    eco: "A04",
    name: "Zukertort Defense: Sicilian Knight Variation",
    pgn: "1. Nf3 Na6 2. e4 c5",
    Length: 4,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Drunken Cavalry Variation",
    pgn: "1. Nf3 Na6 2. e4 Nh6",
    Length: 4,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Vos Gambit",
    pgn: "1. Nf3 d6 2. d4 e5",
    Length: 4,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Wade Defense",
    pgn: "1. Nf3 d6 2. e4 Bg4",
    Length: 4,
  },
  {
    eco: "A05",
    name: "King's Indian Attack",
    pgn: "1. Nf3 Nf6 2. g3 d5",
    Length: 4,
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Spassky Variation",
    pgn: "1. Nf3 Nf6 2. g3 b5",
    Length: 4,
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Symmetrical Defense",
    pgn: "1. Nf3 Nf6 2. g3 g6",
    Length: 4,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 Nf6 2. Nc3 Nc6",
    Length: 4,
  },
  {
    eco: "A07",
    name: "Hungarian Opening: Wiedenhagen-Beta Gambit",
    pgn: "1. g3 d5 2. Nf3 g5",
    Length: 4,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Double Fianchetto",
    pgn: "1. Nf3 d5 2. g3 g6",
    Length: 4,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Keres Variation",
    pgn: "1. Nf3 d5 2. g3 Bg4",
    Length: 4,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Omega-Delta Gambit",
    pgn: "1. Nf3 d5 2. g3 e5",
    Length: 4,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Sicilian Variation",
    pgn: "1. Nf3 d5 2. g3 c5",
    Length: 4,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Advance Variation",
    pgn: "1. Nf3 d5 2. c4 d4",
    Length: 4,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Réti Accepted",
    pgn: "1. Nf3 d5 2. c4 dxc4",
    Length: 4,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Zilbermints Gambit",
    pgn: "1. Nf3 d5 2. c4 b5",
    Length: 4,
  },
  {
    eco: "A10",
    name: "English Opening: Adorjan Defense",
    pgn: "1. c4 g6 2. e4 e5",
    Length: 4,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense, Löhn Gambit",
    pgn: "1. c4 d5 2. cxd5 e6",
    Length: 4,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense, Schulz Gambit",
    pgn: "1. c4 d5 2. cxd5 Nf6",
    Length: 4,
  },
  {
    eco: "A10",
    name: "English Opening: Myers Gambit",
    pgn: "1. c4 g5 2. d4 Bg7",
    Length: 4,
  },
  {
    eco: "A10",
    name: "English Opening: Zilbermints Gambit",
    pgn: "1. c4 g5 2. d4 e5",
    Length: 4,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense",
    pgn: "1. c4 e6 2. Nf3 d5",
    Length: 4,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, King's Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 g6",
    Length: 4,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Old Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 d6",
    Length: 4,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 b6",
    Length: 4,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Scandinavian Defense",
    pgn: "1. Nf3 Nf6 2. c4 d5",
    Length: 4,
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Grünfeld Defense",
    pgn: "1. c4 Nf6 2. Nc3 d5",
    Length: 4,
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Hedgehog System",
    pgn: "1. c4 Nf6 2. Nc3 e6",
    Length: 4,
  },
  {
    eco: "A20",
    name: "English Opening: Drill Variation",
    pgn: "1. c4 e5 2. g3 h5",
    Length: 4,
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation, Nimzowitsch-Flohr Variation",
    pgn: "1. c4 e5 2. Nf3 e4",
    Length: 4,
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Kramnik-Shirov Counterattack",
    pgn: "1. c4 e5 2. Nc3 Bb4",
    Length: 4,
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Two Knights Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6",
    Length: 4,
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Reversed Closed Sicilian",
    pgn: "1. c4 e5 2. Nc3 Nc6",
    Length: 4,
  },
  {
    eco: "A35",
    name: "English Opening: Symmetrical Variation, Two Knights Variation",
    pgn: "1. c4 c5 2. Nc3 Nc6",
    Length: 4,
  },
  {
    eco: "A40",
    name: "English Defense",
    pgn: "1. d4 e6 2. c4 b6",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Hartlaub-Charlick Gambit",
    pgn: "1. d4 e5 2. dxe5 d6",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Mosquito Gambit",
    pgn: "1. d4 e5 2. dxe5 Qh4",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Soller Gambit",
    pgn: "1. d4 e5 2. dxe5 f6",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Krebs",
    pgn: "1. d4 e5 2. Nf3 e4",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Horwitz Defense: Zilbermints Gambit",
    pgn: "1. d4 e6 2. c4 e5",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Kangaroo Defense",
    pgn: "1. d4 e6 2. c4 Bb4+",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Montevideo Defense",
    pgn: "1. d4 Nc6 2. d5 Nb8",
    Length: 4,
  },
  {
    eco: "A40",
    name: "Queen's Pawn Game: Anglo-Slav Opening",
    pgn: "1. d4 c6 2. c4 d6",
    Length: 4,
  },
  {
    eco: "A41",
    name: "Rat Defense: English Rat",
    pgn: "1. d4 d6 2. c4 e5",
    Length: 4,
  },
  {
    eco: "A41",
    name: "Wade Defense",
    pgn: "1. d4 d6 2. Nf3 Bg4",
    Length: 4,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni Gambit, Schlenker Defense",
    pgn: "1. d4 c5 2. dxc5 Na6",
    Length: 4,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni-Indian Defense",
    pgn: "1. d4 c5 2. d5 Nf6",
    Length: 4,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Cormorant Gambit",
    pgn: "1. d4 c5 2. dxc5 b6",
    Length: 4,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5 2. d5 d6",
    Length: 4,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni, Mujannah Formation",
    pgn: "1. d4 c5 2. d5 f5",
    Length: 4,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Snail Variation",
    pgn: "1. d4 c5 2. d5 Na6",
    Length: 4,
  },
  {
    eco: "A44",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5 2. d5 e5",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit Accepted",
    pgn: "1. d4 Nf6 2. g4 Nxg4",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit, Oshima Defense",
    pgn: "1. d4 Nf6 2. g4 e5",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Indian Defense: Lazard Gambit",
    pgn: "1. d4 Nf6 2. Nd2 e5",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Indian Defense: Maddigan Gambit",
    pgn: "1. d4 Nf6 2. Nc3 e5",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Indian Defense: Reversed Chigorin Defense",
    pgn: "1. d4 Nf6 2. Nc3 c5",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Queen's Pawn Game: Chigorin Variation",
    pgn: "1. d4 Nf6 2. Nc3 d5",
    Length: 4,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Classical Defense",
    pgn: "1. d4 Nf6 2. Bg5 e6",
    Length: 4,
  },
  {
    eco: "A46",
    name: "Döry Defense",
    pgn: "1. d4 Nf6 2. Nf3 Ne4",
    Length: 4,
  },
  {
    eco: "A46",
    name: "Indian Defense: Czech-Indian",
    pgn: "1. d4 Nf6 2. Nf3 c6",
    Length: 4,
  },
  {
    eco: "A46",
    name: "Indian Defense: Knights Variation, Alburt-Miles Variation",
    pgn: "1. d4 Nf6 2. Nf3 a6",
    Length: 4,
  },
  {
    eco: "A46",
    name: "Indian Defense: Polish Variation",
    pgn: "1. d4 Nf6 2. Nf3 b5",
    Length: 4,
  },
  {
    eco: "A46",
    name: "Indian Defense: Spielmann-Indian",
    pgn: "1. d4 Nf6 2. Nf3 c5",
    Length: 4,
  },
  {
    eco: "A46",
    name: "Indian Defense: Wade-Tartakower Defense",
    pgn: "1. d4 Nf6 2. Nf3 d6",
    Length: 4,
  },
  {
    eco: "A47",
    name: "Pseudo Queen's Indian Defense",
    pgn: "1. d4 Nf6 2. Nf3 b6",
    Length: 4,
  },
  {
    eco: "A48",
    name: "East Indian Defense",
    pgn: "1. d4 Nf6 2. Nf3 g6",
    Length: 4,
  },
  {
    eco: "A50",
    name: "Indian Defense: Medusa Gambit",
    pgn: "1. d4 Nf6 2. c4 g5",
    Length: 4,
  },
  {
    eco: "A50",
    name: "Indian Defense: Pyrenees Gambit",
    pgn: "1. d4 Nf6 2. c4 b5",
    Length: 4,
  },
  {
    eco: "A50",
    name: "Mexican Defense",
    pgn: "1. d4 Nf6 2. c4 Nc6",
    Length: 4,
  },
  {
    eco: "A50",
    name: "Queen's Indian Accelerated",
    pgn: "1. d4 Nf6 2. c4 b6",
    Length: 4,
  },
  {
    eco: "A50",
    name: "Slav Indian",
    pgn: "1. d4 Nf6 2. c4 c6",
    Length: 4,
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense",
    pgn: "1. d4 Nf6 2. c4 e5",
    Length: 4,
  },
  {
    eco: "A53",
    name: "Old Indian Defense",
    pgn: "1. d4 Nf6 2. c4 d6",
    Length: 4,
  },
  {
    eco: "A56",
    name: "Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 c5",
    Length: 4,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Hevendehl Gambit",
    pgn: "1. d4 f5 2. g4 e5",
    Length: 4,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Omega-Isis Gambit",
    pgn: "1. d4 f5 2. Nf3 e5",
    Length: 4,
  },
  {
    eco: "A80",
    name: "Queen's Pawn Game: Veresov Attack, Dutch System",
    pgn: "1. d4 f5 2. Nc3 d5",
    Length: 4,
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit Accepted",
    pgn: "1. d4 f5 2. e4 fxe4",
    Length: 4,
  },
  {
    eco: "A82",
    name: "Rat Defense: Balogh Defense",
    pgn: "1. e4 d6 2. d4 f5",
    Length: 4,
  },
  {
    eco: "A84",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 e6",
    Length: 4,
  },
  {
    eco: "A84",
    name: "Dutch Defense: Normal Variation",
    pgn: "1. d4 f5 2. c4 Nf6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Borg Defense: Borg Gambit",
    pgn: "1. e4 g5 2. d4 Bg7",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Borg Defense: Zilbermints Gambit",
    pgn: "1. e4 g5 2. d4 e5",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Carr Defense: Zilbermints Gambit",
    pgn: "1. e4 h6 2. d4 e5",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Fried Fox Defense",
    pgn: "1. e4 f6 2. d4 Kf7",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Goldsmith Defense: Picklepuss Defense",
    pgn: "1. e4 h5 2. d4 Nf6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Guatemala Defense",
    pgn: "1. e4 b6 2. d4 Ba6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Colorado Countergambit",
    pgn: "1. e4 Nc6 2. Nf3 f5",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Franco-Nimzowitsch Variation",
    pgn: "1. e4 Nc6 2. Nf3 e6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: French Connection",
    pgn: "1. e4 Nc6 2. Nc3 e6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation",
    pgn: "1. e4 Nc6 2. d4 e5",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Mikenas Variation",
    pgn: "1. e4 Nc6 2. d4 d6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Neo-Mongoloid Defense",
    pgn: "1. e4 Nc6 2. d4 f6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Pirc Connection",
    pgn: "1. e4 Nc6 2. Nc3 g6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation",
    pgn: "1. e4 Nc6 2. d4 d5",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Williams Variation",
    pgn: "1. e4 Nc6 2. Nf3 d6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Woodchuck Variation",
    pgn: "1. e4 Nc6 2. d4 a6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Pirc Defense",
    pgn: "1. e4 d6 2. d4 Nf6",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Rat Defense: Antal Defense",
    pgn: "1. e4 d6 2. d4 Nd7",
    Length: 4,
  },
  {
    eco: "B00",
    name: "St. George Defense: Zilbermints Gambit",
    pgn: "1. e4 a6 2. d4 e5",
    Length: 4,
  },
  {
    eco: "B00",
    name: "Ware Defense: Snagglepuss Defense",
    pgn: "1. e4 a5 2. d4 Nc6",
    Length: 4,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Blackburne-Kloosterboer Gambit",
    pgn: "1. e4 d5 2. exd5 c6",
    Length: 4,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Mieses-Kotroc Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5",
    Length: 4,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation",
    pgn: "1. e4 d5 2. exd5 Nf6",
    Length: 4,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Brooklyn Variation",
    pgn: "1. e4 Nf6 2. e5 Ng8",
    Length: 4,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Mokele Mbembe",
    pgn: "1. e4 Nf6 2. e5 Ne4",
    Length: 4,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Normal Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5",
    Length: 4,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Scandinavian Variation",
    pgn: "1. e4 Nf6 2. Nc3 d5",
    Length: 4,
  },
  {
    eco: "B06",
    name: "Modern Defense",
    pgn: "1. e4 g6 2. d4 Bg7",
    Length: 4,
  },
  {
    eco: "B06",
    name: "Modern Defense: Fianchetto Gambit",
    pgn: "1. e4 g6 2. d4 f5",
    Length: 4,
  },
  {
    eco: "B06",
    name: "Modern Defense: Norwegian Defense",
    pgn: "1. e4 g6 2. d4 Nf6",
    Length: 4,
  },
  {
    eco: "B07",
    name: "King's Pawn Game: Maróczy Defense",
    pgn: "1. e4 d6 2. d4 e5",
    Length: 4,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. Nc3 d5",
    Length: 4,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack",
    pgn: "1. e4 c6 2. c4 d5",
    Length: 4,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Open Variation",
    pgn: "1. e4 c6 2. c4 e5",
    Length: 4,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4 d5",
    Length: 4,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: De Bruycker Defense",
    pgn: "1. e4 c6 2. d4 Na6",
    Length: 4,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Masi Variation",
    pgn: "1. e4 c6 2. d4 Nf6",
    Length: 4,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Massachusetts Defense",
    pgn: "1. e4 c6 2. d4 f5",
    Length: 4,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Snyder Variation, Queen Fianchetto Variation",
    pgn: "1. e4 c5 2. b3 b6",
    Length: 4,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 e6",
    Length: 4,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Traditional",
    pgn: "1. e4 c5 2. Nc3 Nc6",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Brussels Gambit",
    pgn: "1. e4 c5 2. Nf3 f5",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Bücker Variation",
    pgn: "1. e4 c5 2. Nf3 h6",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Dragon",
    pgn: "1. e4 c5 2. Nf3 g6",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Jalalabad Variation",
    pgn: "1. e4 c5 2. Nf3 e5",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Katalimov Variation",
    pgn: "1. e4 c5 2. Nf3 b6",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Mongoose Variation",
    pgn: "1. e4 c5 2. Nf3 Qa5",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Polish Gambit",
    pgn: "1. e4 c5 2. Nf3 b5",
    Length: 4,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Quinteros Variation",
    pgn: "1. e4 c5 2. Nf3 Qc7",
    Length: 4,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation",
    pgn: "1. e4 c5 2. Nf3 a6",
    Length: 4,
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6",
    Length: 4,
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Old Sicilian",
    pgn: "1. e4 c5 2. Nf3 Nc6",
    Length: 4,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation",
    pgn: "1. e4 c5 2. Nf3 e6",
    Length: 4,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations",
    pgn: "1. e4 c5 2. Nf3 d6",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense",
    pgn: "1. e4 e6 2. d4 d5",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: Baeuerle Gambit",
    pgn: "1. e4 e6 2. d4 b5",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: Franco-Hiva Gambit",
    pgn: "1. e4 e6 2. d4 f5",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: Franco-Sicilian Defense",
    pgn: "1. e4 e6 2. d4 c5",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: King's Indian Attack, Franco-Hiva Gambit",
    pgn: "1. e4 e6 2. d3 f5",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: Knight Variation, Franco-Hiva Gambit",
    pgn: "1. e4 e6 2. Nf3 f5",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: Mediterranean Defense",
    pgn: "1. e4 e6 2. d4 Nf6",
    Length: 4,
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense",
    pgn: "1. e4 e6 2. d4 a6",
    Length: 4,
  },
  {
    eco: "C00",
    name: "Rat Defense: Small Center Defense",
    pgn: "1. d4 e6 2. e4 d6",
    Length: 4,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Bavarian Gambit",
    pgn: "1. e4 e5 2. c4 d5",
    Length: 4,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Beyer Gambit",
    pgn: "1. e4 e5 2. d4 d5",
    Length: 4,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Clam Variation, King's Gambit Reversed",
    pgn: "1. e4 e5 2. d3 f5",
    Length: 4,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: MacLeod Attack, Lasa Gambit",
    pgn: "1. e4 e5 2. c3 f5",
    Length: 4,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Wayward Queen Attack, Kiddie Countergambit",
    pgn: "1. e4 e5 2. Qh5 Nf6",
    Length: 4,
  },
  {
    eco: "C21",
    name: "Center Game Accepted",
    pgn: "1. e4 e5 2. d4 exd4",
    Length: 4,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Boi Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5",
    Length: 4,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Calabrese Countergambit",
    pgn: "1. e4 e5 2. Bc4 f5",
    Length: 4,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Khan Gambit",
    pgn: "1. e4 e5 2. Bc4 d5",
    Length: 4,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Philidor Counterattack",
    pgn: "1. e4 e5 2. Bc4 c6",
    Length: 4,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Berlin Defense",
    pgn: "1. e4 e5 2. Bc4 Nf6",
    Length: 4,
  },
  {
    eco: "C25",
    name: "Vienna Game: Anderssen Defense",
    pgn: "1. e4 e5 2. Nc3 Bc5",
    Length: 4,
  },
  {
    eco: "C25",
    name: "Vienna Game: Max Lange Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6",
    Length: 4,
  },
  {
    eco: "C26",
    name: "Vienna Game: Falkbeer Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation",
    pgn: "1. e4 e5 2. f4 Bc5",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Keene's Defense",
    pgn: "1. e4 e5 2. f4 Qh4+",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Mafia Defense",
    pgn: "1. e4 c5 2. f4 e5",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Norwalde Variation",
    pgn: "1. e4 e5 2. f4 Qf6",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit",
    pgn: "1. e4 e5 2. f4 f5",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Petrov's Defense",
    pgn: "1. e4 e5 2. f4 Nf6",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Queen's Knight Defense",
    pgn: "1. e4 e5 2. f4 Nc6",
    Length: 4,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Zilbermints Double Countergambit",
    pgn: "1. e4 e5 2. f4 g5",
    Length: 4,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit",
    pgn: "1. e4 e5 2. f4 d5",
    Length: 4,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted",
    pgn: "1. e4 e5 2. f4 exf4",
    Length: 4,
  },
  {
    eco: "C40",
    name: "Elephant Gambit",
    pgn: "1. e4 e5 2. Nf3 d5",
    Length: 4,
  },
  {
    eco: "C40",
    name: "Gunderam Defense",
    pgn: "1. e4 e5 2. Nf3 Qe7",
    Length: 4,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Busch-Gass Gambit",
    pgn: "1. e4 e5 2. Nf3 Bc5",
    Length: 4,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Damiano Defense",
    pgn: "1. e4 e5 2. Nf3 f6",
    Length: 4,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Gunderam Gambit",
    pgn: "1. e4 e5 2. Nf3 c6",
    Length: 4,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: McConnell Defense",
    pgn: "1. e4 e5 2. Nf3 Qf6",
    Length: 4,
  },
  {
    eco: "C40",
    name: "Latvian Gambit",
    pgn: "1. e4 e5 2. Nf3 f5",
    Length: 4,
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6",
    Length: 4,
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6",
    Length: 4,
  },
  {
    eco: "C44",
    name: "King's Knight Opening: Normal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d5 2. e3 Nf6",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit",
    pgn: "1. d4 d5 2. Bf4 c5",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Alburt Defense",
    pgn: "1. d4 d5 2. Nc3 Bf5",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Anti-Veresov",
    pgn: "1. d4 d5 2. Nc3 Bg4",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Irish Gambit",
    pgn: "1. d4 d5 2. Nc3 c5",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Shaviliuk Gambit",
    pgn: "1. d4 d5 2. Nc3 e5",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Shropshire Defense",
    pgn: "1. d4 d5 2. Nc3 h5",
    Length: 4,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Levitsky Attack, Welling Variation",
    pgn: "1. d4 d5 2. Bg5 Bg4",
    Length: 4,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Anti-Torre",
    pgn: "1. Nf3 d5 2. d4 Bg4",
    Length: 4,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Chigorin Variation",
    pgn: "1. d4 d5 2. Nf3 Nc6",
    Length: 4,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Krause Variation",
    pgn: "1. d4 d5 2. Nf3 c5",
    Length: 4,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Symmetrical Variation",
    pgn: "1. d4 d5 2. Nf3 Nf6",
    Length: 4,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Defense",
    pgn: "1. d4 d5 2. c4 c5",
    Length: 4,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense",
    pgn: "1. d4 d5 2. c4 Bf5",
    Length: 4,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Marshall Defense",
    pgn: "1. d4 d5 2. c4 Nf6",
    Length: 4,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Zilbermints Gambit",
    pgn: "1. d4 d5 2. c4 b5",
    Length: 4,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 Nc6",
    Length: 4,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit",
    pgn: "1. d4 d5 2. c4 e5",
    Length: 4,
  },
  {
    eco: "D10",
    name: "Slav Defense",
    pgn: "1. d4 d5 2. c4 c6",
    Length: 4,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted",
    pgn: "1. d4 d5 2. c4 dxc4",
    Length: 4,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined",
    pgn: "1. d4 d5 2. c4 e6",
    Length: 4,
  },
  {
    eco: "E60",
    name: "Indian Defense: West Indian Defense",
    pgn: "1. d4 Nf6 2. c4 g6",
    Length: 4,
  },
];

export const eco_5 = [
  {
    eco: "A00",
    name: "Amar Opening: Paris Gambit",
    pgn: "1. Nh3 d5 2. g3 e5 3. f4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Barnes Opening: Gedult Gambit",
    pgn: "1. f3 f5 2. e4 fxe4 3. Nc3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Basman Gambit",
    pgn: "1. g4 d5 2. Bg2 h5 3. gxh5",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Fritz Gambit",
    pgn: "1. g4 d5 2. Bg2 Bxg4 3. c4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Grob Opening: Spike Attack",
    pgn: "1. g4 d5 2. Bg2 c6 3. g5",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Grob Opening: Spike, Hurst Attack",
    pgn: "1. g4 e5 2. Bg2 d5 3. c4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Grob Opening: Zilbermints Gambit",
    pgn: "1. g4 d5 2. e4 dxe4 3. Nc3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Bücker Gambit",
    pgn: "1. g3 d5 2. Bg2 e5 3. b4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Norwegian Defense",
    pgn: "1. g3 e5 2. Nf3 e4 3. Nh4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Kádas Gambit",
    pgn: "1. h4 e5 2. d4 exd4 3. c3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Myers Variation",
    pgn: "1. h4 d5 2. d4 c5 3. e4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Steinbok Gambit",
    pgn: "1. h4 f5 2. e4 fxe4 3. d3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Polish Opening: Bugayev Advance Variation",
    pgn: "1. b4 e5 2. Bb2 f6 3. b5",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Polish Opening: King's Indian Variation, Schiffler Attack",
    pgn: "1. b4 Nf6 2. Bb2 g6 3. e4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Polish Opening: Myers Variation",
    pgn: "1. b4 d5 2. Bb2 c6 3. a4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Polish Opening: Tartakower Gambit",
    pgn: "1. b4 e5 2. Bb2 f6 3. e4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Caro-Kann Variation, St. Patrick's Attack",
    pgn: "1. Nc3 d5 2. e4 c6 3. h3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Dougherty Gambit",
    pgn: "1. Nc3 d5 2. e4 dxe4 3. f3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Dunst-Perrenet Gambit",
    pgn: "1. Nc3 d5 2. e4 dxe4 3. d3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Hector Gambit",
    pgn: "1. Nc3 d5 2. e4 dxe4 3. Bc4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Kluever Gambit",
    pgn: "1. Nc3 f5 2. e4 fxe4 3. d3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Napoleon Attack",
    pgn: "1. Nc3 e5 2. Nf3 Nc6 3. d4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Nowokunski Gambit",
    pgn: "1. Nc3 e5 2. f4 exf4 3. e4",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Venezolana Variation",
    pgn: "1. Nc3 d5 2. d3 Nf6 3. g3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Bouncing Bishop Variation",
    pgn: "1. e3 e5 2. Bc4 b5 3. Bb3",
    Length: 5,
  },
  {
    eco: "A00",
    name: "Venezolana Opening",
    pgn: "1. d3 c5 2. Nc3 Nc6 3. g3",
    Length: 5,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Modern Variation",
    pgn: "1. b3 e5 2. Bb2 Nc6 3. e3",
    Length: 5,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Pachman Gambit",
    pgn: "1. b3 e5 2. Bb2 Nc6 3. f4",
    Length: 5,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Ringelbach Gambit",
    pgn: "1. b3 f5 2. Bb2 e6 3. e4",
    Length: 5,
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Spike Variation",
    pgn: "1. b3 Nf6 2. Bb2 g6 3. g4",
    Length: 5,
  },
  {
    eco: "A02",
    name: "Bird Opening: Batavo-Polish Attack",
    pgn: "1. f4 Nf6 2. Nf3 g6 3. b4",
    Length: 5,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Lisitsyn Gambit Deferred",
    pgn: "1. Nf3 f5 2. d3 Nf6 3. e4",
    Length: 5,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Speelsmet Gambit",
    pgn: "1. Nf3 c5 2. d4 cxd4 3. e3",
    Length: 5,
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Smyslov Variation",
    pgn: "1. Nf3 Nf6 2. g3 g6 3. b4",
    Length: 5,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Myers Polish Attack",
    pgn: "1. Nf3 Nf6 2. a4 g6 3. b4",
    Length: 5,
  },
  {
    eco: "A06",
    name: "Nimzo-Larsen Attack: Norfolk Gambit",
    pgn: "1. Nf3 d5 2. b3 c5 3. e4",
    Length: 5,
  },
  {
    eco: "A08",
    name: "King's Indian Attack: Sicilian Variation",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2",
    Length: 5,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Penguin Variation",
    pgn: "1. Nf3 d5 2. c4 d4 3. Rg1",
    Length: 5,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Variation, Chabanon Gambit",
    pgn: "1. c4 f5 2. Nf3 d6 3. e4",
    Length: 5,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Variation, Ferenc Gambit",
    pgn: "1. c4 f5 2. Nc3 Nf6 3. e4",
    Length: 5,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bogoljubov Variation",
    pgn: "1. Nf3 d5 2. c4 c6 3. b3",
    Length: 5,
  },
  {
    eco: "A18",
    name: "English Opening: Mikenas-Carls Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4",
    Length: 5,
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation",
    pgn: "1. c4 e5 2. Nc3 d6 3. Nf3",
    Length: 5,
  },
  {
    eco: "A22",
    name: "English Opening: Carls-Bremen System",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3",
    Length: 5,
  },
  {
    eco: "A22",
    name: "English Opening: King's English, Mazedonisch",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. f4",
    Length: 5,
  },
  {
    eco: "A27",
    name: "English Opening: King's English Variation, Three Knights System",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. Nf3",
    Length: 5,
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation, Napolitano Gambit",
    pgn: "1. c4 c5 2. Nf3 Nf6 3. b4",
    Length: 5,
  },
  {
    eco: "A31",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. Nf3",
    Length: 5,
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Fianchetto Variation",
    pgn: "1. c4 Nf6 2. Nc3 c5 3. g3",
    Length: 5,
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Three Knights Variation",
    pgn: "1. c4 c5 2. Nc3 Nf6 3. Nf3",
    Length: 5,
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Two Knights, Fianchetto Variation",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3",
    Length: 5,
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Brooklyn",
    pgn: "1. d4 e5 2. Nf3 e4 3. Ng1",
    Length: 5,
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Mokele Mbembe",
    pgn: "1. d4 e5 2. Nf3 e4 3. Ne5",
    Length: 5,
  },
  {
    eco: "A40",
    name: "Kangaroo Defense: Keres Defense, Transpositional Variation",
    pgn: "1. d4 e6 2. c4 Bb4+ 3. Nc3",
    Length: 5,
  },
  {
    eco: "A40",
    name: "Modern Defense: Lizard Defense, Pirc-Diemer Gambit",
    pgn: "1. d4 g6 2. h4 Nf6 3. h5",
    Length: 5,
  },
  {
    eco: "A40",
    name: "Polish Defense: Spassky Gambit Accepted",
    pgn: "1. d4 b5 2. e4 Bb7 3. Bxb5",
    Length: 5,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni-Indian Defense, Kingside Move Order",
    pgn: "1. d4 Nf6 2. Nf3 c5 3. d5",
    Length: 5,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni-Staunton Gambit",
    pgn: "1. d4 c5 2. d5 f5 3. e4",
    Length: 5,
  },
  {
    eco: "A43",
    name: "Benoni Defense: French Benoni",
    pgn: "1. e4 e6 2. d4 c5 3. d5",
    Length: 5,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Zilbermints-Benoni Gambit",
    pgn: "1. d4 c5 2. Nf3 cxd4 3. b4",
    Length: 5,
  },
  {
    eco: "A43",
    name: "Queen's Pawn Game: Liedmann Gambit",
    pgn: "1. d4 c5 2. c4 cxd4 3. e3",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Amazon Attack: Siberian Attack",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Qd3",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Indian Defense: Gedult Attack, Gedult Attack",
    pgn: "1. d4 Nf6 2. f3 d5 3. g4",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Indian Defense: Paleface Attack, Blackmar-Diemer Gambit Deferred",
    pgn: "1. d4 Nf6 2. f3 d5 3. e4",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Queen's Pawn Game: Veresov, Richter Attack",
    pgn: "1. d4 Nf6 2. f3 d5 3. Nc3",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Classical Defense, Big Center Variation",
    pgn: "1. d4 Nf6 2. Bg5 e6 3. e4",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Edge Variation",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. Bh4",
    Length: 5,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Raptor Variation",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. h4",
    Length: 5,
  },
  {
    eco: "A46",
    name: "Indian Defense: London System",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bf4",
    Length: 5,
  },
  {
    eco: "A46",
    name: "Torre Attack: Classical Defense",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5",
    Length: 5,
  },
  {
    eco: "A46",
    name: "Yusupov-Rubinstein System",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. e3",
    Length: 5,
  },
  {
    eco: "A48",
    name: "London System",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bf4",
    Length: 5,
  },
  {
    eco: "A48",
    name: "Torre Attack: Fianchetto Defense",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bg5",
    Length: 5,
  },
  {
    eco: "A49",
    name: "Indian Defense: Przepiorka Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3",
    Length: 5,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Aged Gibbon Gambit",
    pgn: "1. d4 Nf6 2. c4 d6 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Janzen-Korchnoi Gambit",
    pgn: "1. d4 f5 2. h3 Nf6 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Kingfisher Gambit",
    pgn: "1. d4 f5 2. Nc3 d5 3. e4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Classical Line",
    pgn: "1. d4 f5 2. Qd3 e6 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Leningrad",
    pgn: "1. d4 f5 2. Qd3 g6 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Modern",
    pgn: "1. d4 f5 2. Qd3 d6 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Stonewall",
    pgn: "1. d4 f5 2. Qd3 d5 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Senechaud Gambit",
    pgn: "1. d4 f5 2. Bf4 e6 3. g4",
    Length: 5,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Spielmann Gambit",
    pgn: "1. d4 f5 2. Nc3 Nf6 3. g4",
    Length: 5,
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit, American Attack",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nd2",
    Length: 5,
  },
  {
    eco: "A84",
    name: "Dutch Defense: Bellon Gambit",
    pgn: "1. d4 f5 2. c4 e6 3. e4",
    Length: 5,
  },
  {
    eco: "A84",
    name: "Dutch Defense: Rubinstein Variation",
    pgn: "1. d4 f5 2. c4 e6 3. Nc3",
    Length: 5,
  },
  {
    eco: "A85",
    name: "Dutch Defense: Queen's Knight Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. Nc3",
    Length: 5,
  },
  {
    eco: "A86",
    name: "Dutch Defense: Fianchetto Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Lion Defense: Lion's Jaw",
    pgn: "1. e4 d6 2. d4 Nf6 3. f3",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Colorado Countergambit Accepted",
    pgn: "1. e4 Nc6 2. Nf3 f5 3. exf5",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Hornung Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Be3",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Linksspringer Variation",
    pgn: "1. e4 Nc6 2. d4 e5 3. d5",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Advance Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. e5",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Owen Defense: Naselwaus Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. Bg5",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Owen Defense: Smith Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. Nf3",
    Length: 5,
  },
  {
    eco: "B00",
    name: "Pirc Defense: Roscher Gambit",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nf3",
    Length: 5,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4",
    Length: 5,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Buckley Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. Na3",
    Length: 5,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Krejcik Variation, Krejcik Gambit",
    pgn: "1. e4 Nf6 2. Bc4 Nxe4 3. Bxf7+",
    Length: 5,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Sämisch Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. Nc3",
    Length: 5,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4",
    Length: 5,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Welling Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. b3",
    Length: 5,
  },
  {
    eco: "B03",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4",
    Length: 5,
  },
  {
    eco: "B06",
    name: "Modern Defense: Bishop Attack",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bc4",
    Length: 5,
  },
  {
    eco: "B06",
    name: "Modern Defense: Standard Line",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3",
    Length: 5,
  },
  {
    eco: "B06",
    name: "Modern Defense: Three Pawns Attack",
    pgn: "1. e4 g6 2. d4 Bg7 3. f4",
    Length: 5,
  },
  {
    eco: "B06",
    name: "Modern Defense: Westermann Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bd2",
    Length: 5,
  },
  {
    eco: "B06",
    name: "Modern Defense: Wind Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bd3",
    Length: 5,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Goldman Variation",
    pgn: "1. e4 c6 2. Nc3 d5 3. Qf3",
    Length: 5,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Labahn Attack, Double Gambit",
    pgn: "1. e4 c6 2. b4 d5 3. b5",
    Length: 5,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Labahn Attack, Polish Variation",
    pgn: "1. e4 c6 2. b4 e5 3. Bb2",
    Length: 5,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Toikkanen Gambit",
    pgn: "1. e4 c6 2. c4 d5 3. e5",
    Length: 5,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Two Knights Attack",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3",
    Length: 5,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5",
    Length: 5,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Maróczy Variation",
    pgn: "1. e4 c6 2. d4 d5 3. f3",
    Length: 5,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Mieses Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Be3",
    Length: 5,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Modern Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2",
    Length: 5,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Exchange Variation",
    pgn: "1. e4 c6 2. d4 d5 3. exd5",
    Length: 5,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3",
    Length: 5,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Euwe Attack, Prins Gambit",
    pgn: "1. e4 c5 2. b3 d5 3. Bb2",
    Length: 5,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Abrahams Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. Bb2",
    Length: 5,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Marshall Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. a3",
    Length: 5,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Santasiere Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. c4",
    Length: 5,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Halasz Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. f4",
    Length: 5,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Morphy Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. Nf3",
    Length: 5,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3",
    Length: 5,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 e6 3. g3",
    Length: 5,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Chameleon Variation",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. Nge2",
    Length: 5,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Grob Attack",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g4",
    Length: 5,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Grand Prix Attack",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. f4",
    Length: 5,
  },
  {
    eco: "B24",
    name: "Sicilian Defense: Closed, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3",
    Length: 5,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Fianchetto",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Aronin System",
    pgn: "1. e4 c5 2. Nf3 a6 3. Be2",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Kieseritzky System",
    pgn: "1. e4 c5 2. Nf3 a6 3. b3",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 a6 3. c4",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Quiet System",
    pgn: "1. e4 c5 2. Nf3 a6 3. d3",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Réti System",
    pgn: "1. e4 c5 2. Nf3 a6 3. g3",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Wing Gambit",
    pgn: "1. e4 c5 2. Nf3 a6 3. b4",
    Length: 5,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Yerevan System",
    pgn: "1. e4 c5 2. Nf3 a6 3. Nc3",
    Length: 5,
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Advance Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. e5",
    Length: 5,
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Closed Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. Nc3",
    Length: 5,
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5",
    Length: 5,
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Portsmouth Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. b4",
    Length: 5,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4",
    Length: 5,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Delayed Alapin Variation, with e6",
    pgn: "1. e4 c5 2. Nf3 e6 3. c3",
    Length: 5,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation, Westerinen Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. b3",
    Length: 5,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Kramnik Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. c4",
    Length: 5,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Wing Gambit Deferred",
    pgn: "1. e4 c5 2. Nf3 e6 3. b4",
    Length: 5,
  },
  {
    eco: "B50",
    name: "Sicilian Defense",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4",
    Length: 5,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Delayed Alapin Variation, with d6",
    pgn: "1. e4 c5 2. Nf3 d6 3. c3",
    Length: 5,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Kopec System",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bd3",
    Length: 5,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Wing Gambit, Deferred Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. b4",
    Length: 5,
  },
  {
    eco: "B51",
    name: "Sicilian Defense: Moscow Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Alapin Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Be3",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Banzai-Leong Gambit, Pinova Gambit",
    pgn: "1. e4 e6 2. b4 Bxb4 3. e5",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Diemer-Duhm Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. c4",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Franco-Hiva Gambit Accepted",
    pgn: "1. e4 e6 2. d4 f5 3. exf5",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Horwitz Attack, Papa-Ticulat Gambit",
    pgn: "1. e4 e6 2. b3 d5 3. Bb2",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Morphy Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nh3",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Pelikan Variation",
    pgn: "1. e4 e6 2. Nc3 d5 3. f4",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Perseus Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nf3",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Schlechter Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Bd3",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, Three Pawn Attack",
    pgn: "1. e4 e6 2. d4 a6 3. c4",
    Length: 5,
  },
  {
    eco: "C00",
    name: "French Defense: Two Knights Variation",
    pgn: "1. e4 e6 2. Nf3 d5 3. Nc3",
    Length: 5,
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. exd5",
    Length: 5,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5",
    Length: 5,
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2",
    Length: 5,
  },
  {
    eco: "C10",
    name: "French Defense: Paulsen Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3",
    Length: 5,
  },
  {
    eco: "C20",
    name: "Barnes Opening: Walkerling",
    pgn: "1. f3 e5 2. e4 Nf6 3. Bc4",
    Length: 5,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: King's Head Opening",
    pgn: "1. e4 e5 2. f3 Nf6 3. Nc3",
    Length: 5,
  },
  {
    eco: "C20",
    name: "King's Pawn Opening: Speers",
    pgn: "1. e4 e5 2. Qg4 Nf6 3. Qf5",
    Length: 5,
  },
  {
    eco: "C20",
    name: "Portuguese Opening: Miguel Gambit",
    pgn: "1. e4 e5 2. Bb5 Bc5 3. b4",
    Length: 5,
  },
  {
    eco: "C20",
    name: "Portuguese Opening: Portuguese Gambit",
    pgn: "1. e4 e5 2. Bb5 Nf6 3. d4",
    Length: 5,
  },
  {
    eco: "C21",
    name: "Center Game",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4",
    Length: 5,
  },
  {
    eco: "C21",
    name: "Center Game: Halasz-McDonnell Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. f4",
    Length: 5,
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3",
    Length: 5,
  },
  {
    eco: "C21",
    name: "Center Game: Ross Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Bd3",
    Length: 5,
  },
  {
    eco: "C21",
    name: "Center Game: von der Lasa Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Bc4",
    Length: 5,
  },
  {
    eco: "C21",
    name: "Danish Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. c3",
    Length: 5,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Calabrese Countergambit, Jaenisch Variation",
    pgn: "1. e4 e5 2. Bc4 f5 3. d3",
    Length: 5,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. d4",
    Length: 5,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lopez Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. Qe2",
    Length: 5,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: McDonnell Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. b4",
    Length: 5,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Philidor Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3",
    Length: 5,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Stein Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. f4",
    Length: 5,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Berlin Defense, Greco Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. f4",
    Length: 5,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Ponziani Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4",
    Length: 5,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4",
    Length: 5,
  },
  {
    eco: "C25",
    name: "Vienna Game: Fyfe Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. d4",
    Length: 5,
  },
  {
    eco: "C25",
    name: "Vienna Game: Giraffe Attack",
    pgn: "1. e4 e5 2. Nc3 Bc5 3. Qg4",
    Length: 5,
  },
  {
    eco: "C25",
    name: "Vienna Game: Hamppe-Meitner Variation",
    pgn: "1. e4 e5 2. Nc3 Bc5 3. Na4",
    Length: 5,
  },
  {
    eco: "C25",
    name: "Vienna Game: Omaha Gambit",
    pgn: "1. e4 e5 2. Nc3 d6 3. f4",
    Length: 5,
  },
  {
    eco: "C25",
    name: "Vienna Game: Paulsen Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. g3",
    Length: 5,
  },
  {
    eco: "C26",
    name: "Vienna Game: Mengarini Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. a3",
    Length: 5,
  },
  {
    eco: "C26",
    name: "Vienna Game: Mieses Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. g3",
    Length: 5,
  },
  {
    eco: "C26",
    name: "Vienna Game: Stanley Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4",
    Length: 5,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4",
    Length: 5,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Keene's Defense",
    pgn: "1. e4 e5 2. f4 Qh4+ 3. g3",
    Length: 5,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit Accepted",
    pgn: "1. e4 e5 2. f4 d5 3. exd5",
    Length: 5,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Blackburne Attack",
    pgn: "1. e4 e5 2. f4 d5 3. Nf3",
    Length: 5,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Hinrichsen Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. d4",
    Length: 5,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Milner-Barry Variation",
    pgn: "1. e4 e5 2. f4 d5 3. Nc3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Basman Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Qe2",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Breyer Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Qf3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Carrera Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Qh5",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Dodo Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Qg4",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Eisenberg Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nh3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Gaga Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. g3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Mason-Keres Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nc3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Orsini Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. b3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Paris Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Ne2",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Polerio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. d4",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Schurig Gambit, with Bb5",
    pgn: "1. e4 e5 2. f4 exf4 3. Bb5",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Schurig Gambit, with Bd3",
    pgn: "1. e4 e5 2. f4 exf4 3. Bd3",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Stamma Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. h4",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Tartakower Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Be2",
    Length: 5,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Tumbleweed",
    pgn: "1. e4 e5 2. f4 exf4 3. Kf2",
    Length: 5,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 f5 3. exf5",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Diepstraten Countergambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. c4",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Lobster Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. g4",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mason Countergambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. d4",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mlotkowski Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nc3",
    Length: 5,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Senechaud Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. b4",
    Length: 5,
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4",
    Length: 5,
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4",
    Length: 5,
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5",
    Length: 5,
  },
  {
    eco: "C42",
    name: "Russian Game: Italian Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Bc4",
    Length: 5,
  },
  {
    eco: "C42",
    name: "Russian Game: Three Knights Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nc3",
    Length: 5,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4",
    Length: 5,
  },
  {
    eco: "C44",
    name: "Irish Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nxe5",
    Length: 5,
  },
  {
    eco: "C44",
    name: "King's Knight Opening: Konstantinopolsky",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. g3",
    Length: 5,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Dresden Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c4",
    Length: 5,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Pachman Wing Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. b4",
    Length: 5,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2",
    Length: 5,
  },
  {
    eco: "C44",
    name: "Ponziani Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3",
    Length: 5,
  },
  {
    eco: "C44",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4",
    Length: 5,
  },
  {
    eco: "C46",
    name: "Three Knights Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3",
    Length: 5,
  },
  {
    eco: "C50",
    name: "Italian Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4",
    Length: 5,
  },
  {
    eco: "C60",
    name: "Ruy Lopez",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Blackmar Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. f3",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Diemer-Rosenberg Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Be3",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Fritz Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Bc4",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit Accepted",
    pgn: "1. d4 d5 2. Bf4 c5 3. dxc5",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit, Morris Countergambit",
    pgn: "1. d4 d5 2. Bf4 c5 3. e4",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Hübsch Gambit",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. e4",
    Length: 5,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Stonewall Attack",
    pgn: "1. d4 d5 2. e3 Nf6 3. Bd3",
    Length: 5,
  },
  {
    eco: "D01",
    name: "Rapport-Jobava System",
    pgn: "1. d4 d5 2. Nc3 Nf6 3. Bf4",
    Length: 5,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5",
    Length: 5,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: London System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bf4",
    Length: 5,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Symmetrical Variation, Pseudo-Catalan",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. g3",
    Length: 5,
  },
  {
    eco: "D03",
    name: "Queen's Pawn Game: Torre Attack",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bg5",
    Length: 5,
  },
  {
    eco: "D04",
    name: "Queen's Pawn Game: Colle System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3",
    Length: 5,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Queen Attack",
    pgn: "1. d4 d5 2. c4 Bf5 3. Qb3",
    Length: 5,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3",
    Length: 5,
  },
  {
    eco: "D10",
    name: "Slav Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3",
    Length: 5,
  },
  {
    eco: "D10",
    name: "Slav Defense: Diemer Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. e4",
    Length: 5,
  },
  {
    eco: "D10",
    name: "Slav Defense: Exchange Variation",
    pgn: "1. d4 d5 2. c4 c6 3. cxd5",
    Length: 5,
  },
  {
    eco: "D11",
    name: "Slav Defense: Modern Line",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3",
    Length: 5,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Accelerated Mannheim Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Qa4+",
    Length: 5,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3",
    Length: 5,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Saduleto Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4",
    Length: 5,
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Normal Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3",
    Length: 5,
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Queen's Knight Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3",
    Length: 5,
  },
  {
    eco: "E00",
    name: "Catalan Opening",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3",
    Length: 5,
  },
  {
    eco: "E00",
    name: "Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Qb3",
    Length: 5,
  },
  {
    eco: "E00",
    name: "Indian Defense: Devin Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. g4",
    Length: 5,
  },
  {
    eco: "E00",
    name: "Indian Defense: Seirawan Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Bg5",
    Length: 5,
  },
  {
    eco: "E10",
    name: "Indian Defense: Anti-Nimzo-Indian",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3",
    Length: 5,
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Advance Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. d5",
    Length: 5,
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Alekhine Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. f3",
    Length: 5,
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Basman-Williams Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. h4",
    Length: 5,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Fianchetto Variation, Immediate Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3",
    Length: 5,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Normal Variation, King's Knight Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. c4",
    Length: 5,
  },
  {
    eco: "E60",
    name: "Queen's Pawn, Mengarini Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Qc2",
    Length: 5,
  },
  {
    eco: "E61",
    name: "King's Indian Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3",
    Length: 5,
  },
];

export const eco_6 = [
  {
    eco: "A00",
    name: "Grob Opening: Keene Defense",
    pgn: "1. g4 d5 2. h3 e5 3. Bg2 c6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Grob Opening: Romford Countergambit",
    pgn: "1. g4 d5 2. Bg2 Bxg4 3. c4 d4",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Grob Opening: Zilbermints Gambit, Schiller Defense",
    pgn: "1. g4 d5 2. e4 dxe4 3. Nc3 h5",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Pachman Gambit",
    pgn: "1. g3 f5 2. e4 fxe4 3. Qh5+ g6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Polish Opening: Queen's Indian Variation",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 b6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Polish Opening: Queenside Defense",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 a6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Sodium Attack: Durkin Gambit",
    pgn: "1. Na3 e5 2. Nc4 Nc6 3. e4 f5",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Hergert Gambit",
    pgn: "1. Nc3 d6 2. f4 e5 3. fxe5 Nc6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Hulsemann Gambit",
    pgn: "1. Nc3 e5 2. e3 d5 3. Qh5 Be6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Liebig Gambit",
    pgn: "1. Nc3 e5 2. e3 d5 3. Qh5 Nf6",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Melleby Gambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 c5",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Pfeiffer Gambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 e5",
    Length: 6,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Sleipnir Gambit",
    pgn: "1. Nc3 d5 2. e3 e5 3. d4 Bb4",
    Length: 6,
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Langheld Gambit",
    pgn: "1. f4 e5 2. fxe5 d6 3. exd6 Nf6",
    Length: 6,
  },
  {
    eco: "A03",
    name: "Bird Opening: Lasker Variation",
    pgn: "1. f4 d5 2. Nf3 Nf6 3. e3 c5",
    Length: 6,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Arctic Defense, Drunken Knight Variation",
    pgn: "1. Nf3 f6 2. e4 Nh6 3. d4 Nf7",
    Length: 6,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: The Walrus",
    pgn: "1. Nf3 e5 2. Nxe5 Nc6 3. Nxc6 dxc6",
    Length: 6,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Keres Variation",
    pgn: "1. Nf3 d5 2. g3 Bg4 3. Bg2 Nd7",
    Length: 6,
  },
  {
    eco: "A08",
    name: "King's Indian Attack: French Variation",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6",
    Length: 6,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Advance Variation, Michel Gambit",
    pgn: "1. Nf3 d5 2. c4 d4 3. b4 c5",
    Length: 6,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Réti Gambit, Keres Variation",
    pgn: "1. Nf3 d5 2. c4 dxc4 3. e3 Be6",
    Length: 6,
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense, Malvinas Variation",
    pgn: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qa5",
    Length: 6,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bogoljubov Variation",
    pgn: "1. Nf3 d5 2. c4 c6 3. b3 Bg4",
    Length: 6,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense",
    pgn: "1. Nf3 d5 2. c4 e6 3. g3 c5",
    Length: 6,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Kurajica Defense",
    pgn: "1. Nf3 d5 2. c4 e6 3. g3 c6",
    Length: 6,
  },
  {
    eco: "A13",
    name: "English Opening: Neo-Catalan",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5",
    Length: 6,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Grünfeld Formation",
    pgn: "1. c4 Nf6 2. Nf3 g6 3. g3 d5",
    Length: 6,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Romanishin Variation",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 a6",
    Length: 6,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Scandinavian Defense, Exchange Variation",
    pgn: "1. Nf3 Nf6 2. c4 d5 3. cxd5 Nxd5",
    Length: 6,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Slav Formation",
    pgn: "1. Nf3 Nf6 2. c4 g6 3. g3 c6",
    Length: 6,
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Nimzo-English",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. Nc3 Bb4",
    Length: 6,
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Formation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. Nf3 b6",
    Length: 6,
  },
  {
    eco: "A18",
    name: "English Opening: Mikenas-Carls Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 Nc6",
    Length: 6,
  },
  {
    eco: "A19",
    name: "English Opening: Mikenas-Carls, Sicilian",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 c5",
    Length: 6,
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Keres Defense",
    pgn: "1. c4 e5 2. Nc3 d6 3. g3 c6",
    Length: 6,
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Smyslov Defense",
    pgn: "1. c4 e5 2. Nc3 d6 3. Nf3 Bg4",
    Length: 6,
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Two Knights Variation, Reversed Dragon",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 d5",
    Length: 6,
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Two Knights Variation, Smyslov System",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 Bb4",
    Length: 6,
  },
  {
    eco: "A23",
    name: "English Opening: King's English Variation, Two Knights Variation, Keres Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 c6",
    Length: 6,
  },
  {
    eco: "A24",
    name: "English Opening: King's English Variation, Two Knights Variation, Fianchetto Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 g6",
    Length: 6,
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6",
    Length: 6,
  },
  {
    eco: "A35",
    name: "English Opening: Symmetrical Variation, Four Knights Variation",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6",
    Length: 6,
  },
  {
    eco: "A40",
    name: "English Defense: Eastbourne Gambit",
    pgn: "1. d4 b6 2. c4 Bb7 3. Nc3 e5",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex Declined: Diemer Counterattack",
    pgn: "1. d4 e5 2. d5 Bc5 3. e4 Qh4",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Englund Gambit",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Felbecker Gambit",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Bc5",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Soller Gambit Deferred",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 f6",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Zilbermints Gambit",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Nge7",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Mikenas Defense: Cannstatter Variation",
    pgn: "1. d4 Nc6 2. c4 e5 3. d5 Nd4",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Mikenas Defense: Lithuanian Variation",
    pgn: "1. d4 Nc6 2. c4 e5 3. d5 Nce7",
    Length: 6,
  },
  {
    eco: "A40",
    name: "Slav Indian: Kudischewitsch Gambit",
    pgn: "1. d4 c6 2. Nf3 Nf6 3. c4 b5",
    Length: 6,
  },
  {
    eco: "A41",
    name: "Modern Defense",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6",
    Length: 6,
  },
  {
    eco: "A41",
    name: "Modern Defense: Neo-Modern Defense",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 e5",
    Length: 6,
  },
  {
    eco: "A41",
    name: "Rat Defense: English Rat, Lisbon Gambit",
    pgn: "1. d4 d6 2. c4 e5 3. dxe5 Nc6",
    Length: 6,
  },
  {
    eco: "A41",
    name: "Rat Defense: English Rat, Pounds Gambit",
    pgn: "1. d4 d6 2. c4 e5 3. dxe5 Be6",
    Length: 6,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Hawk Variation",
    pgn: "1. d4 Nf6 2. Nf3 c5 3. d5 c4",
    Length: 6,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni, Schmid Variation",
    pgn: "1. d4 c5 2. d5 d6 3. Nc3 g6",
    Length: 6,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Woozle",
    pgn: "1. d4 c5 2. d5 Nf6 3. Nc3 Qa5",
    Length: 6,
  },
  {
    eco: "A43",
    name: "Benoni Defense: Zilbermints-Benoni Gambit, Tamarkin Countergambit",
    pgn: "1. d4 c5 2. Nf3 cxd4 3. b4 e5",
    Length: 6,
  },
  {
    eco: "A43",
    name: "Indian Defense: Pseudo-Benko",
    pgn: "1. d4 Nf6 2. Nf3 c5 3. d5 b5",
    Length: 6,
  },
  {
    eco: "A44",
    name: "Benoni Defense: Semi-Benoni",
    pgn: "1. d4 c5 2. d5 e5 3. e4 d6",
    Length: 6,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Borg Variation",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. Bf4 g5",
    Length: 6,
  },
  {
    eco: "A46",
    name: "Torre Attack: Classical Defense, Nimzowitsch Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 h6",
    Length: 6,
  },
  {
    eco: "A47",
    name: "Indian Defense: Schnepper Gambit",
    pgn: "1. d4 Nf6 2. Nf3 b6 3. c3 e5",
    Length: 6,
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense, Fajarowicz Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4",
    Length: 6,
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4",
    Length: 6,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Czech Variation, with Nc3",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 c6",
    Length: 6,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Czech Variation, with Nf3",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nf3 c6",
    Length: 6,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5",
    Length: 6,
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Tartakower-Indian",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nf3 Bg4",
    Length: 6,
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Ukrainian Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 e5",
    Length: 6,
  },
  {
    eco: "A56",
    name: "Benoni Defense: Czech Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e5",
    Length: 6,
  },
  {
    eco: "A56",
    name: "Benoni Defense: Hromádka System",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 d6",
    Length: 6,
  },
  {
    eco: "A56",
    name: "Benoni Defense: Weenink Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. dxc5 e6",
    Length: 6,
  },
  {
    eco: "A56",
    name: "Vulture Defense",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 Ne4",
    Length: 6,
  },
  {
    eco: "A57",
    name: "Benko Gambit",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5",
    Length: 6,
  },
  {
    eco: "A60",
    name: "Benoni Defense: Modern Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e6",
    Length: 6,
  },
  {
    eco: "A81",
    name: "Dutch Defense: Semi-Leningrad Variation",
    pgn: "1. d4 f5 2. g3 Nf6 3. Bg2 g6",
    Length: 6,
  },
  {
    eco: "A84",
    name: "Dutch Defense: Bladel Variation",
    pgn: "1. d4 f5 2. c4 g6 3. Nc3 Nh6",
    Length: 6,
  },
  {
    eco: "A86",
    name: "Dutch Defense: Leningrad Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 g6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Borg Defense: Troon Gambit",
    pgn: "1. e4 g5 2. d4 h6 3. h4 g4",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Hippopotamus Defense",
    pgn: "1. e4 Nh6 2. d4 g6 3. c4 f6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Breyer Variation",
    pgn: "1. e4 Nc6 2. Nc3 Nf6 3. d4 e5",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Bielefelder Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Bc5",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Hammer Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 f6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Herford Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Qh4",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, de Smet Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 d6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Aachen Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. exd5 Nb4",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Brandics Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 a6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Erben Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 g6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Heinola-Deppe Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 e5",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Vehre Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 Nf6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Exchange Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Owen Defense: Hekili-Loa Gambit",
    pgn: "1. e4 b6 2. d4 c5 3. dxc5 Nc6",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Owen Defense: Unicorn Variation",
    pgn: "1. e4 f6 2. d4 b6 3. c4 Bb7",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Owen Defense: Wind Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. f3 e5",
    Length: 6,
  },
  {
    eco: "B00",
    name: "Rat Defense: Fuller Gambit",
    pgn: "1. e4 d6 2. f4 d5 3. exd5 Nf6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Blackburne Gambit",
    pgn: "1. e4 d5 2. exd5 c6 3. dxc6 Nxc6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Boehnke Gambit",
    pgn: "1. e4 d5 2. exd5 e5 3. dxe6 Bxe6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Gubinsky-Melts Defense",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Icelandic-Palme Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. c4 e6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Kloosterboer Gambit",
    pgn: "1. e4 d5 2. exd5 c6 3. dxc6 e5",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Main Line",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Marshall Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Panov Transfer",
    pgn: "1. e4 d5 2. exd5 Nf6 3. c4 c6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Richter Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 g6",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Valencian Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8",
    Length: 6,
  },
  {
    eco: "B01",
    name: "Van Geet Opening: Grünfeld Defense",
    pgn: "1. e4 d5 2. Nc3 dxe4 3. Nxe4 e5",
    Length: 6,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Brooklyn Variation, Everglades Variation",
    pgn: "1. e4 Nf6 2. e5 Ng8 3. d4 f5",
    Length: 6,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Mokele Mbembe, Modern Line",
    pgn: "1. e4 Nf6 2. e5 Ne4 3. d4 f6",
    Length: 6,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Mokele Mbembe, Vavra Defense",
    pgn: "1. e4 Nf6 2. e5 Ne4 3. d4 e6",
    Length: 6,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Scandinavian Variation, Geschev Gambit",
    pgn: "1. e4 Nf6 2. Nc3 d5 3. exd5 c6",
    Length: 6,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: The Squirrel",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nf4",
    Length: 6,
  },
  {
    eco: "B03",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6",
    Length: 6,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: O'Sullivan Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 b5",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Modern Defense: Bishop Attack, Bücker Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bc4 b5",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Modern Defense: Lizard Defense, Mittenberger Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d5",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Modern Defense: Modern Pterodactyl",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Modern Defense: Mongredien Defense, with Nc3",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 b6",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Modern Defense: Mongredien Defense, with Nf3",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nf3 b6",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Modern Defense: Standard Defense",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6",
    Length: 6,
  },
  {
    eco: "B06",
    name: "Rat Defense: Accelerated Gurgenidze",
    pgn: "1. e4 g6 2. d4 d6 3. Nc3 c6",
    Length: 6,
  },
  {
    eco: "B07",
    name: "Czech Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 c6",
    Length: 6,
  },
  {
    eco: "B07",
    name: "Pirc Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6",
    Length: 6,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Pseudo-Scandinavian",
    pgn: "1. e4 c6 2. c4 d5 3. exd5 Qxd5",
    Length: 6,
  },
  {
    eco: "B11",
    name: "Caro-Kann Defense: Two Knights Attack, Mindeno Variation",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4",
    Length: 6,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Botvinnik-Carls Defense",
    pgn: "1. e4 c6 2. d4 d5 3. e5 c5",
    Length: 6,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: De Bruycker Defense",
    pgn: "1. e4 c6 2. d4 Na6 3. Nc3 Nc7",
    Length: 6,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Edinburgh Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 Qb6",
    Length: 6,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4",
    Length: 6,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Campomanes Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 Nf6",
    Length: 6,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Gurgenidze Counterattack",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 b5",
    Length: 6,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Gurgenidze System",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 g6",
    Length: 6,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Carlsbad Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. a3 bxa3",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Bird Opening: Dutch Variation, Batavo Gambit",
    pgn: "1. e4 c5 2. f4 d5 3. Nf3 dxe4",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: McDonnell Attack, Tal Gambit",
    pgn: "1. e4 c5 2. f4 d5 3. exd5 Nf6",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Alapin Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 Nf6",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Center Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 e5",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Push Variation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 d3",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Scandinavian Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 d5",
    Length: 6,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Wing Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 Qa5",
    Length: 6,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Barmen Defense",
    pgn: "1. e4 c5 2. c3 d5 3. exd5 Qxd5",
    Length: 6,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Korchnoi Defense",
    pgn: "1. e4 c5 2. Nc3 e6 3. g3 d5",
    Length: 6,
  },
  {
    eco: "B24",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6",
    Length: 6,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Acton Extension",
    pgn: "1. e4 c5 2. Nf3 g6 3. c4 Bh6",
    Length: 6,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Double-Dutch Gambit",
    pgn: "1. e4 c5 2. Nf3 f5 3. exf5 Nh6",
    Length: 6,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Frederico Variation",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 f5",
    Length: 6,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Pterodactyl",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7",
    Length: 6,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Paulsen Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c4 e6",
    Length: 6,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Robatsch Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c4 d6",
    Length: 6,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Barcza Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 Nf6",
    Length: 6,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Ljubojevic Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 b5",
    Length: 6,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Steiner Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 d6",
    Length: 6,
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Rossolimo Variation, Brooklyn Retreat Defense",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Nb8",
    Length: 6,
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6",
    Length: 6,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Franco-Sicilian Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 e6",
    Length: 6,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4",
    Length: 6,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Drazic Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 a6",
    Length: 6,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation, Open",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4",
    Length: 6,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Marshall Counterattack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 d5",
    Length: 6,
  },
  {
    eco: "B50",
    name: "Sicilian Defense",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4",
    Length: 6,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Kotov Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. g3 b5",
    Length: 6,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations, Anti-Qxd4 Move Order",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 Nf6",
    Length: 6,
  },
  {
    eco: "B52",
    name: "Sicilian Defense: Moscow Variation, Main Line",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7",
    Length: 6,
  },
  {
    eco: "C00",
    name: "French Defense: Diemer-Duhm Gambit Accepted",
    pgn: "1. e4 e6 2. d4 d5 3. c4 dxe4",
    Length: 6,
  },
  {
    eco: "C00",
    name: "French Defense: La Bourdonnais Variation, Reuter Gambit",
    pgn: "1. e4 e6 2. f4 d5 3. Nf3 dxe4",
    Length: 6,
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, Sanky-George Gambit",
    pgn: "1. e4 e6 2. d4 a6 3. c4 b5",
    Length: 6,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5",
    Length: 6,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Extended Bishop Swap",
    pgn: "1. e4 e6 2. d4 d5 3. e5 Bd7",
    Length: 6,
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Guimard Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nc6",
    Length: 6,
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Haberditz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 f5",
    Length: 6,
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Modern System",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 a6",
    Length: 6,
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Morozevich Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Be7",
    Length: 6,
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Closed Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6",
    Length: 6,
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5",
    Length: 6,
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4",
    Length: 6,
  },
  {
    eco: "C10",
    name: "Sicilian Defense: Marshall Gambit",
    pgn: "1. e4 c5 2. Nc3 e6 3. d4 d5",
    Length: 6,
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6",
    Length: 6,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4",
    Length: 6,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Clam Variation, Radisch Gambit",
    pgn: "1. e4 e5 2. d3 Nf6 3. f4 Bc5",
    Length: 6,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: MacLeod Attack, Norwalde Gambit",
    pgn: "1. e4 e5 2. c3 d5 3. Qh5 Bd6",
    Length: 6,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Philidor Gambit",
    pgn: "1. e4 e5 2. d4 d6 3. dxe5 Bd7",
    Length: 6,
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 c5",
    Length: 6,
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Svenonius Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 Ne7",
    Length: 6,
  },
  {
    eco: "C21",
    name: "Danish Gambit Declined: Sorensen Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 d5",
    Length: 6,
  },
  {
    eco: "C22",
    name: "Center Game: Normal Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6",
    Length: 6,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Anderssen Gambit",
    pgn: "1. e4 e5 2. Bc4 b5 3. Bxb5 c6",
    Length: 6,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Countergambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 d5",
    Length: 6,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Thorold Gambit",
    pgn: "1. e4 e5 2. Bc4 b5 3. Bxb5 f5",
    Length: 6,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: del Rio Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 Qg5",
    Length: 6,
  },
  {
    eco: "C25",
    name: "Vienna Game: Philidor Countergambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. d4 f5",
    Length: 6,
  },
  {
    eco: "C25",
    name: "Vienna Game: Zhuravlev Countergambit",
    pgn: "1. e4 e5 2. Nc3 Bb4 3. Qg4 Nf6",
    Length: 6,
  },
  {
    eco: "C26",
    name: "Bishop's Opening: Horwitz Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. Nc3 b5",
    Length: 6,
  },
  {
    eco: "C26",
    name: "Vienna Game: Stanley Variation, Reversed Spanish",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bb4",
    Length: 6,
  },
  {
    eco: "C27",
    name: "Vienna Game: Frankenstein-Dracula Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4",
    Length: 6,
  },
  {
    eco: "C28",
    name: "Vienna Game: Stanley Variation, Three Knights Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6",
    Length: 6,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Main Line",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Keene Defense",
    pgn: "1. e4 e5 2. f4 Qh4+ 3. g3 Qe7",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Miles Defense",
    pgn: "1. e4 e5 2. f4 Nc6 3. Nf3 f5",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Greco Variation",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 Qh4+",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Schiller's Defense",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 Bc5",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Senechaud Countergambit",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 g5",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Soller-Zilbermints Gambit",
    pgn: "1. e4 e5 2. f4 f6 3. fxe5 Nc6",
    Length: 6,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Zilbermints Double Gambit",
    pgn: "1. e4 e5 2. f4 Nc6 3. Nf3 g5",
    Length: 6,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Miles Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 Bc5",
    Length: 6,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Modern Transfer",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 exf4",
    Length: 6,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Nimzowitsch-Marshall Countergambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 c6",
    Length: 6,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Staunton Line",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Anderssen Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 g5",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bledow Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Cozio Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Gianutio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 f5",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Kieseritzky Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 b5",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Lopez Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 c6",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Maurian Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nc6",
    Length: 6,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Steinitz Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Ne7",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Becker Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 h6",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Bonsch-Osmolovsky Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Ne7",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Fischer Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Gianutio Countergambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 f5",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: MacLeod Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Schallopp Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nf6",
    Length: 6,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Wagenbach Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 h5",
    Length: 6,
  },
  {
    eco: "C35",
    name: "King's Gambit Accepted: Cunningham Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Be7",
    Length: 6,
  },
  {
    eco: "C36",
    name: "King's Gambit Accepted: Modern Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d5",
    Length: 6,
  },
  {
    eco: "C40",
    name: "Elephant Gambit: Maróczy Gambit",
    pgn: "1. e4 e5 2. Nf3 d5 3. exd5 Bd6",
    Length: 6,
  },
  {
    eco: "C40",
    name: "Elephant Gambit: Paulsen Countergambit",
    pgn: "1. e4 e5 2. Nf3 d5 3. exd5 e4",
    Length: 6,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Busch-Gass Gambit, Chiodini Gambit",
    pgn: "1. e4 e5 2. Nf3 Bc5 3. Nxe5 Nc6",
    Length: 6,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Gunderam Defense, Gunderam Gambit",
    pgn: "1. e4 e5 2. Nf3 Qe7 3. Bc4 f5",
    Length: 6,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Fraser Defense",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Nc6",
    Length: 6,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Greco Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qe7",
    Length: 6,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Morgado Defense",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 Nf6",
    Length: 6,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Strautins Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 b5",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lopez Countergambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4 f5",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Countergambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5",
    Length: 6,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Bd7",
    Length: 6,
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6",
    Length: 6,
  },
  {
    eco: "C42",
    name: "Russian Game: Damiano Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4",
    Length: 6,
  },
  {
    eco: "C42",
    name: "Russian Game: Stafford Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6",
    Length: 6,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4",
    Length: 6,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Symmetrical Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 d5",
    Length: 6,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Jaenisch Counterattack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6",
    Length: 6,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Ponziani Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 f5",
    Length: 6,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Romanishin Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Be7",
    Length: 6,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Réti Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nge7",
    Length: 6,
  },
  {
    eco: "C44",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4",
    Length: 6,
  },
  {
    eco: "C44",
    name: "Scotch Game: Lolli Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4",
    Length: 6,
  },
  {
    eco: "C46",
    name: "Three Knights Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4",
    Length: 6,
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6",
    Length: 6,
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Winawer Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 f5",
    Length: 6,
  },
  {
    eco: "C47",
    name: "Four Knights Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6",
    Length: 6,
  },
  {
    eco: "C50",
    name: "Italian Game: Anti-Fried Liver Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6",
    Length: 6,
  },
  {
    eco: "C50",
    name: "Italian Game: Blackburne-KostiÄ‡ Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nd4",
    Length: 6,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Piano",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5",
    Length: 6,
  },
  {
    eco: "C50",
    name: "Italian Game: Hungarian Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7",
    Length: 6,
  },
  {
    eco: "C50",
    name: "Italian Game: Paris Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6",
    Length: 6,
  },
  {
    eco: "C50",
    name: "Italian Game: Rousseau Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f5",
    Length: 6,
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Alapin Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bb4",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Brentano Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g5",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Bulgarian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a5",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Cozio Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Fianchetto Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Lucena Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Be7",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Nürnberg Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f6",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Pollock Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Na5",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Retreat Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nb8",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Rotary-Albany Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 b6",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Spanish Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5",
    Length: 6,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Vinogradov Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Qe7",
    Length: 6,
  },
  {
    eco: "C61",
    name: "Ruy Lopez: Bird Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4",
    Length: 6,
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6",
    Length: 6,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5",
    Length: 6,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5",
    Length: 6,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6",
    Length: 6,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Netherlands Variation",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 f5",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Reversed Albin Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 c5",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Zeller Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Bf5",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit, Morris Countergambit Accepted",
    pgn: "1. d4 d5 2. Bf4 c5 3. e4 dxe4",
    Length: 6,
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Fianchetto Defense",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. Nc3 d5",
    Length: 6,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5",
    Length: 6,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Boyce Defense",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Ne4",
    Length: 6,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Zilbermints Countergambit",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. c4 b5",
    Length: 6,
  },
  {
    eco: "D03",
    name: "Queen's Pawn Game: Torre Attack, Gossip Variation",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bg5 Ne4",
    Length: 6,
  },
  {
    eco: "D03",
    name: "Queen's Pawn Game: Torre Attack, Grünfeld Variation",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bg5 g6",
    Length: 6,
  },
  {
    eco: "D04",
    name: "Queen's Pawn Game: Colle System, Anti-Colle",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 Bf5",
    Length: 6,
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Colle System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 e6",
    Length: 6,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Attack, Salvio Countergambit",
    pgn: "1. d4 d5 2. c4 c5 3. dxc5 d4",
    Length: 6,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Defense, Gusev Countergambit",
    pgn: "1. d4 d5 2. c4 c5 3. cxd5 Nf6",
    Length: 6,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Marshall Defense, Tan Gambit",
    pgn: "1. d4 d5 2. c4 Nf6 3. cxd5 c6",
    Length: 6,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4",
    Length: 6,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Exchange Variation",
    pgn: "1. d4 d5 2. c4 Nc6 3. cxd5 Qxd5",
    Length: 6,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Lazard Gambit",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nf3 e5",
    Length: 6,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Main Line",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nf3 Bg4",
    Length: 6,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Tartakower Gambit",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 e5",
    Length: 6,
  },
  {
    eco: "D10",
    name: "Slav Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 dxc4",
    Length: 6,
  },
  {
    eco: "D10",
    name: "Slav Defense: Winawer Countergambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 e5",
    Length: 6,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Alekhine System",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 Nf6",
    Length: 6,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Greco Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 b5",
    Length: 6,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, McDonnell Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 e5",
    Length: 6,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Modern Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 Nc6",
    Length: 6,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Rubinstein Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 c5",
    Length: 6,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Schwartz Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 f5",
    Length: 6,
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Godes Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nd7",
    Length: 6,
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Gunsberg Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 c5",
    Length: 6,
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Rosenthal Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 e6",
    Length: 6,
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Slav Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 b5",
    Length: 6,
  },
  {
    eco: "D22",
    name: "Queen's Gambit Accepted: Alekhine Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6",
    Length: 6,
  },
  {
    eco: "D23",
    name: "Queen's Gambit Accepted",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6",
    Length: 6,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Tarrasch Defense, Pseudo-Tarrasch",
    pgn: "1. d4 d5 2. c4 e6 3. Nf3 c5",
    Length: 6,
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Alapin Variation",
    pgn: "1. d4 e6 2. c4 b6 3. Nc3 d5",
    Length: 6,
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Charousek Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Be7",
    Length: 6,
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Janowski Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 a6",
    Length: 6,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Accelerated Move Order",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6",
    Length: 6,
  },
  {
    eco: "D32",
    name: "Tarrasch Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5",
    Length: 6,
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Normal Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6",
    Length: 6,
  },
  {
    eco: "D70",
    name: "Neo-Grünfeld Defense: Goglidze Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. f3 d5",
    Length: 6,
  },
  {
    eco: "D70",
    name: "Neo-Grünfeld Defense: with Nf3",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 d5",
    Length: 6,
  },
  {
    eco: "D70",
    name: "Neo-Grünfeld Defense: with g3",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 d5",
    Length: 6,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5",
    Length: 6,
  },
  {
    eco: "E00",
    name: "Catalan Opening",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5",
    Length: 6,
  },
  {
    eco: "E00",
    name: "Catalan Opening: Hungarian Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 e5",
    Length: 6,
  },
  {
    eco: "E10",
    name: "Indian Defense: Dzindzi-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 a6",
    Length: 6,
  },
  {
    eco: "E10",
    name: "Indian Defense: Döry Indian",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Ne4",
    Length: 6,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+",
    Length: 6,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6",
    Length: 6,
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4",
    Length: 6,
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Adorjan Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. d5 b5",
    Length: 6,
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Alekhine Variation, Leko Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. f3 e5",
    Length: 6,
  },
];

export const eco_7 = [
  {
    eco: "A00",
    name: "Barnes Opening: Gedult Gambit",
    pgn: "1. f3 d5 2. e4 g6 3. d4 dxe4 4. c3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Keres Gambit",
    pgn: "1. g4 d5 2. Bg2 e5 3. d4 exd4 4. c3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Richter-Grob Gambit",
    pgn: "1. g4 d5 2. Bg2 c6 3. c4 dxc4 4. b3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Grob Opening: Zilbermints Gambit, Zilbermints-Hartlaub Gambit",
    pgn: "1. g4 d5 2. e4 dxe4 3. Nc3 e5 4. d3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Brooklyn Defense, Brooklyn Benko Gambit",
    pgn: "1. g3 e5 2. Nf3 e4 3. Ng1 Nf6 4. b4",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Winterberg Gambit",
    pgn: "1. g3 d5 2. Bg2 e5 3. c4 dxc4 4. b3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Kádas Opening: Kádas Gambit",
    pgn: "1. h4 d5 2. d4 c5 3. Nf3 cxd4 4. c3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Polish Opening: Schiffler-Sokolsky Variation",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 d5 4. e3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Polish Opening: Schuehler Gambit",
    pgn: "1. b4 c6 2. Bb2 a5 3. b5 cxb5 4. e4",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Gladbacher Gambit",
    pgn: "1. Nc3 e5 2. b3 d5 3. e4 dxe4 4. d3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Novosibirsk Variation",
    pgn: "1. Nc3 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qh4",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Pfeiffer Gambit, Sleipnir Countergambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 e5 4. Nf3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Reversed Scandinavian",
    pgn: "1. Nc3 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qa4",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Sicilian Two Knights",
    pgn: "1. Nc3 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Keoni-Hiva Gambit, Akahi Variation",
    pgn: "1. e3 e5 2. Nc3 Nf6 3. f4 exf4 4. Nf3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Keoni-Hiva Gambit, Alua Variation",
    pgn: "1. e3 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Keoni-Hiva Gambit, Ekolu Variation",
    pgn: "1. e3 e5 2. Nc3 d5 3. f4 exf4 4. Nf3",
    Length: 7,
  },
  {
    eco: "A00",
    name: "Ware Opening: Ware Gambit",
    pgn: "1. a4 e5 2. a5 d5 3. e3 f5 4. a6",
    Length: 7,
  },
  {
    eco: "A02",
    name: "Bird Opening: Siegener Gambit",
    pgn: "1. f4 e5 2. d4 exd4 3. Nf3 c5 4. c3",
    Length: 7,
  },
  {
    eco: "A02",
    name: "Bird Opening: Swiss Gambit",
    pgn: "1. f4 f5 2. e4 fxe4 3. Nc3 Nf6 4. g4",
    Length: 7,
  },
  {
    eco: "A03",
    name: "Bird Opening: Williams Gambit",
    pgn: "1. f4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Qe2",
    Length: 7,
  },
  {
    eco: "A03",
    name: "Bird Opening: Williams-Zilbermints Gambit",
    pgn: "1. f4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Nge2",
    Length: 7,
  },
  {
    eco: "A06",
    name: "Nimzo-Larsen Attack: Norfolk Gambit",
    pgn: "1. Nf3 d5 2. b3 Nf6 3. Bb2 c5 4. e4",
    Length: 7,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Pachman Gambit",
    pgn: "1. Nf3 d5 2. e3 c5 3. c4 dxc4 4. b3",
    Length: 7,
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Regina-Nu Gambit",
    pgn: "1. Nf3 d5 2. b3 c5 3. c4 dxc4 4. Nc3",
    Length: 7,
  },
  {
    eco: "A08",
    name: "Réti-Zukertort Opening: Reversed Grünfeld",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6 4. d4",
    Length: 7,
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Reversed Blumenfeld Gambit",
    pgn: "1. Nf3 d5 2. c4 d4 3. e3 c5 4. b4",
    Length: 7,
  },
  {
    eco: "A10",
    name: "English Opening: Porcupine Variation",
    pgn: "1. c4 f5 2. Nc3 Nf6 3. e4 fxe4 4. g4",
    Length: 7,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bogoljubov Variation",
    pgn: "1. Nf3 Nf6 2. c4 c6 3. b3 d5 4. Bb2",
    Length: 7,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Anti-Anti-Grünfeld",
    pgn: "1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4",
    Length: 7,
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation",
    pgn: "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. Nf3",
    Length: 7,
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Zvjaginsev-Krasenkow Attack",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. Nf3 Bb4 4. g4",
    Length: 7,
  },
  {
    eco: "A18",
    name: "English Opening: Mikenas-Carls Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 d5 4. e5",
    Length: 7,
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation, Kahiko-Hula Gambit",
    pgn: "1. c4 e5 2. e3 Nf6 3. f4 exf4 4. Nf3",
    Length: 7,
  },
  {
    eco: "A28",
    name: "English Opening: Four Knights System, Nimzowitsch Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e4",
    Length: 7,
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Flexible Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d3",
    Length: 7,
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Korchnoi Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. a3",
    Length: 7,
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Quiet Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3",
    Length: 7,
  },
  {
    eco: "A29",
    name: "English Opening: King's English Variation, Four Knights Variation, Fianchetto Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. g3",
    Length: 7,
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Stockholm Variation",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. Qd5",
    Length: 7,
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Queen Pterodactyl, Quiet Line",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. e3",
    Length: 7,
  },
  {
    eco: "A42",
    name: "Modern Defense: Averbakh System",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4",
    Length: 7,
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit, Maltese Falcon",
    pgn: "1. d4 Nf6 2. g4 Nxg4 3. f3 Nf6 4. e4",
    Length: 7,
  },
  {
    eco: "A45",
    name: "Indian Defense: Omega Gambit, Arafat Gambit",
    pgn: "1. d4 Nf6 2. e4 Nxe4 3. Bd3 Nf6 4. Bg5",
    Length: 7,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Poisoned Pawn Variation",
    pgn: "1. d4 Nf6 2. Bg5 c5 3. d5 Qb6 4. Nc3",
    Length: 7,
  },
  {
    eco: "A46",
    name: "Queen's Pawn Game: Veresov Attack, Classical Defense",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Nc3 d5 4. Bg5",
    Length: 7,
  },
  {
    eco: "A46",
    name: "Torre Attack: Wagner Gambit",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 c5 4. e4",
    Length: 7,
  },
  {
    eco: "A50",
    name: "Mexican Defense: Horsefly Gambit",
    pgn: "1. d4 Nf6 2. c4 Nc6 3. d5 Ne5 4. f4",
    Length: 7,
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense, Fajarowicz-Steiner Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. Qc2",
    Length: 7,
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Adler Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Nf3",
    Length: 7,
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Alekhine Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4",
    Length: 7,
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Rubinstein Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Bf4",
    Length: 7,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation, Fianchetto Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. g3",
    Length: 7,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation, Grinberg Gambit",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. e4",
    Length: 7,
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation, Main Line",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. f3",
    Length: 7,
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Two Knights Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 e5 4. Nf3",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Bishop Attack",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Bg5",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Hjørring Countergambit",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. e4",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Main Line",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Nf3",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Pseudo-Sämisch",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. f3",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Quiet Line",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Nd2",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Sosonko Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. a4",
    Length: 7,
  },
  {
    eco: "A57",
    name: "Benko Gambit: Mutkin Countergambit",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. g4",
    Length: 7,
  },
  {
    eco: "A80",
    name: "Dutch Defense: Krejcik Gambit, Tate Gambit",
    pgn: "1. d4 f5 2. g4 fxg4 3. e4 d5 4. Nc3",
    Length: 7,
  },
  {
    eco: "A81",
    name: "Dutch Defense: Blackburne Variation",
    pgn: "1. d4 f5 2. g3 Nf6 3. Bg2 e6 4. Nh3",
    Length: 7,
  },
  {
    eco: "A81",
    name: "Dutch Defense: Leningrad Variation, Karlsbad Variation",
    pgn: "1. d4 f5 2. g3 g6 3. Bg2 Bg7 4. Nh3",
    Length: 7,
  },
  {
    eco: "A82",
    name: "Dutch Defense: Blackmar's Second Gambit",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. f3",
    Length: 7,
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit, Tartakower Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. g4",
    Length: 7,
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5",
    Length: 7,
  },
  {
    eco: "A90",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2",
    Length: 7,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Keres Attack",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. Nc3",
    Length: 7,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Paulsen Attack",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. Nf3",
    Length: 7,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Exchange Variation, Marshall Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5 4. Nc3",
    Length: 7,
  },
  {
    eco: "B00",
    name: "Van Geet Opening: Berlin Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5",
    Length: 7,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Main Line, Leonhardt Gambit",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. b4",
    Length: 7,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Classical Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Nf3",
    Length: 7,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Wuss Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Be2",
    Length: 7,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Scandinavian Variation, Myers Gambit",
    pgn: "1. e4 Nf6 2. Nc3 d5 3. d3 dxe4 4. Bg5",
    Length: 7,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Spielmann Gambit",
    pgn: "1. e4 Nf6 2. Nc3 d5 3. e5 Nfd7 4. e6",
    Length: 7,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Steiner Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. b3",
    Length: 7,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack, Lasker Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5",
    Length: 7,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack, Tate Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. a4",
    Length: 7,
  },
  {
    eco: "B03",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4",
    Length: 7,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Balogh Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Bc4",
    Length: 7,
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3",
    Length: 7,
  },
  {
    eco: "B06",
    name: "Modern Defense: Pseudo-Austrian Attack",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. f4",
    Length: 7,
  },
  {
    eco: "B06",
    name: "Modern Defense: Two Knights Variation",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3",
    Length: 7,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Anhanguera",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Be3",
    Length: 7,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Benoni",
    pgn: "1. d4 g6 2. e4 Bg7 3. Nc3 c5 4. d5",
    Length: 7,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Rhamphorhynchus",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5",
    Length: 7,
  },
  {
    eco: "B07",
    name: "Lion Defense: Anti-Philidor",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4",
    Length: 7,
  },
  {
    eco: "B07",
    name: "Lion Defense: Bayonet Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. g4",
    Length: 7,
  },
  {
    eco: "B07",
    name: "Modern Defense: Geller's System",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nf3 d6 4. c3",
    Length: 7,
  },
  {
    eco: "B07",
    name: "Pirc Defense: Byrne Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bg5",
    Length: 7,
  },
  {
    eco: "B07",
    name: "Pirc Defense: Kholmov System",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bc4",
    Length: 7,
  },
  {
    eco: "B07",
    name: "Pirc Defense: Sveshnikov System",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. g3",
    Length: 7,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3",
    Length: 7,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4",
    Length: 7,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Van Weersel Attack",
    pgn: "1. e4 c6 2. c4 d5 3. cxd5 cxd5 4. Qb3",
    Length: 7,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Apocalypse Attack",
    pgn: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. Ne5",
    Length: 7,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Hector Gambit",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 dxe4 4. Ng5",
    Length: 7,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Hillbilly Attack, Schaeffer Gambit",
    pgn: "1. e4 c6 2. Bc4 d5 3. Bb3 dxe4 4. Qh5",
    Length: 7,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Scorpion-Horus Gambit",
    pgn: "1. e4 c6 2. Nc3 d5 3. d3 dxe4 4. Bg5",
    Length: 7,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Spike Variation, Scorpion-Grob Gambit",
    pgn: "1. e4 c6 2. g4 d5 3. Nc3 dxe4 4. d3",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Bayonet Attack",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. g4",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Bronstein Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Ne2",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Prins Attack",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. b4",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Short Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Tal Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. h4",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Van der Wiel Attack",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3",
    Length: 7,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Ulysses Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nf3 dxe4 4. Ng5",
    Length: 7,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Exchange Variation, Bulla Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. g4",
    Length: 7,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4",
    Length: 7,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Main Line",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4",
    Length: 7,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Rasa-Studier Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. f3",
    Length: 7,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: von Hennig Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Bc4",
    Length: 7,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Big Clamp Formation",
    pgn: "1. e4 c5 2. d3 Nc6 3. c3 d6 4. f4",
    Length: 7,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Kronberger Variation, Nemeth Gambit",
    pgn: "1. e4 c5 2. Na3 Nc6 3. d4 cxd4 4. Bc4",
    Length: 7,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Morphy Gambit, Andreaschek Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. Nf3 e5 4. c3",
    Length: 7,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Dubois Variation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 d3 4. c4",
    Length: 7,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit, Danish Variation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nf3",
    Length: 7,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Cortlever Gambit",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Bc4",
    Length: 7,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Smith-Morra Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. c3",
    Length: 7,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Zagorovsky Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Qxd4",
    Length: 7,
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, San Francisco Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Na5 4. b4",
    Length: 7,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4",
    Length: 7,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Smith-Morra Gambit Deferred",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. c3",
    Length: 7,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations, Tartakower",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. c3",
    Length: 7,
  },
  {
    eco: "B53",
    name: "Sicilian Defense: Chekhover Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4",
    Length: 7,
  },
  {
    eco: "C00",
    name: "French Defense: Carlson Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nf3 dxe4 4. Ne5",
    Length: 7,
  },
  {
    eco: "C00",
    name: "French Defense: Orthoschnapp Gambit",
    pgn: "1. e4 e6 2. c4 d5 3. cxd5 exd5 4. Qb3",
    Length: 7,
  },
  {
    eco: "C00",
    name: "French Defense: Wing Gambit",
    pgn: "1. e4 e6 2. Nf3 d5 3. e5 c5 4. b4",
    Length: 7,
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation, Monte Carlo Variation",
    pgn: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. c4",
    Length: 7,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3",
    Length: 7,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Frenkel Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. b4",
    Length: 7,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Nimzowitsch Attack",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4",
    Length: 7,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Nimzowitsch System",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3",
    Length: 7,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. dxc5",
    Length: 7,
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System, Euwe-Keres Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. Ngf3",
    Length: 7,
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System, Süchting Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. c3",
    Length: 7,
  },
  {
    eco: "C10",
    name: "French Defense: Classical Variation, Svenonius Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nc6 4. exd5",
    Length: 7,
  },
  {
    eco: "C11",
    name: "French Defense: Burn Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5",
    Length: 7,
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Delayed Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. exd5",
    Length: 7,
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5",
    Length: 7,
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Swiss Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bd3",
    Length: 7,
  },
  {
    eco: "C11",
    name: "French Defense: Henneberger Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Be3",
    Length: 7,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Alekhine-Maróczy Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2",
    Length: 7,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Delayed Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. exd5",
    Length: 7,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Fingerslip Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2",
    Length: 7,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Winckelmann-Riemer Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. a3",
    Length: 7,
  },
  {
    eco: "C16",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5",
    Length: 7,
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. Bc4",
    Length: 7,
  },
  {
    eco: "C21",
    name: "Center Game: Lanc-Arnold Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 Bc5 4. c3",
    Length: 7,
  },
  {
    eco: "C22",
    name: "Center Game: Hall Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qc4",
    Length: 7,
  },
  {
    eco: "C22",
    name: "Center Game: Paulsen Attack Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3",
    Length: 7,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lopez Variation, Lopez Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. Qe2 Nf6 4. f4",
    Length: 7,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: McDonnell Gambit, La Bourdonnais-Denker Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. c3",
    Length: 7,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: McDonnell Gambit, McDonnell Double Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. f4",
    Length: 7,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Pachman Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. Ne2 Nxe4 4. Nec3",
    Length: 7,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Vienna Hybrid",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d3 Nc6 4. Nc3",
    Length: 7,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Warsaw Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. c3",
    Length: 7,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Knight Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3",
    Length: 7,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4",
    Length: 7,
  },
  {
    eco: "C26",
    name: "Bishop's Opening: Vienna Hybrid, Spielmann Attack",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bc5 4. d3",
    Length: 7,
  },
  {
    eco: "C27",
    name: "Bishop's Opening: Boden-Kieseritzky Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nc3",
    Length: 7,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Steinitz Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. d3",
    Length: 7,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3",
    Length: 7,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation, Rotlewi Countergambit",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. b4",
    Length: 7,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical, Soldatenkov Variation",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. fxe5",
    Length: 7,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Norwalde Variation, Schubert Variation",
    pgn: "1. e4 e5 2. f4 Qf6 3. Nc3 Qxf4 4. d4",
    Length: 7,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Anderssen Attack",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. Bb5+",
    Length: 7,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3",
    Length: 7,
  },
  {
    eco: "C31",
    name: "Van Geet Opening: Grünfeld Defense, Steiner Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. Nc3 dxe4 4. Nxe4",
    Length: 7,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bogoljubov Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3",
    Length: 7,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Fischer Defense, Schulder Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. b4",
    Length: 7,
  },
  {
    eco: "C36",
    name: "King's Gambit Accepted: Modern Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5",
    Length: 7,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4",
    Length: 7,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Quade Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Nc3",
    Length: 7,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4",
    Length: 7,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4",
    Length: 7,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: La Bourdonnais Gambit",
    pgn: "1. e4 e5 2. Nf3 Qf6 3. Bc4 Qg6 4. O-O",
    Length: 7,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Foltys-Leonhardt Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4",
    Length: 7,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Main Line",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Bird Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. c3",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Morphy Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Bc4",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation, Klein Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Bc4",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch, Locock Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Ng5",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Countergambit, Zukertort Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. Nc3",
    Length: 7,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7 4. c3",
    Length: 7,
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3",
    Length: 7,
  },
  {
    eco: "C42",
    name: "Russian Game: Cochrane Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7",
    Length: 7,
  },
  {
    eco: "C42",
    name: "Russian Game: Karklins-Martinovsky Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nd3",
    Length: 7,
  },
  {
    eco: "C42",
    name: "Russian Game: Moody Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Qe2 Nc6 4. d4",
    Length: 7,
  },
  {
    eco: "C42",
    name: "Russian Game: Paulsen Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nc4",
    Length: 7,
  },
  {
    eco: "C43",
    name: "Bishop's Opening: Urusov Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3",
    Length: 7,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Center Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Dresden Opening: The Goblin",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c4 Nf6 4. Nxe5",
    Length: 7,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Schulze-Müller Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nxe5 Nxe5 4. d4",
    Length: 7,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d4",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Latvian Gambit: Clam Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d3 f5 4. exf5",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Neumann Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. Bc4",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Spanish Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Bb5",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Scotch Game: Relfsson Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bb5",
    Length: 7,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4",
    Length: 7,
  },
  {
    eco: "C45",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4",
    Length: 7,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Gunsberg Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. a3",
    Length: 7,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Halloween Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Nxe5",
    Length: 7,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Italian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3",
    Length: 7,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4",
    Length: 7,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5",
    Length: 7,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3",
    Length: 7,
  },
  {
    eco: "C50",
    name: "Italian Game: Jerome Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Bxf7+",
    Length: 7,
  },
  {
    eco: "C50",
    name: "Italian Game: Rosentreter Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d4",
    Length: 7,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4",
    Length: 7,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3",
    Length: 7,
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Modern Bishop's Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3",
    Length: 7,
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Open Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4",
    Length: 7,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Knight Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5",
    Length: 7,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Dyckhoff Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3",
    Length: 7,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Bxc6",
    Length: 7,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Jaenisch Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. exf5",
    Length: 7,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Schönemann Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. d4",
    Length: 7,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Central Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3",
    Length: 7,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Spanish Wing Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. b4",
    Length: 7,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O",
    Length: 7,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Halloween Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nxe5",
    Length: 7,
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Endgame Variation",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. dxe5",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Lange Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Nxe4",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Rasmussen Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Nge2",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Sneiders Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Qh5",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Soller Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Be3",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Rasa-Studier Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Be3",
    Length: 7,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: von Popiel Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Bg5",
    Length: 7,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Richter Variation",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5 4. f3",
    Length: 7,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Two Knights System",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Nbd7 4. Nf3",
    Length: 7,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Veresov Variation",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5 4. Bxf6",
    Length: 7,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Chandler Gambit",
    pgn: "1. d4 d5 2. Nf3 c5 3. g3 cxd4 4. Bg2",
    Length: 7,
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Colle System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 e6 4. Bd3",
    Length: 7,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Queen Attack Deferred",
    pgn: "1. d4 d5 2. c4 Bf5 3. Nc3 e6 4. Qb3",
    Length: 7,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Janowski Variation",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. Nf3",
    Length: 7,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Main Line, Alekhine Variation",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nf3 Bg4 4. Qa4",
    Length: 7,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Normal Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3",
    Length: 7,
  },
  {
    eco: "D10",
    name: "Slav Defense: Slav Gambit, Alekhine Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 dxc4 4. e4",
    Length: 7,
  },
  {
    eco: "D10",
    name: "Slav Defense: Winawer Countergambit, Anti-Winawer Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 e5 4. e4",
    Length: 7,
  },
  {
    eco: "D11",
    name: "Slav Defense: Bonet Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Bg5",
    Length: 7,
  },
  {
    eco: "D11",
    name: "Slav Defense: Breyer Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nbd2",
    Length: 7,
  },
  {
    eco: "D11",
    name: "Slav Defense: Quiet Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3",
    Length: 7,
  },
  {
    eco: "D15",
    name: "Slav Defense: Three Knights Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3",
    Length: 7,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, McDonnell Defense, Somov Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 e5 4. Bxc4",
    Length: 7,
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Alekhine Defense, Borisenko-Furman Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e4",
    Length: 7,
  },
  {
    eco: "D23",
    name: "Queen's Gambit Accepted: Mannheim Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Qa4+",
    Length: 7,
  },
  {
    eco: "D24",
    name: "Queen's Gambit Accepted: Showalter Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3",
    Length: 7,
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Normal Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3",
    Length: 7,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Traditional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5",
    Length: 7,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Marshall Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4",
    Length: 7,
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5",
    Length: 7,
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Harrwitz Attack",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bf4",
    Length: 7,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3",
    Length: 7,
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Modern Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5",
    Length: 7,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Gibbon Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. g4",
    Length: 7,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Lutikov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. f3",
    Length: 7,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Stockholm Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5",
    Length: 7,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Zaitsev Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. h4",
    Length: 7,
  },
  {
    eco: "D81",
    name: "Grünfeld Defense: Russian Variation, Accelerated Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Qb3",
    Length: 7,
  },
  {
    eco: "D82",
    name: "Grünfeld Defense: Brinckmann Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4",
    Length: 7,
  },
  {
    eco: "D90",
    name: "Grünfeld Defense: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3",
    Length: 7,
  },
  {
    eco: "E01",
    name: "Catalan Opening: Closed",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2",
    Length: 7,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Grünfeld Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nbd2",
    Length: 7,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: New England Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nfd2",
    Length: 7,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3",
    Length: 7,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Miles Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Bf4",
    Length: 7,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Petrosian Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3",
    Length: 7,
  },
  {
    eco: "E14",
    name: "Queen's Indian Defense: Spassky System",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. e3",
    Length: 7,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3",
    Length: 7,
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Kmoch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3",
    Length: 7,
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Mikenas Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qd3",
    Length: 7,
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Romanishin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. g3",
    Length: 7,
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3",
    Length: 7,
  },
  {
    eco: "E22",
    name: "Nimzo-Indian Defense: Spielmann Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3",
    Length: 7,
  },
  {
    eco: "E30",
    name: "Nimzo-Indian Defense: Leningrad Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5",
    Length: 7,
  },
  {
    eco: "E32",
    name: "Nimzo-Indian Defense: Classical Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2",
    Length: 7,
  },
  {
    eco: "E40",
    name: "Nimzo-Indian Defense: Normal Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3",
    Length: 7,
  },
  {
    eco: "E60",
    name: "Indian Defense: King's Indian Variation, Fianchetto Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2",
    Length: 7,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Santasiere Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. b4",
    Length: 7,
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4",
    Length: 7,
  },
];

export const eco_8 = [
  {
    eco: "A00",
    name: "Amar Gambit",
    pgn: "1. Nh3 d5 2. g3 e5 3. f4 Bxh3 4. Bxh3 exf4",
    Length: 8,
  },
  {
    eco: "A00",
    name: "Amsterdam Attack",
    pgn: "1. e3 e5 2. c4 d6 3. Nc3 Nc6 4. b3 Nf6",
    Length: 8,
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Lasker Variation",
    pgn: "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 g5",
    Length: 8,
  },
  {
    eco: "A04",
    name: "Colle System: Rhamphorhynchus Variation",
    pgn: "1. Nf3 c5 2. e3 g6 3. d4 Bg7 4. dxc5 Qa5+",
    Length: 8,
  },
  {
    eco: "A04",
    name: "Modern Defense: Semi-Averbakh Variation, Polish Variation",
    pgn: "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qb6",
    Length: 8,
  },
  {
    eco: "A04",
    name: "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation",
    pgn: "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qa5+",
    Length: 8,
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Shabalov Gambit",
    pgn: "1. Nf3 e6 2. c4 a6 3. Nc3 c5 4. g3 b5",
    Length: 8,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Yugoslav Variation",
    pgn: "1. Nf3 Nf6 2. g3 d5 3. Bg2 c6 4. O-O Bg4",
    Length: 8,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bled Variation",
    pgn: "1. Nf3 d5 2. b3 Nf6 3. Bb2 g6 4. c4 c6",
    Length: 8,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Capablanca Variation",
    pgn: "1. c4 Nf6 2. Nf3 c6 3. b3 d5 4. Bb2 Bg4",
    Length: 8,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, London Defensive System",
    pgn: "1. c4 Nf6 2. g3 c6 3. Nf3 d5 4. b3 Bf5",
    Length: 8,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, New York System",
    pgn: "1. Nf3 Nf6 2. c4 c6 3. b3 d5 4. Bb2 Bf5",
    Length: 8,
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Torre System",
    pgn: "1. c4 Nf6 2. g3 c6 3. Nf3 d5 4. b3 Bg4",
    Length: 8,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Bogoljubov Defense",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Bd6",
    Length: 8,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense Accepted",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4",
    Length: 8,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense, Semi-Slav Defense",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 c6",
    Length: 8,
  },
  {
    eco: "A13",
    name: "English Opening: Neo-Catalan Declined",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7",
    Length: 8,
  },
  {
    eco: "A13",
    name: "English Opening: Romanishin Gambit",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 a6 4. Bg2 b5",
    Length: 8,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, King's Indian Formation, Double Fianchetto",
    pgn: "1. Nf3 Nf6 2. c4 b6 3. g3 Bb7 4. Bg2 g6",
    Length: 8,
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 b6 4. Bg2 Bb7",
    Length: 8,
  },
  {
    eco: "A19",
    name: "English Opening: Anglo-Indian Defense, Flohr-Mikenas-Carls Variation, Nei Gambit",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 c5 4. e5 Ng8",
    Length: 8,
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Troger Defense",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 d6 4. Bg2 Be6",
    Length: 8,
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Bellon Gambit",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 e4 4. Ng5 b5",
    Length: 8,
  },
  {
    eco: "A22",
    name: "English Opening: King's English, Erbenheimer Gambit",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 e4 4. Ng5 Ng4",
    Length: 8,
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Taimanov Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7",
    Length: 8,
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Bradley Beach Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d4 e4",
    Length: 8,
  },
  {
    eco: "A32",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense",
    pgn: "1. d4 Nf6 2. c4 c5 3. Nf3 cxd4 4. Nxd4 e6",
    Length: 8,
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Symmetrical Variation",
    pgn: "1. c4 c5 2. g3 g6 3. Bg2 Bg7 4. Nc3 Nc6",
    Length: 8,
  },
  {
    eco: "A40",
    name: "Colle System: Pterodactyl Variation",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 Qa5+",
    Length: 8,
  },
  {
    eco: "A40",
    name: "English Defense: Perrin Variation",
    pgn: "1. d4 e6 2. c4 b6 3. e4 Bb7 4. Bd3 Nc6",
    Length: 8,
  },
  {
    eco: "A40",
    name: "Mikenas Defense: Pozarek Gambit",
    pgn: "1. d4 Nc6 2. c4 e5 3. dxe5 Nxe5 4. Nc3 Nxc4",
    Length: 8,
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Central, Benoni Pterodactyl",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 c5 4. d5 Qa5+",
    Length: 8,
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Fianchetto, Queen Benoni Pterodactyl",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Qa5",
    Length: 8,
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Fianchetto, Queen Pterodactyl",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. g3 c5 4. Bg2 Qa5+",
    Length: 8,
  },
  {
    eco: "A40",
    name: "Zaire Defense",
    pgn: "1. d4 Nc6 2. d5 Nb8 3. e4 Nf6 4. e5 Ng8",
    Length: 8,
  },
  {
    eco: "A41",
    name: "Robatsch Defense",
    pgn: "1. d4 d6 2. Nf3 g6 3. c4 Bg7 4. e4 Bg4",
    Length: 8,
  },
  {
    eco: "A41",
    name: "Réti-Zukertort Opening: Wade Defense, Chigorin Plan",
    pgn: "1. d4 d6 2. Nf3 Bg4 3. c4 Nd7 4. Qb3 Rb8",
    Length: 8,
  },
  {
    eco: "A42",
    name: "Modern Defense: Kotov Variation",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 Nc6",
    Length: 8,
  },
  {
    eco: "A42",
    name: "Modern Defense: Randspringer Variation",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 f5",
    Length: 8,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Raptor Variation, Hergert Gambit",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. h4 Nxg5 4. hxg5 e5",
    Length: 8,
  },
  {
    eco: "A47",
    name: "Marienbad System",
    pgn: "1. d4 Nf6 2. Nf3 b6 3. g3 Bb7 4. Bg2 c5",
    Length: 8,
  },
  {
    eco: "A48",
    name: "Indian Defense: Colle System, King's Indian Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. e3 Bg7 4. Bd3 d6",
    Length: 8,
  },
  {
    eco: "A48",
    name: "Torre Attack: Fianchetto Defense, Euwe Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. Nbd2 c5",
    Length: 8,
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense, Fajarowicz Defense, Bonsdorf Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. a3 b6",
    Length: 8,
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Alekhine Variation, Tartakower Defense",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4 d6",
    Length: 8,
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6",
    Length: 8,
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Chigorin Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 c6",
    Length: 8,
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Nimzowitsch Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 b6",
    Length: 8,
  },
  {
    eco: "A84",
    name: "Dutch Defense: Krause Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. Nc3 d6 4. Nf3 Nc6",
    Length: 8,
  },
  {
    eco: "A90",
    name: "Dutch Defense: Nimzo-Dutch Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Bb4+",
    Length: 8,
  },
  {
    eco: "A91",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7",
    Length: 8,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Main Line",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. f4 Ng6",
    Length: 8,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Riemann Defense",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. f4 Nc6",
    Length: 8,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Nimzowitsch Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5 Ne5",
    Length: 8,
  },
  {
    eco: "B00",
    name: "St. George Defense: Polish Variation",
    pgn: "1. e4 a6 2. d4 b5 3. Nf3 Bb7 4. Bd3 e6",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Anderssen Counterattack",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Kiel Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. c4 Nb4",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Kádas Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 c6 4. dxc6 e5",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Main Line, Mieses Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation, Gipslis Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. Nf3 Bg4",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation, Wing Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 g6 4. c4 b5",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Elbow Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Bb5+ c6",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Richter Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. Nf3 g6",
    Length: 8,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Schiller-Pytel Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 c6",
    Length: 8,
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Alburt Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 g6",
    Length: 8,
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Larsen Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 dxe5",
    Length: 8,
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Larsen-Haakert Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Nc6",
    Length: 8,
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Schmid Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Nb6",
    Length: 8,
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Main Line",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Modern Defense: Bishop Attack, Monkey's Bum",
    pgn: "1. e4 g6 2. Bc4 Bg7 3. Qf3 e6 4. d4 Bxd4",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Modern Defense: Norwegian Defense, Norwegian Gambit",
    pgn: "1. e4 g6 2. d4 Nf6 3. e5 Nh5 4. Be2 d6",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Modern Defense: Two Knights Variation, Suttles Variation",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Nf3 d6",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Norwegian Defense",
    pgn: "1. e4 g6 2. d4 Nf6 3. e5 Nh5 4. g4 Ng7",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Austrian, Austriadactylus Western",
    pgn: "1. e4 g6 2. d4 Bg7 3. f4 c5 4. Nf3 Qa5+",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Austrian, Grand Prix Pterodactyl",
    pgn: "1. e4 g6 2. Nc3 Bg7 3. f4 c5 4. Nf3 Qa5",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Austrian, Pteranodon",
    pgn: "1. e4 g6 2. d4 Bg7 3. f4 c5 4. c3 Qa5",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Benoni Pterodactyl",
    pgn: "1. d4 g6 2. Nc3 Bg7 3. e4 c5 4. d5 Qa5",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Pterodactyl",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5 Qa5",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Fianchetto, King Pterodactyl",
    pgn: "1. e4 g6 2. d4 Bg7 3. g3 c5 4. Nf3 Qa5+",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Fianchetto, Rhamphorhynchus",
    pgn: "1. e4 g6 2. d4 Bg7 3. g3 c5 4. dxc5 Qa5+",
    Length: 8,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Western, Anhanguera",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nf3 c5 4. Be3 Qa5+",
    Length: 8,
  },
  {
    eco: "B07",
    name: "Lion Defense: Anti-Philidor, Lion's Cave",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4 e5",
    Length: 8,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7",
    Length: 8,
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Modern Variation",
    pgn: "1. e4 c6 2. c4 d5 3. exd5 cxd5 4. cxd5 Nf6",
    Length: 8,
  },
  {
    eco: "B11",
    name: "Caro-Kann Defense: Two Knights Attack, Mindeno Variation, Exchange Line",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4 4. h3 Bxf3",
    Length: 8,
  },
  {
    eco: "B11",
    name: "Caro-Kann Defense: Two Knights Attack, Mindeno Variation, Retreat Line",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4 4. h3 Bh5",
    Length: 8,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Van der Wiel Attack, Dreyev Defense",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3 Qb6",
    Length: 8,
  },
  {
    eco: "B16",
    name: "Caro-Kann Defense: Finnish Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 h6",
    Length: 8,
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7",
    Length: 8,
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5",
    Length: 8,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Gloria Variation",
    pgn: "1. e4 c5 2. c4 d6 3. Nc3 Nc6 4. g3 h5",
    Length: 8,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Smith-Morra Declined",
    pgn: "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. d4 cxd4",
    Length: 8,
  },
  {
    eco: "B24",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7",
    Length: 8,
  },
  {
    eco: "B27",
    name: "Modern Defense: Pterodactyl Variation",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5",
    Length: 8,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Western, Pterodactyl",
    pgn: "1. e4 c5 2. Nf3 g6 3. c3 Bg7 4. d4 Qa5",
    Length: 8,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Western, Rhamphorhynchus",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+",
    Length: 8,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Taimanov Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Nxd4 e5",
    Length: 8,
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Gambit Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 d5 4. exd5 Nf6",
    Length: 8,
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 Nxc3",
    Length: 8,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Accelerated Dragon",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6",
    Length: 8,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Flohr Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qc7",
    Length: 8,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Godiva Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qb6",
    Length: 8,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Löwenthal Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5",
    Length: 8,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Nimzo-American Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 d5",
    Length: 8,
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6",
    Length: 8,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation, Normal",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6",
    Length: 8,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Kveinis Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Qb6",
    Length: 8,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Paulsen-Basman Defense",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Bc5",
    Length: 8,
  },
  {
    eco: "B41",
    name: "Sicilian Defense: Kan Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6",
    Length: 8,
  },
  {
    eco: "B44",
    name: "Sicilian Defense: Taimanov Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6",
    Length: 8,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations, Anti-Qxd4 Move Order Accepted",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 Nf6 4. dxc5 Nxe4",
    Length: 8,
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Dragon Variation, Accelerated Dragon",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 g6",
    Length: 8,
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Modern Variations, Main Line",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6",
    Length: 8,
  },
  {
    eco: "C00",
    name: "French Defense: Hoffmann Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Qe2 e5 4. f4 exf4",
    Length: 8,
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, St. George Gambit",
    pgn: "1. e4 e6 2. d4 a6 3. c4 b5 4. cxb5 axb5",
    Length: 8,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6",
    Length: 8,
  },
  {
    eco: "C04",
    name: "French Defense: Tarrasch Variation, Guimard Defense, Main Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nc6 4. Ngf3 Nf6",
    Length: 8,
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Chistyakov Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5",
    Length: 8,
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System, Shaposhnikov Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Nf6",
    Length: 8,
  },
  {
    eco: "C08",
    name: "French Defense: Tarrasch Variation, Open System",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5",
    Length: 8,
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Blackburne Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7",
    Length: 8,
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Ellis Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 e5",
    Length: 8,
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Maric Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Qd5",
    Length: 8,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4",
    Length: 8,
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Normal Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7",
    Length: 8,
  },
  {
    eco: "C16",
    name: "French Defense: Winawer Variation, Petrosian Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 Qd7",
    Length: 8,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5",
    Length: 8,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Weber Gambit",
    pgn: "1. e4 e5 2. d3 d5 3. exd5 c6 4. dxc6 Nxc6",
    Length: 8,
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. Bc4 b5",
    Length: 8,
  },
  {
    eco: "C22",
    name: "Center Game: Berger Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6",
    Length: 8,
  },
  {
    eco: "C22",
    name: "Center Game: l'Hermet Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 f5",
    Length: 8,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Countergambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 d5 4. Bxd5 Nf6",
    Length: 8,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Kitchener Folly",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d3 Be7 4. Nf3 O-O",
    Length: 8,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Cunningham Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 Be7",
    Length: 8,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Quelle Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 Bc5 4. fxe5 d6",
    Length: 8,
  },
  {
    eco: "C26",
    name: "Vienna Game: Mieses Variation, Erben Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. g3 d5 4. exd5 c6",
    Length: 8,
  },
  {
    eco: "C26",
    name: "Vienna Game: Stanley Variation, Eifel Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bc5 4. Nge2 b5",
    Length: 8,
  },
  {
    eco: "C27",
    name: "Bishop's Opening: Boden-Kieseritzky Gambit, Lichtenhein Defense",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nc3 d5",
    Length: 8,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation, Rubinstein Countergambit",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 f5",
    Length: 8,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical, Hanham Variation",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. Nc3 Nd7",
    Length: 8,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Hobbs-Zilbermints Gambit",
    pgn: "1. e4 e5 2. f4 Nc6 3. Nf3 g5 4. fxg5 h6",
    Length: 8,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Shirazi Line",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ Ke7",
    Length: 8,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Pickler Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 c6 4. dxc6 Bc5",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Anderssen Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 c6",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bledow Countergambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 Nf6",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Boden Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Nc6",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bogoljubov Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3 c6",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bryan Countergambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 b5",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Cozio Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 d6",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, First Jaenisch Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Nf6",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Greco Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Bc5",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Lopez Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5",
    Length: 8,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Tartakower Gambit, Weiss Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Be2 f5 4. exf5 d6",
    Length: 8,
  },
  {
    eco: "C35",
    name: "King's Gambit Accepted: Cunningham Defense, McCormick Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Nf6",
    Length: 8,
  },
  {
    eco: "C36",
    name: "King's Gambit Accepted: Abbazia Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5 Nf6",
    Length: 8,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Blachly Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. Bc4 g5",
    Length: 8,
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Traditional Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7",
    Length: 8,
  },
  {
    eco: "C40",
    name: "Elephant Gambit: Wasp Variation",
    pgn: "1. e4 e5 2. Nf3 d5 3. Nxe5 dxe4 4. Bc4 Qg5",
    Length: 8,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Damiano Defense, Damiano Gambit, Chigorin Gambit",
    pgn: "1. e4 e5 2. Nf3 f6 3. Nxe5 Qe7 4. Nf3 d5",
    Length: 8,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Corkscrew Countergambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Nf6",
    Length: 8,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Polerio-Svedenborg Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 d5",
    Length: 8,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Albin-Blackburne Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Nd7",
    Length: 8,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Boden Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Qxd4 Bd7",
    Length: 8,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Nf6",
    Length: 8,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Sharp Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 Nb6",
    Length: 8,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Larsen Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 g6",
    Length: 8,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7",
    Length: 8,
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4",
    Length: 8,
  },
  {
    eco: "C42",
    name: "Russian Game: Damiano Variation, Kholmov Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4 4. Qe2 Qe7",
    Length: 8,
  },
  {
    eco: "C42",
    name: "Russian Game: Stafford Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6 4. Nxc6 dxc6",
    Length: 8,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Murrey Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3 Nc6",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Caro Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 Bd7",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Leonhardt Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 Nf6",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 f6",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Scotch Game: Benima Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d4 exd4",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Scotch Game: Haxo Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Dubois Réti Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Göring Gambit Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 d5",
    Length: 8,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, London Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bb4+",
    Length: 8,
  },
  {
    eco: "C45",
    name: "Scotch Game: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5",
    Length: 8,
  },
  {
    eco: "C45",
    name: "Scotch Game: Malaniuk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bb4+",
    Length: 8,
  },
  {
    eco: "C45",
    name: "Scotch Game: Schmidt Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6",
    Length: 8,
  },
  {
    eco: "C45",
    name: "Scotch Game: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4",
    Length: 8,
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Schlechter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4 4. Nd5 Nf6",
    Length: 8,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4",
    Length: 8,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bc5",
    Length: 8,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4",
    Length: 8,
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Double Spanish",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4",
    Length: 8,
  },
  {
    eco: "C50",
    name: "Four Knights Game: Italian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Nc3 Nf6",
    Length: 8,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Lucchini Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 f5",
    Length: 8,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Normal",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5",
    Length: 8,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4",
    Length: 8,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6",
    Length: 8,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Fontaine Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 b5",
    Length: 8,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Hein Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 d5",
    Length: 8,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6",
    Length: 8,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Alexandre Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 f5",
    Length: 8,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Closed Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7",
    Length: 8,
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Invitation Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 d6",
    Length: 8,
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 Nxe4",
    Length: 8,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Knight Attack, Normal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5",
    Length: 8,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Ponziani-Steinitz Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Nxe4",
    Length: 8,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Counterattack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5",
    Length: 8,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Cozio Defense, Paulsen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. Nc3 g6",
    Length: 8,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Fianchetto Defense, Kevitz Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. c3 f5",
    Length: 8,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Defense, Boden Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Qe7",
    Length: 8,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Charousek Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Bb6",
    Length: 8,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Cordel Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 f5",
    Length: 8,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Konikowski Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 d5",
    Length: 8,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Beverwijk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5",
    Length: 8,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Fishing Pole Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Ng4",
    Length: 8,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Mortimer Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Ne7",
    Length: 8,
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Berlin Defense, Improved Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6",
    Length: 8,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Rio Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4",
    Length: 8,
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation, Lutikov Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 bxc6",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Bird's Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nd4",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Alapin's Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bb4",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Caro Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Classical Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bc5",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Cozio Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nge7",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Fianchetto Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 g6",
    Length: 8,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Schliemann Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 f5",
    Length: 8,
  },
  {
    eco: "C71",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Brombacher Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 c5",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Elbert Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e5",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Gedult Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 a6",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Lamb Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 Nc6",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Langeheinicke Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e3",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: O'Kelly Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 c6",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Vienna Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 Bf5",
    Length: 8,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Weinsbach Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e6",
    Length: 8,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Two Knights System, Grünfeld Defense",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Nbd7 4. Nf3 g6",
    Length: 8,
  },
  {
    eco: "D02",
    name: "Queen's Gambit Declined: Baltic Defense, Pseudo-Slav",
    pgn: "1. d4 d5 2. Nf3 Bf5 3. c4 e6 4. Nc3 c6",
    Length: 8,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Levitsky Attack, Euwe Variation, Modern Line",
    pgn: "1. d4 d5 2. Nf3 c6 3. Bg5 h6 4. Bh4 Qb6",
    Length: 8,
  },
  {
    eco: "D04",
    name: "Queen's Pawn Game: Colle System, Grünfeld Formation",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 g6 4. Bd3 Bg7",
    Length: 8,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Pseudo-Chigorin",
    pgn: "1. d4 d5 2. c4 Bf5 3. Nc3 e6 4. Nf3 Nc6",
    Length: 8,
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Modern Gambit",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. Nf3 Nf6",
    Length: 8,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Tartakower Defense",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 c5",
    Length: 8,
  },
  {
    eco: "D11",
    name: "Slav Defense: Quiet Variation, Pin Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bg4",
    Length: 8,
  },
  {
    eco: "D12",
    name: "Slav Defense: Quiet Variation, Schallopp Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5",
    Length: 8,
  },
  {
    eco: "D13",
    name: "Slav Defense: Exchange Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. cxd5 cxd5",
    Length: 8,
  },
  {
    eco: "D15",
    name: "Slav Defense: Chebanenko Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 a6",
    Length: 8,
  },
  {
    eco: "D15",
    name: "Slav Defense: Schlechter Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 g6",
    Length: 8,
  },
  {
    eco: "D15",
    name: "Slav Defense: Süchting Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 Qb6",
    Length: 8,
  },
  {
    eco: "D15",
    name: "Slav Defense: Two Knights Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4",
    Length: 8,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Rubinstein Defense, Yefimov Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 c5 4. d5 b5",
    Length: 8,
  },
  {
    eco: "D22",
    name: "Queen's Gambit Accepted: Alekhine Defense, Haberditz Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e3 b5",
    Length: 8,
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Janowski-Larsen Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 Bg4",
    Length: 8,
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Smyslov Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 g6",
    Length: 8,
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Winawer Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 Be6",
    Length: 8,
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Normal Variation, Traditional System",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6",
    Length: 8,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Capablanca Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5 h6",
    Length: 8,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5 Bb4+",
    Length: 8,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4",
    Length: 8,
  },
  {
    eco: "D32",
    name: "Queen's Gambit Declined: Tarrasch Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5",
    Length: 8,
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Schara Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 cxd4",
    Length: 8,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Barmen Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7",
    Length: 8,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Three Knights, Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 dxc4",
    Length: 8,
  },
  {
    eco: "D38",
    name: "Queen's Gambit Declined: Ragozin Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4",
    Length: 8,
  },
  {
    eco: "D40",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5",
    Length: 8,
  },
  {
    eco: "D43",
    name: "Semi-Slav Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6",
    Length: 8,
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Been-Koomen Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5",
    Length: 8,
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Modern Variation, Knight Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7",
    Length: 8,
  },
  {
    eco: "D53",
    name: "Queen's Gambit Declined",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7",
    Length: 8,
  },
  {
    eco: "D85",
    name: "Grünfeld Defense: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5",
    Length: 8,
  },
  {
    eco: "D90",
    name: "Grünfeld Defense: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7",
    Length: 8,
  },
  {
    eco: "E02",
    name: "Catalan Opening: Open Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4",
    Length: 8,
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5",
    Length: 8,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Bxd2+",
    Length: 8,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Haiti Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Nc6",
    Length: 8,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Nimzowitsch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Qe7",
    Length: 8,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Retreat Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Be7",
    Length: 8,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Vitolins Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 c5",
    Length: 8,
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Wade-Smyslov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 a5",
    Length: 8,
  },
  {
    eco: "E12",
    name: "Nimzo-Indian Defense: Three Knights Variation, Duchamp Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4",
    Length: 8,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6",
    Length: 8,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Traditional Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7",
    Length: 8,
  },
  {
    eco: "E33",
    name: "Nimzo-Indian Defense: Classical Variation, Zurich Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6",
    Length: 8,
  },
  {
    eco: "E34",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5",
    Length: 8,
  },
  {
    eco: "E38",
    name: "Nimzo-Indian Defense: Classical Variation, Berlin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5",
    Length: 8,
  },
  {
    eco: "E40",
    name: "Nimzo-Indian Defense: Normal Variation, Taimanov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 Nc6",
    Length: 8,
  },
  {
    eco: "E41",
    name: "Nimzo-Indian Defense: Hübner Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5",
    Length: 8,
  },
  {
    eco: "E43",
    name: "Nimzo-Indian Defense: St. Petersburg Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6",
    Length: 8,
  },
  {
    eco: "E46",
    name: "Nimzo-Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O",
    Length: 8,
  },
  {
    eco: "E60",
    name: "Grünfeld Defense: Counterthrust Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 d5",
    Length: 8,
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6",
    Length: 8,
  },
];

export const eco_9 = [
  {
    eco: "A00",
    name: "Hungarian Opening: Burk Gambit",
    pgn: "1. g3 e5 2. a3 d5 3. Nf3 e4 4. Nh4 Be7 5. d3",
    Length: 9,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Paris Gambit",
    pgn: "1. g3 e5 2. Nh3 d5 3. f4 Bxh3 4. Bxh3 exf4 5. O-O",
    Length: 9,
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Lipke Variation",
    pgn: "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 Nh6 5. d4",
    Length: 9,
  },
  {
    eco: "A03",
    name: "Bird Opening: Thomas Gambit",
    pgn: "1. f4 d5 2. b3 Nf6 3. Bb2 d4 4. Nf3 c5 5. e3",
    Length: 9,
  },
  {
    eco: "A08",
    name: "Réti-Zukertort Opening: Reversed Grünfeld",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6 4. d4 e6 5. O-O",
    Length: 9,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense",
    pgn: "1. Nf3 d5 2. c4 e6 3. g3 b6 4. Bg2 Bb7 5. O-O",
    Length: 9,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Wimpy System",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. b3 d5 4. Bb2 c5 5. e3",
    Length: 9,
  },
  {
    eco: "A14",
    name: "English Opening: Agincourt Defense, Neo-Catalan Declined",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. O-O",
    Length: 9,
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. Nf3 b6 4. e4 Bb7 5. Bd3",
    Length: 9,
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Closed System",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3",
    Length: 9,
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Hungarian Attack",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Rb1",
    Length: 9,
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Botvinnik System",
    pgn: "1. e4 c5 2. c4 Nc6 3. Nc3 g6 4. g3 Bg7 5. Bg2",
    Length: 9,
  },
  {
    eco: "A37",
    name: "English Opening: Symmetrical Variation, Three Knights, Fianchetto Variation",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Nf3",
    Length: 9,
  },
  {
    eco: "A40",
    name: "English Defense: Hartlaub Gambit Declined",
    pgn: "1. c4 e6 2. d4 b6 3. Nc3 Bb7 4. e4 f5 5. d5",
    Length: 9,
  },
  {
    eco: "A42",
    name: "Modern Defense: Averbakh Variation, Pseudo-Sämisch",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Be3 Nf6 5. f3",
    Length: 9,
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit, Stummer Gambit",
    pgn: "1. d4 Nf6 2. g4 Nxg4 3. e4 d6 4. Be2 Nf6 5. Nc3",
    Length: 9,
  },
  {
    eco: "A46",
    name: "Torre Attack: Classical Defense, Petrosian Gambit",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 c5 4. e3 b6 5. d5",
    Length: 9,
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Dus-Khotimirsky Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 e5 4. e3 Nbd7 5. Bd3",
    Length: 9,
  },
  {
    eco: "A55",
    name: "Old Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Nbd7 4. e4 e5 5. Nf3",
    Length: 9,
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted: Dlugy Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. f3",
    Length: 9,
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted: Modern Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. e3",
    Length: 9,
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted: Pawn Return Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. b6",
    Length: 9,
  },
  {
    eco: "A57",
    name: "Benko Gambit: Zaitsev System",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. Nc3",
    Length: 9,
  },
  {
    eco: "A58",
    name: "Benko Gambit Accepted: Fully Accepted Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6",
    Length: 9,
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Alekhine Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 g6 5. h4",
    Length: 9,
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Lasker Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 g6 5. f3",
    Length: 9,
  },
  {
    eco: "A87",
    name: "Dutch Defense: Leningrad Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 g6 4. Bg2 Bg7 5. Nf3",
    Length: 9,
  },
  {
    eco: "A91",
    name: "Dutch Defense: Classical Variation, Blackburne Attack",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nh3",
    Length: 9,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Richter Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5 Nb8 5. f3",
    Length: 9,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Anderssen Counterattack, Goteborg System",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Nf3",
    Length: 9,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Banker Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. c4",
    Length: 9,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Correspondence Refutation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. g4",
    Length: 9,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Lusophobe Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Bb5+ Nbd7 5. Be2",
    Length: 9,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Kmoch Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. Bc4 Nb6 4. Bb3 c5 5. d3",
    Length: 9,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Exchange Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. exd6",
    Length: 9,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4",
    Length: 9,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Hunt Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. c5",
    Length: 9,
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Alekhine Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. c4",
    Length: 9,
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Panov Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. h3",
    Length: 9,
  },
  {
    eco: "B06",
    name: "Modern Defense: Anti-Modern",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Bc4 d6 5. Qe2",
    Length: 9,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Sicilian, Siroccopteryx",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 Qa5 5. Bc4",
    Length: 9,
  },
  {
    eco: "B07",
    name: "Pirc Defense: 150 Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. Qd2",
    Length: 9,
  },
  {
    eco: "B07",
    name: "Pirc Defense: 150 Attack, Sveshnikov-Jansa Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. h3",
    Length: 9,
  },
  {
    eco: "B07",
    name: "Pirc Defense: Bayonet Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. h4",
    Length: 9,
  },
  {
    eco: "B07",
    name: "Pirc Defense: Chinese Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. g4",
    Length: 9,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2",
    Length: 9,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Schlechter Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. h3",
    Length: 9,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Ljubojevic Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Bc4",
    Length: 9,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance, Short Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. c3 e6 5. Be2",
    Length: 9,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Mieses Attack, Landau Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Bd3 Nf6 4. e5 Nfd7 5. e6",
    Length: 9,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3",
    Length: 9,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Gunderam Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. c5",
    Length: 9,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Alekhine Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Bd3",
    Length: 9,
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Modern Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5",
    Length: 9,
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Marienbad Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. a3 d5 4. exd5 Qxd5 5. Bb2",
    Length: 9,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Coles Sicilian Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Bc4",
    Length: 9,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Heidenfeld Variation",
    pgn: "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Na3",
    Length: 9,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Anhanguera",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. Be3",
    Length: 9,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Benoni Gambit",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. d5",
    Length: 9,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Rhamphorhynchus",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3",
    Length: 9,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Unpin",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. Bd2",
    Length: 9,
  },
  {
    eco: "B34",
    name: "Sicilian Defense: Accelerated Dragon, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nxc6",
    Length: 9,
  },
  {
    eco: "B34",
    name: "Sicilian Defense: Accelerated Dragon, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3",
    Length: 9,
  },
  {
    eco: "B36",
    name: "Sicilian Defense: Accelerated Dragon, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4",
    Length: 9,
  },
  {
    eco: "B41",
    name: "Sicilian Defense: Kan Variation, Maróczy Bind, Réti Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4",
    Length: 9,
  },
  {
    eco: "B42",
    name: "Sicilian Defense: Kan Variation, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3",
    Length: 9,
  },
  {
    eco: "B43",
    name: "Sicilian Defense: Kan Variation, Knight Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3",
    Length: 9,
  },
  {
    eco: "B44",
    name: "Sicilian Defense: Taimanov Variation, Szén Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5",
    Length: 9,
  },
  {
    eco: "B45",
    name: "Sicilian Defense: Taimanov Variation, Normal Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3",
    Length: 9,
  },
  {
    eco: "B52",
    name: "Sicilian Defense: Moscow Variation, Sokolsky Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7 4. Bxd7+ Qxd7 5. c4",
    Length: 9,
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Modern Variations, Ginsberg Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Bc4",
    Length: 9,
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Prins Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3",
    Length: 9,
  },
  {
    eco: "C00",
    name: "French Defense: Reversed Philidor Formation",
    pgn: "1. e4 e6 2. d3 d5 3. Nd2 Nf6 4. Ngf3 Nc6 5. Be2",
    Length: 9,
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation, Svenonius Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. exd5 exd5 5. Bg5",
    Length: 9,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Nimzowitsch Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4 cxd4 5. Nf3",
    Length: 9,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Paulsen Attack",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3",
    Length: 9,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Ruisdonk Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 cxd4 5. Bd3",
    Length: 9,
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Pawn Center Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. f4",
    Length: 9,
  },
  {
    eco: "C11",
    name: "French Defense: Steinitz Variation, Gledhill Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. Qg4",
    Length: 9,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5",
    Length: 9,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. exd5",
    Length: 9,
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Richter Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6",
    Length: 9,
  },
  {
    eco: "C15",
    name: "French Defense: MacCutcheon Variation, Wolf Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 Nf6 5. Bg5",
    Length: 9,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Fingerslip Variation, Schwarz's Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 Ne7 5. Nb1",
    Length: 9,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3",
    Length: 9,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Advance Variation, Moscow Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Qg4",
    Length: 9,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Bogoljubov Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2",
    Length: 9,
  },
  {
    eco: "C21",
    name: "Center Game: Halasz-McDonnell Gambit, Crocodile Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. f4 Bc5 4. Nf3 Nc6 5. c3",
    Length: 9,
  },
  {
    eco: "C21",
    name: "Center Game: Lanc-Arnold Gambit, Schippler Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 Bc5 4. c3 dxc3 5. Bc4",
    Length: 9,
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2",
    Length: 9,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Countergambit, Walker Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 d5 4. Bxd5 Nf6 5. d4",
    Length: 9,
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lopez Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. Qe2 Nc6 4. c3 Nf6 5. f4",
    Length: 9,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Krejcik Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. f3 Bc5 4. Ne2 Nc6 5. b4",
    Length: 9,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Pierce Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. d4",
    Length: 9,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Main Line",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2",
    Length: 9,
  },
  {
    eco: "C25",
    name: "Vienna Game: Stanley Variation, Meitner-Mieses Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Bc5 4. Qg4 Qf6 5. Nd5",
    Length: 9,
  },
  {
    eco: "C28",
    name: "Bishop's Opening: Vienna Hybrid, Hromádka Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. d3 Bb4 5. Ne2",
    Length: 9,
  },
  {
    eco: "C28",
    name: "Vienna Game: Stanley Variation, Bronstein Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. f4 Nxe4 5. Nf3",
    Length: 9,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Modern Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. d3",
    Length: 9,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Paulsen Attack",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3",
    Length: 9,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Norwalde Variation, Bücker Gambit",
    pgn: "1. e4 e5 2. f4 Qf6 3. Nc3 Qxf4 4. Nf3 Bb4 5. Bc4",
    Length: 9,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Rubinstein Variation",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. Nc3 Nf6 5. Qe2",
    Length: 9,
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit Accepted",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4",
    Length: 9,
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Keres Variation",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. Nd2",
    Length: 9,
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Old Line",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. Qe2",
    Length: 9,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Classical Defense, Cozio Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Qf3",
    Length: 9,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Paulsen Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3 Bb4 5. e5",
    Length: 9,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Fischer Defense, Spanish Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. d4 Nf6 5. Bd3",
    Length: 9,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Schallopp Defense, Tashkent Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nf6 4. e5 Nh5 5. g4",
    Length: 9,
  },
  {
    eco: "C35",
    name: "King's Gambit Accepted: Cunningham Defense, Bertin Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Bh4+ 5. g3",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Australian Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. h4",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Ghulam-Kassim Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Lolli Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Bxf7+",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: McDonnell Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Nc3",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Wild Muzio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit, Sörensen Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Nc3",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit, Testa Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Bxf4",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5",
    Length: 9,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Sörensen Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Ne5",
    Length: 9,
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Hanstein Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. O-O",
    Length: 9,
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Philidor Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. h4",
    Length: 9,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Allgaier Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5",
    Length: 9,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5",
    Length: 9,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Bilguer Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4",
    Length: 9,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Foltys Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4 fxe4 5. d3",
    Length: 9,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Leonhardt Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4 fxe4 5. Nc3",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Delmar Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. c3",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Krause Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. O-O",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Schlechter Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Nc3",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham, Kmoch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Ng5",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Shirov Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Nc3 Ngf6 5. g4",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation, Rellstab Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5 Nxe4 5. Qd5",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation, Sokolsky Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5 Nxe4 5. Nbd2",
    Length: 9,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Paulsen Attack",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 d5 5. exd5",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Cochrane Gambit, Bishop Check Line",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7 Kxf7 5. Bc4+",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Cochrane Gambit, Center Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7 Kxf7 5. d4",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Cozio Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Qe2",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: French Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d3",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Kaufmann Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. c4",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Millennium Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Bd3",
    Length: 9,
  },
  {
    eco: "C42",
    name: "Russian Game: Nimzowitsch Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Nc3",
    Length: 9,
  },
  {
    eco: "C43",
    name: "Bishop's Opening: Urusov Gambit, Keidansky Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 Nxe4 5. Qxd4",
    Length: 9,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Center Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qxd4",
    Length: 9,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qe2",
    Length: 9,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Tal Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Bb5",
    Length: 9,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening, Basman Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d4 exd4 5. e5",
    Length: 9,
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening, Inverted Hanham",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d3 d5 5. Nbd2",
    Length: 9,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Ponziani Countergambit, Schmidt Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 f5 4. d4 d6 5. d5",
    Length: 9,
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit, Double Pawn Sacrifice",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Bc4",
    Length: 9,
  },
  {
    eco: "C44",
    name: "Scotch Game: Napoleon Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 exd4 5. Bc4",
    Length: 9,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Advance Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. e5",
    Length: 9,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Sarratt Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Alekhine Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. e5",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Braune Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Be3",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Fraser Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nf3",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Horwitz Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Paulsen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nf5",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Potter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nb3",
    Length: 9,
  },
  {
    eco: "C45",
    name: "Scotch Game: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3",
    Length: 9,
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Steinitz-Rosenthal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6 4. d4 exd4 5. Nd5",
    Length: 9,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Italian Variation, Noa Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Nxe4 5. Bxf7+",
    Length: 9,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Belgrade Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nd5",
    Length: 9,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Krause Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Nxe5",
    Length: 9,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Ranken Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 a6 5. Bxc6",
    Length: 9,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Be2",
    Length: 9,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Nxd4",
    Length: 9,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation, Henneberger Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. O-O",
    Length: 9,
  },
  {
    eco: "C50",
    name: "Italian Game: Classical Variation, Albin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. c3",
    Length: 9,
  },
  {
    eco: "C50",
    name: "Italian Game: Deutz Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4",
    Length: 9,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Italian Four Knights Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Nc3",
    Length: 9,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. a4",
    Length: 9,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Cordel Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. Bb2",
    Length: 9,
  },
  {
    eco: "C53",
    name: "Italian Game: Bird's Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. b4",
    Length: 9,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Center Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4",
    Length: 9,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Giuoco Pianissimo",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3",
    Length: 9,
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O",
    Length: 9,
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Perreux Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. Ng5",
    Length: 9,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Counterattack, Bishop Sacrifice Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Bxf7+",
    Length: 9,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Counterattack, Knight Sacrifice Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Nxf7",
    Length: 9,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Spanish Countergambit, Harding Countergambit, Fricke Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. Nxe5 Qg5 5. O-O",
    Length: 9,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Spanish Countergambit, Harding Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. Nxe5 Qg5 5. Nxc6",
    Length: 9,
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Old Steinitz Defense, Semi-Duras Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. c4",
    Length: 9,
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Steinitz Defense, Center Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 exd4 5. O-O",
    Length: 9,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Defense, Zaitsev Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. O-O Nd4 5. b4",
    Length: 9,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 d6 5. Bxc6+",
    Length: 9,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Duras Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 d6 5. c4",
    Length: 9,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Kaufmann Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Bc5 5. Be3",
    Length: 9,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Nyholm Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d4 exd4 5. O-O",
    Length: 9,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Classical Variation, Zukertort Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3",
    Length: 9,
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation, Keres Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nc3",
    Length: 9,
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Normal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O",
    Length: 9,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Schliemann Defense Deferred, Jaenisch Gambit Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 f5 5. exf5",
    Length: 9,
  },
  {
    eco: "C71",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Nc3",
    Length: 9,
  },
  {
    eco: "C71",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c4",
    Length: 9,
  },
  {
    eco: "C72",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. O-O",
    Length: 9,
  },
  {
    eco: "C74",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3",
    Length: 9,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3",
    Length: 9,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Bayreuth Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Bxc6",
    Length: 9,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Jaffe Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. c3",
    Length: 9,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Mackenzie Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d4",
    Length: 9,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Tarrasch Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Nc3",
    Length: 9,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Wormald Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Qe2",
    Length: 9,
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O",
    Length: 9,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Ryder Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Qxf3",
    Length: 9,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Zeller Defense, Soller Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Bf5 4. f3 Nf6 5. Bc4",
    Length: 9,
  },
  {
    eco: "D02",
    name: "London System: Poisoned Pawn Variation",
    pgn: "1. d4 Nf6 2. Nf3 d5 3. Bf4 c5 4. e3 Qb6 5. Nc3",
    Length: 9,
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Colle System, Traditional Colle",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. c3",
    Length: 9,
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Zukertort Variation",
    pgn: "1. d4 d5 2. Nf3 c5 3. e3 Nf6 4. Nbd2 e6 5. b3",
    Length: 9,
  },
  {
    eco: "D05",
    name: "Rubinstein Opening",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. b3",
    Length: 9,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Modern Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2",
    Length: 9,
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3",
    Length: 9,
  },
  {
    eco: "D10",
    name: "Slav Defense: Exchange Variation, Boor Attack",
    pgn: "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 Nf6 5. f3",
    Length: 9,
  },
  {
    eco: "D15",
    name: "Slav Defense: Alekhine Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e3",
    Length: 9,
  },
  {
    eco: "D15",
    name: "Slav Defense: Chebanenko Variation, Advance System",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 a6 5. c5",
    Length: 9,
  },
  {
    eco: "D15",
    name: "Slav Defense: Geller Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e4",
    Length: 9,
  },
  {
    eco: "D16",
    name: "Slav Defense: Alapin Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4",
    Length: 9,
  },
  {
    eco: "D24",
    name: "Queen's Gambit Accepted: Bogoljubov Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3 a6 5. e4",
    Length: 9,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Tarrasch Defense, Pseudo-Tarrasch Bishop Attack",
    pgn: "1. d4 d5 2. c4 e6 3. Nf3 c5 4. cxd5 exd5 5. Bg5",
    Length: 9,
  },
  {
    eco: "D30",
    name: "Semi-Slav Defense: Quiet Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2",
    Length: 9,
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Charousek Variation, Miladinovic Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. e4 dxe4 5. f3",
    Length: 9,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Anti-Noteboom, Stonewall Variation, Portisch Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e3 f5 5. g4",
    Length: 9,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Gunderam Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. f3",
    Length: 9,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. g3",
    Length: 9,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. Bg5",
    Length: 9,
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Marshall Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. e4",
    Length: 9,
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Two Knights Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3",
    Length: 9,
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation, Positional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5",
    Length: 9,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4",
    Length: 9,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Vienna Variation, Quiet Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 dxc4 5. e3",
    Length: 9,
  },
  {
    eco: "D38",
    name: "Queen's Gambit Declined: Ragozin Defense, Alekhine Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Qa4+",
    Length: 9,
  },
  {
    eco: "D40",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense, Pillsbury Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. Bg5",
    Length: 9,
  },
  {
    eco: "D41",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5",
    Length: 9,
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Main Line",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3",
    Length: 9,
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Pseudo-Tarrasch Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. cxd5",
    Length: 9,
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Modern Variation, Knight Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3",
    Length: 9,
  },
  {
    eco: "D53",
    name: "Queen's Gambit Declined: Modern Variation, Heral Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6",
    Length: 9,
  },
  {
    eco: "D85",
    name: "Grünfeld Defense: Exchange Variation, Nadanian Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. Na4",
    Length: 9,
  },
  {
    eco: "D90",
    name: "Grünfeld Defense: Flohr Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qa4+",
    Length: 9,
  },
  {
    eco: "D91",
    name: "Grünfeld Defense: Three Knights Variation, Petrosian System",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bg5",
    Length: 9,
  },
  {
    eco: "D92",
    name: "Grünfeld Defense: Three Knights Variation, Hungarian Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bf4",
    Length: 9,
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Three Knights Variation, Burille Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3",
    Length: 9,
  },
  {
    eco: "D96",
    name: "Grünfeld Defense: Russian Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3",
    Length: 9,
  },
  {
    eco: "E04",
    name: "Catalan Opening: Open Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3",
    Length: 9,
  },
  {
    eco: "E06",
    name: "Catalan Opening: Closed",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3",
    Length: 9,
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit: Dus-Khotimirsky Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5 5. Bg5",
    Length: 9,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3",
    Length: 9,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. Qa4",
    Length: 9,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Quiet Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3",
    Length: 9,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Timman's Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. Qb3",
    Length: 9,
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation, Korchnoi Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. d5",
    Length: 9,
  },
  {
    eco: "E24",
    name: "Nimzo-Indian Defense: Sämisch Variation, Accelerated",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3",
    Length: 9,
  },
  {
    eco: "E36",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3",
    Length: 9,
  },
  {
    eco: "E42",
    name: "Nimzo-Indian Defense: Hübner Variation, Rubinstein Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Ne2",
    Length: 9,
  },
  {
    eco: "E44",
    name: "Nimzo-Indian Defense: Fischer Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6 5. Ne2",
    Length: 9,
  },
  {
    eco: "E46",
    name: "Nimzo-Indian Defense: Reshevsky Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Ne2",
    Length: 9,
  },
  {
    eco: "E47",
    name: "Nimzo-Indian Defense: Normal Variation, Bishop Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3",
    Length: 9,
  },
  {
    eco: "E50",
    name: "Nimzo-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3",
    Length: 9,
  },
  {
    eco: "E61",
    name: "King's Indian Defense: Smyslov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. Bg5",
    Length: 9,
  },
  {
    eco: "E62",
    name: "King's Indian Defense: Fianchetto Variation, Delayed Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. g3",
    Length: 9,
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Accelerated Averbakh Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Bg5",
    Length: 9,
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Kramer Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nge2",
    Length: 9,
  },
  {
    eco: "E71",
    name: "King's Indian Defense: Makogonov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. h3",
    Length: 9,
  },
  {
    eco: "E72",
    name: "King's Indian Defense: Normal Variation, Deferred Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. g3",
    Length: 9,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Normal Variation, Standard Development",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2",
    Length: 9,
  },
  {
    eco: "E76",
    name: "King's Indian Defense: Four Pawns Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4",
    Length: 9,
  },
  {
    eco: "E80",
    name: "King's Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3",
    Length: 9,
  },
  {
    eco: "E90",
    name: "King's Indian Defense: Normal Variation, Rare Defenses",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3",
    Length: 9,
  },
];

export const eco_10 = [
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Fritz Gambit, Romford Countergambit",
    pgn: "1. g4 d5 2. Bg2 Bxg4 3. c4 d4 4. Bxb7 Nd7 5. Bxa8 Qxa8",
    Length: 10,
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Asten Gambit",
    pgn: "1. g3 Nc6 2. Nc3 d5 3. d4 e5 4. dxe5 d4 5. Ne4 f5",
    Length: 10,
  },
  {
    eco: "A04",
    name: "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation Accepted",
    pgn: "1. Nf3 g6 2. d4 Bg7 3. e4 d6 4. c4 c5 5. dxc5 Qa5+",
    Length: 10,
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Wahls Defense",
    pgn: "1. Nf3 Nf6 2. g3 g6 3. Bg2 Bg7 4. O-O O-O 5. d3 d5",
    Length: 10,
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Pachman System",
    pgn: "1. Nf3 d5 2. g3 g6 3. Bg2 Bg7 4. O-O e5 5. d3 Ne7",
    Length: 10,
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation",
    pgn: "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. g3 g6 5. Bg2 Nb6",
    Length: 10,
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation",
    pgn: "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. g3 g6 5. Bg2 Nxc3",
    Length: 10,
  },
  {
    eco: "A25",
    name: "English Opening: Closed, Taimanov Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Rb1 Nh6 5. Bg2 Bg7",
    Length: 10,
  },
  {
    eco: "A26",
    name: "English Opening: King's English Variation, Closed System, Full Symmetry",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6",
    Length: 10,
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Quiet Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3 Bb4 5. Qc2 Bxc3",
    Length: 10,
  },
  {
    eco: "A33",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 e6",
    Length: 10,
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Rubinstein Variation",
    pgn: "1. c4 Nf6 2. Nc3 c5 3. g3 d5 4. cxd5 Nxd5 5. Bg2 Nc7",
    Length: 10,
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Botvinnik System Reversed, with e3",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 e5",
    Length: 10,
  },
  {
    eco: "A37",
    name: "English Opening: Symmetrical Variation, Botvinnik System Reversed, with Nf3",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Nf3 e5",
    Length: 10,
  },
  {
    eco: "A38",
    name: "English Opening: Symmetrical Variation, Full Symmetry Line",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. g3 g6 5. Bg2 Bg7",
    Length: 10,
  },
  {
    eco: "A40",
    name: "Colle System: Siroccopteryx Variation",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 cxd4 5. Nxd4 Qa5+",
    Length: 10,
  },
  {
    eco: "A40",
    name: "English Defense: Hartlaub Gambit Accepted",
    pgn: "1. c4 e6 2. d4 b6 3. Nc3 Bb7 4. e4 f5 5. exf5 Nf6",
    Length: 10,
  },
  {
    eco: "A40",
    name: "English Defense: Poli Gambit",
    pgn: "1. d4 e6 2. c4 b6 3. e4 Bb7 4. f3 f5 5. exf5 Nh6",
    Length: 10,
  },
  {
    eco: "A40",
    name: "Modern Defense: Beefeater Variation",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 f5",
    Length: 10,
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Fianchetto, Queen Pteranodon",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 Qa5",
    Length: 10,
  },
  {
    eco: "A42",
    name: "Pterodactyl Defense",
    pgn: "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qa5+ 5. Nc3 d6",
    Length: 10,
  },
  {
    eco: "A42",
    name: "Pterodactyl Defense: Central, Bogolubovia",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Nc3 c5 5. Nge2 Qa5",
    Length: 10,
  },
  {
    eco: "A46",
    name: "Queen's Pawn Game: Torre Attack, Breyer Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. e3 c5 5. c3 Qb6",
    Length: 10,
  },
  {
    eco: "A48",
    name: "Queen's Pawn Game: Torre Attack, Grünfeld Variation, Main Line",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. Nbd2 d5 5. e3 O-O",
    Length: 10,
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Alekhine Variation, Abonyi Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4 Nxe5 5. f4 Nec6",
    Length: 10,
  },
  {
    eco: "A56",
    name: "Benoni Defense: King's Indian System",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e5 4. Nc3 d6 5. e4 g6",
    Length: 10,
  },
  {
    eco: "A60",
    name: "Benoni Defense: Modern Variation, Snake Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 Bd6",
    Length: 10,
  },
  {
    eco: "A81",
    name: "Dutch Defense: Leningrad, Basman System",
    pgn: "1. d4 f5 2. g3 c6 3. Bg2 g6 4. Nf3 Bg7 5. O-O Nh6",
    Length: 10,
  },
  {
    eco: "A86",
    name: "Dutch Defense: Hort-Antoshin System",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 d6 4. Bg2 c6 5. Nc3 Qc7",
    Length: 10,
  },
  {
    eco: "A90",
    name: "Dutch Defense: Nimzo-Dutch Variation, Alekhine Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Bb4+ 5. Bd2 Be7",
    Length: 10,
  },
  {
    eco: "A92",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O",
    Length: 10,
  },
  {
    eco: "B00",
    name: "Owen Defense: Matovinsky Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. Bd3 f5 4. exf5 Bxg2 5. Qh5+ g6",
    Length: 10,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Anderssen Counterattack, Collijn Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Nf3 Bg4",
    Length: 10,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Bronstein Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 Nf6 5. Nf3 a6",
    Length: 10,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Classical Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bf5",
    Length: 10,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Valencian Variation, Ilundain Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 c6",
    Length: 10,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Valencian Variation, Main Line",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 Bg4",
    Length: 10,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack, Cambridge Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 g5",
    Length: 10,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack, Fianchetto Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 g6",
    Length: 10,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack, Trifunovic Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 Bf5",
    Length: 10,
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Flohr Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. Be2 c6",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Modern Defense: Dunworthy Variation",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 d5 4. exd5 c6 5. dxc6 Bxd4",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Modern Defense: Gurgenidze Defense",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. f4 d5 5. e5 h5",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Modern Defense: Masur Gambit",
    pgn: "1. e4 g6 2. d4 Nh6 3. Nc3 f5 4. Bxh6 Bxh6 5. exf5 O-O",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation Declined",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nf3 d6 5. Be2 Qa5+",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Central, Anhanguera",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nc3 d6 5. Be3 Qa5",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Central, Benoni Quetzalcoatlus",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. d5 d6 5. Nc3 Qa5",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Central, Quetzalcoatlus Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nc3 d6 5. dxc5 Qa5",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Benoni Pteranodon",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 Qa5",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Pteranodon",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5 Bxc3+ 5. bxc3 Qa5",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Sicilian, Quetzalcoatlus",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 Qa5 5. Be2 d6",
    Length: 10,
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Western, Siroccopteryx",
    pgn: "1. e4 g6 2. Nf3 Bg7 3. d4 c5 4. Bc4 cxd4 5. Nxd4 Qa5+",
    Length: 10,
  },
  {
    eco: "B07",
    name: "Pirc Defense: 150 Attack, Inner Doll Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. Qd2 Bg4",
    Length: 10,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O",
    Length: 10,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Dragon Formation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 c5",
    Length: 10,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6",
    Length: 10,
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 e6",
    Length: 10,
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack, Fianchetto Defense",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 g6",
    Length: 10,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Tartakower Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6",
    Length: 10,
  },
  {
    eco: "B16",
    name: "Caro-Kann Defense: Bronstein-Larsen Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ gxf6",
    Length: 10,
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Modern Variation, Ivanchuk Defense",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5 Ndf6",
    Length: 10,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Fianchetto Defense",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 g6",
    Length: 10,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Kan Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Nf3 a6",
    Length: 10,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Barmen Defense, Modern Line",
    pgn: "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 Nf6 5. Nf3 Bg4",
    Length: 10,
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6",
    Length: 10,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Kalashnikov Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6",
    Length: 10,
  },
  {
    eco: "B32",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Geller Line",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 a6 5. c4 e5",
    Length: 10,
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5",
    Length: 10,
  },
  {
    eco: "B37",
    name: "Sicilian Defense: Accelerated Dragon, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7",
    Length: 10,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Alapin Variation, Sherzer Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. c3 Nf6 4. e5 Nd5 5. d4 Nc6",
    Length: 10,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Four Knights Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6",
    Length: 10,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Gaw-Paw Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Qb6",
    Length: 10,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Pin Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4",
    Length: 10,
  },
  {
    eco: "B41",
    name: "Sicilian Defense: Kan Variation, Maróczy Bind, Hedgehog Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4 g6",
    Length: 10,
  },
  {
    eco: "B42",
    name: "Sicilian Defense: Kan Variation, Polugaevsky Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 Bc5",
    Length: 10,
  },
  {
    eco: "B42",
    name: "Sicilian Defense: Kan Variation, Swiss Cheese Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 g6",
    Length: 10,
  },
  {
    eco: "B43",
    name: "Sicilian Defense: Kan Variation, Wing Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 b5",
    Length: 10,
  },
  {
    eco: "B46",
    name: "Sicilian Defense: Taimanov Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 a6",
    Length: 10,
  },
  {
    eco: "B47",
    name: "Sicilian Defense: Taimanov Variation, Bastrikov Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7",
    Length: 10,
  },
  {
    eco: "B53",
    name: "Sicilian Defense: Chekhover Variation, Zaitsev Defense",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Bb5 Qd7",
    Length: 10,
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6",
    Length: 10,
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Kupreichik Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bd7",
    Length: 10,
  },
  {
    eco: "B70",
    name: "Sicilian Defense: Dragon Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6",
    Length: 10,
  },
  {
    eco: "B80",
    name: "Sicilian Defense: Scheveningen Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6",
    Length: 10,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6",
    Length: 10,
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation, Bogoljubov Variation",
    pgn: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nc3 Nf6 5. Bg5 Nc6",
    Length: 10,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Euwe Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Bd7",
    Length: 10,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Wade Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 Bd7",
    Length: 10,
  },
  {
    eco: "C03",
    name: "French Defense: Guimard Variation, Thunderbunny Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nc6 4. c3 dxe4 5. Nxe4 e5",
    Length: 10,
  },
  {
    eco: "C08",
    name: "French Defense: Tarrasch Variation, Open System, Advance Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 c4",
    Length: 10,
  },
  {
    eco: "C09",
    name: "French Defense: Tarrasch Variation, Open System, Main Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 Nc6",
    Length: 10,
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Fort Knox Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bd7 5. Nf3 Bc6",
    Length: 10,
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Tartakower Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ne4",
    Length: 10,
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Vistaneckis Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ng8",
    Length: 10,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Alekhine Gambit Accepted",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Bxc3+",
    Length: 10,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Fingerslip Variation, Kunin Double Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 dxe4 5. Qg4 Qxd4",
    Length: 10,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Retreat Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Ba5",
    Length: 10,
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Chigorin Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Qe7",
    Length: 10,
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Classical Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Nf6",
    Length: 10,
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Copenhagen Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Bb4+",
    Length: 10,
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Schlechter Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 d5",
    Length: 10,
  },
  {
    eco: "C22",
    name: "Center Game: Charousek Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Bb4+ 5. c3 Be7",
    Length: 10,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Fraser-Minckwitz Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 b6",
    Length: 10,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Paulsen Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 d6",
    Length: 10,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Sörensen Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 g5",
    Length: 10,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Zukertort Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 d5",
    Length: 10,
  },
  {
    eco: "C25",
    name: "Vienna Game: Paulsen Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. g3 Nf6 4. Bg2 Bc5 5. Nge2 d5",
    Length: 10,
  },
  {
    eco: "C25",
    name: "Vienna Game: Paulsen Variation, Mariotti Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. g3 Bc5 4. Bg2 h5 5. Nf3 h4",
    Length: 10,
  },
  {
    eco: "C27",
    name: "Vienna Game: Stanley Variation, Monster Declined",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Be7",
    Length: 10,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Bardeleben Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3 f5",
    Length: 10,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Breyer Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Nf3 Be7",
    Length: 10,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Boren-Svenonius Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 Qh4+ 5. Kf1 Bd6",
    Length: 10,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Brentano Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O d5",
    Length: 10,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Kling and Horwitz Counterattack",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O Qe7",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Allgaier, Schlechter Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 Nf6",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Brentano Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d5",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Kolisch Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d6",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Long Whip",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 h5",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Neumann Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nc6",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Paulsen Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Bg7",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Rosenthal Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Qe7",
    Length: 10,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky, Polerio Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Be7",
    Length: 10,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Poisoned Pawn Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Qg5 5. d4 Qxg2",
    Length: 10,
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack, Closed Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 Nf6",
    Length: 10,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Vukovic Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d4 Nxe4 5. d5 Bc5",
    Length: 10,
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit, Main Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Nxc3 Bb4",
    Length: 10,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Kingside Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. e5 Ng4",
    Length: 10,
  },
  {
    eco: "C45",
    name: "Scotch Game: Classical Variation, Intermezzo Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 Qf6",
    Length: 10,
  },
  {
    eco: "C45",
    name: "Scotch Game: Modern Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3 Bb4",
    Length: 10,
  },
  {
    eco: "C45",
    name: "Scotch Game: Romanishin Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nb3 Bb4+",
    Length: 10,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Krause Gambit, Leonhardt Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Nxe5 Qe7",
    Length: 10,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Oxford Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. d5 Nd4",
    Length: 10,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Schmid Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nxd4 Nxe4",
    Length: 10,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Dubois Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 f5 5. Ng5 f4",
    Length: 10,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Be7",
    Length: 10,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Mayet Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bf8",
    Length: 10,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, McDonnell Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5",
    Length: 10,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Stone-Ware Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bd6",
    Length: 10,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Main Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5",
    Length: 10,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Center Holding Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6",
    Length: 10,
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, Janowski Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O d6",
    Length: 10,
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, Max Lange Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5",
    Length: 10,
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, de Riviere Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Be7",
    Length: 10,
  },
  {
    eco: "C56",
    name: "Italian Game: Scotch Gambit, Double Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4",
    Length: 10,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Fritz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nd4",
    Length: 10,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Kloss Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nb4",
    Length: 10,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Ulvestad Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 b5",
    Length: 10,
  },
  {
    eco: "C58",
    name: "Italian Game: Two Knights Defense, Polerio Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5",
    Length: 10,
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Alapin Defense, Alapin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bb4 4. c3 Ba5 5. Bxc6 dxc6",
    Length: 10,
  },
  {
    eco: "C61",
    name: "Ruy Lopez: Bird Variation, Paulsen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4 4. Nxd4 exd4 5. O-O Ne7",
    Length: 10,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, KostiÄ‡ Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 Be7",
    Length: 10,
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 Nf6",
    Length: 10,
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Mortimer Trap",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Ne7 5. Nxe5 c6",
    Length: 10,
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Closed Berlin Defense, Chigorin Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Nd7",
    Length: 10,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Rio de Janeiro Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7",
    Length: 10,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Rosenthal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 a6",
    Length: 10,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, l'Hermet Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6",
    Length: 10,
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Bronstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Qd6",
    Length: 10,
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Gligoric Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O f6",
    Length: 10,
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, King's Bishop Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bd6",
    Length: 10,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Graz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Bc5",
    Length: 10,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Norwegian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Na5",
    Length: 10,
  },
  {
    eco: "C74",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense, Siesta Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 f5",
    Length: 10,
  },
  {
    eco: "C75",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 Bd7",
    Length: 10,
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Brix Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O g6",
    Length: 10,
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Central Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d5",
    Length: 10,
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense, Neo-Arkhangelsk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Bc5",
    Length: 10,
  },
  {
    eco: "C79",
    name: "Ruy Lopez: Morphy Defense, Steinitz Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6",
    Length: 10,
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4",
    Length: 10,
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Bogoljubow Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 g6",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Euwe Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 e6",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Gunderam Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Bf5",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Holwell Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Qd6",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Kaulich Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 c5",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Pietrowsky Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nc6",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Ritter Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 b6",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Schlutter Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nbd7",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Teichmann Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Bg4",
    Length: 10,
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Ziegler Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 c6",
    Length: 10,
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: London System, Pterodactyl Variation",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. Bf4 c5 4. c3 cxd4 5. cxd4 Qa5+",
    Length: 10,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Defense, Haberditz Variation",
    pgn: "1. d4 d5 2. c4 c5 3. cxd5 Nf6 4. e4 Nxe4 5. dxc5 Qa5+",
    Length: 10,
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Argentinian Gambit",
    pgn: "1. d4 d5 2. c4 Bf5 3. cxd5 Bxb1 4. Qa4+ c6 5. dxc6 Nxc6",
    Length: 10,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Balogh Variation",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 Qe7",
    Length: 10,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Janowski Variation",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 f6",
    Length: 10,
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Lasker Trap",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. e3 Bb4+ 5. Bd2 dxe3",
    Length: 10,
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Be6 Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Be6",
    Length: 10,
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Bf5 Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bf5",
    Length: 10,
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Bg4 Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bg4",
    Length: 10,
  },
  {
    eco: "D16",
    name: "Slav Defense: Smyslov Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Na6",
    Length: 10,
  },
  {
    eco: "D16",
    name: "Slav Defense: Soultanbeieff Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 e6",
    Length: 10,
  },
  {
    eco: "D16",
    name: "Slav Defense: Steiner Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bg4",
    Length: 10,
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5",
    Length: 10,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Linares Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 c5 4. d5 Nf6 5. Nc3 b5",
    Length: 10,
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5",
    Length: 10,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Spielmann Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. e3 c6 5. Nbd2 g6",
    Length: 10,
  },
  {
    eco: "D30",
    name: "Semi-Slav Defense: Quiet Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Nbd7",
    Length: 10,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Variation, Belyavsky Line",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. Bg5 f6",
    Length: 10,
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Symmetrical Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. e3 Nf6 5. Nf3 Nc6",
    Length: 10,
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation, Positional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 c6",
    Length: 10,
  },
  {
    eco: "D39",
    name: "Queen's Gambit Declined: Ragozin Defense, Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Bg5 dxc4",
    Length: 10,
  },
  {
    eco: "D44",
    name: "Semi-Slav Defense Accepted",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4",
    Length: 10,
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Accelerated Meran Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 a6",
    Length: 10,
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Normal Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7",
    Length: 10,
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Pseudo-Tarrasch Variation, Canal Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. cxd5 Qb6",
    Length: 10,
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Manhattan Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 Bb4",
    Length: 10,
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Modern Variation, Knight Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6",
    Length: 10,
  },
  {
    eco: "D53",
    name: "Queen's Gambit Declined: Lasker Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 Ne4",
    Length: 10,
  },
  {
    eco: "D71",
    name: "Neo-Grünfeld Defense: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 d5 5. cxd5 Nxd5",
    Length: 10,
  },
  {
    eco: "D83",
    name: "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O",
    Length: 10,
  },
  {
    eco: "E05",
    name: "Catalan Opening: Open Defense, Classical Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3 Be7",
    Length: 10,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Andersson Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 Ne4",
    Length: 10,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Hedgehog Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 g6",
    Length: 10,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Main Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5",
    Length: 10,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Marco Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 Be7",
    Length: 10,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Petrosian Variation, Farago Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Ba6 5. Qc2 Bb7",
    Length: 10,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Check Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+",
    Length: 10,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 c5",
    Length: 10,
  },
  {
    eco: "E16",
    name: "Queen's Indian Defense: Capablanca Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+",
    Length: 10,
  },
  {
    eco: "E17",
    name: "Queen's Indian Defense: Traditional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7",
    Length: 10,
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation, Euwe Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. d5 Ne4",
    Length: 10,
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation, Shocron Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nc3 c5 5. d5 b5",
    Length: 10,
  },
  {
    eco: "E23",
    name: "Nimzo-Indian Defense: Spielmann Variation, Romanovsky Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3 c5 5. dxc5 Nc6",
    Length: 10,
  },
  {
    eco: "E27",
    name: "Nimzo-Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 O-O",
    Length: 10,
  },
  {
    eco: "E33",
    name: "Nimzo-Indian Defense: Classical Variation, Milner-Barry Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6 5. Nf3 d6",
    Length: 10,
  },
  {
    eco: "E35",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. cxd5 exd5",
    Length: 10,
  },
  {
    eco: "E38",
    name: "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Steiner Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 Bxc3+",
    Length: 10,
  },
  {
    eco: "E39",
    name: "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Pirc Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 O-O",
    Length: 10,
  },
  {
    eco: "E41",
    name: "Nimzo-Indian Defense: Normal Variation, Hübner Deferred",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Nf3 O-O",
    Length: 10,
  },
  {
    eco: "E45",
    name: "Nimzo-Indian Defense: Normal Variation, Bronstein (Byrne) Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6 5. Ne2 Ba6",
    Length: 10,
  },
  {
    eco: "E48",
    name: "Nimzo-Indian Defense: Normal Variation, Bishop Attack, Classical Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5",
    Length: 10,
  },
  {
    eco: "E51",
    name: "Nimzo-Indian Defense: Normal Variation, Ragozin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5",
    Length: 10,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Fianchetto Variation, Pterodactyl Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. g3 c5 5. Bg2 Qa5+",
    Length: 10,
  },
  {
    eco: "E76",
    name: "King's Indian Defense: Four Pawns Attack, Modern Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 Na6",
    Length: 10,
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Sämisch Variation, Normal Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O",
    Length: 10,
  },
];

export const eco_11 = [
  {
    eco: "A00",
    name: "Amar Opening: Gent Gambit",
    pgn: "1. Nh3 d5 2. g3 e5 3. f4 Bxh3 4. Bxh3 exf4 5. O-O fxg3 6. hxg3",
    Length: 11,
  },
  {
    eco: "A00",
    name: "Polish Opening: King's Indian Variation, Sokolsky Attack",
    pgn: "1. b4 Nf6 2. Bb2 g6 3. c4 Bg7 4. e3 d6 5. Nf3 O-O 6. d4",
    Length: 11,
  },
  {
    eco: "A00",
    name: "Polish Opening: Rooks Swap Line",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 a6 4. a4 axb5 5. axb5 Rxa1 6. Bxa1",
    Length: 11,
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Jendrossek Gambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 f5 4. Nf2 Nf6 5. Nf3 c5 6. b4",
    Length: 11,
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Double Fianchetto Attack",
    pgn: "1. Nf3 Nf6 2. g3 g6 3. b3 Bg7 4. Bb2 O-O 5. Bg2 d6 6. O-O",
    Length: 11,
  },
  {
    eco: "A26",
    name: "English Opening: King's English Variation, Botvinnik System",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. e4",
    Length: 11,
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Edge Variation, Hergert Gambit",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. Bh4 d5 4. f3 Nf6 5. Nc3 Bf5 6. e4",
    Length: 11,
  },
  {
    eco: "A47",
    name: "Marienbad System: Berg Variation",
    pgn: "1. d4 Nf6 2. Nf3 b6 3. g3 Bb7 4. Bg2 c5 5. c4 cxd4 6. Qxd4",
    Length: 11,
  },
  {
    eco: "A48",
    name: "Queen's Pawn Game: Barry Attack, Grünfeld Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Be2",
    Length: 11,
  },
  {
    eco: "A65",
    name: "Benoni Defense: King's Pawn Line",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4",
    Length: 11,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense, El Columpio Gambit",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. e6",
    Length: 11,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense, Exchange Variation",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. exd6",
    Length: 11,
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense, Pin Variation",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. Bb5",
    Length: 11,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Lasker Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bg4 6. h3",
    Length: 11,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Jadoul Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. Bb5+ Nbd7 6. c4",
    Length: 11,
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Melbourne Shuffle Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. Bb5+ Nbd7 6. Nc3",
    Length: 11,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Hunt Variation, Lasker Simul Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3",
    Length: 11,
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Modern Variation, Alekhine Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. Nf3 Bg4 6. Be2",
    Length: 11,
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Vitolins Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. c4 Nb6 6. d5",
    Length: 11,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Kurajica Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. Be3",
    Length: 11,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Unzicker Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. e5",
    Length: 11,
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Weiss Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. Bd3",
    Length: 11,
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Maróczy Variation, Maróczy Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. f3 dxe4 4. fxe4 e5 5. Nf3 exd4 6. Bc4",
    Length: 11,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Exchange Variation, Rubinstein Variation",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Bd3 Nc6 5. c3 Nf6 6. Bf4",
    Length: 11,
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Forgacs Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6 6. Bc4",
    Length: 11,
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Modern Variation, Kasparov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Ng3",
    Length: 11,
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation, Flohr Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. Nh3",
    Length: 11,
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation, Main Line",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4",
    Length: 11,
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation, Maróczy Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. f4",
    Length: 11,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Stoltz Attack",
    pgn: "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Bc4 Nb6 6. Bb3",
    Length: 11,
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Grand Prix Attack, Schofman Variation",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. f4 g6 4. Nf3 Bg7 5. Bc4 e6 6. f5",
    Length: 11,
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4",
    Length: 11,
  },
  {
    eco: "B26",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. Be3",
    Length: 11,
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Pteranodon",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3 Bxc3+ 6. bxc3",
    Length: 11,
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Gufeld Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 e5 6. d4",
    Length: 11,
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Lutikov Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 Nf6 6. d4",
    Length: 11,
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Totsky Attack",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 Nf6 6. Qa4",
    Length: 11,
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Gurgenidze Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. Re1 e5 6. b4",
    Length: 11,
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nxc6",
    Length: 11,
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation, Retreat Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nf3",
    Length: 11,
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation, Schlechter Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nb3",
    Length: 11,
  },
  {
    eco: "B38",
    name: "Sicilian Defense: Accelerated Dragon, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7 6. Be3",
    Length: 11,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Four Knights Variation, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Nxc6",
    Length: 11,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Kan Variation, Wing Attack, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. Nc3 a6 4. g3 b5 5. d4 cxd4 6. Nxd4",
    Length: 11,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Pin Variation, Koch Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. e5",
    Length: 11,
  },
  {
    eco: "B48",
    name: "Sicilian Defense: Taimanov Variation, Bastrikov Variation, English Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be3",
    Length: 11,
  },
  {
    eco: "B51",
    name: "Sicilian Defense: Moscow Variation, Dorfman Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+ Nc6 4. O-O Bd7 5. Qe2 g6 6. e5",
    Length: 11,
  },
  {
    eco: "B55",
    name: "Sicilian Defense: Prins Variation, Venice Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3 e5 6. Bb5+",
    Length: 11,
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Classical Variation, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. g3",
    Length: 11,
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Spielmann Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Nde2",
    Length: 11,
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Venice Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Bb5+",
    Length: 11,
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Yates Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bd3",
    Length: 11,
  },
  {
    eco: "B57",
    name: "Sicilian Defense: Classical Variation, Sozin Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bc4",
    Length: 11,
  },
  {
    eco: "B58",
    name: "Sicilian Defense: Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2",
    Length: 11,
  },
  {
    eco: "B60",
    name: "Sicilian Defense: Richter-Rauzer Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5",
    Length: 11,
  },
  {
    eco: "B70",
    name: "Sicilian Defense: Dragon Variation, Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2",
    Length: 11,
  },
  {
    eco: "B70",
    name: "Sicilian Defense: Dragon Variation, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. g3",
    Length: 11,
  },
  {
    eco: "B71",
    name: "Sicilian Defense: Dragon Variation, Levenfish Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f4",
    Length: 11,
  },
  {
    eco: "B72",
    name: "Sicilian Defense: Dragon Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3",
    Length: 11,
  },
  {
    eco: "B80",
    name: "Sicilian Defense: Scheveningen Variation, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. g3",
    Length: 11,
  },
  {
    eco: "B80",
    name: "Sicilian Defense: Scheveningen Variation, Vitolins Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bb5+",
    Length: 11,
  },
  {
    eco: "B81",
    name: "Sicilian Defense: Scheveningen Variation, Keres Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. g4",
    Length: 11,
  },
  {
    eco: "B82",
    name: "Sicilian Defense: Scheveningen Variation, Matanovic Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. f4",
    Length: 11,
  },
  {
    eco: "B83",
    name: "Sicilian Defense: Scheveningen Variation, Classical Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2",
    Length: 11,
  },
  {
    eco: "B86",
    name: "Sicilian Defense: Sozin Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4",
    Length: 11,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Adams Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. h3",
    Length: 11,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Dekker Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. g4",
    Length: 11,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, English Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3",
    Length: 11,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Freak Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Rg1",
    Length: 11,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Lipnitsky Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4",
    Length: 11,
  },
  {
    eco: "B91",
    name: "Sicilian Defense: Najdorf Variation, Zagreb Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. g3",
    Length: 11,
  },
  {
    eco: "B92",
    name: "Sicilian Defense: Najdorf Variation, Opocensky Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2",
    Length: 11,
  },
  {
    eco: "B93",
    name: "Sicilian Defense: Najdorf Variation, Amsterdam Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. f4",
    Length: 11,
  },
  {
    eco: "B94",
    name: "Sicilian Defense: Najdorf Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5",
    Length: 11,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Main Line",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. a3",
    Length: 11,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Milner-Barry Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. Bd3",
    Length: 11,
  },
  {
    eco: "C11",
    name: "French Defense: Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. Nf3",
    Length: 11,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Bernstein Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bh4",
    Length: 11,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Chigorin Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. exf6",
    Length: 11,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Dr. Olland Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bc1",
    Length: 11,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Janowski Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Be3",
    Length: 11,
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4",
    Length: 11,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Exchange Variation, Canal Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. exd5 exd5 5. Bd3 Ne7 6. Qh5",
    Length: 11,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Kondratiyev Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd3 c5 5. exd5 Qxd5 6. Bd2",
    Length: 11,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Bogoljubov Variation, Icelandic Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2 Ne7 6. f4",
    Length: 11,
  },
  {
    eco: "C18",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3",
    Length: 11,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Hamppe-Allgaier Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. h4 g4 6. Ng5",
    Length: 11,
  },
  {
    eco: "C25",
    name: "Vienna Game: Hamppe-Muzio Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O",
    Length: 11,
  },
  {
    eco: "C26",
    name: "Vienna Game: Paulsen Variation, Pollock Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. g3 Bc5 4. Bg2 Nc6 5. Nge2 d5 6. exd5",
    Length: 11,
  },
  {
    eco: "C27",
    name: "Vienna Game: Adams' Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Nc6 6. d4",
    Length: 11,
  },
  {
    eco: "C29",
    name: "Vienna Game: Heyde Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3 f5 6. d4",
    Length: 11,
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Kaufmann Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Nf3 Bg4 6. Qe2",
    Length: 11,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation, Euwe Attack",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 Bg4 5. fxe5 dxe5 6. Qa4+",
    Length: 11,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Pawn Sacrifice Line",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ g6 5. fxg6 Qe7+ 6. Kd1",
    Length: 11,
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Symmetrical Variation",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Nf3 d5 5. d4 Bd6 6. Bd3",
    Length: 11,
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Qe2",
    Length: 11,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Chigorin's Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 d5 5. Bxd5 g5 6. g3",
    Length: 11,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, McDonnell Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Nc3 Bg7 6. g3",
    Length: 11,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Ghulam-Kassim Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4 gxf3 6. Qxf3",
    Length: 11,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Kotov Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4 gxf3 6. Bxf4",
    Length: 11,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit, Bird Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Ne5 Qh4+ 6. g3",
    Length: 11,
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Mayet Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. d4 d6 6. c3",
    Length: 11,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense, Rubinstein Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. d4",
    Length: 11,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Cotter Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7",
    Length: 11,
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Damiano Defense, Damiano Gambit",
    pgn: "1. e4 e5 2. Nf3 f6 3. Nxe5 fxe5 4. Qh5+ g6 5. Qxe5+ Qe7 6. Qxh8",
    Length: 11,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Bronstein Attack",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Be2",
    Length: 11,
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Nimzowitsch Attack",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Ne3",
    Length: 11,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Steiner Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. O-O Be7 6. dxe5",
    Length: 11,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Bishop Sacrifice",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. Bxf7+",
    Length: 11,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lopez Countergambit, Jaenisch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. Bc4 exd4 5. Ng5 Nh6 6. Nxh7",
    Length: 11,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Countergambit, del Rio Attack",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. dxe5 fxe4 5. Ng5 d5 6. e6",
    Length: 11,
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Spanish Variation, Harrwitz Attack, Nikitin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Bb5 dxe4 5. Nxe5 Qd5 6. Qa4",
    Length: 11,
  },
  {
    eco: "C44",
    name: "Scotch Game: Vitzthum Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5 Nh6 6. Qh5",
    Length: 11,
  },
  {
    eco: "C45",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2",
    Length: 11,
  },
  {
    eco: "C45",
    name: "Scotch Game: Blumenfeld Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. Nb5",
    Length: 11,
  },
  {
    eco: "C45",
    name: "Scotch Game: Ghulam-Kassim Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. Bd3",
    Length: 11,
  },
  {
    eco: "C45",
    name: "Scotch Game: Mieses Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. e5",
    Length: 11,
  },
  {
    eco: "C45",
    name: "Scotch Game: Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. Nd2",
    Length: 11,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. Nxe5 Qe7 6. f4",
    Length: 11,
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Double Spanish",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3",
    Length: 11,
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Nimzowitsch Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. Bxc6",
    Length: 11,
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Canal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Nc3 d6 6. Bg5",
    Length: 11,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Showalter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. a4 a6 6. Nc3",
    Length: 11,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Slow Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O",
    Length: 11,
  },
  {
    eco: "C53",
    name: "Italian Game: Giuoco Piano, Mestel Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6 6. Bg5",
    Length: 11,
  },
  {
    eco: "C53",
    name: "Italian Game: Scotch Gambit, Walbrodt-Baird Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. O-O",
    Length: 11,
  },
  {
    eco: "C54",
    name: "Italian Game: Classical Variation, Greco Gambit, Traditional Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4",
    Length: 11,
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Max Lange Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5",
    Length: 11,
  },
  {
    eco: "C56",
    name: "Italian Game: Scotch Gambit, Nakhmanson Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4 6. Nc3",
    Length: 11,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Fried Liver Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Nxf7",
    Length: 11,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Lolli Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. d4",
    Length: 11,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Variation, Trencianske-Teplice Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Bxf7+ Ke7 6. d4",
    Length: 11,
  },
  {
    eco: "C58",
    name: "Italian Game: Two Knights Defense, Polerio Defense, Bishop Check Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+",
    Length: 11,
  },
  {
    eco: "C58",
    name: "Italian Game: Two Knights Defense, Polerio Defense, Kieseritzky Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. d3",
    Length: 11,
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Steinitz Defense, Nimzowitsch Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. Nc3 Nf6 6. Bxc6",
    Length: 11,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Minckwitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. dxe5",
    Length: 11,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Open Berlin Defense, Showalter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. Ba4",
    Length: 11,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Open Berlin Defense, l'Hermet Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. dxe5",
    Length: 11,
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation, Romanovsky Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nc3 f6 6. d3",
    Length: 11,
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Norwegian Variation, Nightingale Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Na5 6. Bxf7+",
    Length: 11,
  },
  {
    eco: "C73",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Bxc6+ bxc6 6. d4",
    Length: 11,
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Duras Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3 d6 6. c4",
    Length: 11,
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Morphy Defense, Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Qe2",
    Length: 11,
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4",
    Length: 11,
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open, Knorre Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Nc3",
    Length: 11,
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed, Center Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d4",
    Length: 11,
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed, Martinez Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d3",
    Length: 11,
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed, Morphy Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Nc3",
    Length: 11,
  },
  {
    eco: "C85",
    name: "Ruy Lopez: Closed, Delayed Exchange",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Bxc6",
    Length: 11,
  },
  {
    eco: "C86",
    name: "Ruy Lopez: Closed, Worrall Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Qe2",
    Length: 11,
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Malich Gambit",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 c5 4. Bxf6 gxf6 5. e4 dxe4 6. d5",
    Length: 11,
  },
  {
    eco: "D13",
    name: "Slav Defense: Exchange Variation, Schallopp Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5 5. cxd5 cxd5 6. Nc3",
    Length: 11,
  },
  {
    eco: "D15",
    name: "Slav Defense: Geller Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e4 b5 6. e5",
    Length: 11,
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation, Bled Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Nh4",
    Length: 11,
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation, Krause Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5",
    Length: 11,
  },
  {
    eco: "D18",
    name: "Slav Defense: Czech Variation, Classical System",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3",
    Length: 11,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Billinger Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. a3",
    Length: 11,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Christensen Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Nf3",
    Length: 11,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Korchnoi Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Kf1",
    Length: 11,
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Novikov Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Nd2",
    Length: 11,
  },
  {
    eco: "D22",
    name: "Queen's Gambit Accepted: Alekhine Defense, Alatortsev Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e3 Bg4 5. Bxc4 e6 6. d5",
    Length: 11,
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense, Normal Line",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O",
    Length: 11,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Capablanca Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. c4 d5 4. Bg5 c6 5. Nbd2 Nbd7 6. e3",
    Length: 11,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Marshall Gambit, Forgotten Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Nc3",
    Length: 11,
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Marshall Gambit, Main Line",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Bd2",
    Length: 11,
  },
  {
    eco: "D33",
    name: "Tarrasch Defense: Rubinstein System",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3",
    Length: 11,
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation, Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7 5. cxd5 exd5 6. Bf4",
    Length: 11,
  },
  {
    eco: "D36",
    name: "Queen's Gambit Declined: Exchange Variation, Reshevsky Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 c6 6. Qc2",
    Length: 11,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Miles Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. Qc2",
    Length: 11,
  },
  {
    eco: "D41",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense, Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e4",
    Length: 11,
  },
  {
    eco: "D41",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense, Pillsbury Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e3",
    Length: 11,
  },
  {
    eco: "D43",
    name: "Semi-Slav Defense: Anti-Moscow Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 h6 6. Bh4",
    Length: 11,
  },
  {
    eco: "D44",
    name: "Semi-Slav Defense: Botvinnik Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4 6. e4",
    Length: 11,
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Rubinstein System",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 Nf6 5. e3 Nbd7 6. Ne5",
    Length: 11,
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Stoltz Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Qc2",
    Length: 11,
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Main Line",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3",
    Length: 11,
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Pseudo-Tarrasch Variation, Primitive Pillsbury Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. Nf3 cxd4 6. Qxd4",
    Length: 11,
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Alekhine Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. Nf3 c6 6. e4",
    Length: 11,
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Capablanca Variation, Anti-Cambridge Springs Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6 6. a3",
    Length: 11,
  },
  {
    eco: "D52",
    name: "Queen's Gambit Declined",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3",
    Length: 11,
  },
  {
    eco: "D54",
    name: "Queen's Gambit Declined: Neo-Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 O-O 6. Rc1",
    Length: 11,
  },
  {
    eco: "D55",
    name: "Queen's Gambit Declined: Modern Variation, Normal Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3",
    Length: 11,
  },
  {
    eco: "D77",
    name: "Neo-Grünfeld Defense: Classical Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4",
    Length: 11,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Lutikov Variation, Murrey Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. f3 c5 5. cxd5 Nxd5 6. Na4",
    Length: 11,
  },
  {
    eco: "D83",
    name: "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit, Capablanca Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Rc1",
    Length: 11,
  },
  {
    eco: "D93",
    name: "Grünfeld Defense: Three Knights Variation, Hungarian Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bf4 O-O 6. e3",
    Length: 11,
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Makogonov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. b4",
    Length: 11,
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Opocensky Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Bd2",
    Length: 11,
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Three Knights Variation, Paris Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Bd3",
    Length: 11,
  },
  {
    eco: "D95",
    name: "Grünfeld Defense: Three Knights Variation, Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Qb3",
    Length: 11,
  },
  {
    eco: "E03",
    name: "Catalan Opening: Open Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Qa4+ Nbd7 6. Qxc4",
    Length: 11,
  },
  {
    eco: "E12",
    name: "Nimzo-Indian Defense: Three Knights Variation, Duchamp Variation, Modern Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4 5. Bg5 Bb7 6. Nd2",
    Length: 11,
  },
  {
    eco: "E17",
    name: "Queen's Indian Defense: Anti-Queen's Indian System",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. Nc3",
    Length: 11,
  },
  {
    eco: "E17",
    name: "Queen's Indian Defense: Classical Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O",
    Length: 11,
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Romanishin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. g3 O-O 6. Bg2",
    Length: 11,
  },
  {
    eco: "E26",
    name: "Nimzo-Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 c5 6. e3",
    Length: 11,
  },
  {
    eco: "E28",
    name: "Nimzo-Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. a3 Bxc3+ 6. bxc3",
    Length: 11,
  },
  {
    eco: "E51",
    name: "Nimzo-Indian Defense: Normal Variation, Sämisch Deferred",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5 6. a3",
    Length: 11,
  },
  {
    eco: "E61",
    name: "King's Indian Defense: Semi-Classical Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. e3 d6 6. Be2",
    Length: 11,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5",
    Length: 11,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Semi-Averbakh System",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Be3",
    Length: 11,
  },
  {
    eco: "E77",
    name: "King's Indian Defense: Four Pawns Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. f4",
    Length: 11,
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Steiner Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Bg5",
    Length: 11,
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Sämisch Variation, Bobotsov-Korchnoi-Petrosian Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Nge2",
    Length: 11,
  },
  {
    eco: "E90",
    name: "King's Indian Defense: Larsen Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be3",
    Length: 11,
  },
  {
    eco: "E90",
    name: "King's Indian Defense: Zinnowitz Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Bg5",
    Length: 11,
  },
  {
    eco: "E91",
    name: "King's Indian Defense: Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2",
    Length: 11,
  },
];

export const eco_12 = [
  {
    eco: "A00",
    name: "Grob Opening: Keene Defense, Main Line",
    pgn: "1. g4 d5 2. h3 e5 3. Bg2 c6 4. d4 e4 5. c4 Bd6 6. Nc3 Ne7",
    Length: 12,
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Tarrasch Defense",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. b3 c5 5. Bg2 Nc6 6. O-O Be7",
    Length: 12,
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Grünfeld Defense, Korchnoi Variation",
    pgn: "1. Nf3 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. g3 Bg7 6. Bg2 e5",
    Length: 12,
  },
  {
    eco: "A25",
    name: "English Opening: Closed, Taimanov Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 d6 6. Nge2 Nh6",
    Length: 12,
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Bremen-Hort Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 d6 6. Nge2 Be6",
    Length: 12,
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation, Hedgehog Defense",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 e6 4. g3 b6 5. Bg2 Bb7 6. O-O Be7",
    Length: 12,
  },
  {
    eco: "A33",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation, Geller Variation",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 e6 6. g3 Qb6",
    Length: 12,
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Central, Benoni Beefeater Pterodactyl",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 d6 5. e4 Bxc3+ 6. bxc3 Qa5",
    Length: 12,
  },
  {
    eco: "A61",
    name: "Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6",
    Length: 12,
  },
  {
    eco: "A90",
    name: "Dutch Defense: Stonewall Variation, Modern Variation",
    pgn: "1. d4 f5 2. c4 e6 3. Nf3 Nf6 4. g3 c6 5. Bg2 d5 6. O-O Bd6",
    Length: 12,
  },
  {
    eco: "A92",
    name: "Dutch Defense: Alekhine Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O Ne4",
    Length: 12,
  },
  {
    eco: "A92",
    name: "Dutch Defense: Stonewall Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d5",
    Length: 12,
  },
  {
    eco: "A96",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d6",
    Length: 12,
  },
  {
    eco: "B00",
    name: "St. George Defense: San Jorge Variation",
    pgn: "1. e4 a6 2. d4 b5 3. Nf3 Bb7 4. Bd3 d6 5. O-O g6 6. c3 Bg7",
    Length: 12,
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack, Mikenas Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3 d6",
    Length: 12,
  },
  {
    eco: "B06",
    name: "Modern Defense: Two Knights Variation, Suttles Variation, Tal Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3 c6 5. Bg5 Qb6 6. Qd2 Qxb2",
    Length: 12,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System, Chigorin Line",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O Nc6",
    Length: 12,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System, Czech Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O c6",
    Length: 12,
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System, Parma Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O Bg4",
    Length: 12,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Carlsbad Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 e6",
    Length: 12,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Czerniak Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 Qa5",
    Length: 12,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Mieses Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Nf3 Bg4",
    Length: 12,
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Reifir-Spielmann Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 Qb6",
    Length: 12,
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack, Fianchetto Defense, Fianchetto Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 g6 6. cxd5 Bg7",
    Length: 12,
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack, Main Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 e6 6. Nf3 Bb4",
    Length: 12,
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Tiviakov-Fischer Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Bc4 Ngf6 6. Nxf6+ Nxf6",
    Length: 12,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Morphy Defense",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Bc5",
    Length: 12,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Paulsen Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 a6",
    Length: 12,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Pin Defense",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Bb4",
    Length: 12,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Scheveningen Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 d6 6. Bc4 e6",
    Length: 12,
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Taimanov Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Bc4 a6 6. Nf3 Ne7",
    Length: 12,
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Barmen Defense, Central Exchange",
    pgn: "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 cxd4 5. cxd4 Nc6 6. Nf3 Bg4",
    Length: 12,
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed, Botvinnik Defense, with Nge2",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. Nge2 e5",
    Length: 12,
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed, Botvinnik Defense, with f4",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4 e5",
    Length: 12,
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Pterodactyl, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3 Bxc3+ 6. bxc3 Qxc3+",
    Length: 12,
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Main Line",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 e6 5. Nxd5 exd5 6. d4 Nc6",
    Length: 12,
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Closed, Anti-Sveshnikov Variation, Kharlov-Kramnik Line",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e5 4. Bc4 Be7 5. d3 d6 6. Nd2 Bg5",
    Length: 12,
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Four Knights Variation, Cobra Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e6 6. Ndb5 Bc5",
    Length: 12,
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Pin Variation, Jaffe Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. Bd3 e5",
    Length: 12,
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Delayed Alapin Variation, Basman-Palatnik Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Be2 Nc6 5. d4 cxd4 6. cxd4 Nxe4",
    Length: 12,
  },
  {
    eco: "B57",
    name: "Sicilian Defense: Classical Variation, Anti-Sozin Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 Qb6",
    Length: 12,
  },
  {
    eco: "B58",
    name: "Sicilian Defense: Boleslavsky Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2 e5",
    Length: 12,
  },
  {
    eco: "B60",
    name: "Sicilian Defense: Richter-Rauzer Variation, Dragon Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 g6",
    Length: 12,
  },
  {
    eco: "B60",
    name: "Sicilian Defense: Richter-Rauzer Variation, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 Bd7",
    Length: 12,
  },
  {
    eco: "B62",
    name: "Sicilian Defense: Richter-Rauzer Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6",
    Length: 12,
  },
  {
    eco: "B71",
    name: "Sicilian Defense: Dragon Variation, Levenfish Variation, Main Line",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f4 Nbd7",
    Length: 12,
  },
  {
    eco: "B83",
    name: "Sicilian Defense: Scheveningen Variation, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 d6 6. Be2 Nf6",
    Length: 12,
  },
  {
    eco: "B84",
    name: "Sicilian Defense: Scheveningen Variation, Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e6",
    Length: 12,
  },
  {
    eco: "B88",
    name: "Sicilian Defense: Sozin Attack, Leonhardt Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 e6",
    Length: 12,
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, English Attack, Anti-English",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 Ng4",
    Length: 12,
  },
  {
    eco: "B95",
    name: "Sicilian Defense: Najdorf Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6",
    Length: 12,
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, Traditional Line",
    pgn: "1. e4 e6 2. d4 a6 3. Nf3 b5 4. Bd3 c5 5. c3 Bb7 6. O-O Nf6",
    Length: 12,
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Lputian Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. a3 Nh6",
    Length: 12,
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Botvinnik Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 b6",
    Length: 12,
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Closed Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6",
    Length: 12,
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Eliskases Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5 5. Ngf3 cxd4 6. Bc4 Qd8",
    Length: 12,
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Burn Variation, Morozevich Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 dxe4 5. Nxe4 Be7 6. Bxf6 gxf6",
    Length: 12,
  },
  {
    eco: "C11",
    name: "French Defense: Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. dxc5 Nc6",
    Length: 12,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Lasker Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Bxc3",
    Length: 12,
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Tartakower Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Nfd7",
    Length: 12,
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Breyer Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 c5",
    Length: 12,
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Maróczy Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 a6",
    Length: 12,
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Spielmann Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 O-O",
    Length: 12,
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Teichmann Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 f6",
    Length: 12,
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Frankfurt Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ng8 6. Be3 b6",
    Length: 12,
  },
  {
    eco: "C14",
    name: "French Defense: Classical Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7",
    Length: 12,
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Alekhine Gambit, Kan Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Bxc3+ 6. Nxc3 Nc6",
    Length: 12,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Maróczy-Wallis Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 cxd4 6. axb4 dxc3",
    Length: 12,
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Retreat Variation, Armenian Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Ba5 6. b4 cxd4",
    Length: 12,
  },
  {
    eco: "C18",
    name: "French Defense: Winawer Variation, Classical Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Qc7",
    Length: 12,
  },
  {
    eco: "C18",
    name: "French Defense: Winawer Variation, Portisch-Hook Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Qa5",
    Length: 12,
  },
  {
    eco: "C19",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7",
    Length: 12,
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Wayward Queen Attack, Mellon Gambit",
    pgn: "1. e4 e5 2. Qh5 Nc6 3. Bc4 Nh6 4. d3 g6 5. Qf3 f6 6. Ne2 d5",
    Length: 12,
  },
  {
    eco: "C20",
    name: "King's Pawn Opening: Van Hooydoon Gambit",
    pgn: "1. e4 e5 2. Qe2 Nc6 3. c3 Nf6 4. Nf3 Bc5 5. d4 exd4 6. cxd4 Nxd4",
    Length: 12,
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Urusov Gambit, Panov Variation",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 d5 5. exd5 Bb4+ 6. c3 Qe7+",
    Length: 12,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Hamppe-Allgaier Gambit, Alapin Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. h4 g4 6. Ng5 d6",
    Length: 12,
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Hamppe-Muzio Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O gxf3",
    Length: 12,
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Morphy Defense",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. exd5 e4 5. d3 Bb4 6. Bd2 e3",
    Length: 12,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Classical Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Ne7 5. Nc3 g5 6. d4 Bg7",
    Length: 12,
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Lopez-Gianutio Countergambit, Hein Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 f5 4. Qe2 Qh4+ 5. Kd1 fxe4 6. Nc3 Kd8",
    Length: 12,
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Greco Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. Bc4 h6 5. d4 g5 6. h4 Bg7",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit Accepted, From's Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qe7",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Holloway Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Nc6",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Sarratt Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Cochrane Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 f3",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Santa Maria Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nf6",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Silberschmidt Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nh6",
    Length: 12,
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Viennese Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nc6",
    Length: 12,
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense, de Riviere Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Nxg4 d5",
    Length: 12,
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Corkscrew Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Nf6 4. Bc4 fxe4 5. Nf7 Qe7 6. Nxh8 d5",
    Length: 12,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Lion's Claw, with Bc4 and h6",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. O-O h6",
    Length: 12,
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Lion's Claw, with Be2 and c6",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Be2 Be7 6. O-O c6",
    Length: 12,
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack, Marshall Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6",
    Length: 12,
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack, Mason-Showalter Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6",
    Length: 12,
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Bardeleben Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qe2 Nc5 6. Nxd4 Nc6",
    Length: 12,
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit, Bardeleben Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Bc4 Nf6 6. Nxc3 Bb4",
    Length: 12,
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Cochrane-Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. O-O d6 6. c3 Bg4",
    Length: 12,
  },
  {
    eco: "C45",
    name: "Scotch Game: Classical Variation, Millennium Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Qg6",
    Length: 12,
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Belgrade Gambit, Modern Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nd5 Nxe4 6. Qe2 f5",
    Length: 12,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation, Marshall Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Ba4 Bc5 6. Nxe5 O-O",
    Length: 12,
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation, Marshall Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Nxe5 Bc5 6. O-O O-O",
    Length: 12,
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 Bxc3",
    Length: 12,
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Symmetrical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6",
    Length: 12,
  },
  {
    eco: "C50",
    name: "Italian Game: Hungarian Defense, Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d4 exd4 5. c3 Nf6 6. e5 Ne4",
    Length: 12,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Hirschbach Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Qg5",
    Length: 12,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Lange Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Nh6",
    Length: 12,
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Anderssen Variation, Cordel Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Be7 6. d4 Na5",
    Length: 12,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O d6",
    Length: 12,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Bronstein Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 d6",
    Length: 12,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Laroche Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 Nf6",
    Length: 12,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Leonhardt Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 b5",
    Length: 12,
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Pierce Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4",
    Length: 12,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Greco Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. c3 Nf6 6. e5 d5",
    Length: 12,
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, La Bourdonnais Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 d6 5. d4 exd4 6. cxd4 Bb6",
    Length: 12,
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, Max Lange Attack, Spielmann Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5 Ng4",
    Length: 12,
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Pincus Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. d4 Bb4+",
    Length: 12,
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Defense, Benelux Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3 O-O 6. d4 Bb6",
    Length: 12,
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Berlin Defense, Closed Wolf Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 exd4",
    Length: 12,
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Berlin Defense, Hedgehog Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 Be7",
    Length: 12,
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Trifunovic Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 d5",
    Length: 12,
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Alapin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bg4 6. h3 h5",
    Length: 12,
  },
  {
    eco: "C73",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Bxc6+ bxc6 6. d4 f6",
    Length: 12,
  },
  {
    eco: "C75",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 Bd7 6. d4 Nge7",
    Length: 12,
  },
  {
    eco: "C76",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense, Fianchetto Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. c3 a6 5. Ba4 d6 6. d4 Bd7",
    Length: 12,
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 d6",
    Length: 12,
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense, Arkhangelsk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 Bb7",
    Length: 12,
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open, Riga Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 exd4",
    Length: 12,
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open, Skipworth Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Re1 d5",
    Length: 12,
  },
  {
    eco: "C87",
    name: "Ruy Lopez: Closed, Averbakh Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 d6",
    Length: 12,
  },
  {
    eco: "D14",
    name: "Slav Defense: Exchange Variation, Symmetrical Line",
    pgn: "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 Nf6 5. Nf3 Nc6 6. Bf4 Bf5",
    Length: 12,
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation, Wiesbaden Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 e6",
    Length: 12,
  },
  {
    eco: "D18",
    name: "Slav Defense: Czech Variation, Lasker Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3 Na6",
    Length: 12,
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense, Steinitz Variation, Development Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O Nc6",
    Length: 12,
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense, Steinitz Variation, Exchange Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O cxd4",
    Length: 12,
  },
  {
    eco: "D27",
    name: "Queen's Gambit Accepted: Classical Defense, Main Line",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6",
    Length: 12,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Semmering Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Nbd7 6. Bd3 c5",
    Length: 12,
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Stonewall Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Ne4 6. Bd3 f5",
    Length: 12,
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Tarrasch Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. dxc5 d4 6. Na4 b5",
    Length: 12,
  },
  {
    eco: "D33",
    name: "Tarrasch Defense: Prague Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6",
    Length: 12,
  },
  {
    eco: "D33",
    name: "Tarrasch Defense: Swedish Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 c4",
    Length: 12,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack, Fianchetto Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bf4 O-O 6. e3 b6",
    Length: 12,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack, Orthodox Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 Be7 5. Bf4 O-O 6. e3 c6",
    Length: 12,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack, Two Knights Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4 O-O 6. e3 Nbd7",
    Length: 12,
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Knight Defense, Alekhine Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7 5. Bg5 h6 6. Bh4 dxc4",
    Length: 12,
  },
  {
    eco: "D38",
    name: "Queen's Gambit Declined: Westphalian Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Bg5 Nbd7 6. e3 c5",
    Length: 12,
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Stonewall Defense",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. c4 c6 4. Nc3 e6 5. e3 Ne4 6. Bd3 f5",
    Length: 12,
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Bogoljubov Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Be7",
    Length: 12,
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Bd6",
    Length: 12,
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Romih Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Bb4",
    Length: 12,
  },
  {
    eco: "D52",
    name: "Queen's Gambit Declined: Cambridge Springs Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5",
    Length: 12,
  },
  {
    eco: "D55",
    name: "Queen's Gambit Declined: Neo-Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 h6",
    Length: 12,
  },
  {
    eco: "D60",
    name: "Queen's Gambit Declined: Orthodox Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7",
    Length: 12,
  },
  {
    eco: "D77",
    name: "Neo-Grünfeld Defense: Classical Variation, Modern Defense",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4 dxc4",
    Length: 12,
  },
  {
    eco: "D77",
    name: "Neo-Grünfeld Defense: Classical Variation, Polgar Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4 Nc6",
    Length: 12,
  },
  {
    eco: "D78",
    name: "Neo-Grünfeld Defense: Classical Variation, Original Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 c6 6. O-O d5",
    Length: 12,
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Lundin Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5 Ne4 5. Nxe4 dxe4 6. Qd2 c5",
    Length: 12,
  },
  {
    eco: "D95",
    name: "Grünfeld Defense: Botvinnik Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Qb3 e6",
    Length: 12,
  },
  {
    eco: "E04",
    name: "Catalan Opening: Open Defense, Modern Sharp Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3 Nc6 6. Qa4 Bb4+",
    Length: 12,
  },
  {
    eco: "E07",
    name: "Catalan Opening: Closed",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3 O-O 6. O-O Nbd7",
    Length: 12,
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit Accepted",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5 5. dxe6 fxe6 6. cxb5 d5",
    Length: 12,
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit: Spielmann Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. c4 c5 4. d5 b5 5. Bg5 exd5 6. cxd5 h6",
    Length: 12,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Classical Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 exd5",
    Length: 12,
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Modern Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5",
    Length: 12,
  },
  {
    eco: "E13",
    name: "Queen's Indian Defense: Kasparov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4 5. Bg5 h6 6. Bh4 Bb7",
    Length: 12,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Check Variation, Intermezzo Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Be7",
    Length: 12,
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Check Variation, Modern Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Qe7",
    Length: 12,
  },
  {
    eco: "E16",
    name: "Queen's Indian Defense: Riumin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+ 6. Bd2 Be7",
    Length: 12,
  },
  {
    eco: "E16",
    name: "Queen's Indian Defense: Yates Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 a5 5. g3 b6 6. Bg2 Bb7",
    Length: 12,
  },
  {
    eco: "E26",
    name: "Nimzo-Indian Defense: Sämisch Variation, O'Kelly Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 c5 6. e3 b6",
    Length: 12,
  },
  {
    eco: "E30",
    name: "Nimzo-Indian Defense: Leningrad Variation, Averbakh Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 c5 6. d5 b5",
    Length: 12,
  },
  {
    eco: "E31",
    name: "Nimzo-Indian Defense: Leningrad Variation, Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 c5 6. d5 d6",
    Length: 12,
  },
  {
    eco: "E32",
    name: "Nimzo-Indian Defense: Classical Variation, Keres Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. a3 Bxc3+ 6. Qxc3 b6",
    Length: 12,
  },
  {
    eco: "E32",
    name: "Nimzo-Indian Defense: Classical Variation, Vitolins-Adorjan Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. a3 Bxc3+ 6. Qxc3 b5",
    Length: 12,
  },
  {
    eco: "E36",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation, Botvinnik Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Nc6",
    Length: 12,
  },
  {
    eco: "E36",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation, Main Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Ne4",
    Length: 12,
  },
  {
    eco: "E46",
    name: "Nimzo-Indian Defense: Simagin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Ne2 d5 6. a3 Bd6",
    Length: 12,
  },
  {
    eco: "E52",
    name: "Nimzo-Indian Defense: Normal Variation, Schlechter Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 b6",
    Length: 12,
  },
  {
    eco: "E53",
    name: "Nimzo-Indian Defense: Normal Variation, Gligoric System",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5",
    Length: 12,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Fianchetto Variation, Yugoslav System",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c5",
    Length: 12,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Semi-Classical Variation, Benoni Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. e3 d6 6. Be2 c6",
    Length: 12,
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Semi-Classical Variation, Hollywood Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. Nc3 O-O 5. e3 d6 6. Be2 Nc6",
    Length: 12,
  },
  {
    eco: "E62",
    name: "King's Indian Defense: Fianchetto Variation, Karlsbad Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nc6",
    Length: 12,
  },
  {
    eco: "E64",
    name: "King's Indian Defense: Fianchetto Variation, Yugoslav Variation, Rare Line",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 c5",
    Length: 12,
  },
  {
    eco: "E67",
    name: "King's Indian Defense: Fianchetto Variation, Debrecen Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nbd7",
    Length: 12,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Flexible Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 h6",
    Length: 12,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Geller Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Nbd7",
    Length: 12,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Modern Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Na6",
    Length: 12,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Nc6 Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Nc6",
    Length: 12,
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Spanish Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 a6",
    Length: 12,
  },
  {
    eco: "E74",
    name: "King's Indian Defense: Averbakh Variation, Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 c5",
    Length: 12,
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Sämisch Variation, Sämisch Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 c5",
    Length: 12,
  },
  {
    eco: "E82",
    name: "King's Indian Defense: Sämisch Variation, Double Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 b6",
    Length: 12,
  },
  {
    eco: "E83",
    name: "King's Indian Defense: Sämisch Variation, Yates Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 Nc6",
    Length: 12,
  },
  {
    eco: "E85",
    name: "King's Indian Defense: Sämisch Variation, Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5",
    Length: 12,
  },
  {
    eco: "E91",
    name: "King's Indian Defense: Kazakh Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 Na6",
    Length: 12,
  },
  {
    eco: "E92",
    name: "King's Indian Defense: Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5",
    Length: 12,
  },
];
