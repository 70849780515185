import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const HangmanHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>{t("Hangman.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("Hangman.helpDetails.instructions.start_game.primary")}
            secondary={t(
              "Hangman.helpDetails.instructions.start_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Hangman.helpDetails.instructions.guess_letters.primary"
            )}
            secondary={t(
              "Hangman.helpDetails.instructions.guess_letters.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Hangman.helpDetails.instructions.avoid_wrong_guesses.primary"
            )}
            secondary={t(
              "Hangman.helpDetails.instructions.avoid_wrong_guesses.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Hangman.helpDetails.instructions.game_over.primary")}
            secondary={t(
              "Hangman.helpDetails.instructions.game_over.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Hangman.helpDetails.instructions.chess_knowledge.primary"
            )}
            secondary={t(
              "Hangman.helpDetails.instructions.chess_knowledge.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default HangmanHelp;
