import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const GuessWhoHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>{t("GuessWho.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("GuessWho.helpDetails.instructions.how_to_play.primary")}
            secondary={t(
              "GuessWho.helpDetails.instructions.how_to_play.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("GuessWho.helpDetails.instructions.make_guess.primary")}
            secondary={t(
              "GuessWho.helpDetails.instructions.make_guess.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("GuessWho.helpDetails.instructions.game_end.primary")}
            secondary={t(
              "GuessWho.helpDetails.instructions.game_end.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessWho.helpDetails.instructions.improve_knowledge.primary"
            )}
            secondary={t(
              "GuessWho.helpDetails.instructions.improve_knowledge.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default GuessWhoHelp;
