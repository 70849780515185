import React, { useEffect } from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useShare } from "../../../context/ShareContext";
import PatreonIcon from "../../../icons/PatreonIcon";
import { useTranslation } from "react-i18next";

const About = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setShareData } = useShare();

  const { t } = useTranslation("Other");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/about",
      title: "Chessboard Magic - About",
      description:
        "Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);
  return (
    <Box>
      <Helmet>
        <title>About Chessboard Magic</title>
        <meta
          name="description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta property="og:title" content="About Chessboard Magic" />
        <meta
          property="og:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Chessboard Magic" />
        <meta
          name="twitter:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title={t("About.title")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box id="about" sx={{ flex: 1 }}>
          <Box>
            <p>{t("About.body.1")}</p>
            <p>{t("About.body.2")}</p>
            <p>{t("About.body.3")} </p>
            <p>{t("About.body.4")} </p>
            <p>{t("About.body.5")} </p>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 2, textTransform: "none" }}
              href="https://lichess.org/@/HollowLeaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/${
                    useTheme().palette.mode === "dark"
                      ? "lichessdotorgwhite.png"
                      : "lichessdotorg.png"
                  }`}
                  alt="lichess.org"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 2, textTransform: "none" }}
              href="https://www.chess.com/member/hollowleaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/chessdotcom.png`}
                  alt="chess.com"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, textTransform: "none" }}
              href="https://patreon.com/ChessboardMagic"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<PatreonIcon sx={{ width: 24, height: 24 }} />}
            >
              Patreon
            </Button>
          </Box>
        </Box>
        <Box id="version" sx={{ flex: 1, paddingBottom: "20px" }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.5</b>
                {"  "}11th October 2024{"  "}
                <span style={{ color: "#555555" }}>[Halloween]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              While this release includes several new games and enhancements to
              the website, there are also smaller applications and features
              introduced.
              <Typography>
                <p>
                  <strong>New Games</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <strong>Guess The Move:</strong> Test your chess knowledge
                    by guessing the best move in a position according to the
                    Chess engine.
                  </li>
                  <li>
                    <strong>Guess Who:</strong> Identify famous chess players by
                    choosing their name from a list
                  </li>
                  <li>
                    <strong>Hand and Brain:</strong> Play a fun variant where
                    the engine acts as the brain, recommending a piece to move,
                    but you make the final decision.
                  </li>
                </ul>

                <p>
                  <strong>New Tool</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <strong>PGN Viewer:</strong> This is a very early release,
                    but it allows you to upload and play through any PGN.
                  </li>
                </ul>
                <p>
                  <strong>Progressive Web Application (PWA) Update</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Chessboard Magic is now a PWA! You can install the website
                    directly onto your device, making it easier to access. After
                    installation, the site becomes available offline, with
                    faster load times and a total download size of around 30MB
                    (mainly due to images). Just click the "Install" button on
                    the site to get started and enjoy offline access, faster
                    performance, and a smoother experience.
                  </li>
                </ul>
                <p>
                  <strong>Extended Language Support</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Chessboard Magic is now available in multiple languages,
                    including French, German, Spanish, Arabic, Chinese, Russian,
                    Hindi, and Vietnamese! You can easily switch to your
                    preferred language in the menu, or let the site
                    automatically detect it based on your browser settings. This
                    update brings a more accessible experience for users around
                    the world, with fully translated games, tools, and
                    resources. If you notice any unusual translations, feel free
                    to reach out.
                  </li>
                </ul>

                <p>
                  <strong>Other Updates</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Guess the Evaluation now shows the engine evaluation as well
                    as the Label
                  </li>
                  <li>Added Colours to each section of the website</li>
                  <li>
                    Moved Notation Trainer to the Learn Section of the website
                  </li>
                  <li>
                    Updated the Opening Explorer to include an engine evaluation
                    and side bar
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.4</b>
                {"  "}6th September 2024{"  "}
                <span style={{ color: "#555555" }}>[Opponent Prep]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              While the new Opponent Prep tool is a highlight of this update,
              this release also includes several smaller applications and
              enhancements to the website.
              <Typography>
                <p>
                  <strong>Analytics</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Opponent Prep (v0.1): A new application has been added that
                    allows you to explore and compare two different Lichess
                    profiles, providing detailed statistics and insights into
                    each player's opening strengths and weaknesses.
                  </li>
                </ul>
                <p>
                  <strong>Other Updates</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Home Page Update to highlight a random tool.</li>
                  <li>
                    Increased the collection of Classic Chess Games and
                    introduced a random game picker feature.
                  </li>
                  <li>
                    Added a collection of Chess Miniatures to the Library
                    Section.
                  </li>
                  <li>
                    Added Chess Resources to the newly created Learn Section.
                  </li>
                  <li>Introduced sharing functionality to the website.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.3</b>
                {"  "}8th August 2024{"  "}
                <span style={{ color: "#555555" }}>[Explore Chess]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              This update adds the long awaiting Analytics section to the
              website with our first tool User Scount. This is supplemented by a
              sizable game library as well as new tools and games.
              <Typography>
                <p>
                  <strong>New Games</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Chess Crossword: Solve chess-themed crosswords and improve
                    your chess knowledge while having fun.
                  </li>
                  <li>
                    Chess Image Puzzle: "Engage in jigsaw-like puzzles featuring
                    images of renowned chess players, piecing together each
                    portrait for a challenging experience.
                  </li>
                </ul>
                <p>
                  <strong>Analytics</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    User Scout (v0.1): Added a new application that will allow
                    you to explore Lichess profiles. You can see statistics as
                    well as explore opening strengths and weaknesses.
                  </li>
                </ul>
                <p>
                  <strong>Tools</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Notation Trainer Update: Updated so that the games are based
                    on the World Championship Games, or a my selection of
                    Classic Chess Games. Additional fixes include the addition
                    of arrows to show the current move as well as moving on
                    after three incorrect guesses.
                  </li>
                  <li>
                    Gif Generator: Turn Chess PGNs into visually engaging
                    sequences effortlessly with our GIF Generator. You may have
                    seen these on social media, and now you will be able to
                    generate your own.
                  </li>
                </ul>
                <p>
                  <strong>New Library Items</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    World Chess Championships: Explore and play through the
                    World Chess Championship games (from 1,886 to 2023),
                    featuring matches from the world's top players.
                  </li>
                  <li>
                    Classic Chess Games: Explore and play through Classic chess
                    games, featuring matches from some of the greatest players
                    in history.
                  </li>
                  <li>
                    FIDE Rules of Basic Play: Basic chess rules cover piece
                    movement, check and checkmate, castling, pawn promotion, and
                    the initial setup of the board.
                  </li>
                  <li>
                    FIDE Rules of Competitive Play: Competitive chess rules
                    ensure fair play, covering player conduct, piece movement,
                    clock use, and dispute resolution.
                  </li>
                </ul>
                <p>
                  <strong>Other Updates</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Home Page Update to maximise the space and give a better
                    experience.
                  </li>
                  <li>Increased the number of games in Guess the Elo.</li>
                  <li>Increased the number of positions in Guess the Eval.</li>
                  <li>
                    Included a profile so that you can login to see your
                    statistics as well choose your chessboard and piece theme.
                  </li>
                  <li>
                    Added a host of different pieces from Lichess as well as a
                    host of different board colour combinations.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.2</b>
                {"  "}5th July 2024{"  "}
                <span style={{ color: "#555555" }}>[Alphabet of Chess]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              This update expanded on the initial release by adding several
              word-based puzzles and introducing a library section. Significant
              work was done on the technology stack to enhance performance and
              usability, with processes implemented to ensure long-term success.
              <Typography>
                <p>
                  <strong>New Games</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Chess Hangman: Guess the chess-related word before the
                    hangman is complete.
                  </li>
                  <li>
                    Chess Anagrams: Unscramble the letters to form chess-related
                    terms.
                  </li>
                  <li>
                    Chess Wordsearch: Find chess terms hidden in a grid of
                    letters.
                  </li>
                </ul>
                <p>
                  <strong>Tools</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Notation Trainer: To help you develop your Chess notation
                    skills.
                  </li>
                </ul>
                <p>
                  <strong>New Library Items</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Chess Quotes: A collection of famous chess quotes.</li>
                  <li>
                    Chess Terms: Definitions and explanations of common chess
                    terminology.
                  </li>
                </ul>
                <p>
                  <strong>Other Updates</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Added Time Controls for Guess the Elo.</li>
                  <li>
                    Updated the Help section for Guess the Elo to specify that
                    the games are sampled from Lichess in 2020.
                  </li>
                  <li>Increased the number of games in Guess the Elo.</li>
                  <li>Increased the number of positions in Guess the Eval.</li>
                  <li>Showed the Computer Line for Guess the Eval.</li>
                  <li>Included links to our Facebook and Twitter profiles.</li>
                  <li>Several cosmetic adjustments.</li>
                  <li>Added Dark and Light Mode.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.1</b>
                {"  "}27th May 2024{"  "}
                <span style={{ color: "#555555" }}>[Initial Release]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              The purpose of this release was to launch the initial version of
              the website with a few puzzles. The goal was to test the
              technology stack as well as the overall design and user
              experience.
              <Typography>
                <p>Games</p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Guess the Elo: Estimate player ratings based on their games.
                  </li>
                  <li>
                    Play The Opening: Reproduce the opening from its name.
                  </li>
                  <li>Guess the Opening: A chess-themed version of Wordle.</li>
                  <li>
                    Guess the Eval: Predict the evaluation of chess positions.
                  </li>
                  <li>Chess Slide: Solve sliding puzzles with chess pieces.</li>
                </ul>
                <p>Tools</p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Fischer Random Generator: Generate random chess positions.
                  </li>
                  <li>Opening Explorer: Explore different chess openings.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
