import React from "react";
import { SvgIcon } from "@mui/material";

function YouTubeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1200 1200">
      <path
        d="m217.38 230.01c-83.613 6.5703-146.81 74.559-150.88 158.33-3.3555 69.02-6.5039 148.44-6.5039 203.27 0 55.457 3.2227 136.07 6.6172 205.64 4.0391 82.699 65.789 150.23 148.23 157.85 91.266 8.4258 223.32 16.906 385.15 16.906 161.41 0 293.2-8.4375 384.43-16.844 82.766-7.625 144.64-75.598 148.62-158.62 3.5117-73.242 6.9453-157.05 6.9453-204.93 0-47.371-3.3594-129.89-6.832-202.57-4.0195-84.094-67.316-152.5-151.25-159.08-89.836-7.043-219.41-13.953-381.92-13.953-162.93 0-292.76 6.9492-382.62 14.008zm274.62 201.31 278.4 160.29-278.4 160.29z"
        fill="#FF0000"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default YouTubeIcon;
