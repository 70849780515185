import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  useTheme,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { worldChampionshipGames } from "../../../data/historicgames/worldChampionship";
import GameViewer from "../../../components/GameViewer";
import ExpandableTypography from "../../../components/ExpandableTypograph";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

// Extract the unique types from the worldChampionshipGames data
const types = [...new Set(worldChampionshipGames.map((game) => game.Type))];

function WorldChampionships() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const defaultType = "2006-2023 | Reunified Championship";
  const defaultEvent = "2023 | I. Nepomniachtchi vs D. Liren";

  const [selectedType, setSelectedType] = useState(defaultType);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [selectedGameIndex, setSelectedGameIndex] = useState(0);

  const { setShareData } = useShare();
  const { t } = useTranslation("Library");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/worldchampionships",
      title: "Chessboard Magic - Chess World Championships",
      description:
        "Explore and play through the World Chess Championship games, featuring matches from the world's top players",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    const initialEventIndex = worldChampionshipGames.findIndex(
      (event) => event.Event === defaultEvent && event.Type === defaultType
    );
    if (initialEventIndex !== -1) {
      setSelectedEventIndex(initialEventIndex);
    }
  }, [defaultType, defaultEvent]);

  // Filter events based on the selected type
  const filteredEvents = worldChampionshipGames.filter(
    (game) => game.Type === selectedType
  );

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setSelectedEventIndex(0); // Reset event selection when type changes
    setSelectedGameIndex(0); // Reset game selection when type changes
  };

  const handleEventChange = (event) => {
    setSelectedEventIndex(event.target.value);
    setSelectedGameIndex(0); // Reset to first game when changing event
  };

  const handleGameChange = (event) => {
    setSelectedGameIndex(event.target.value);
  };

  return (
    <Box>
      <ContentHeader
        title={t("WorldChampionships.header.title")}
        subtitle={t("WorldChampionships.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[6]}
      />
      <Helmet>
        <title>Chess World Championship Matches</title>
        <meta
          name="description"
          content="Explore and play through the World Chess Championship games, featuring matches from the world's top players."
        />
        <meta property="og:title" content="Chess World Championship Matches" />
        <meta
          property="og:description"
          content="Explore and play through the World Chess Championship games, featuring matches from the world's top players."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/library/worldchampionships.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/notationtrainer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess World Championship Matches" />
        <meta
          name="twitter:description"
          content="Explore and play through the World Chess Championship games, featuring matches from the world's top players."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/library/worldchampionships.png`}
        />
      </Helmet>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl variant="outlined" margin="normal">
            <Select
              id="type-select"
              value={selectedType}
              onChange={handleTypeChange}
              sx={{ maxWidth: "400px" }}
            >
              {types.map((type, index) => (
                <MenuItem key={index} value={type}>
                  <Typography
                    component="span"
                    sx={{ fontWeight: "bold", mr: 1 }}
                  >
                    {type.slice(0, 10)}
                  </Typography>
                  <Typography component="span">{type.slice(12)}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {selectedType && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FormControl variant="outlined" margin="normal">
              <Select
                id="event-select"
                value={selectedEventIndex}
                onChange={handleEventChange}
                sx={{ maxWidth: "400px" }}
              >
                {filteredEvents.map((event, index) => (
                  <MenuItem key={index} value={index}>
                    <Typography
                      component="span"
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      {event.Event.slice(0, 4)}
                    </Typography>
                    <Typography component="span">
                      {event.Event.slice(6)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {filteredEvents[selectedEventIndex] && (
          <Box>
            <Typography sx={{ pb: 0 }}>
              {t("WorldChampionships.game.site")}
              {": "}
              <b>{filteredEvents[selectedEventIndex].Site}</b>,{" "}
              {t("WorldChampionships.game.date")}
              {": "}
              <b>{filteredEvents[selectedEventIndex].EventDate}</b>
            </Typography>
            <ExpandableTypography
              text={filteredEvents[selectedEventIndex].EventDescription}
              charLimit="160"
              buttonPosition="right"
              textWidth="700px"
            />
            <Box>
              <FormControl variant="outlined" margin="normal">
                <InputLabel id="game-select-label">
                  {t("WorldChampionships.buttons.selectGame")}
                </InputLabel>
                <Select
                  labelId="game-select-label"
                  id="game-select"
                  value={selectedGameIndex}
                  onChange={handleGameChange}
                  label={t("WorldChampionships.buttons.selectGame")}
                >
                  {filteredEvents[selectedEventIndex].Games.map(
                    (game, gameIndex) => (
                      <MenuItem key={gameIndex} value={gameIndex}>
                        {t("WorldChampionships.game.game")} {gameIndex + 1}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {filteredEvents[selectedEventIndex].Games[selectedGameIndex] && (
                <GameViewer
                  BlackPlayer={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .Black
                  }
                  BlackElo={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .BlackElo
                  }
                  WhitePlayer={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .White
                  }
                  WhiteElo={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .WhiteElo
                  }
                  Date={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .Date
                  }
                  Result={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .Result
                  }
                  Round={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .Round
                  }
                  PlyCount={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .PlyCount
                  }
                  Moves={
                    filteredEvents[selectedEventIndex].Games[selectedGameIndex]
                      .Moves
                  }
                  WhiteImage={filteredEvents[selectedEventIndex].Games[
                    selectedGameIndex
                  ].White.split(", ")[0].toUpperCase()}
                  BlackImage={filteredEvents[selectedEventIndex].Games[
                    selectedGameIndex
                  ].Black.split(", ")[0].toUpperCase()}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default WorldChampionships;
