import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NotationTrainerHelp = () => {
  const { t } = useTranslation("Learn");

  return (
    <>
      <Typography>{t("NotationTrainer.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "NotationTrainer.helpDetails.instructions.load_game.primary"
            )}
            secondary={t(
              "NotationTrainer.helpDetails.instructions.load_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "NotationTrainer.helpDetails.instructions.type_move.primary"
            )}
            secondary={t(
              "NotationTrainer.helpDetails.instructions.type_move.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "NotationTrainer.helpDetails.instructions.learn_opening.primary"
            )}
            secondary={t(
              "NotationTrainer.helpDetails.instructions.learn_opening.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "NotationTrainer.helpDetails.instructions.practice_skills.primary"
            )}
            secondary={t(
              "NotationTrainer.helpDetails.instructions.practice_skills.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default NotationTrainerHelp;
