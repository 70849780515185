import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";

const Privacy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Privacy Policy for Chessboard Magic application."
        />
        <meta property="og:title" content="Privacy Policy" />
        <meta
          property="og:description"
          content="Privacy Policy for Chessboard Magic application."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com/privacy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy" />
        <meta
          name="twitter:description"
          content="Privacy Policy for Chessboard Magic application."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title="Privacy Policy"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box id="privacy" sx={{ flex: 1 }}>
          <Box>
            <Typography variant="body1" paragraph>
              <strong>App Name:</strong> Chessboard Magic
              <br />
              <strong>Effective Date:</strong> July 5, 2024
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>1. Introduction</strong>
              <br />
              Welcome to Chessboard Magic. We respect your privacy and are
              committed to protecting your personal data. This Privacy Policy
              explains how we collect, use, and share information when you use
              our app.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>2. Information Collection and Use</strong>
              <br />
              We use Google Analytics to collect data about your usage of
              Chessboard Magic. This data includes:
              <ul>
                <li>
                  Device information (such as device type and operating system)
                </li>
                <li>
                  Usage data (such as pages visited and time spent on the app)
                </li>
                <li>IP address (anonymized)</li>
              </ul>
              The purpose of collecting this data is to understand user behavior
              and improve the functionality of Chessboard Magic.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>No Other Information Collected</strong>
              <br />
              We do not collect any other personal information apart from the
              data specified above that is gathered through Google Analytics.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>3. Google Analytics</strong>
              <br />
              Google Analytics is a web analytics service provided by Google.
              Google uses the collected data to track and monitor the use of our
              service. This data is shared with other Google services. Google
              may use the collected data to contextualize and personalize the
              ads of its own advertising network. You can opt-out of having made
              your activity on the Service available to Google Analytics by
              installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js, and
              dc.js) from sharing information with Google Analytics about visits
              activity.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>4. Data Security</strong>
              <br />
              We strive to use commercially acceptable means to protect your
              personal data. However, remember that no method of transmission
              over the internet, or method of electronic storage is 100% secure
              and reliable, and we cannot guarantee its absolute security.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>5. Changes to This Privacy Policy</strong>
              <br />
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>6. Contact Us</strong>
              <br />
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at: toan.hoang@simplifyinsights.com
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacy;
