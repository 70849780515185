import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { worldChampionshipGames } from "../../../data/historicgames/worldChampionship";
import { useTranslation } from "react-i18next";

import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { useUser } from "../../../context/UserContext";

import { trackEvent } from "../../../config/ga";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";

import HelpModal from "../../../components/HelpModal";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import WhereAreMyPiecesHelp from "../../../help/WhereAreMyPiecesHelp";

function WhereAreMyPieces() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation("Games");
  const { userData, userRef } = useUser();

  const [boardMap, setBoardMap] = useState();
  const [toPlay, setToPlay] = useState();
  const [selectedPiece, setSelectedPiece] = useState(null); // Store dragged piece
  const [fen, setFen] = useState("8/8/8/8/8/8/8/8 w - - 0 1"); // Start with an empty board
  const [selectedGameFen, setSelectedGameFen] = useState();

  const [selectedGame, setSelectedGame] = useState(null);
  const [checkResults, setCheckResults] = useState({});
  const [completed, setCompleted] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const [moves, setMoves] = useState([]); // Store all the moves of the game
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);

  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/wherearemypieces",
      title: "Chessboard Magic - Where Are My Pieces?",
      description:
        "Guess the hidden chess pieces from famous games! Each piece is obscured by a circle, and your goal is to identify them all.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewGame = () => {
    setCompleted(false);
    setCheckResults({});
    setGameStarted(true);

    // GA Tracking
    trackEvent("Games", "Game-Play", "Where Are My Pieces");
    // Internal Tracking
    incrementGameProperty("WhereAreMyPieces-Play");

    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.WhereAreMyPieces) {
        userData.Puzzles.WhereAreMyPieces.Played =
          (userData.Puzzles.WhereAreMyPieces.Played || 0) + 1;
      } else {
        userData.Puzzles.WhereAreMyPieces = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }

    // Randomly select a match
    const randomMatch =
      worldChampionshipGames[
        Math.floor(Math.random() * worldChampionshipGames.length)
      ];

    const randomGame =
      randomMatch.Games[Math.floor(Math.random() * randomMatch.Games.length)];

    setSelectedGame(randomGame);

    const newGame = new Chess();
    newGame.loadPgn(randomGame.Moves);

    const maxMoveCount = Math.min(randomGame.PlyCount / 2, 50);
    const randomMoveCount =
      Math.floor(Math.random() * (maxMoveCount - 20)) + 20;

    const history = newGame.history({ verbose: true });

    newGame.reset();
    for (let i = 0; i < randomMoveCount; i++) {
      newGame.move(history[i]);
    }

    setSelectedGameFen(newGame.fen());
    setBoardMap(newGame.board());
    setToPlay(newGame.turn());
    setMoves(history); // Store the entire move history
    setCurrentMoveIndex(randomMoveCount); // Set the current move index to the last move made
    setFen("8/8/8/8/8/8/8/8 w - - 0 1");
  };

  const handleNextMove = () => {
    if (currentMoveIndex < moves.length - 1) {
      // Create a new Chess instance
      const newGame = new Chess();

      // Load moves sequentially up to currentMoveIndex + 1 to ensure game state is correct
      for (let i = 0; i <= currentMoveIndex + 1; i++) {
        newGame.move(moves[i].san); // Move the piece by using SAN (Standard Algebraic Notation)
      }

      // Update the board with the new FEN and current move index
      setFen(newGame.fen());
      setCurrentMoveIndex(currentMoveIndex + 1);
    }
  };

  const handlePreviousMove = () => {
    if (currentMoveIndex > 0) {
      // Create a new Chess instance
      const newGame = new Chess();

      // Load moves sequentially up to currentMoveIndex - 1
      for (let i = 0; i < currentMoveIndex - 1; i++) {
        newGame.move(moves[i].san); // Move the piece by using SAN (Standard Algebraic Notation)
      }

      // Update the board with the new FEN and current move index
      setFen(newGame.fen());
      setCurrentMoveIndex(currentMoveIndex - 1);
    }
  };

  const handlePieceDrop = (sourceSquare, targetSquare, piece) => {
    // Convert FEN to board array
    const board = fenToBoardArray(fen);

    // Map square notation to array index
    const fileToIndex = (file) => file.charCodeAt(0) - "a".charCodeAt(0);
    const rankToIndex = (rank) => 8 - parseInt(rank);

    const srcRow = rankToIndex(sourceSquare[1]);
    const srcCol = fileToIndex(sourceSquare[0]);

    // If the targetSquare is null or undefined, remove the piece from the source square
    if (!targetSquare) {
      board[srcRow][srcCol] = null;
    } else {
      const tgtRow = rankToIndex(targetSquare[1]);
      const tgtCol = fileToIndex(targetSquare[0]);

      // Move the piece from source to target
      board[tgtRow][tgtCol] = board[srcRow][srcCol];
      board[srcRow][srcCol] = null; // Remove the piece from the source square
    }

    // Convert board array back to FEN
    const newFen = boardArrayToFen(board);

    // Update the FEN and re-render the board
    setFen(newFen);

    console.log("FEN: ", newFen);
    // Return true to indicate a valid drop
    return true;
  };

  const handleSquareClick = (square) => {
    if (!selectedPiece) return; // Don't do anything if no piece is selected

    // Convert FEN to board array
    const board = fenToBoardArray(fen);

    // Map square notation to array index
    const fileToIndex = (file) => file.charCodeAt(0) - "a".charCodeAt(0);
    const rankToIndex = (rank) => 8 - parseInt(rank);

    const row = rankToIndex(square[1]);
    const col = fileToIndex(square[0]);

    // If selected piece is "D" (delete), remove the piece from the board
    if (selectedPiece.type === "D") {
      board[row][col] = null; // Remove the piece from the square
    } else {
      // Otherwise, place the selected piece on the clicked square
      board[row][col] =
        selectedPiece.color === "w"
          ? selectedPiece.type.toUpperCase()
          : selectedPiece.type;
    }

    // Convert board array back to FEN
    const newFen = boardArrayToFen(board);

    // Update the FEN and re-render the board
    setFen(newFen);
    console.log("FEN: ", newFen);
  };

  const generateSquareStyles = (boardMap) => {
    const squareStyles = {};

    // If the game is completed, don't apply any new styles
    if (completed) {
      return squareStyles;
    }

    boardMap?.forEach((row) => {
      row.forEach((square) => {
        if (square) {
          const isCorrect = checkResults[square.square];

          // Apply green color if the piece is correct, else default color
          squareStyles[square.square] = {
            backgroundColor: isCorrect
              ? "green"
              : square.color === "w"
              ? "white"
              : "black",
            borderRadius: "50%",
            boxShadow:
              square.color === "w"
                ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                : "0px 0px 10px 2px rgba(0, 0, 0, 0.7)",
          };
        }
      });
    });

    return squareStyles;
  };

  const fenToBoardArray = (fen) => {
    const [position] = fen.split(" "); // Split FEN into board position and other data
    const rows = position.split("/"); // Split into individual rows
    const board = rows.map((row) => {
      let expandedRow = [];
      for (let char of row) {
        if (isNaN(char)) {
          // It's a piece, push it to the row
          expandedRow.push(char);
        } else {
          // It's a number, push empty squares
          expandedRow = [...expandedRow, ...Array(parseInt(char)).fill(null)];
        }
      }
      return expandedRow;
    });
    return board;
  };

  const boardArrayToFen = (board) => {
    const fenRows = board.map((row) => {
      let emptyCount = 0;
      return (
        row
          .map((cell) => {
            if (cell === null) {
              emptyCount++;
              return "";
            } else {
              if (emptyCount > 0) {
                const result = emptyCount + cell;
                emptyCount = 0;
                return result;
              }
              return cell;
            }
          })
          .join("") + (emptyCount > 0 ? emptyCount : "")
      );
    });
    return fenRows.join("/") + " w - - 0 1"; // You can adjust this based on actual game data
  };

  const handleCheck = () => {
    if (!selectedGame || !fen || !selectedGameFen) return;

    const currentBoard = fenToBoardArray(fen);
    const correctBoard = fenToBoardArray(selectedGameFen);

    const results = {};
    let allCorrect = true;

    // Loop through both boards and compare each square
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const currentPiece = currentBoard[row][col];
        const correctPiece = correctBoard[row][col];

        const square = `${String.fromCharCode(97 + col)}${8 - row}`;

        // If both pieces match, set the result to true, else set it to false
        if (currentPiece === correctPiece) {
          results[square] = true;
        } else {
          results[square] = false;
          allCorrect = false; // If any square doesn't match, the board is not 100% correct
        }
      }
    }

    setCheckResults(results);

    // If all squares are correct, set `completed` to true
    if (allCorrect) {
      setCompleted(true);
      setGameStarted(false);
    }
  };

  const handleGiveUp = () => {
    setCompleted(true);
    handleNextMove();
    handlePreviousMove();
    setGameStarted(false);
  };

  const renderDraggablePieces = () => {
    const pieces = ["p", "r", "n", "b", "q", "k"];
    const selectedPieceSet = pieceSets[userData?.pieceset || "Maestro"]; // Access the selected piece set

    const handleClearBoard = () => {
      setFen("8/8/8/8/8/8/8/8 w - - 0 1"); // Reset the FEN to an empty board
    };

    return (
      <Box display="flex" flexDirection="column">
        {/* White Pieces */}
        <Box>
          <Box display="flex" flexDirection="row">
            {pieces.map((piece) => (
              <div
                key={`w${piece}`}
                onClick={() => setSelectedPiece({ type: piece, color: "w" })}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  margin: "5px",
                  paddingTop: "3px",
                  textAlign: "center",
                  lineHeight: "40px",
                  backgroundColor:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "w"
                      ? "lightgreen"
                      : "transparent", // Remove background color for unselected pieces
                  boxShadow:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "w"
                      ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                      : "none", // Remove shadow for unselected pieces
                }}
              >
                {selectedPieceSet[`w${piece.toUpperCase()}`]({
                  squareWidth: 40, // Set the size of the piece
                })}
              </div>
            ))}
          </Box>
        </Box>

        {/* Black Pieces */}
        <Box>
          <Box display="flex" flexDirection="row">
            {pieces.map((piece) => (
              <div
                key={`b${piece}`}
                onClick={() => setSelectedPiece({ type: piece, color: "b" })}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  margin: "5px",
                  paddingTop: "3px",
                  textAlign: "center",
                  lineHeight: "40px",
                  backgroundColor:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "b"
                      ? "lightgreen"
                      : "transparent", // Remove background color for unselected pieces
                  boxShadow:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "b"
                      ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                      : "none", // Remove shadow for unselected pieces
                }}
              >
                {selectedPieceSet[`b${piece.toUpperCase()}`]({
                  squareWidth: 40, // Set the size of the piece
                })}
              </div>
            ))}
          </Box>
        </Box>

        {/* Delete Option */}
        <Box>
          <Box display="flex" flexDirection="row">
            <Button
              variant="contained"
              onClick={() => setSelectedPiece({ type: "D" })}
              style={{
                margin: "5px",
                textAlign: "center",
                backgroundColor:
                  selectedPiece?.type === "D" ? "lightcoral" : "transparent", // Highlight when selected
              }}
              startIcon={<DeleteIcon style={{ color: colors.black[900] }} />}
            >
              {t("WhereAreMyPieces.buttons.removePieces")}
            </Button>

            <Button
              variant="contained"
              onClick={handleClearBoard}
              style={{
                margin: "5px",
                textAlign: "center",
              }}
              startIcon={
                <RestartAltIcon style={{ color: colors.black[900] }} />
              }
            >
              {t("WhereAreMyPieces.buttons.clearBoard")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <ContentHeader
        title={t("WhereAreMyPieces.header.title")}
        subtitle={t("WhereAreMyPieces.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Where Are My Pieces?</title>
        <meta
          name="description"
          content="Guess the hidden chess pieces from famous games! Each piece is obscured by a circle, and your goal is to identify them all."
        />
        <meta property="og:title" content="Where Are My Pieces?" />
        <meta
          property="og:description"
          content="Guess the hidden chess pieces from famous games! Each piece is obscured by a circle, and your goal is to identify them all."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/wherearemypieces.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/wherearemypieces`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Where Are My Pieces?" />
        <meta
          name="twitter:description"
          content="Guess the hidden chess pieces from famous games! Each piece is obscured by a circle, and your goal is to identify them all."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/wherearemypieces.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.black[900] }}
            />
          }
        >
          {t("WhereAreMyPieces.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleCheck}
          style={{ marginRight: 10 }}
          startIcon={<TaskAltIcon style={{ color: colors.black[900] }} />}
        >
          {t("WhereAreMyPieces.buttons.check")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
        >
          {t("WhereAreMyPieces.buttons.help")}
        </Button>
        <Button
          variant="contained"
          onClick={handleGiveUp}
          startIcon={
            <HighlightOffRoundedIcon style={{ color: colors.red[500] }} />
          }
          disabled={!gameStarted}
        >
          {t("WhereAreMyPieces.buttons.quit")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("WhereAreMyPieces.helpDetails.title")}
          content={<WhereAreMyPiecesHelp />}
        ></HelpModal>
      </Box>
      <Typography sx={{ pt: 1, pb: 1 }}>
        {toPlay === "b" ? "Black" : "White"} to Play
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column", // Stack vertically on small screens
            sm: "column", // Stack vertically on medium screens
            md: "row", // Arrange side-by-side on larger screens
          },
          alignItems: "flex-start", // Align items at the start of the flex container
          gap: 2, // Add space between Chessboard and the side column
        }}
      >
        <Box sx={{ minWidth: "450px" }}>
          <Chessboard
            position={fen}
            onPieceDrop={handlePieceDrop}
            onSquareClick={handleSquareClick}
            customSquareStyles={generateSquareStyles(boardMap)}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Maestro"]}
            areArrowsAllowed={false}
            arePiecesDraggable={true}
          />
          {completed && (
            <>
              <Box sx={{ pt: 1, pb: 1 }}>
                <Typography>
                  {selectedGame?.White} vs {selectedGame?.Black} (
                  {selectedGame?.Date})
                </Typography>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Button
                  variant="contained"
                  onClick={handlePreviousMove}
                  disabled={currentMoveIndex === 0}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  style={{ marginRight: 10 }}
                >
                  <KeyboardDoubleArrowLeftIcon
                    style={{ color: colors.black[900] }}
                  />
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNextMove}
                  disabled={currentMoveIndex === moves.length - 1}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <KeyboardDoubleArrowRightIcon
                    style={{ color: colors.black[900] }}
                  />
                </Button>
              </Box>
            </>
          )}
        </Box>

        {/* Draggable pieces */}
        {renderDraggablePieces()}
      </Box>
    </Box>
  );
}

export default WhereAreMyPieces;
