import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { courses } from "../../../data/courses/courses";
import { processPGN } from "../../../utils/pgnUtils";
import GameViewer from "../../../components/GameViewer";
import PositionViewer from "../../../components/PositionViewer";

function ChessOpenings() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [lines, setLines] = useState([]);
  const [selectedLineIndex, setSelectedLineIndex] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    const course = courses.find(
      (course) => course.ID === "TOAN.HOANG.CAROKANN"
    );
    if (course) {
      setSelectedCourse(course);

      let lineCounter = 0;
      const flattenedLines = course.Chapters.flatMap((chapter) =>
        chapter.ChapterLines.map((line) => {
          let cleanedPGN = null;
          let squareColours = null;
          let arrowColours = null;
          let comments = null;

          if (line.PGN) {
            const processedPGN = processPGN(line.PGN);
            cleanedPGN = processedPGN.cleanedPGN;
            squareColours = processedPGN.squareColours;
            arrowColours = processedPGN.arrowColours;
            comments = processedPGN.comments;
          }

          return {
            uniqueNumber: lineCounter++,
            chapterName: chapter.ChapterName,
            chapterSample: chapter.ChapterSample,
            line: line,
            cleanedPGN: cleanedPGN,
            squareColours: squareColours,
            arrowColours: arrowColours,
            comments: comments,
            FEN: line.FEN,
          };
        })
      );

      setLines(flattenedLines);
    }
  }, []);

  const handleCourseHomeClick = () => {
    setSelectedLineIndex(-1);
  };

  const handleLineClick = (index, chapterName) => {
    setSelectedLineIndex(index);
    setOpenAccordion(chapterName);
  };

  const handlePreviousLineClick = () => {
    if (selectedLineIndex > 0) {
      const newIndex = selectedLineIndex - 1;
      setSelectedLineIndex(newIndex);
      setOpenAccordion(lines[newIndex].chapterName);
    } else if (selectedLineIndex === 0) {
      setSelectedLineIndex(null);
    }
  };

  const handleNextLineClick = () => {
    if (selectedLineIndex === null) {
      setSelectedLineIndex(0);
      setOpenAccordion(lines[0].chapterName);
    } else if (selectedLineIndex < lines.length - 1) {
      const newIndex = selectedLineIndex + 1;
      setSelectedLineIndex(newIndex);
      setOpenAccordion(lines[newIndex].chapterName);
    }
  };

  const selectedLine = lines[selectedLineIndex];

  const chapters = lines.reduce((acc, line) => {
    const chapterIndex = acc.findIndex(
      (chapter) => chapter.chapterName === line.chapterName
    );
    if (chapterIndex === -1) {
      acc.push({
        chapterName: line.chapterName,
        chapterSample: line.chapterSample,
        lines: [line],
      });
    } else {
      acc[chapterIndex].lines.push(line);
    }
    return acc;
  }, []);

  return (
    <Box>
      <ContentHeader
        title={selectedCourse?.Name}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />

      <Box display="flex">
        <Box
          sx={{
            minWidth: "300px",
            width: "300px",
            p: 0,
            mr: 1,
            pb: 2,
          }}
        >
          <Box display="flex-start" mb={2}>
            <Button
              variant="contained"
              onClick={handleCourseHomeClick}
              sx={{ padding: 0, minWidth: 40, minHeight: 40, mr: 1 }}
              disabled={selectedLineIndex === null}
            >
              <HomeRoundedIcon />
            </Button>
            <Button
              variant="contained"
              onClick={handlePreviousLineClick}
              sx={{ padding: 0, minWidth: 40, minHeight: 40, mr: 1 }}
              disabled={selectedLineIndex === -1 || selectedLineIndex === null}
            >
              <KeyboardDoubleArrowLeftIcon />
            </Button>
            <Button
              variant="contained"
              onClick={handleNextLineClick}
              sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
              disabled={selectedLineIndex === lines.length - 1}
            >
              <KeyboardDoubleArrowRightIcon />
            </Button>
          </Box>

          {chapters.map((chapter, chapterIndex) => (
            <Accordion
              key={`chapter-${chapterIndex}`}
              expanded={openAccordion === chapter.chapterName}
              onChange={() =>
                setOpenAccordion(
                  openAccordion === chapter.chapterName
                    ? false
                    : chapter.chapterName
                )
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`chapter-${chapterIndex}-content`}
                id={`chapter-${chapterIndex}-header`}
              >
                <Box>
                  <Typography>{chapter.chapterName}</Typography>
                  {chapter.chapterSample && (
                    <Typography variant="body2" color="textSecondary">
                      {chapter.chapterSample}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 1 }}>
                <List sx={{ p: 0 }}>
                  {chapter.lines.map((line) => (
                    <React.Fragment key={`line-${line.uniqueNumber}`}>
                      <Divider
                        sx={{
                          my: 1,
                          ml: 1,
                          mr: 1,
                          borderColor: colors.grey[200],
                        }}
                      />
                      <ListItem
                        sx={{ p: 0, ml: 2 }}
                        onClick={() =>
                          handleLineClick(
                            line.uniqueNumber,
                            chapter.chapterName
                          )
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontWeight:
                                  selectedLineIndex === line.uniqueNumber
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {line.line.LineName}
                            </Typography>
                          }
                          secondary={line.line.LineSample}
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 1,
          }}
        >
          {selectedLine && (
            <div>
              <Typography>{selectedLine.chapterName}</Typography>
              <Typography variant="h5" sx={{ pb: 2 }}>
                {selectedLine.line.LineName}
              </Typography>

              {selectedLine.line.LineDescription.split("\n").map(
                (line, index) => (
                  <Typography sx={{ pb: 1 }}>{line}</Typography>
                )
              )}
            </div>
          )}
          {selectedLine && selectedLine.cleanedPGN && (
            <GameViewer
              BoardOrientation={selectedCourse.Orientation}
              Moves={selectedLine.cleanedPGN}
              SquareHighlights={selectedLine.squareColours}
              ArrowsHighlights={selectedLine.arrowColours}
              Comments={selectedLine.comments}
            />
          )}
          {selectedLine && selectedLine.FEN && (
            <PositionViewer
              BoardOrientation={selectedCourse.Orientation}
              FEN={selectedLine.FEN}
              ShowFen={true}
              ShowOrientation={true}
            />
          )}
          {!selectedLine && (
            <div>
              {selectedCourse ? (
                <>
                  {selectedCourse.FullDescription.split("\n").map(
                    (line, index) => (
                      <Typography sx={{ pb: 1 }}>{line}</Typography>
                    )
                  )}
                </>
              ) : (
                <p>Course not found.</p>
              )}
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ChessOpenings;
