import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const WordsearchHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <Box>
      <Typography>{t("Wordsearch.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "Wordsearch.helpDetails.instructions.find_words.primary"
            )}
            secondary={t(
              "Wordsearch.helpDetails.instructions.find_words.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Wordsearch.helpDetails.instructions.spot_words.primary"
            )}
            secondary={t(
              "Wordsearch.helpDetails.instructions.spot_words.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Wordsearch.helpDetails.instructions.highlight_words.primary"
            )}
            secondary={t(
              "Wordsearch.helpDetails.instructions.highlight_words.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Wordsearch.helpDetails.instructions.complete_challenge.primary"
            )}
            secondary={t(
              "Wordsearch.helpDetails.instructions.complete_challenge.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default WordsearchHelp;
